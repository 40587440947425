
import React, { useState, useEffect } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import Button from '@material-ui/core/Button';

function ModalAssicurazioni(props) {
    const [nome, setNome] = useState("")
    const [moltiplicatore, setMoltiplicatore] = useState(1)
    const [note, setNote] = useState("")
    const [id, setId] = useState(null)

    function chiudiModal(){
      props.closeModal(false)
    }
    function saveNuovaAssicurazione(e){
        e.preventDefault()
        let data = {
            nome:nome,
            moltiplicatore:moltiplicatore,
            note:note,
            id:id
        }
        props.saveNewAssicurazione(data)
    }

    useEffect(() => {
        if(props.assicurazione){
            setNome(props.assicurazione.nome)
            setMoltiplicatore(props.assicurazione.moltiplicatore)
            setNote(props.assicurazione.note)
            setId(props.assicurazione.id)
        }else{
            setNome("")
            setMoltiplicatore(1)
            setNote("")
            setId(null)
        }
    }, [props.assicurazione])

    return (
          <Modal isOpen={props.isOpenModal} className="modal-lg">
            <form onSubmit={(e)=>saveNuovaAssicurazione(e)}>
              <ModalBody>
                <div>
                  <ModalHeader>
                    <div style={{fontWeight:"bold",fontSize:"20px"}}>Assicurazione</div>
                  </ModalHeader>
                </div>
                <div>
                    <div className="form-group-sm">
                      <label>Nome</label>
                      <input type="text" required className="form-control" value={nome} onChange={(e)=>{setNome(e.target.value)}}/>
                    </div>
                    <div className="form-group-sm">
                      <label>Moltiplicatore</label>
                      <input type="number" className="form-control" name="moltiplicatore" value={moltiplicatore} onChange={(e)=>{setMoltiplicatore(e.target.value)}}/>
                    </div>
                    <div className="form-group-sm">
                      <label>Note</label>
                      <input type="text" className="form-control" name="note" value={note} onChange={(e)=>{setNote(e.target.value)}}/>
                    </div>
                    
                </div>
                <ModalFooter>
                  <Button size="small" type="submit" variant="contained" color="primary"> <i className="far fa-save"></i> Salva</Button>
                  <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{e.preventDefault();chiudiModal()}}> <i className="fas fa-times"></i> Chiudi</Button>
                </ModalFooter>   
              </ModalBody>
              
            </form>
        </Modal>
    );
}

export default ModalAssicurazioni;