import React,{ useState,useContext,useEffect } from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import { SettingContext } from '../../contexts/SettingContext';
import { NotePraticaContext } from '../../contexts/NotePraticaContext';
import { AccountContext } from '../../contexts/AccountContext';
import DefaultSpinner from '../DefaultSpinner';
import { Button, FormControl, Grid, InputLabel, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import moment from 'moment'
import { MenuItem } from 'react-bootstrap-typeahead';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();
//TODO: non aggiorna tutti i dati della pratica, verificare

function Stati(props) {
    const notifications = useContext(NotificationContext);
    const accounts = useContext(AccountContext)
    const settings = useContext(SettingContext)
    const notapraticas = useContext(NotePraticaContext)
    const [saved] = useState(!props.pratica.attiva)
    const [prodotto, setProdotto] = useState(null)
    const [nuovostatoSelected, setNuovostatoSelected] = useState(null)
    const [nuovanota, setNuovanota] = useState("")
    const [loading, setLoading] = useState(true)
    const [dataApprovazione, setDataApprovazione] = useState(null)

    function checkIfStatoDisabled(stato){
        let element = JSON.parse(notapraticas.note).filter(x=>x.stato_id === Number(stato.id))
        if(element.length > 0 && !stato.ripetibile){
            return true
        }else{
            return false
        }
    }

    function applicaStato(e){
        let oldStatonota=prodotto.stati_pratica.filter(x=>x.id === notapraticas.old_stato)
        let newStatonota=prodotto.stati_pratica.filter(x=>x.id === nuovostatoSelected)
        
        if(!newStatonota[0].ripetibile){
            let elements = JSON.parse(notapraticas.note).filter(x=>x.stato_id === Number(newStatonota[0].id))
            if(elements.length>0){
                notifications.NotifyAlarm(true,"Impossibile aggiungere lo stato")
                return
            }
        }
        let messaggio=""
        let stato=null
        let old_stato = null
        let data_approvazione = null
        if(newStatonota.length>0){
            messaggio="[Stati] IMPOSTATO STATO <b>"+newStatonota[0].name+"</b>"
            stato=newStatonota[0]
            if(stato.approvata){
                data_approvazione=dataApprovazione
            }
        }
        if(oldStatonota.length>0 && newStatonota.length>0){
            messaggio = "[Stati] DA <b>"+oldStatonota[0].name+"</b> A <b>"+newStatonota[0].name+"</b>"
            old_stato = oldStatonota[0]
        }
        let nota={
            name:accounts.account.nome +" "+accounts.account.cognome,
            id_utente:accounts.account.id,
            stato:stato ? stato.name : "",
            stato_id:stato ? stato.id : "",
            nota:messaggio,
            timestamp:moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
        }
        let newnote = JSON.parse(notapraticas.note)
        newnote.push(nota)
        let data={
            type:"stato",
            stato:stato,
            data_approvazione:data_approvazione,
            id:props.pratica.id,
            newnote:JSON.stringify(newnote)
        }
        axios.put('/api/1.1/pratica/stato',data,{headers:header}).then((res)=>{
            setDataApprovazione(null)
            notapraticas.setOldStato(stato.id)
            props.returnNote(JSON.stringify(newnote))
            notifications.NotifyAlarm(false,"Nota aggiunta")
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Errore")
        })
    }
    function aggiungiNota(e){
        e.preventDefault()
        notapraticas.aggiungiNota(nuovanota,true)
        setNuovanota("")
    }

    useEffect(() => {
        if(props.pratica){
            var prd=settings.prodotti.list.filter(x=>x.id===props.pratica.categoria_id)
            setProdotto(prd[0])
            setNuovostatoSelected(props.pratica.stato_attuale_id)
            setLoading(false)
        }
    }, [props.pratica])

    return (
        <div>
            {!loading ? 
            <>
            {!saved ?
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <form onSubmit={(e)=>aggiungiNota(e)}>
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{padding:"5px"}}>
                            <TextField id="outlined-nota" label="Nuova Nota" variant="outlined" 
                                size="small" value={nuovanota} name="nuovanota" type="textarea" 
                                multiline rows={4} fullWidth={true}
                                required disabled={saved}
                                onChange={(e)=>{setNuovanota(e.target.value)}}
                                />
                            <div style={{marginTop:"10px"}}>
                                <Button size="small"
                                disabled={saved}
                                type="submit" variant="contained" color="primary" type="submit"><i className="fas fa-plus"></i> Aggiungi nota</Button>
                            </div>
                        </Grid>
                    </form>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <form onSubmit={(e)=>e.preventDefault()}> 
                        <Grid container>
                            <Grid item lg={10} md={10} sm={10} xs={10} style={{padding:"5px"}}>
                                <FormControl style={{width:"100%"}}>
                                    <InputLabel id="outline-9">Stato</InputLabel>
                                    <Select
                                        required disabled={saved}
                                        labelId="outline-9"
                                        value={nuovostatoSelected}
                                        onChange={(e)=>setNuovostatoSelected(e.target.value)}
                                        label="Stato"
                                        >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                            {prodotto && notapraticas.note && prodotto.stati_pratica.map((stato,i)=>{
                                                return(
                                                    <MenuItem key={i} value={stato.id} disabled={checkIfStatoDisabled(stato)}>{stato.name}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                                {nuovostatoSelected && prodotto.stati_pratica.filter(x=>x.id === nuovostatoSelected)[0].approvata && !props.pratica.data_approvazione ?
                                    <TextField required label="Data di approvazione" type="datetime-local" value={dataApprovazione} style={{margin:"5px 0"}}
                                        InputLabelProps={{
                                        shrink: true
                                        }}
                                        fullWidth={true}
                                        onChange={(e)=>{setDataApprovazione(e.target.value)}}
                                    />
                                :null}
                            </Grid>
                            <Grid item lg={2} md={2} sm={2} xs={2} style={{padding:"5px"}}>
                                <Button size="small" type="submit" variant="contained" color="primary" disabled={notapraticas.old_stato===nuovostatoSelected} onClick={(e)=>applicaStato(e)}><i className="fas fa-plus"></i> Applica</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            :null}
            <div>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold"}}>Nota</TableCell>
                                <TableCell style={{fontWeight:"bold",maxWidth:"10%"}}>Stato</TableCell>
                                <TableCell style={{fontWeight:"bold",maxWidth:"10%"}}>Data</TableCell>
                                <TableCell style={{fontWeight:"bold",maxWidth:"10%"}}>Utente</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notapraticas.note && JSON.parse(notapraticas.note).sort(function(a,b){
                                    return moment(b.timestamp, "DD/MM/YYYY HH:mm:ss").toDate() - moment(a.timestamp, "DD/MM/YYYY HH:mm:ss").toDate();
                                }).map((elm,i)=>{
                                return(
                                    <TableRow key={i}>
                                        <TableCell><div dangerouslySetInnerHTML={{__html:elm.nota}} /> </TableCell>
                                        <TableCell>{elm.stato ? elm.stato : "-"}</TableCell>
                                        <TableCell>{elm.timestamp}</TableCell>
                                        <TableCell component="th" scope="row">{elm.name}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            </>
        :    
            <div style={{textAlign:"center"}}>
                <DefaultSpinner></DefaultSpinner>
            </div>}
        </div>
        
    );
}

export default Stati;