import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import PraticaItem from '../pratica/PraticaItem'
import DefaultSpinner from '../DefaultSpinner';
import Button from '@material-ui/core/Button';
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import ModalFiltriArchivio from '../modali/ModalFiltriArchivio';
import ModalArchivioRete from '../modali/ModalArchivioRete';
import ModalRicercaRapida from '../modali/ModalRicercaRapida';
import { SettingContext } from '../../contexts/SettingContext';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function WorklistPratica(props) {
    const settings = useContext(SettingContext)
    const [results, setResults] = useState([])
    const [users, setUsers] = useState([])
    const [searching, setSearching] = useState(true)
    const [loading, setLoading] = useState(true)
    const [praticheSelected, setPraticheSelected] = useState({list:[]})
    const [filtriArchivio, setFiltriArchivio] = useState({item:null})
    const [ricercaRapida, setRicercaRapida] = useState({item:null})
    const [archivioRete, setArchivioRete] = useState({item:null})
    const [isOpenModalFiltriArchivio, setIsOpenModalFiltriArchivio] = useState(false)
    const [isOpenModalArchivioRete, setIsOpenModalArchivioRete] = useState(false)
    const [isOpenModalRicercaRapida, setIsOpenModalRicercaRapida] = useState(false)
    const [datainizio, setDatainizio] = useState("");
    const [datafine, setDatafine] = useState("");
       
    function changeFiltriArchivioECerca(result){
        setFiltriArchivio({item:result})
        setTimeout(() => {
            send()
        }, 1000);
    }
    function changeFiltriArchivio(result){
        setFiltriArchivio({item:result})
    }
    function closeFiltriArchivio(value){
        setIsOpenModalFiltriArchivio(!value)
    }
    function changeArchivioReteECerca(result){
        setArchivioRete({item:result})
        setTimeout(() => {
            send()
        }, 1000);
    }
    function changeArchivioRete(result){
        setArchivioRete({item:result})
    }
    function closeArchivioRete(value){
        setIsOpenModalArchivioRete(!value)
    }
    function changeRicercaRapidaECerca(result){
        setRicercaRapida({item:result})
        setTimeout(() => {
            send()
        }, 1000);
    }
    function changeRicercaRapida(result){
        setRicercaRapida({item:result})
    }
    function closeRicercaRapida(value){
        setIsOpenModalRicercaRapida(!value)
    }

    useEffect(() => {
        axios.all([
            axios.get('/api/1.1/user', {headers:header})
        ]).then(axios.spread((resutenti) => {
            setUsers(resutenti.data.data);           
            // axios.get('/api/1.1/pratica',{headers:header}).then((res)=>{
            //     setResults(res.data.data)
            // }).catch(error=>{
            //     console.log(error)
            // })
            setSearching(false)
            setLoading(false)
        }))
    }, [])
  
    function checkedItem(id){
        let list=praticheSelected.list
        var index = list.indexOf(id)
        if(index>=0){
            list.splice(index, 1);
        }else{
            list.push(id)
        }
        setPraticheSelected({list:list})
    }
    function send(){
        setSearching(true)
        let data={tiporicerca:"pratichewl",
                    filters:{
                        dataInizio:datainizio,
                        dataFine:datafine,
                        ricercaRapida:ricercaRapida.item,
                        archivioRete:archivioRete.item,
                        filtriArchivio:filtriArchivio.item
                    }}
        axios.post('/api/1.1/pratica',data,{headers:header}).then((res)=>{
            setResults(res.data.data)
            setSearching(false)
        }).catch(error=>{
            console.log(error)
        })
    }

    return (
        !loading ? 
        <Grid container style={{paddingRight:"15px"}}>
            <Grid item lg={12} md={12} sm={12} xs={12}><h4>Worklist Pratiche</h4></Grid>
            <Grid item lg={3} md={3} sm={12} xs={12} style={{marginBottom:"15px"}}>
                <div>
                    <TextField required label="Da" type="date" value={datainizio} style={{marginBottom:"10px"}}
                        InputLabelProps={{
                        shrink: true
                        }}
                        fullWidth={true}
                        onChange={(e)=>{setDatainizio(e.target.value)}}
                    />
                    <TextField required label="A" type="date" value={datafine} style={{marginBottom:"10px"}}
                        InputLabelProps={{
                        shrink: true
                        }}
                        fullWidth={true}
                        onChange={(e)=>{setDatafine(e.target.value)}}
                    />
                </div>
                <div style={{marginBottom:"10px"}}>
                    <Button style={{width:"100%"}} size="small" variant={isOpenModalArchivioRete ? "contained":"outlined"} color="primary" onClick={(e)=>closeArchivioRete(false)}><i className="fas fa-search"></i> Archivio Rete</Button>
                </div>
                <div style={{marginBottom:"10px"}}>
                    <Button style={{width:"100%"}} size="small" variant={isOpenModalFiltriArchivio ? "contained":"outlined"} color="primary" onClick={(e)=>closeFiltriArchivio(false)}><i className="fas fa-search"></i> Filtri Archivio</Button>
                </div>
                <div style={{marginBottom:"10px"}}>
                    <Button style={{width:"100%"}} size="small" variant={isOpenModalRicercaRapida ? "contained":"outlined"}  color="primary" onClick={(e)=>closeRicercaRapida(false)}><i className="fas fa-search"></i> Ricerca Rapida</Button>
                </div>
                <Button style={{width:"100%"}} size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{send()}}><i className="fas fa-search"></i> Cerca</Button>
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12} style={{padding:"5px"}}>
                {searching ? <DefaultSpinner></DefaultSpinner> : 
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>                              
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Id</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Operazione</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Riferimento</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Stato pratica</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Data caricata</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Data approvata</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Data liquidata</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {results && results.map((res,i)=>{
                            return(
                                <PraticaItem key={i} pratica={res} showCheck={true} checkedItem={checkedItem} isPratica={true} shownotebutton={false}/>
                            )
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
                }
            </Grid>
            <ModalArchivioRete setFiltri={changeArchivioRete} filtri={archivioRete.item} 
            setFiltriECerca={changeArchivioReteECerca}
            user={users} isOpenModal={isOpenModalArchivioRete} closeModal={closeArchivioRete} />
            <ModalFiltriArchivio 
                setFiltriECerca={changeFiltriArchivioECerca}
                setFiltri={changeFiltriArchivio} 
                filtri={filtriArchivio.item} 
                isOpenModal={isOpenModalFiltriArchivio} 
                closeModal={closeFiltriArchivio} />
            <ModalRicercaRapida setFiltri={changeRicercaRapida} 
            setFiltriECerca={changeRicercaRapidaECerca}
            filtri={ricercaRapida.item} isOpenModal={isOpenModalRicercaRapida} closeModal={closeRicercaRapida} />
        </Grid>
        : <DefaultSpinner></DefaultSpinner>
    );
}

export default WorklistPratica;