export function sortByName(a, b){
    if(a.name < b.name) { return -1; }
    if(a.name > b.name) { return 1; }
    return 0
}
export function sortByNome(a, b){
    if(a.nome < b.nome) { return -1; }
    if(a.nome > b.nome) { return 1; }
    return 0
}
export function sortByImportoNetto(a, b){
    return b.importoNetto - a.importoNetto
}