import React,{useState} from 'react';
import {HorizontalBar,Doughnut,Line} from 'react-chartjs-2';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import Button from '@material-ui/core/Button';
import moment from 'moment'
import { Grid } from '@material-ui/core';

let Auth=new AuthService();
var header=Auth.getHeadersJWT();
const data3={
  labels: [],
  datasets: [
    {
      label: 'My First dataset',
      fill: false,
      lineTension: 0.1,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(75,192,192,1)',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40]
    }
  ]
};

function Statistiche(props) {
  const [dataDoughnut, setDataDoughnut] = useState({})
  const [dataHorizontalBar, setDataHorizontalBar] = useState({})
  var date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
  const [datainizio, setDatainizio] = useState(date);
  const [datafine, setDatafine] = useState(date);
  const [showChart, setShowChart] = useState(false)


  function setChartDoughnut(intestazione,dato){
    setDataDoughnut({
      labels: intestazione,
      datasets: [{
        data: dato,
        backgroundColor: ['#FF6384','#36A2EB','#FFCE56'],
        hoverBackgroundColor: [ '#FF6384','#36A2EB','#FFCE56']
      }]
    })
  }

  function setChartHorizontalBar(period,intestazione,dato){
    setDataHorizontalBar(
      {
        labels: period,
        datasets: [
          {
            label: 'prova',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            hoverBorderColor: 'rgba(255,99,132,1)',
            data: dato
          }
        ]
      }
    
    )
  }

  function cercaPeriodo(e){
    e.preventDefault();
    let a = datafine.split("-")
    let dataf = new Date(Number(a[0]), Number(a[1]) + 1, 0);
    // console.log("/api/1.1/statistiche?start=" + datainizio + "-01&end="+dataf.toISOString().split('T')[0])
    //TODO: errore su ultimo giorno
    axios.all([
      axios.get("/api/1.1/statistiche?start=" + datainizio + "-01&end="+dataf.toISOString().split('T')[0] ,{headers:header})
    ]).then(axios.spread((resstatistiche) => {
      var lis = resstatistiche.data.data;
      console.log(lis);
      let formattedDato={
        intestazione:[],
        dato:[],
        period:[]
      }
      for (let index = 0; index < lis.length; index++) {
        const element = lis[index];
        formattedDato["intestazione"].push(element.assegnataa)
        formattedDato["dato"].push(element.dcount)
        formattedDato["period"].push(moment(new Date(element.month)).format("YYYY-MM-DD"))
        //aggregare in base ad agenzia
        //se già stata inserita fare push, altrimenti inserire nuovo
      }

      setShowChart(true);
      setChartHorizontalBar(formattedDato["period"],formattedDato["intestazione"],formattedDato["dato"])
      setChartDoughnut(formattedDato["intestazione"],formattedDato["dato"]);
      }))
  }

    return (
        <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <form onSubmit={(e)=>{cercaPeriodo(e)}}>
                <Grid container>
                  <Grid item lg={5} md={5} sm={5} xs={12} style={{padding:"5px"}}>
                      <span>Data inizio</span> <br/>
                      <input type="month" required className="form-control input-sm" value={datainizio} onChange={(e)=>{setDatainizio(e.target.value)}} />
                  </Grid>
                  <Grid item lg={5} md={5} sm={5} xs={12} style={{padding:"5px"}}>
                      <span>Data fine</span> <br/>
                      <input type="month" required className="form-control input-sm" value={datafine} onChange={(e)=>{setDatafine(e.target.value)}}/>
                  </Grid>
                  <Grid item lg={2} md={2} sm={2} xs={12} style={{padding:"5px"}}>
                    <span></span>
                    <br/>
                    <Button size="small" type="submit" variant="contained" color="primary"><i className="fas fa-search"></i>Cerca</Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          {showChart? <>
            <div className="col-6">
                <HorizontalBar data={dataHorizontalBar} />
            </div>
            <div className="col-6">
                <Doughnut data={dataDoughnut}/>
            </div>
            <div className="col-12" style={{height:"200px",marginTop:"15px"}}>
                <Line height={5} options={{maintainAspectRatio: false}}  data={data3} ></Line>
            </div>
          </>:null}

        </Grid>
    );
}

export default Statistiche;