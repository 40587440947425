import React,{useEffect, useState} from 'react';
import axios from 'axios';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import AuthService from '../utente/AuthService'
import { Button, FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import moment from 'moment'
import { MenuItem } from 'react-bootstrap-typeahead';

var Auth = new AuthService()
let header=Auth.getHeadersJWT();

function ModalInserimentoUtente(props) {
    const [user, setUser] = useState(props.utente)

    useEffect(() => {
        setUser(props.utente)
    }, [props.utente])

    function salvaModificaNuovoUtente(e){
        e.preventDefault()
        let data={
            tipologia:"changeuser",
            user:user
        }
        if(user.id){
            axios.put("/api/1.1/user",data,{headers:header}).then(res=>{
                props.returnUser(res.data.data)
            })
        }else{
            axios.post("/api/1.1/user",data,{headers:header}).then(res=>{
                props.returnUser(res.data.data)
            })
        }
    }
    function onChangeUser(e){
        e.preventDefault()
        setUser({...user , [e.target.name]:e.target.value})
    }
    function onChangeAgenzia(e){
        e.preventDefault()
        let valore = props.listaAgenzie.list.find(x=>x.id_agenzia === Number(e.target.value))
        if(valore){
            setUser({...user , id_agenzia:valore.id_agenzia,descrizione:valore.descrizione})
        }
    }
    

    function chiudiModal(e){
        e.preventDefault()
        props.setIsOpenModal(false)
    }
    
    return (
        <Modal isOpen={props.isOpenModal && user} className="modal-lg">
            <form onSubmit={(e)=>salvaModificaNuovoUtente(e)}>
              <ModalBody>
                <div>
                  <ModalHeader>
                    {user.id ? <div style={{fontWeight:"bold",fontSize:"20px"}}>Utente <small>({user.nome} {user.cognome})</small></div>:"Nuovo utente"}
                  </ModalHeader>
                </div>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Nome" required name="nome" value={user.nome} onChange={(e)=>onChangeUser(e)} type="text" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Cognome" required name="cognome" value={user.cognome} onChange={(e)=>onChangeUser(e)} type="text" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Email" required name="email" value={user.email} onChange={(e)=>onChangeUser(e)} type="email" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Password" required name="password" value={user.password} onChange={(e)=>onChangeUser(e)} type="password" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outlined-tipiimpiego">Ruolo</InputLabel>
                            <Select
                                required
                                style={{width:"100%"}}
                                labelId="outlined-Ruolo"
                                name="role" 
                                value={user.role} 
                                onChange={(e)=>onChangeUser(e)}
                                label="Ruolo"
                                >
                                <MenuItem value=""></MenuItem>
                                <MenuItem value="admin" hidden={!Auth.hasPermission(["admin"])}>Admin</MenuItem>
                                <MenuItem value="agenzia" hidden={!Auth.hasPermission(["admin"])}>Agenzia</MenuItem>
                                <MenuItem value="collaboratore" hidden={!Auth.hasPermission(["admin","agenzia"])}>Collaboratore</MenuItem>
                                <MenuItem value="operatore" hidden={!Auth.hasPermission(["admin","agenzia"])}>Operatore</MenuItem>
                                <MenuItem value="telemarketing" hidden={!Auth.hasPermission(["admin","agenzia"])}>Telemarketing</MenuItem>
                            </Select>
                        </FormControl> 
                    </Grid>
                    {user.role === "agenzia" ? 
                    <>
                        <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                            <TextField style={{width:"100%"}} required label="Descrizione" name="descrizione" value={user.descrizione} onChange={(e)=>onChangeUser(e)} type="text" />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                            <TextField style={{width:"100%"}} required label="Percentuale Agenzia" name="percentuale_agenzia" value={user.percentuale_agenzia} onChange={(e)=>onChangeUser(e)} type="number" />
                        </Grid>
                    </>
                    :user.role === "collaboratore" || user.role === "operatore" || user.role === "telemarketing" ?
                        <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                            <FormControl style={{width:"100%"}}>
                                <InputLabel id="outlined-agenzia">Agenzia</InputLabel>
                                <Select
                                    required
                                    style={{width:"100%"}}
                                    labelId="outlined-agenzia"
                                    name="descrizione" value={user.id_agenzia} onChange={(e)=>onChangeAgenzia(e)}
                                    label="Agenzia"
                                    >
                                    <MenuItem value=""></MenuItem>
                                    {props.listaAgenzie.list.map((la,i)=>{
                                        return(
                                            <MenuItem key={i} value={la.id_agenzia}>{la.descrizione}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl> 
                        </Grid>
                    :null}
                

                
                {user.role === "collaboratore" || user.role === "operatore" || user.role === "telemarketing" ?
                <>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Luogo di nascita" name="luogo_di_nascita" value={user.luogo_di_nascita} onChange={(e)=>onChangeUser(e)} type="text" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField label="Data di nascita"
                            InputLabelProps={{
                            shrink: true
                            }}
                            fullWidth={true}
                            value={user.data_di_nascita ? moment(new Date(user.data_di_nascita)).format("YYYY-MM-DD"):null} onChange={(e)=>onChangeUser(e)} type="date">
                        </TextField>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Partita Iva" name="partita_iva" value={user.partita_iva} onChange={(e)=>onChangeUser(e)} type="text" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Codice fiscale" name="codice_fiscale" value={user.codice_fiscale} onChange={(e)=>onChangeUser(e)} type="text" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Indirizzo" name="indirizzo" value={user.indirizzo} onChange={(e)=>onChangeUser(e)} type="text" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Provincia" name="provincia" value={user.provincia} onChange={(e)=>onChangeUser(e)} type="text" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Comune" name="comune" value={user.comune} onChange={(e)=>onChangeUser(e)} type="text" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="CAP" name="cap" value={user.cap} onChange={(e)=>onChangeUser(e)} type="text" />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Giorni per fatturare" name="giorni_per_fatturare" value={user.giorni_per_fatturare} onChange={(e)=>onChangeUser(e)} type="number" />
                    </Grid>
                </>
                :null }
                </Grid>
                <ModalFooter>
                  <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>salvaModificaNuovoUtente(e)}> <i className="far fa-save"></i> Salva</Button>
                  <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{chiudiModal(e)}}> <i className="fas fa-times"></i> Chiudi</Button>
                </ModalFooter>   
              </ModalBody>
              
            </form>
        </Modal>
    );
}

export default ModalInserimentoUtente;