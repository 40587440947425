import React, { useState, useEffect } from 'react';
import AuthService from '../../components/utente/AuthService';
import DefaultSpinner from '../DefaultSpinner';
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core';

let Auth=new AuthService();

function Impostazioni(props) {
    
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(!Auth.loggedIn()){
            window.location.href="/login"
        }
        setLoading(false)
    }, [])

    return (
        <div>
            {!loading ? 
                <>
                    <Grid container>    
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin"])} to="/impostazioni/account" style={{textDecoration:"none"}}>
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Account</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin","agenzia"])} to="/impostazioni/users" style={{textDecoration:"none"}}> 
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Collaboratori</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin"])} to="/impostazioni/prodotti" style={{textDecoration:"none"}}>
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Prodotti e Stati</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin"])} to="/impostazioni/assicurazioni" style={{textDecoration:"none"}}>
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Assicurazioni</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin"])} to="/impostazioni/tipodocumento" style={{textDecoration:"none"}}>
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Tipo documenti</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin"])} to="/impostazioni/bancheeservizi" style={{textDecoration:"none"}}>
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Banche e servizi</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin"])} to="/impostazioni/precompilati" style={{textDecoration:"none"}}>
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Documenti</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin"])} to="/impostazioni/ws-config" style={{textDecoration:"none"}}>
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Web services</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin"])} to="/impostazioni/fonti" style={{textDecoration:"none"}}>
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Fonti</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin"])} to="/impostazioni/tipifinanziamento" style={{textDecoration:"none"}}>
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Tipi finanziamento</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Link hidden={!Auth.hasPermission(["admin"])} to="/impostazioni/estinzioni" style={{textDecoration:"none"}}>
                                <div className="rectimpostazioni">
                                    <Grid container>
                                        <Grid item lg={9} md={9} sm={9} xs={9}>Estinzione</Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3} style={{textAlign:"right"}}><i className="fas fa-arrow-right"></i></Grid>
                                    </Grid>
                                </div>
                            </Link>
                        </Grid>
                    </Grid>
                </>
            :<DefaultSpinner></DefaultSpinner>}
        </div>
    );
}

export default Impostazioni;