import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios'
import AuthService from '../../components/utente/AuthService';
import { NotificationContext } from '../../contexts/NotificationContext';
import { NotePraticaContext } from '../../contexts/NotePraticaContext';
import {downloadDoc} from '../../commons/documenti'
import { Button, FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { MenuItem } from 'react-bootstrap-typeahead';
import moment from 'moment'
import ModalDocumentiSort from '../modali/ModalDocumentiSort';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function Documenti(props) {
    const [openModal, setOpenModal] = useState(false)
    const notifications = useContext(NotificationContext);
    const notapraticas = useContext(NotePraticaContext);
    const [saved] = useState(!props.pratica.attiva)
    const [tipoDocumenti, setTipoDocumenti] = useState(null)
    const [document, setDocument] = useState(null)
    const [nomeDocumento, setNomeDocumento] = useState("")
    const [tipoDocumentoSelected, setTipoDocumentoSelected] = useState(null)
    const [listOfDocument, setListOfDocument] = useState({list:[]})
    const [filename, setFilename] = useState(null)
   
    useEffect(() => {
        axios.get("/api/1.1/tipodocumento",{headers:header}).then((res)=>{
            setTipoDocumenti(res.data.data)
        })
        axios.get('/api/1.1/document/pratica/'+props.pratica.id,{headers:header}).then((res)=>{
            setListOfDocument({list:res.data.data})
        })
    }, [])

    function salvaDocumento(e){
        e.preventDefault()
        if(document){
            let formData = new FormData()
            let nome_tipo = tipoDocumenti.find(x=>x.id === Number(tipoDocumentoSelected))
            formData.append('file', document, document.name)
            formData.append('nome_tipo',nome_tipo.nome)
            formData.append('privacy',nome_tipo.privacy)
            formData.append('nome',nomeDocumento)
            formData.append('pratica_id',props.pratica.id)

            axios.post("/api/1.1/document/pratica",formData,{headers:header}).then((res)=>{
                let loc = listOfDocument.list
                loc.push(res.data)
                setListOfDocument({list:loc})
                notapraticas.aggiungiNota("[Documenti] Aggiunto documento <b>"+nomeDocumento+"</b>",false)
                notifications.NotifyAlarm(false,"Documento salvato")
                setFilename(null)
                setNomeDocumento("");
                setDocument(null);
                setTipoDocumentoSelected(null);
            })
        }
    }
    function handleImageChange(e){
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        setFilename(file.name)
        reader.onloadend = () => {
            setDocument(file)
        }
        reader.readAsDataURL(file);
    }
    function deleteDoc(doc){
        let nomeDocumento=null
        if(window.confirm("Sei sicuro di voler eliminare il documento?")){ 
            axios.delete("/api/1.1/document/pratica/"+doc.id,{headers:header}).then((res)=>{
                let loc = listOfDocument.list
                loc.forEach((element,i) => {
                    if(element.id === doc.id){
                        nomeDocumento=doc.alt+"("+doc.id+")"
                        loc.splice(i,1)
                    }
                });
                notapraticas.aggiungiNota("[Documenti] Eliminato documento <b>"+nomeDocumento+"</b>",false)
                setListOfDocument({list:loc})
                notifications.NotifyAlarm(false,"Documento eliminato")
            }).catch(error=>{
                notifications.NotifyAlarm(true,"errore cancellazione!")
            })
        }
    }

    return (
        <div>
        {!saved ?
            <form onSubmit={(e)=>salvaDocumento(e)}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{paddingRight:"15px"}}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outlined-tipiimpiego">Tipo Documento</InputLabel>
                            <Select
                                labelId="outline-td"
                                onChange={(e)=>setTipoDocumentoSelected(e.target.value)} value={tipoDocumentoSelected}
                                label="Tipo Documento"
                                required disabled={saved}
                                style={{width:"100%"}}>
                                <MenuItem value={null}></MenuItem>
                                {tipoDocumenti && tipoDocumenti.map((doc,i)=>{
                                    return(
                                        <MenuItem key={i} value={doc.id}>{doc.nome}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{paddingRight:"15px"}}>
                        <TextField type="text" label="Nota" disabled={saved} onChange={(e)=>{setNomeDocumento(e.target.value)}} value={nomeDocumento} style={{width:"100%"}}/>
                    </Grid>
                </Grid>
                <Grid container style={{padding:"15px 0"}}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Button variant="contained" component="label">
                            Seleziona file
                            <input type="file" hidden required onChange={(e) => handleImageChange(e)}/>
                        </Button>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {filename ? <div><small>File: <b>{filename}</b></small></div>: null}
                    </Grid>
                    
                </Grid>
                <div>
                    <Button size="small" type="submit" variant="contained" color="primary" disabled={saved}><i className="fas fa-file-upload"></i> Carica documento</Button>
                </div>
                <hr/>
            </form>
        :null}
        <div>
            {listOfDocument.list && listOfDocument.list.length>0?
                <>
                <TableContainer>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold"}}>Tipo</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>File</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Nota</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Data</TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                                <TableCell style={{fontWeight:"bold",textAlign:"right"}}>
                                    {listOfDocument.list.length > 1 ?
                                        <Button size="small" color="secondary" variant="contained" onClick={(e)=>setOpenModal(true)}>Scarica tutto</Button>
                                    :null}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listOfDocument.list.map((doc,i)=>{
                                return(
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">{doc.nome_tipo}</TableCell>
                                        <TableCell>{doc.url.split('/')[doc.url.split('/').length-1]}</TableCell>
                                        <TableCell>{doc.alt}</TableCell>
                                        <TableCell>{moment(new Date(doc.timestamp)).format("DD/MM/YYYY HH:mm:ss")}</TableCell>
                                        <TableCell style={{textAlign:"right"}}><Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{deleteDoc(doc)}} disabled={!props.pratica.attiva}><i className="fas fa-trash"></i> Cancella</Button></TableCell>
                                        <TableCell style={{textAlign:"right"}}><Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{downloadDoc(doc.proxy_url,doc.file.split("?")[0].split("/")[doc.file.split("?")[0].split("/").length - 1],header,'application/pdf')}}><i className="fas fa-download"></i>Scarica</Button></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ModalDocumentiSort pratica_id={props.pratica.id} isOpenModal={openModal} setIsOpenModal={setOpenModal} documenti={listOfDocument.list}></ModalDocumentiSort>
                </>
            :<div style={{textAlign:'center'}}>
                Nessun documento caricato
            </div>}
        </div>
    </div>
    );
}

export default Documenti;