import React,{useState,useEffect, useContext} from 'react';
import AuthService from '../../components/utente/AuthService';
import DefaultSpinner from '../DefaultSpinner';
import axios from 'axios';
import { Button, Checkbox, Grid, Paper, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { sortByNome } from '../../commons/sortsfunct';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SettingContext } from '../../contexts/SettingContext';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function Fonti(props) {
    const notifications = useContext(NotificationContext);
    const settings = useContext(SettingContext);
    const [loading, setLoading] = useState(true)
    const [nome, setNome] = useState("")
    const [isAzienda, setIsAzienda] = useState(false)
    const [isPrivato, setIsPrivato] = useState(false)
    
    function addFonte(e){
        e.preventDefault()
        let data={
            nome:nome,
            isAzienda:isAzienda,
            isPrivato:isPrivato
        }
        axios.post('/api/1.1/fonti',data,{headers:header}).then((res)=>{
            let listafonti= settings.fonti.list;
            listafonti.push(res.data.data)
            settings.setFonti({list:listafonti})
            notifications.NotifyAlarm(false,"Salvataggio completato!")
            setNome("")
        }).catch(error=>{
            notifications.NotifyAlarm(true,"errore salvataggio!")
        })
    }

    function deleteFonte(id_fonte){
        axios.delete('/api/1.1/fonti/'+id_fonte,{headers:header}).then((res)=>{
            let listafonti= settings.fonti.list;
            for (let i = 0; i < listafonti.length; i++) {
                const element = listafonti[i];
                if(element.id === id_fonte){
                    listafonti.splice(i,1)
                }
            }
            settings.setFonti({list:listafonti})
            notifications.NotifyAlarm(true,"Cancellazione completata!")
        }).catch(error=>{
            notifications.NotifyAlarm(true,"errore salvataggio!")
        })
    }
    
    useEffect(() => {
        if(settings.fonti){
            setLoading(false)
        }
    }, [])

    return (
        <div>
            {!loading ? 
            <div>
                <div style={{marginBottom:"15px"}}>
                <h4>Aggiungi nuova fonte</h4>
                <form onSubmit={(e)=>{addFonte(e)}}>
                    <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <TextField required label="Nome" type="text" value={nome}
                                fullWidth={true}
                                onChange={(e)=>{setNome(e.target.value)}}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Switch
                                checked={isAzienda}
                                onChange={(e)=>setIsAzienda(!isAzienda)}
                                name="isAzienda"
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <label>Azienda</label>
                            <Switch
                                checked={isPrivato}
                                onChange={(e)=>setIsPrivato(!isPrivato)}
                                color="primary"
                                name="isAzienda"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <label>Privato</label>
                            <Button size="small" type="submit" variant="contained" color="primary" style={{marginLeft:"15px"}}><i className="fas fa-trash"></i> Salva</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold"}}>Nome</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Azienda</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Privato</TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {settings.fonti.list.sort(sortByNome).map((fonte,i)=>{
                                return(
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">{fonte.nome}</TableCell>
                                        <TableCell>{fonte.isAzienda ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{fonte.isPrivato ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell><Button size="small" type="submit" variant="contained" color="secondary" style={{marginLeft:"15px"}} onClick={()=>{deleteFonte(fonte.id)}}><i className="fas fa-trash"></i> Cancella</Button></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            :
            <DefaultSpinner></DefaultSpinner>}
        </div>
    );
}

export default Fonti;