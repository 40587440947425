import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import Rappresentanti from './Rappresentanti';
import Intervista from './Intervista';
import Anagrafica from './Anagrafica';
import Azienda from './Azienda';
import Documenti from './Documenti';
import Impegni from './Impegni';
import Stati from './Stati';
import ElencoPreventivi from './ElencoPreventivi';
import CensimentiAssicurativi from './CensimentiAssicurativi';
import Simulatore from '../simulatore/Simulatore';
import DefaultSpinner from '../DefaultSpinner';
import SimulatoreManuale from '../simulatore/SimulatoreManuale';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Grid } from '@material-ui/core';
import TabPanel from '../../commons/TabPanel';
import PraticheAssociate from './PraticheAssociate';

let Auth = new AuthService();
let header=Auth.getHeadersJWT();

let urls=[{url:"anagrafica",index:0},
  {url:"azienda",index:1},
  {url:"documenti",index:2},
  {url:"impegni",index:3},
  {url:"stati",index:4},
  {url:"simulatore",index:5},
  {url:"simulatore-manuale",index:6},
  {url:"preventivi-provvigioni",index:7},
  {url:"censimenti-assicurativi",index:8},
  {url:"intervista",index:9},
  {url:"ruoli",index:10},
  {url:"pratiche-associate",index:11}]

function Cliente(props) {
  const [loading, setLoading] = useState(true)
  const [pratica, setPratica] = useState(null)
  const [rappresentanti, setRappresentanti] = useState(null)
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function setIdPreventivoPredefinito(id_preventivo_predefinito){
    setPratica({...pratica, id_preventivo_predefinito:id_preventivo_predefinito})
  }
  function reloadPreventivi(preventiviLista){
    setPratica({...pratica, elencoProvvigioni:preventiviLista})
  }
  function setNuovoProdotto(pratica){
    setPratica(pratica)
  }

  function checkIsPreventivoPredefinitoExists(){
    let trovato = false
    pratica.elencoProvvigioni.forEach(element => {
      if(element.id === pratica.id_preventivo_predefinito){
        trovato = true
      }
    });
    return trovato
  }
  function aggiornaSchemaUtenti(titolare,agenzia){
    setPratica({...pratica, titolare:titolare,id_capo:agenzia})
  }
  function changeurl(value){
    var newurl = "/cliente/"+pratica.id+"/"+value
    window.history.pushState(null, null, newurl)
  }
  function aggiornaImpegniPratica(impegni){
    setPratica({...pratica, impegni_resume:impegni})
  }
  function aggiornaListaImpegniPratica(finanziamentiincorsolista){
    setPratica({...pratica, finanziamentiincorsolista:finanziamentiincorsolista})
  }

  useEffect(() => {
    axios.get("/api/1.1/cliente/"+props.match.params.id_pratica,{headers:header}).then(res=>{
        setPratica(res.data.data)
        if(res.data.data.tipopratica === "AZIENDA"){
          setValue(1)
        }
        if(props.match.params.nome_pagina){
          let index = urls.filter(x=>x.url === props.match.params.nome_pagina)
          if(index){
            setValue(index[0].index)
          }
        }             
        setLoading(false)
    })
  }, [])
  
  useEffect(() => {
    if(pratica && pratica.id){
      axios.all([
        axios.get('/api/1.1/rappresentanti/'+pratica.id,{headers:header}),
    ]).then(axios.spread((resrappresentanti) => {
        setRappresentanti(resrappresentanti.data.data)
        setLoading(false)
    })).catch(error=>{
        setLoading(false)
    })
  }
  }, [pratica])

  return (
      <div>
        {!loading && pratica ? 
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <h2>Cliente #<b>{pratica.id}</b></h2>
            <h5>
              {pratica.tipopratica === "AZIENDA" ? 
                <span><i className={"fas fa-industry"}></i> {!pratica.azienda && pratica.intervista_azienda ? pratica.intervista_azienda.ragioneSociale: pratica.azienda ? pratica.azienda.ragione_sociale : ""}</span> 
              :
                <span><i className={"fas fa-user-alt"}></i> {!pratica.anagrafica && pratica.intervista ? pratica.intervista.nome: pratica.anagrafica ? pratica.anagrafica.nome : ""} {!pratica.anagrafica && pratica.intervista ? pratica.intervista.cognome: pratica.anagrafica ? pratica.anagrafica.cognome : ""}</span> }
              {pratica.chiusa ? 
                <span style={{padding:"5px 15px",backgroundColor:"#63141e",color:"white",fontSize:"15px",position:"absolute",right:"15px",top:"20px"}}>CHIUSA</span>
                : 
                null
              }
            </h5>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
          <AppBar position="relative" color="default" style={{zIndex:"0"}} >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
                <Tab onClick={()=>{changeurl("anagrafica")}} hidden={pratica.tipopratica === "AZIENDA"} label={pratica.anagrafica ? <div>Dati anagrafica <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Dati anagrafica <i style={{opacity:"0.1"}} className="fas fa-check"></i></div>} {...a11yProps(0)} />
                <Tab onClick={()=>{changeurl("azienda")}} label={pratica.azienda ? <div>Dati azienda <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Dati azienda <i style={{opacity:"0.5"}} className="fas fa-check"></i></div>} {...a11yProps(1)} />
                <Tab onClick={()=>{changeurl("documenti")}} label={pratica.attiva ? <div>Documenti <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Documenti <i style={{opacity:"0.5"}} className="fas fa-check"></i></div>} {...a11yProps(2)} />
                <Tab onClick={()=>{changeurl("impegni")}} label="Impegni" {...a11yProps(3)} />
                <Tab onClick={()=>{changeurl("stati")}} label="Stati" {...a11yProps(4)} />
                <Tab onClick={()=>{changeurl("simulatore")}} hidden={pratica.chiusa || pratica.tipopratica === "AZIENDA"} label="Simulatore" {...a11yProps(5)} />
                <Tab onClick={()=>{changeurl("simulatore-manuale")}} hidden={pratica.chiusa || pratica.tipopratica === "AZIENDA"} label="Simulatore man." {...a11yProps(6)} />
                <Tab onClick={()=>{changeurl("preventivi-provvigioni")}} label={checkIsPreventivoPredefinitoExists() ? <div>Elenco prev e prov <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Elenco prev e prov  <i style={{opacity:"0.5"}} className="fas fa-check"></i></div>} {...a11yProps(7)} />
                <Tab onClick={()=>{changeurl("censimenti-assicurativi")}} label="Censimenti ass." {...a11yProps(8)} />
                <Tab onClick={()=>{changeurl("intervista")}} label={pratica.intervista || pratica.intervista_azienda ? <div>Intervista <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Intervista <i style={{opacity:"0.5"}} className="fas fa-check"></i></div>} {...a11yProps(9)} />
                <Tab onClick={()=>{changeurl("ruoli")}} label={pratica.titolare ? <div>Ruoli <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Ruoli <i style={{opacity:"0.5"}} className="fas fa-check"></i></div>} {...a11yProps(10)} />
                <Tab onClick={()=>{changeurl("pratiche-associate")}} label={"PRATICHE ASSOCIATE"} {...a11yProps(11)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} hidden={pratica.tipopratica === "AZIENDA"}>
              <Anagrafica pratica={pratica}></Anagrafica>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Azienda pratica={pratica}></Azienda>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Documenti pratica={pratica}></Documenti>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Impegni pratica={pratica} tipopratica={pratica.tipopratica} aggiornaImpegniPratica={aggiornaImpegniPratica} aggiornaListaImpegniPratica={aggiornaListaImpegniPratica}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Stati pratica={pratica} tipopratica={pratica.tipopratica} setNuovoProdotto={setNuovoProdotto}></Stati>
            </TabPanel>
            <TabPanel hidden={pratica.chiusa || pratica.tipopratica === "AZIENDA"} value={value} index={5}>
              <Simulatore pratica={pratica} tipo={"cliente"} reloadPreventivi={reloadPreventivi} setIdPreventivoPredefinito={setIdPreventivoPredefinito}></Simulatore>
            </TabPanel>
            <TabPanel hidden={pratica.chiusa || pratica.tipopratica === "AZIENDA"} value={value} index={6}>
              <SimulatoreManuale pratica={pratica} tipo={"cliente"} reloadPreventivi={reloadPreventivi} setIdPreventivoPredefinito={setIdPreventivoPredefinito}></SimulatoreManuale>
            </TabPanel>
            <TabPanel value={value} index={7}>
              <ElencoPreventivi pratica={pratica} setIdPreventivoPredefinito={setIdPreventivoPredefinito}></ElencoPreventivi>
            </TabPanel>
            <TabPanel value={value} index={8}>
              <CensimentiAssicurativi pratica={pratica}></CensimentiAssicurativi>
            </TabPanel>
            <TabPanel value={value} index={9}>
              <Intervista intervista={pratica.intervista} intervista_azienda={pratica.intervista_azienda}/>
            </TabPanel>
            <TabPanel value={value} index={10}>
              <Rappresentanti pratica={pratica} rappresentanti={rappresentanti} aggiornaSchemaUtenti={aggiornaSchemaUtenti}/>
            </TabPanel>
            <TabPanel value={value} index={11}>
              {/* <Rappresentanti pratica={pratica} rappresentanti={rappresentanti} aggiornaSchemaUtenti={aggiornaSchemaUtenti}/> */}
              <PraticheAssociate id_pratica={pratica.id}></PraticheAssociate>
            </TabPanel>
          </Grid> 
        </Grid> 
        : 
        <div style={{textAlign:'center'}}>
          <DefaultSpinner></DefaultSpinner>
        </div>}            
            
      </div>
    );
}

export default Cliente; 