import React, { useState,useEffect } from 'react';
import RisultatoSimulatore from '../simulatore/RisultatoSimulatore';
import ViewRisultato from '../simulatore/ViewRisultato';
import AuthService from '../utente/AuthService'

let Auth=new AuthService();

function ElencoPreventivi(props) {
    const [chiusa] = useState(!props.pratica.attiva)
    const [idPredefinito, setIdPredefinito] = useState(props.pratica.id_preventivo_predefinito)
    const [listaPreventivi, setListaPreventivi] = useState({list:props.pratica.elencoProvvigioni})

    useEffect(() => {
        setListaPreventivi({list:props.pratica.elencoProvvigioni})
    }, [props.pratica.elencoProvvigioni])

    useEffect(() => {
        setIdPredefinito(props.pratica.id_preventivo_predefinito)
    }, [props.pratica.id_preventivo_predefinito])

    function setNewIdPrevenivoPredefinito(id_preventivo_predefinito){
        props.setIdPreventivoPredefinito(id_preventivo_predefinito)
    }
    return (
        <div>
            {!props.pratica.approvata && listaPreventivi && listaPreventivi.list.length>0 ? 
                listaPreventivi.list.map((preventivo,i)=>{
                return(
                    <RisultatoSimulatore key={i}
                        saved={true}
                        chiusa={chiusa}
                        id_pratica={props.pratica.id} 
                        loadError={false} 
                        approved={props.pratica.approvata}
                        preventivo={preventivo}
                        idPredefinito = {idPredefinito}
                        hasPermission={!Auth.hasPermission(["admin"])}
                        setNewIdPrevenivoPredefinito={setNewIdPrevenivoPredefinito}/>
                    )
                })
            :props.pratica.approvata && props.pratica.preventivo_predefinito ?
                <ViewRisultato preventivo={JSON.parse(props.pratica.preventivo_predefinito)} />
            :<div style={{textAlign:'center'}}>
                Nessun preventivo caricato
            </div>}
        </div>
    );
}

export default ElencoPreventivi;