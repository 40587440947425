import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import { NotePraticaContext } from '../../contexts/NotePraticaContext';
import DefaultSpinner from '../DefaultSpinner';
import { Button, Grid, TextField } from '@material-ui/core';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();
function CensimentiAssicurativi(props) {
    const notifications = useContext(NotificationContext);
    const notapraticas = useContext(NotePraticaContext);
    const [saved] = useState(!props.pratica.attiva)
    const [praticaListaAss] = useState({list:JSON.parse(props.pratica.assicurazioni)})
    const [assicurazioni, setAssicurazioni] = useState({list:[]})
    const [assicurazioniOrig, setAssicurazioniOrig] = useState({list:[]})

    function salvaAssicurazioni(event){
        event.preventDefault();
        let data={valore:JSON.stringify(assicurazioni.list),id:props.pratica.id,type:"setassicurazionipratica"};
        let cambiamenti = "[Censimenti assicurativi] Modifica censimenti assicurativi"
        axios.put('/api/1.1/pratica', data, {headers:header}).then((res)=>{
            props.aggiornaListaCensimenti(assicurazioni.list)
            for (let i = 0; i < assicurazioni.list.length; i++) {
                const element = assicurazioni.list[i];
                const element2 = assicurazioniOrig.list.filter(x=>x.nome === element.nome)[0]
                if(element.valore !== element2.valore){
                    if(element2.valore && element.valore){
                        cambiamenti = cambiamenti + "<div>Cambiato valore per <b>"+element.nome+"</b> da: <b>"+element2.valore+"</b> a: <b>"+element.valore+"</b></div>"
                    }else if(element.valore){
                        cambiamenti = cambiamenti + "<div>Inserito valore per <b>"+element.nome +"</b>: <b>"+element.valore+"</b></div>"
                    }else{
                        cambiamenti = cambiamenti + "<div>Rimosso valore per <b>"+element.nome +"</b></div>"
                    }
                }   
            }
            if(cambiamenti!=="[Censimenti assicurativi] Modifica censimenti assicurativi"){
                notapraticas.aggiungiNota(cambiamenti,false)
            }
            notifications.NotifyAlarm(false,"Assicurazioni aggionate")
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Assicurazioni non aggionate")
        })
    }
    
    function onChangeAssicurazione(e){
        let ass=assicurazioni.list;
        ass.forEach(element => {
          if(element.nome===e.target.name){
            element.valore=e.target.value;
          }
        });
        setAssicurazioni({list:ass})
    }

    useEffect(() => {
        axios.get("/api/1.1/assicurazione",{headers:header}).then((res)=>{
            let lass = []
            let lass2 = []
            res.data.data.forEach(element => {
                let ttt = praticaListaAss.list.find(x=>x.nome === element.nome)
                lass.push({nome:element.nome,valore:ttt ? ttt.valore : ""})
                lass2.push({nome:element.nome,valore:ttt ? ttt.valore : ""})
            });
            setAssicurazioni({list:lass})
            setAssicurazioniOrig({list:lass2})
        }).catch(error=>{
            console.log(error)
        })
    }, [])

    return (
        <div>
            {assicurazioni ? 
                <Grid container>
                    {assicurazioni.list.map((ass,i)=>{
                        return(
                            <Grid key={i} item  lg={6} md={6} sm={12} xs={12} style={{paddingRight:"15px"}}>
                                <TextField type="text" label={ass.nome} disabled={saved} name={ass.nome} onChange={(e)=>{onChangeAssicurazione(e)}} value={ass.valore} style={{width:"100%"}}/>
                            </Grid>
                        )
                    })}
                    {!saved ?
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop:"15px"}}>
                            <Button size="small" type="submit" variant="contained" color="primary" disabled={saved} onClick={(e)=>{salvaAssicurazioni(e)}}><i className="far fa-save"></i> Salva</Button>
                        </Grid>
                    :null}
                </Grid>
            :<DefaultSpinner></DefaultSpinner>}
        </div>
    );
}

export default CensimentiAssicurativi;