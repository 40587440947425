import React,{useState,useContext,useEffect} from 'react';
import DefaultSpinner from '../DefaultSpinner';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { AccountContext } from '../../contexts/AccountContext';
import moment from 'moment'

function Account(props) {
    const accounts = useContext(AccountContext);
    const [account, setAccount] = useState(null)

    useEffect(() => {
        setAccount(accounts.account)
    }, [accounts])

    return (
        <>
            {account ?
            <Grid item lg={8} md={8} sm={12} xs={12}> 
                <div style={{margin:"15px 0"}}>
                    <img src={account.url_logo} style={{width:"300px",marginRight:"15px"}} alt=""/> 
                </div> 
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row"><span style={{fontWeight:"bold"}}>NOME</span></TableCell>
                                <TableCell>{account.nome}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><span style={{fontWeight:"bold"}}>COGNOME</span></TableCell>
                                <TableCell>{account.cognome}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><span style={{fontWeight:"bold"}}>EMAIL</span></TableCell>
                                <TableCell>{account.email}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><span style={{fontWeight:"bold"}}>RAGIONE SOCIALE</span></TableCell>
                                <TableCell>{account.ragione_sociale}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><span style={{fontWeight:"bold"}}>PARTITA IVA</span></TableCell>
                                <TableCell>{account.partita_iva}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><span style={{fontWeight:"bold"}}>SDI CODE</span></TableCell>
                                <TableCell>{account.sdi_code}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><span style={{fontWeight:"bold"}}>VALIDO FINO AL</span></TableCell>
                                <TableCell>{moment(account.valid_to).format("DD/MM/YYYY HH:mm")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        : <DefaultSpinner></DefaultSpinner>}
    </>
    );
}

export default Account;