export const ADD_USER="ADD_USER";
export const SET_USERS="SET_USERS";
export const DELETE_USER="DELETE_USER";
export const CHANGE_STATUS_USER ="CHANGE_STATUS_USER";

export const addUser = (payload) => ({
  type: ADD_USER,
  payload
})
export const  changeStatusUser = (payload) => ({
  type: CHANGE_STATUS_USER,
  payload
})


export const setUsers = (payload) => ({
  type: SET_USERS,
  payload
})

export const delUser = (payload) => ({
  type: DELETE_USER,
  payload
})


