import React,{useState,useContext} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import { Button, FormControl, FormControlLabel, Grid, InputLabel, Paper, Radio, Select, TextField } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment'
import { MenuItem } from 'react-bootstrap-typeahead';
import { SettingContext } from '../../contexts/SettingContext';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();
let duratafinanziamento = [24,36,48,60,72,84,96,108,120]

function Impegni(props) {
    const notifications = useContext(NotificationContext);
    const settings = useContext(SettingContext);
    const [saved] = useState(false)//props.pratica.chiusa)
    const [pratica_id] = useState(props.pratica.id)
    const [listafinanziamenti, setListafinanziamenti] = useState({list:props.pratica.finanziamentiincorsolista})
    const [tipo, setTipo] = useState("")
    const [rate, setRate] = useState(null)
    const [durata, setDurata] = useState(null)
    const [data_prima_rata, setData_prima_rata] = useState(null)
    const [coobligato, setCoobligato] = useState("N")
    const [finanziamentoSelected, setFinanziamentoSelected] = useState(null)
    const [impegniResume, setImpegniResume] = useState(props.pratica.impegni_resume)
    const [estinzione, setEstinzione] = useState(null)

    function changeImpegnoResume(e){
        let value = null
        if(e.target.value==="S"){
            value=true
        }else{
            value=false
        }
        setImpegniResume({...impegniResume,[e.target.name]:value})
        let imr={...impegniResume,[e.target.name]:value}
        let data={
            type:"setimpegniresume",
            id:props.pratica.id,
            impegniResume:imr
        }
        axios.put('/api/1.1/cliente',data,{headers:header}).then(res=>{
            
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Operazione non riuscita")
        })
    }

    function selectFinanziamento(e){
        let f=null
        if(settings.finanziamenti.list.filter(x=>x.nome === e.target.value).length>0){
            f=settings.finanziamenti.list.filter(x=>x.nome === e.target.value)[0]
        }
        setFinanziamentoSelected(f)
        setTipo(e.target.value)
    }
    function filterPrivatoAzienda(element){
        if(!props.tipopratica){
          return element
        }else if(props.tipopratica==="AZIENDA" && element.isAzienda){
          return element
        }else if(props.tipopratica==="PRIVATO" && element.isPrivato){
          return element
        }
      }
    function saveFinanziamento(e){
        e.preventDefault();
        let data={
            id:pratica_id,
            type:"addfinanziamento",
            dati_finanziamento:{
                tipo:tipo,
                rate:rate,
                durata:durata,
                data_prima_rata:data_prima_rata,
                coobligato:coobligato,
                estinzione:estinzione
            }
        }
        axios.put('/api/1.1/cliente',data,{headers:header}).then((res)=>{
            setListafinanziamenti({list:res.data.data.finanziamentiincorsolista})
            notifications.NotifyAlarm(false,"operazione completata")
            setTipo("")
            setRate("")
            setDurata("")
            setData_prima_rata("")
            setCoobligato(null)
            setFinanziamentoSelected(null)
            setEstinzione(null)
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Operazione non riuscita")
        })
    }
    // function cancellaImpegno(finanziamento){
    //     'deletefinanziamento'
    //     let data = {
    //         id:pratica_id,
    //         type:"deletefinanziamento",
    //         finanziamento_id:finanziamento.id
    //     }
    //     axios.put('/api/1.1/pratica',data,{headers:header}).then((res)=>{
    //         setListafinanziamenti({list:res.data.data.finanziamentiincorsolista})
    //         notifications.NotifyAlarm(false,"operazione completata", {
    //             position: toast.POSITION.BOTTOM_RIGHT
    //           })
    //     })
    // }

    return (
        <div>
            <div style={{marginBottom:"15px"}}>
                {impegniResume !== null ?
                    <div>
                        <Grid container style={{marginBottom:"5px"}}>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <span>Assicurazione in corso?</span> <br/>
                                <FormControlLabel value="S" name="assicurazioneincorso" control={<Radio disabled={saved}  color="primary" />} checked={impegniResume.assicurazioneincorso===true} label="SI" onChange={(e)=>changeImpegnoResume(e)} />
                                <FormControlLabel value="N" name="assicurazioneincorso" control={<Radio disabled={saved} color="primary" />} checked={impegniResume.assicurazioneincorso===false} label="NO" onChange={(e)=>changeImpegnoResume(e)} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <span>Ritardi di pagamento?</span> <br/>
                                <FormControlLabel value="S" name="ritardipagamento" control={<Radio disabled={saved} color="primary" />} checked={impegniResume.ritardipagamento===true} label="SI" onChange={(e)=>changeImpegnoResume(e)} />
                                <FormControlLabel value="N" name="ritardipagamento" control={<Radio disabled={saved} color="primary" />} checked={impegniResume.ritardipagamento===false} label="NO" onChange={(e)=>changeImpegnoResume(e)} />
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12}>
                                <span>Finanziamenti in corso?</span> <br/>
                                <FormControlLabel value="S" name="finanziamentiincorso" control={<Radio disabled={saved} color="primary" />} checked={impegniResume.finanziamentiincorso===true} label="SI" onChange={(e)=>changeImpegnoResume(e)} />
                                <FormControlLabel value="N" name="finanziamentiincorso" control={<Radio disabled={saved} color="primary" />} checked={impegniResume.finanziamentiincorso===false} label="NO" onChange={(e)=>changeImpegnoResume(e)} />
                            </Grid>
                        </Grid>
                        <hr/>
                    </div>
                :null}
                <h5>Aggiungi finanziamento</h5>
                <form onSubmit={(e)=>{saveFinanziamento(e)}}>
                    <Grid container>
                        <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                            <FormControl style={{width:"100%"}}>
                                <InputLabel id="outline-1">Tipo</InputLabel>
                                <Select
                                    style={{width:"100%"}}
                                    labelId="outline-1"
                                    required disabled={saved} 
                                    name="tipo" 
                                    value={finanziamentoSelected ? finanziamentoSelected.nome :""} 
                                    onChange={(e)=>{selectFinanziamento(e)}}
                                    >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                        {settings.finanziamenti.list.filter(filterPrivatoAzienda).map((finanziamento,i)=>{
                                            return(
                                                <MenuItem key={i} value={finanziamento.nome}>{finanziamento.nome}</MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                            <TextField required label="Dec 1° rata" type="date" disabled={saved} value={data_prima_rata}
                                InputLabelProps={{
                                shrink: true
                                }}
                                name="data_prima_rata"
                                fullWidth={true}
                                onChange={(e)=>{setData_prima_rata(e.target.value)}}
                            />
                        </Grid>  
                        <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                            <TextField required disabled={saved} 
                                InputLabelProps={{
                                shrink: true
                                }}
                                label="Rata"
                                value={rate} name="rata" type="number" onChange={(e)=>{setRate(e.target.value)}}
                                fullWidth={true}
                            /> 
                        </Grid>                              
                    </Grid>
                    <Grid container>
                        <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                            <FormControl style={{width:"100%"}}>
                                <InputLabel id="outline-2">Durata</InputLabel>
                                <Select
                                    style={{width:"100%"}}
                                    labelId="outline-2"
                                    required disabled={saved} 
                                    name="durata" 
                                    value={durata} 
                                    onChange={(e)=>{setDurata(e.target.value)}}
                                    >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                        {duratafinanziamento.map((item,i)=>{
                                            return(
                                                <MenuItem key={i} value={item}>{item}</MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                            <FormControl style={{width:"100%"}}>
                                <InputLabel id="outline-3">Estinzione</InputLabel>
                                <Select
                                    style={{width:"100%"}}
                                    labelId="outline-3"
                                    required disabled={saved} 
                                    name="estinzione" 
                                    value={estinzione} 
                                    onChange={(e)=>{setEstinzione(e.target.value)}}
                                    >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                        {settings.estinzioni.list.map((est,i)=>{
                                            return(
                                                <MenuItem key={i} value={est.nome}>{est.nome}</MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                        </Grid>
                        {finanziamentoSelected && finanziamentoSelected.coobligato ?
                            <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                                <span>Coobbligato</span>
                                <div>                                    
                                    <FormControlLabel value="S" control={<Radio color="primary" />} checked={coobligato==="S"} label="SI" onChange={(e)=>setCoobligato("S")} />
                                    <FormControlLabel value="N" control={<Radio color="primary" />} checked={coobligato==="N"} label="NO" onChange={(e)=>setCoobligato("N")} />
                                </div>
                            </Grid>
                        :null}
                        <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                            <Button size="small" type="submit" variant="contained" color="primary" disabled={saved}><i className="fas fa-plus"></i> Aggiungi finanziamento</Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
            {listafinanziamenti.list && listafinanziamenti.list.length>0?
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold"}}>Tipo</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Rata</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Durata</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Coobligato</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Data prima rata</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Estinzione</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listafinanziamenti.list.map((finanziamento,i)=>{
                                return(
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">{finanziamento.tipo}</TableCell>
                                        <TableCell>{finanziamento.rata}</TableCell>
                                        <TableCell>{finanziamento.durata}</TableCell>
                                        <TableCell>{finanziamento.coobligato === true ? "Si":null} {finanziamento.coobligato === false ? "No":null}</TableCell>
                                        <TableCell>{moment(new Date(finanziamento.data_prima_rata)).format("DD/MM/YYYY")}</TableCell>
                                        <TableCell>{finanziamento.estinzione}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div>Nessun finanziamento in corso al momento</div>}
        </div>
    );
}

export default Impegni;