import React,{Component} from 'react'
import { Redirect } from 'react-router-dom'
import AuthService from './AuthService'

class Logout extends Component {
    constructor(props){
        super(props);
        this.state = {};
        this.Auth=new AuthService();
        this.Auth.logout();
    }
    render() { 
        return ( 
            <div>
                <Redirect to="/login"></Redirect>
            </div>    
         )
    }
}
 
export default Logout;