import { Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';

function ViewRisultato(props) {
    return (
    <div>
        <Grid container>
            <h4 style={{paddingLeft:"16px"}}><b>{props.preventivo.nomeBanca}</b></h4>
            <TableContainer>
                <Table aria-label="simple table" size="small">
                    <TableBody>
                        <TableRow><TableCell component="th" scope="row"  style={{fontWeight:"bold", width:"25%"}}>ASSICURAZIONE</TableCell><TableCell>{props.preventivo.nomeAssicurazione}</TableCell></TableRow>
                        <TableRow><TableCell style={{fontWeight:"bold"}}>TAEG</TableCell><TableCell>{props.preventivo.taeg!=="---" ? props.preventivo.taeg +" %": "---"}</TableCell></TableRow>
                        <TableRow><TableCell style={{fontWeight:"bold"}}>TAN</TableCell><TableCell>{props.preventivo.tan!=="---" ? props.preventivo.tan +" %": "---"}</TableCell></TableRow>
                        <TableRow><TableCell style={{fontWeight:"bold"}}>TEG</TableCell><TableCell>{props.preventivo.teg!=="---" ? props.preventivo.teg +" %": "---"}</TableCell></TableRow>
                        <TableRow><TableCell style={{fontWeight:"bold"}}>MONTANTE</TableCell><TableCell>{props.preventivo.montante}€</TableCell></TableRow>
                        <TableRow><TableCell style={{fontWeight:"bold"}}>COMMISSIONE VENDITORE</TableCell><TableCell>{props.preventivo.provAgente}€ | {props.preventivo.percentuale}%</TableCell></TableRow>
                        {props.hasPermission ?
                            <TableRow><TableCell style={{fontWeight:"bold"}}>COMMISSIONE AGENZIA</TableCell><TableCell>{props.preventivo.provAgenzia}€| {props.preventivo.percentualesoc}%</TableCell></TableRow>
                        :null}
                        <TableRow><TableCell style={{fontWeight:"bold"}}>IMPORTO RATA</TableCell><TableCell>{props.preventivo.importoRata}€</TableCell></TableRow>
                        <TableRow><TableCell style={{fontWeight:"bold"}}>NUMERO RATE</TableCell><TableCell>{props.preventivo.numeroRate}</TableCell></TableRow>
                        <TableRow><TableCell style={{fontWeight:"bold"}}>IMPORTO NETTO</TableCell><TableCell>{props.preventivo.importoNetto}€</TableCell></TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    </div>
    );
}

export default ViewRisultato;