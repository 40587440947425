import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios';
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import { SettingContext } from '../../contexts/SettingContext';
import { Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Radio, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import {getProvincieLista,getComuniByProvincia, getProvinciaNomeByCode} from '../../commons/provincie_comuni'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {PDFDocument} from 'pdf-lib';
import fileDownload from 'js-file-download';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

var list_customers = ["NOT CUSTOMERS","AFFIDATE","PARTNER"];
var list_formagiuridica = ["SPA","SRL","SRLS", "SNC","SAS","SS","SOCIETÀ COOPERATIVA","SAPA","PARTITA IVA"]
var list_categoria = ["CORPORATE","PMI"]
var duratafinanziamento = [24,36,48,60,72,84,96,108,120]
//per stampa pdf privacy
let lunghezzaPaginaPDF=1300;

function IntervistaAzienda(props) {
    const notifications = useContext(NotificationContext);
    const settings = useContext(SettingContext);

    const [confermaPrivacy, setConfermaPrivacy] = useState(false)
    const [default_finanziamento, setDefault_finanziamento] = useState({
        tipo:"",
        data_prima_rata:"",
        rata:"",
        durata:"",
        coobligato:null,
        estinzione:null
    })
    const [searching, setSearching] = useState(false)
    const [partitaIvaObbligatoria, setPartitaIvaObbligatoria] = useState(false)
    const [provincie] = useState(getProvincieLista())
    const [customers, setCustomers] = useState({list:[]})
    const [pubblicita, setPubblicita] = useState(null)
    const [tipopratica] = useState("AZIENDA")
    const [ragioneSociale, setRagioneSociale] = useState(null)
    const [formaGiuridica, setFormaGiuridica] = useState(null)
    const [numeroDipendenti, setNumeroDipendenti] = useState(0)
    const [partitaIva, setPartitaIva] = useState(null)
    const [codicefiscale, setCodicefiscale] = useState(null)
    const [indirizzoSedeLegale, setIndirizzoSedeLegale] = useState(null)
    const [civico, setCivico] = useState(null)
    const [comune, setComune] = useState(null)
    const [provincia, setProvincia] = useState(null)
    const [cap, setCap] = useState(null)
    const [indirizzoPec, setIndirizzoPec] = useState(null)
    const [indirizzoEmail, setIndirizzoEmail] = useState(null)
    const [telefono1, setTelefono1] = useState(null)
    const [telefono2, setTelefono2] = useState(null)
    const [capitaleSociale, setCapitaleSociale] = useState(0)
    const [inizioAttivita, setInizioAttivita] = useState(null)
    const [amministratore, setAmministratore] = useState({nome:"",cognome:"",telefono:""})
    const [referente, setReferente] = useState({nome:"",cognome:"",telefono:""})
    const [categoria, setCategoria] = useState(null)
    const [richiestacliente, setRichiestacliente] = useState({list:[]})
    const [idRichiestacliente, setIdRichiestacliente] = useState({list:[]})
    const [isAmministratore, setIsAmministratore] = useState(false)
    const [isReferente, setIsReferente] = useState(false)
    const [finanziamentiincorsolista, setFinanziamentiincorsolista] = useState({list:[]})
    const [cartaDiCredito, setCartaDiCredito] = useState(null)
    const [ritardiPagamento, setRitardiPagamento] = useState("No")
    const [assicurazioniInCorso, setAssicurazioniInCorso] = useState("No")
    const [finanziamentiInCorso, setFinanziamentiInCorso] = useState("No")
    const [finanziamentoSelected, setFinanziamentoSelected] = useState(null)
    const [documento, setDocumento] = useState(null)
    const [scaricato, setScaricato] = useState(false)

    async function exportPDF(e){
        e.preventDefault();
        let doc_proxy = settings.precompilati.list.filter(x=>x.id === documento)[0]
        const existingPdfBytes = await fetch(doc_proxy.proxy_url,{headers:header}).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const pages = pdfDoc.getPages()
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            let list = JSON.parse(doc_proxy.listaopzioni).filter(x=>x.y_real >= (lunghezzaPaginaPDF*i) && x.y_real < (lunghezzaPaginaPDF*(i+1)))
            for (let l = 0; l < list.length; l++) {
                const element = list[l];
                let percentualeConversione = page.getMediaBox().height/lunghezzaPaginaPDF
                page.drawText(element.value, {
                    x: ((element.x)*percentualeConversione)+21,
                    y: (((lunghezzaPaginaPDF*(i+1) - element.y_real)*percentualeConversione)-Number(element.fontSize)-13.5),
                    size: Number(element.fontSize)*percentualeConversione,
                    // color: rgb(0.95, 0.1, 0.1),
                    // rotate: degrees(-45),
                  })               
            } 
        }
        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()
        fileDownload(pdfBytes, doc_proxy.file.split("?")[0].split("/")[doc_proxy.file.split("?")[0].split("/").length - 1], "application/pdf");
        setScaricato(true)
    }

    function setDefaultValue(){
        setCustomers({list:[]})
        setPubblicita(null)
        setRagioneSociale(null)
        setFormaGiuridica(null)
        setNumeroDipendenti(0)
        setPartitaIva(null)
        setCodicefiscale(null)
        setIndirizzoSedeLegale(null)
        setIndirizzoPec(null)
        setIndirizzoEmail(null)
        setTelefono1(null)
        setTelefono2(null)
        setCapitaleSociale(0)
        setInizioAttivita(null)
        setAmministratore({active:false,nome:"",cognome:"",telefono:""})
        setReferente({active:false,nome:"",cognome:"",telefono:""})
        setCategoria(null)
        setRichiestacliente({list:[]})
        setIdRichiestacliente({list:[]})
        setFinanziamentiincorsolista({list:[]})
        setCartaDiCredito(null)
        setRitardiPagamento("No")
        setAssicurazioniInCorso("No")
        setFinanziamentiInCorso("No")
        setCivico(null)
        setComune(null)
        setProvincia(null)
        setCap(null)
    }
    function handleOptionDefFinanziamento(value){
        setDefault_finanziamento({...default_finanziamento,coobligato:value})
    }
    
    function salvaIntervista(e){
        e.preventDefault()
        let datacheck = {
            tiporicerca:"ricercautente",
            tipopratica:tipopratica,
            ragionesociale:ragioneSociale,
            partitaiva:partitaIva
        }
        setSearching(true)
        axios.post('/api/1.1/cliente',datacheck,{headers:header}).then(res=>{
            if(res.data.exists && !partitaIvaObbligatoria){
                notifications.NotifyAlarm(true,""+ragioneSociale+" potrebbe essere già presente, richiesta partita iva per ulteriore verifica")
                setPartitaIvaObbligatoria(true)
                setSearching(false)
                return
            }else if(res.data.exists && partitaIvaObbligatoria){
                    notifications.NotifyAlarm(true,ragioneSociale+", ("+partitaIva+") già esiste")
                    setSearching(false)
                    return
            }
            let data = {
                customers:customers.list,
                pubblicita:pubblicita,
                ragioneSociale:ragioneSociale,
                formaGiuridica:formaGiuridica,
                numeroDipendenti:numeroDipendenti,
                partitaIva:partitaIva,
                codicefiscale:codicefiscale,
                indirizzoSedeLegale:indirizzoSedeLegale,
                civico:civico,
                comune:comune,
                provincia:provincia,
                cap:cap,
                indirizzoPec:indirizzoPec,
                indirizzoEmail:indirizzoEmail,
                telefono1:telefono1,
                telefono2:telefono2,
                capitaleSociale:capitaleSociale,
                inizioAttivita:inizioAttivita,
                isAmministratore:isAmministratore,
                isReferente:isReferente,
                amministratore:amministratore,
                referente:referente,
                categoria:categoria,
                cartaDiCredito:cartaDiCredito,
                ritardiPagamento:ritardiPagamento,
                assicurazioniInCorso:assicurazioniInCorso,
                finanziamentiInCorso:finanziamentiInCorso,
                finanziamentiincorsolista:finanziamentiincorsolista.list,
                richiestacliente:richiestacliente.list,
                id_richiestacliente:idRichiestacliente.list,
                tipopratica:tipopratica
            }
            axios.put('/api/1.1/cliente',data,{headers:header}).then((res)=>{
                notifications.NotifyAlarm(false,"operazione completata")
                  setDefaultValue()              
            }).catch(error=>{
                notifications.NotifyAlarm(true,"Operazione non riuscita")
            }) 
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Operazione non riuscita")
            setSearching(false)
            return
        })
    }       

    function rimuoviFinanziamento(i){
        let lista = finanziamentiincorsolista.list
        lista.splice(i,1)
        setFinanziamentiincorsolista({list:lista})
    }

    function aggiungiFinanziamento(e){
        e.preventDefault()
        let lista = finanziamentiincorsolista.list
        lista.push(default_finanziamento)
        setFinanziamentiincorsolista({list:lista})
        setDefault_finanziamento({
            tipo:"",
            data_prima_rata:"",
            rata:"",
            durata:"",
            coobligato:null
        })
    }
    function setAmministrazioneDati(e){
        setAmministratore({...amministratore,[e.target.name]:e.target.value})
    }
    function selectFinanziamento(e){
        let f=null
        if(settings.finanziamenti.list.filter(x=>x.nome === e.target.value).length>0){
            f=settings.finanziamenti.list.filter(x=>x.nome === e.target.value)[0]
        }
        setFinanziamentoSelected(f)
        setDefault_finanziamento({...default_finanziamento,[e.target.name]:e.target.value})
    }
    function onChangeDefFinanziamento(e){
        setDefault_finanziamento({...default_finanziamento,[e.target.name]:e.target.value})
    }
    function modificaListaCustomers(customer){
        let lista = customers.list
        var index = lista.indexOf(customer)
        if(index>=0){
            lista.splice(index, 1);
        }else{
            lista.push(customer)
        }
        setCustomers({...customers,list:lista})
    }
    function modificaRichiestaCliente(id,nome){
        let lista = richiestacliente.list
        let lista_id = idRichiestacliente.list

        var index = lista.indexOf(nome)
        if(index>=0){
            lista.splice(index, 1);
        }else{
            lista.push(nome)
        }
        var index_id = lista.indexOf(id)
        if(index_id>=0){
            lista_id.splice(index_id, 1);
        }else{
            lista_id.push(id)
        }
        setIdRichiestacliente({...idRichiestacliente,list:lista_id})
        setRichiestacliente({...richiestacliente,list:lista})
    }

    return (
        <div>
            <form onSubmit={(e)=>salvaIntervista(e)}>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Ragione sociale"} 
                        required onChange={(e)=>setRagioneSociale(e.target.value)} value={ragioneSociale} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <span>Customers</span><br/>
                    <div style={{border:"1px solid lightgrey",padding:"10px"}}>
                        {list_customers.map((cust,i)=>{
                            return(
                                <div key={i} className="form-check" >
                                    <input className="form-check-input" type="checkbox" required={customers.list.length===0} checked={customers.list.indexOf(cust)>-1} onChange={(e)=>modificaListaCustomers(cust)} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        {cust}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Telefono 1"} 
                        required onChange={(e)=>setTelefono1(e.target.value)} value={telefono1} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Telefono 2"} 
                        onChange={(e)=>setTelefono2(e.target.value)} value={telefono2} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel id="outline-fonte">Fonte</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            labelId="outline-fonte"
                            value={pubblicita} onChange={(e)=>setPubblicita(e.target.value)}
                            label="Backoffice Pratica"
                            >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {settings.fonti.list.filter((fonte)=>{
                                if(tipopratica==="AZIENDA" && fonte.isAzienda){
                                    return fonte
                                }
                                return null
                            }).map((item,i)=>{
                                return(
                                        <MenuItem key={i} value={item.nome}>{item.nome}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel id="outline-fg">Forma giuridica</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            labelId="outline-fg"
                            value={formaGiuridica} onChange={(e)=>setFormaGiuridica(e.target.value)}
                            label="Forma giuridica"
                            >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {list_formagiuridica && list_formagiuridica.map((item,i)=>{
                                return(
                                        <MenuItem key={i} value={item}>{item}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Numero dipendenti"} 
                        type="number" onChange={(e)=>setNumeroDipendenti(e.target.value)} value={numeroDipendenti} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                <TextField style={{width:"100%"}} label={"Indirizzo sede locale"} onChange={(e)=>setIndirizzoSedeLegale(e.target.value)} value={indirizzoSedeLegale} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                <TextField style={{width:"100%"}} label={"Civico"} onChange={(e)=>setCivico(e.target.value)} value={civico} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provincie ? 
                            <Autocomplete
                                id="combo-box-demo"
                                options={provincie}
                                getOptionLabel={(option) => option.name ? option.name : getProvinciaNomeByCode(option)}
                                style={{width:"100%"}}
                                name="provincia"
                                defaultValue={provincia}
                                onChange={(event, value) => {
                                    if(value){
                                        setProvincia(value.code);
                                    }else{
                                        setComune("");
                                        setProvincia("");
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Provincia"} value={provincia}/>}
                            />
                            :null}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provincia === "EE" ? 
                            <TextField style={{width:"100%"}} label={"Stato"} 
                                required onChange={(e)=>setComune(e.target.value)} value={comune}/>
                        :
                        provincie ? 
                            <Autocomplete
                                id="combo-box-c"
                                options={getComuniByProvincia(provincia)}
                                getOptionLabel={(option) =>{return option.nome ? option.nome : option}}
                                disabled={!provincia}
                                style={{width:"100%"}}
                                name="comunedinascita"
                                defaultValue={comune}
                                onChange={(event, value) => {
                                    if(value){
                                        setComune(value.nome);
                                        setCap(value.cap);
                                    }else{
                                        setComune("");
                                        setCap("")
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Comune"} value={comune}/>}
                                />
                        :null}
                    </Grid>
                </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                <TextField style={{width:"100%"}} label={"CAP"} onChange={(e)=>setCap(e.target.value)} value={cap} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                <TextField style={{width:"100%"}} label={"Codice fiscale"} onChange={(e)=>setCodicefiscale(e.target.value)} value={codicefiscale} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Partita iva"} required={partitaIvaObbligatoria} onChange={(e)=>setPartitaIva(e.target.value)} value={partitaIva} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                <   TextField style={{width:"100%"}} label={"Pec"} onChange={(e)=>setIndirizzoPec(e.target.value)} value={indirizzoPec} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                <TextField style={{width:"100%"}} label={"Email"} onChange={(e)=>setIndirizzoEmail(e.target.value)} value={indirizzoEmail} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                <TextField style={{width:"100%"}} label={"Capitale sociale (€)"} type="number" onChange={(e)=>setCapitaleSociale(e.target.value)} value={capitaleSociale} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Inizio attività"} onChange={(e)=>setInizioAttivita(e.target.value)} value={inizioAttivita}
                        InputLabelProps={{
                            shrink: true
                            }}
                        type="date"/> 
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <input type="checkbox" style={{marginRight:"15px"}} onChange={(e)=>setIsAmministratore(!isAmministratore)} value={isAmministratore} />
                    <span>Amministratore</span> <br/>
                    {isAmministratore ?
                        <div style={{border:"1px solid lightgrey",padding:"5px"}}>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom:"10px"}}>
                                <TextField style={{width:"100%"}} label={"Nome Amministratore"} name="nome" required className="form-control" onChange={(e)=>setAmministrazioneDati(e)} value={amministratore.nome} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom:"10px"}}>
                            <TextField style={{width:"100%"}} label={"Cognome Amministratore"} name="cognome" required className="form-control" onChange={(e)=>setAmministrazioneDati(e)} value={amministratore.cognome} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom:"10px"}}>
                            <TextField style={{width:"100%"}} label={"Telefono Amministratore"} name="telefono" required className="form-control" onChange={(e)=>setAmministrazioneDati(e)} value={amministratore.telefono} />
                            </Grid>
                        </div>
                    :null}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <input type="checkbox" style={{marginRight:"15px"}} onChange={(e)=>setIsReferente(!isReferente)} value={isReferente} />
                    <span>Referente</span> <br/>
                    {isReferente ?
                        <div style={{border:"1px solid lightgrey",padding:"5px"}}>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom:"10px"}}>
                                <TextField style={{width:"100%"}} label={"Nome Referente"} name="nome" required className="form-control" onChange={(e)=>setAmministrazioneDati(e)} value={amministratore.nome} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom:"10px"}}>
                            <TextField style={{width:"100%"}} label={"Cognome Referente"} name="cognome" required className="form-control" onChange={(e)=>setAmministrazioneDati(e)} value={amministratore.cognome} />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom:"10px"}}>
                            <TextField style={{width:"100%"}} label={"Telefono Referente"} name="telefono" required className="form-control" onChange={(e)=>setAmministrazioneDati(e)} value={amministratore.telefono} />
                            </Grid>
                        </div>
                    :null}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel id="outline-cg">Categoria</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            labelId="outline-cg"
                            value={categoria} onChange={(e)=>setCategoria(e.target.value)}
                            label="Categoria"
                            >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {list_categoria && list_categoria.map((item,i)=>{
                                return(
                                        <MenuItem key={i} value={item.nome}>{item.nome}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                <TextField style={{width:"100%"}} label={"Carta di credito"} onChange={(e)=>setCartaDiCredito(e.target.value)} value={cartaDiCredito} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <span>Richiesta cliente</span>
                    <div style={{maxHeight:"200px",overflowY:"scroll",border:"1px solid lightgrey", padding:"5px"}}>
                        {settings.prodotti.list.filter((pratica)=>{
                            if(tipopratica==="AZIENDA" && pratica.isAzienda){
                                return pratica
                            }
                            return null
                        }).map((item,i) => {
                            return(
                                <div className="form-check" key={i}>
                                    <input className="form-check-input" type="checkbox" checked={idRichiestacliente.list.indexOf(item.id)>-1} onChange={(e)=>modificaRichiestaCliente(item.id,item.nome)} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        {item.nome}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </Grid> 
            </Grid>
            <Grid container style={{marginBottom:"15px"}}>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{padding:"5px"}}>
                    <span>Ha mai subito ritardi su pagamenti?</span> <br/>
                    <FormControlLabel control={<Radio color="primary" />} label="SI" value="S" checked={ritardiPagamento === 'Si'} onChange={()=>setRitardiPagamento("Si")}/>
                    <FormControlLabel control={<Radio color="primary" />} label="NO" value="S" checked={ritardiPagamento === 'No'} onChange={()=>setRitardiPagamento("No")}/>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} style={{padding:"5px"}}>
                    <span>Assicurazione in corso?</span> <br/>
                    <FormControlLabel control={<Radio color="primary" />} label="SI" value="S" checked={assicurazioniInCorso === 'Si'} onChange={()=>setAssicurazioniInCorso("Si")}/>
                    <FormControlLabel control={<Radio color="primary" />} label="NO" value="S" checked={assicurazioniInCorso === 'No'} onChange={()=>setAssicurazioniInCorso("No")}/>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} style={{padding:"5px"}}>
                    <span>Ha finanziamenti in corso?</span> <br/>
                    <FormControlLabel control={<Radio color="primary" />} label="SI" value="S" checked={finanziamentiInCorso === 'Si'} onChange={()=>setFinanziamentiInCorso("Si")}/>
                    <FormControlLabel control={<Radio color="primary" />} label="NO" value="S" checked={finanziamentiInCorso === 'No'} onChange={()=>setFinanziamentiInCorso("No")}/>
                </Grid>
            </Grid>
            {finanziamentiInCorso === 'Si' ?
                <>
                    <div className="table-responsive-md">
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:"bold"}}>Tipo</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Rata</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Durata</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Coobligato</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Data prima rata</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Estinzione</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {finanziamentiincorsolista.list.map((finanziamento,i)=>{
                                        return(
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">{finanziamento.tipo}</TableCell>
                                                <TableCell>{finanziamento.rata}</TableCell>
                                                <TableCell>{finanziamento.durata}</TableCell>
                                                <TableCell>{finanziamento.coobligato === "S" ? "Si":null} {finanziamento.coobligato === "N" ? "No":null}</TableCell>
                                                <TableCell>{moment(new Date(finanziamento.data_prima_rata)).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{finanziamento.estinzione}</TableCell>
                                                <TableCell><Button size="small" variant="outlined" color="secondary" onClick={(e)=>rimuoviFinanziamento(i)}>Rimuovi</Button></TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>                      
                    <div style={{marginBottom:"15px"}}>
                        <hr/>
                        <h5>Aggiungi finanziamento:</h5>
                        <Grid container>
                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                            <FormControl style={{width:"100%"}}>
                                <InputLabel id="outline-tp">Tipo</InputLabel>
                                <Select
                                    style={{width:"100%"}}
                                    labelId="outline-tp"
                                    name="tipo"
                                    value={default_finanziamento.tipo} onChange={(e)=>selectFinanziamento(e)}
                                    label="Tipo"
                                    >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {settings.finanziamenti.list.filter((element)=>{
                                        if(tipopratica==="AZIENDA" && element.isAzienda){
                                            return element
                                        }
                                        return null
                                    }).map((finanziamento,i)=>{
                                        return(
                                                <MenuItem key={i} value={finanziamento.nome}>{finanziamento.nome}</MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                                <TextField style={{width:"100%"}} label={"Dec 1° rata"} value={default_finanziamento.data_prima_rata} 
                                InputLabelProps={{
                                    shrink: true
                                    }}
                                name="data_prima_rata" type="date" onChange={(e)=>onChangeDefFinanziamento(e)} /> 
                            </Grid>   
                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                                <TextField style={{width:"100%"}} label={"Rata"} value={default_finanziamento.rata} name="rata" type="number" onChange={(e)=>onChangeDefFinanziamento(e)} /> 
                            </Grid>                             
                        </Grid>
                        <Grid container>
                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                                <FormControl style={{width:"100%"}}>
                                    <InputLabel id="outline-dr">Durata</InputLabel>
                                    <Select
                                        style={{width:"100%"}}
                                        labelId="outline-dr"
                                        name="durata" value={default_finanziamento.durata} onChange={(e)=>onChangeDefFinanziamento(e)}
                                        label="Tipo"
                                        >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {duratafinanziamento.map((item,i) => {
                                            return(
                                                    <MenuItem key={i} value={item}>{item}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                                <FormControl style={{width:"100%"}}>
                                    <InputLabel id="outline-es">Estinzione</InputLabel>
                                    <Select
                                        style={{width:"100%"}}
                                        labelId="outline-ds"
                                        name="estinzione" value={default_finanziamento.estinzione} onChange={(e)=>{onChangeDefFinanziamento(e)}} 
                                        label="Estinzione"
                                        >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {settings.estinzioni.list.map((est,i)=>{
                                            return(
                                                    <MenuItem key={i} value={est.nome}>{est.nome}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {finanziamentoSelected && finanziamentoSelected.coobligato ?
                                <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                                    <span>Coobbligato</span>
                                    <div>                                    
                                        <FormControlLabel value="S" control={<Radio color="primary" />} checked={default_finanziamento.coobligato==="S"} label="SI" onChange={(e)=>handleOptionDefFinanziamento("S")} />
                                        <FormControlLabel value="N" control={<Radio color="primary" />} checked={default_finanziamento.coobligato==="N"} label="NO" onChange={(e)=>handleOptionDefFinanziamento("N")} />
                                    </div>
                                </Grid>
                            :null}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <span className="btn btn-sm btn-primary" onClick={(e)=>{aggiungiFinanziamento(e)}}>Aggiungi finanziamento</span>
                        </Grid>
                    </div>
                </>
            :null}
            <Grid container style={{marginBottom:"15px"}}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <span>Il cliente acconsente al trattamento dei dati?</span>
                    <div>
                        <FormControlLabel control={<Radio color="primary" />} required checked={confermaPrivacy} label="SI" onChange={(e)=>setConfermaPrivacy(true)} />
                        <FormControlLabel control={<Radio color="primary" />} checked={!confermaPrivacy} label="NO" onChange={(e)=>setConfermaPrivacy(false)} />
                    </div>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel id="outline-fg">Documento privacy</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            labelId="outline-fg"
                            value={documento} onChange={(e)=>setDocumento(e.target.value)}
                            label="Forma giuridica"
                            >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {settings.precompilati.list.map((item,i)=>{
                                return(
                                        <MenuItem key={i} value={item.id}>{item.alt}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                    <button disabled={!confermaPrivacy} onClick={(e)=>exportPDF(e)}> Scarica file privacy</button>
                </Grid>
            </Grid>
            <Button size="small" color="primary" variant="contained" type="submit" disabled={!confermaPrivacy && scaricato}>Salva</Button>
            </form>
        </div>
    );
}

export default IntervistaAzienda;