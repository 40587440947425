import React, { useState,useEffect } from 'react'
import AuthService from './AuthService'
import { Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import logo from '../../assets/logo.png'

var Auth = new AuthService()

function Login(props) {
    const [username, setUsername] = useState("")
    const [password, setpassword] = useState("")
    const [loggato, setLoggato] = useState(false)
    const [token, setToken] = useState(null)

    function checkPassword(e){
        e.preventDefault()
        Auth.login(username,password).then(res =>{
            if(!res.data.error){
                localStorage.setItem('tk', res.data.tk);
                localStorage.setItem('nome', res.data.nome);
                localStorage.setItem('rl', res.data.rl);
                localStorage.setItem('role', res.data.role);
                setToken(localStorage.getItem("tk"));
            }
        })
    }

    useEffect(() => {
        if(token!==null){
            setLoggato(true);
        }
    }, [token])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={""}>
                <h3 className="text-center text-white">
                    <img src={logo} alt=""/>
                </h3>
                <h5 style={{textAlign:"center"}}>isiloan.com</h5>
                <form className={""} onSubmit={(e)=>checkPassword(e)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        type="email"
                        label="Indirizzo email"
                        autoComplete="email"
                        autoFocus
                        value={username}
                        onChange={(e)=>setUsername(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e)=>setpassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        >
                        Login
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                            Passord dimenticata?
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            {loggato ? (
                <Redirect to="/"></Redirect>
            ):null}
      </Container>
    );
}
export default Login;