import React,{useState,useEffect,useContext} from 'react';
import initpreventivatore from "./initpreventivatore.json" 
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { AccountContext } from '../../contexts/AccountContext';
import { NotificationContext } from '../../contexts/NotificationContext';
import { Button, FormControl, FormControlLabel, Grid, InputLabel, Radio, Select, TextField } from '@material-ui/core';
import { MenuItem } from 'react-bootstrap-typeahead';

let Auth=new AuthService();
var headers=Auth.getHeadersJWT();

function SimulatoreManuale(props) {
    const notifications = useContext(NotificationContext);
    const [sesso, setSesso] = useState("M")
    const [data_nascita, setData_nascita] = useState(null)
    const [data_assunzione, setData_assunzione] = useState(null)
    // const [tipiImpiego] = useState(initpreventivatore.tipiImpiego)
    // const [qualifiche] = useState(initpreventivatore.qualifiche)
    const [numeroRate] = useState(initpreventivatore.numeroRate)
    const [tipiContratto] = useState(initpreventivatore.tipiContratto)
    const [tipoContrattoSelected, setTipoContrattoSelected] = useState(null)
    const [rataSelected, setRataSelected] = useState(null)
    const [stipendioNetto, setStipendioNetto] = useState(null)
    const [rata, setRata] = useState(null)
    const [isRinnovo, setIsRinnovo] = useState("N")
    const [nomeAssicurazione, setNomeAssicurazione] = useState("")
    const [nomeBanca, setNomeBanca] = useState("")
    const [nettoErogato, setNettoErogato] = useState(null)
    const [provvigione, setProvvigione] = useState(0)
    const [taeg, setTaeg] = useState(0)
    const [tan, setTan] = useState(0)
    const [teg, setTeg] = useState(0)
    const accounts = useContext(AccountContext);
    const [account, setAccount] = useState(null)
    console.log(props.pratica)
    function salvaPreventivoManuele(e){
        e.preventDefault()
        let data={
            id_pratica : props.pratica.id,
            preventivo : {
                nomeAssicurazione:nomeAssicurazione,
                nomeBanca:nomeBanca,
                sesso:sesso,
                dataNascita:data_nascita,
                dataAssunzione:data_assunzione,
                montante:Number(rataSelected)*Number(rata),
                taeg:taeg,
                teg:teg,
                tan:tan,
                importoRata:rata,
                numeroRate:rataSelected,
                PreventivoInErrore :false,
                DescrizioneErrorePreventivo:"",
                percentuale:provvigione,
                percentualesoc:account.percentuale_soc,
                provAgente:(Number(rataSelected)*Number(rata))*(Number(provvigione)/100),
                provAgenzia:(Number(rataSelected)*Number(rata))*(Number(account.percentuale_soc)/100),
                importoNetto:nettoErogato
            },
            isManuale : true
        }
        axios.put("/api/1.1/ws",data,{headers:headers}).then(res=>{
            props.reloadPreventivi(res.data.data)
            props.setIdPreventivoPredefinito(res.data.id_prefedinito) 
            notifications.NotifyAlarm(false,"Preventivo salvato")
        }).catch(error=>{
            console.log(error)
            notifications.NotifyAlarm(true,"Errore, preventivo non salvato")
        })
    }
    
    useEffect(() => {
        setAccount(accounts.account)
    }, [accounts])

    return (
        props.pratica && props.pratica.attiva || props.tipo==="cliente" ?
            <form onSubmit={(e)=>{salvaPreventivoManuele(e)}}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <span style={{marginRight:"5px"}}>Sesso: </span>
                        <FormControlLabel value="M" control={<Radio color="primary" />} checked={sesso==="M"} label="MASCHIO" onChange={()=>setSesso("M")}/>
                        <FormControlLabel value="F" control={<Radio color="primary" />} checked={sesso==="F"} label="FEMMINA" onChange={()=>setSesso("F")}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField required label="Data di nascita" type="date" value={data_nascita}
                            InputLabelProps={{
                            shrink: true
                            }}
                            fullWidth={true}
                            onChange={(e)=>{setData_nascita(e.target.value)}}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField required label="Data di assunzione" type="date"
                            InputLabelProps={{
                            shrink: true
                            }}
                            fullWidth={true}
                            onChange={(e)=>{setData_assunzione(e.target.value)}} value={data_assunzione}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outline-contratto">Tipo contratto*</InputLabel>
                            <Select
                                required
                                style={{width:"100%"}}
                                labelId="outline-contratto"
                                onChange={(e)=>{setTipoContrattoSelected(e.target.value)}}
                                label="Tipo contratto"
                                >
                                <MenuItem value=""><em>None</em></MenuItem>
                                    {tipiContratto && tipiContratto.map((element,i)=>{
                                        return(
                                            <MenuItem key={i} value={element.gruppoTipoContratto}>{element.descrizioneTipoContratto}</MenuItem>
                                        )
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outline-numero-rate">Nro rate*</InputLabel>
                            <Select
                                required
                                style={{width:"100%"}}
                                labelId="outline-numero-rate"
                                onChange={(e)=>{setRataSelected(e.target.value)}}
                                label="Nro rate"
                                >
                                <MenuItem value=""><em>None</em></MenuItem>
                                    {numeroRate && numeroRate.map((element,i)=>{
                                        return(
                                            <MenuItem key={i} value={element}>{element}</MenuItem>
                                        )
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Stipendio Netto" type="number" min={0} required onChange={(e)=>{setStipendioNetto(e.target.value)}} value={stipendioNetto} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Rata" type="number" min={0} required onChange={(e)=>{setRata(e.target.value)}} value={rata} />
                    </Grid>
                </Grid>
                <Grid container>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label="Provvigione" type="number" min={0} required onChange={(e)=>{setProvvigione(e.target.value)}} value={provvigione} />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                    <span style={{marginRight:"5px"}}>Rinnovo: </span>
                    <FormControlLabel value="S" control={<Radio color="primary" />} checked={isRinnovo==="S"} label="SI" onChange={()=>setIsRinnovo("S")}/>
                    <FormControlLabel value="N" control={<Radio color="primary" />} checked={isRinnovo==="N"} label="NO" onChange={()=>setIsRinnovo("N")}/>
                </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Montante" type="number" disabled={true} min={0} required value={Number(rataSelected)*Number(rata)} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Netto erogato" type="number" min={0} required onChange={(e)=>{setNettoErogato(e.target.value)}} value={nettoErogato} />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Taeg" type="number" min={0} required onChange={(e)=>{setTaeg(e.target.value)}} value={taeg} />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Tan" type="number" min={0} required onChange={(e)=>{setTan(e.target.value)}} value={tan} />
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Teg" type="number" min={0} required onChange={(e)=>{setTeg(e.target.value)}} value={teg} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Nome banca" type="text" min={0} required onChange={(e)=>{setNomeBanca(e.target.value)}} value={nomeBanca} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Nome assicurazione" type="text" min={0} required onChange={(e)=>{setNomeAssicurazione(e.target.value)}} value={nomeAssicurazione} />
                    </Grid>
                </Grid>
                <hr/>
                <Button size="small" type="submit" variant="contained" color="primary"><i className="fas fa-save"></i> Salva preventivo </Button>
            </form>
        :<div style={{textAlign:"center"}}>Pratica chiusa, impossibile aggiungere nuove simulazioni.</div>
    );
}

export default SimulatoreManuale;