import React,{useState,useEffect, useContext} from 'react';
import axios from 'axios'
import logo from './assets/logo.png'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme,withStyles  } from '@material-ui/core/styles';
import { Link, Redirect } from 'react-router-dom';
import { Avatar, MenuItem,Badge, CircularProgress,Button, Grid } from '@material-ui/core';
import {Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {NotificationContext} from './contexts/NotificationContext'
import {AccountContext} from './contexts/AccountContext'
import {SettingContext} from './contexts/SettingContext'
import blueGrey from '@material-ui/core/colors/blueGrey';
const color = blueGrey[50];

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#f50a19',
    color: '#f50a19',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: '$ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

//https://tarikhuber.github.io/material-ui-responsive-drawer/
const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
}));

function App(props) {
  let links=[
      {cls:"fas fa-home",link:"",name:"Home"},
      {cls:"fas fa-calendar-alt",link:"agenda",name:"Agenda"},
      {cls:"fas fa-clipboard-list",link:"work-list-cliente",name:"Worklist clienti"},
      {cls:"fas fa-list",link:"work-list-pratica",name:"Worklist pratiche"},
      {cls:"fas fa-project-diagram",link:"simulatore",name:"Simulatore"},
      {cls:"fas fa-file-import",link:"liste",name:"Liste"},
      {cls:"fas fa-chart-line",link:"statistiche",name:"Statistiche"},
      {cls:"fas fa-euro-sign",link:"fatturazione",name:"Fatturazione"},
      {cls:"fas fa-cogs",link:"impostazioni",name:"Impostazioni"}
  ]
//   RICONTROLLARE TUTTE LE FORM CHE NON CI SONO AL MOMENTO
    const [account, setAccount] = useState(null)
    const [itemSelected, setItemSelected] = useState("Home")
    const [alertState, setAlertState] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [alertSeverity, setAlertSeverity] = useState("success")
    const [isLoggedUser, setIsLoggedUser] = useState(false)
    const [isValidUser, setIsValidUser] = useState(true)
    const [fonti, setFonti] = useState({list:[]})
    const [prodotti, setProdotti] = useState({list:[]})
    const [finanziamenti, setFinanziamenti] = useState({list:[]})
    const [estinzioni, setEstinzioni] = useState(null)
    const [precompilati, setPrecompilati] = useState([])

    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }

    const drawer = (
        <List>
            {links.map((text, i) => (
                <div key={i}>
                    <Link key={i} to={"/"+text.link} style={{ textDecoration: 'none',color:"inherit"}} onClick={()=>{setMobileOpen(false);setItemSelected(text.name)}}>
                        <MenuItem selected={text.name === itemSelected}><i className={text.cls} style={{marginRight:"10px"}}></i> {text.name}</MenuItem>
                    </Link>
                </div>
            ))}
        </List>
    );

    useEffect(() => {
      if(localStorage.getItem("tk")){
        let header ={ 'headers': { 'Authorization': 'dtoken '+localStorage.getItem("tk")}}
        axios.all([
          axios.get('/api/1.1/fonti',header),
          axios.get('/api/1.1/prodotto',header),
          axios.get('/api/1.1/tipofinanziamento',header),
          axios.get('/api/1.1/estinzione',header),
          axios.get('/api/1.1/precompilato',header),
          axios.get('/api/1.1/account',header)
        ])
        .then(axios.spread((res1,res2,res3,res4,res5,res6) => {
          setFonti({list:res1.data.data})
          setProdotti({list:res2.data.data})
          setFinanziamenti({list:res3.data.data})
          setEstinzioni({list:res4.data.data})
          setPrecompilati({list:res5.data.data});
          setIsValidUser(true)
          setAccount(res6.data.data)
          setIsLoggedUser(true)
        })).catch((error)=>{
          setIsValidUser(false)
        }) 
      }else{
        window.location.href = "/login"
      }
    }, [localStorage.getItem("tk")])

    function NotifyAlarm(error,messaggio){
      setAlertState(true);
      setAlertSeverity(error ? "error": "success");
      setAlertMessage(messaggio);
    }
    function AggiornaAccount(item){
      setAccount(item)
    }
    function AggiornaFonti(value){
      setFonti(value)
    }
    function AggiornaProdotti(value){
      setProdotti(value)
    }
    function AggiornaFinanziamenti(value){
      setFinanziamenti(value)
    }
    function AggiornaEstinzioni(value){
      setEstinzioni(value)
    }
    function AggiornaPrecompilati(value){
      setPrecompilati(value)
    }

    return (
      <div>
      {isLoggedUser && account ?
        <AccountContext.Provider value={{account:account,setAccount:AggiornaAccount}}>
          <SettingContext.Provider value={{
            fonti:fonti,setFonti:AggiornaFonti,
            prodotti:prodotti,setProdotti:AggiornaProdotti,
            finanziamenti:finanziamenti,setFinanziamenti:AggiornaFinanziamenti,
            estinzioni:estinzioni,setEstinzioni:AggiornaEstinzioni,
            precompilati:precompilati,setPrecompilati:AggiornaPrecompilati
            }}>

          <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar} style={{width:"100%"}}>
              <Toolbar>
              <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}>
                  <MenuIcon />
              </IconButton>
              <img src={logo} alt="" style={{height:"40px",width:"40px",marginRight:"10px"}}/>
              <Typography variant="h6" color="inherit">
                isiloan
              </Typography>
                <Link to="/newclient" style={{position:'absolute',right:"15px"}}> 
                    <Button size="small" color={color} variant="outlined"><i className="fas fa-plus"></i> Aggiungi Cliente</Button>
                </Link>
              </Toolbar>
          </AppBar>

          <nav className={classes.drawer}>
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <Hidden smUp implementation="css">
              <Drawer
                  variant="temporary"
                  anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                  paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                  }}
              >
                  <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                  <CloseIcon/>
                  </IconButton>
                  <div style={{margin:"15px"}}>
                    <Link to={"/account"} style={{color:"inherit",textDecoration:"none"}} onClick={handleDrawerToggle}>
                      <div style={{textAlign:"center",padding:"5px"}}>
                        {account.nome ? 
                          account && !(new Date(account.valid_to) >= new Date()) ? 
                            <StyledBadge
                              overlap="circle"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                }}
                                variant="dot"
                              >
                              <Avatar alt={localStorage.getItem("nome")} style={{backgroundColor:"orange", color:"white",margin:"0 auto"}}>
                                  {localStorage.getItem("nome").charAt(0)}
                              </Avatar>
                            </StyledBadge>
                            :
                              <Avatar alt={localStorage.getItem("nome")} style={{backgroundColor:"orange", color:"white",margin:"0 auto"}}>
                                  {localStorage.getItem("nome").charAt(0)}
                              </Avatar>
                          :
                            <div style={{textAlign:"center",marginTop:"30px"}}>
                              <CircularProgress size="30px"></CircularProgress>
                            </div>}
                        </div>
                        <div style={{textAlign:"center"}}>
                          <b>{localStorage.getItem("nome")}</b>
                        </div>
                      </Link>
                      {account ? 
                        <div style={{textAlign:"center"}}>
                            <small>({account.ragione_sociale}) - <a href="/logout" style={{color:"inherit",textDecoration:"none"}}><i className="fas fa-sign-out-alt"></i></a></small>
                        </div>
                      :null}
                      <hr/>
                  </div>
                  {drawer}
              </Drawer>
              </Hidden>
              <Hidden xsDown implementation="css">
              <Drawer
                  className={classes.drawer}
                  variant="permanent"
                  classes={{
                  paper: classes.drawerPaper,
                  }}>
                  
                  <div className={classes.toolbar} />
                  <div style={{margin:"15px"}}>
                    <Link to={"/account"} style={{color:"inherit",textDecoration:"none"}}>
                      <div style={{textAlign:"center",padding:"5px"}}>
                        
                        {account.nome ? 
                          account && !(new Date(account.valid_to) >= new Date()) ? 
                            <StyledBadge
                              overlap="circle"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                                }}
                                variant="dot"
                              >
                              <Avatar alt={account.nome} style={{backgroundColor:"orange", color:"white",margin:"0 auto"}}>
                                  {localStorage.getItem("nome").charAt(0)}
                              </Avatar>
                            </StyledBadge>
                          :
                              <Avatar alt={localStorage.getItem("nome")} style={{backgroundColor:"orange", color:"white",margin:"0 auto"}}>
                                  {localStorage.getItem("nome").charAt(0)}
                              </Avatar>
                          :
                          <div style={{textAlign:"center",marginTop:"30px"}}>
                            <CircularProgress size="30px"></CircularProgress>
                          </div>}
                      </div>
                      <div style={{textAlign:"center"}}>
                          <b>{localStorage.getItem("nome")}</b>
                      </div>
                    </Link>
                    {account? 
                        <div style={{textAlign:"center"}}>
                            <small>({account.ragione_sociale}) - <a href="/logout" style={{color:"inherit",textDecoration:"none"}}><i className="fas fa-sign-out-alt"></i></a></small>
                        </div>
                      :null}
                    <hr/>
                  </div>
                  {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <div className={classes.content} style={{padding:"10px",width:"100px",paddingTop:"80px"}}>
              <NotificationContext.Provider value={{NotifyAlarm}}>
                {props.children}
              </NotificationContext.Provider>
          </div>
          {alertState && alertMessage && alertSeverity ? 
            <Snackbar anchorOrigin={{vertical:'bottom',horizontal:'right'}}
                open={alertState}
                autoHideDuration={3000}
                onClose={()=>setAlertState(false)}
                key={'bottom-right'}>
                <MuiAlert elevation={6} variant="filled" onClose={()=>setAlertState(false)} severity={alertSeverity} >
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
          :null}
      </div>
      </SettingContext.Provider>
      </AccountContext.Provider>
    :
    <div style={{textAlign:"center",marginTop:"50px"}}>
      <CircularProgress size="60px"></CircularProgress>
    </div>}
    {isValidUser===false ? 
        <Redirect to="/login"></Redirect>
      :null}
    </div>
    );
}

export default App;