import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import { Button, Grid } from '@material-ui/core';
import moment from 'moment'
import ViewRisultato from './ViewRisultato';

let Auth=new AuthService();
var headers=Auth.getHeadersJWT();
function RisultatoSimulatore(props) {
    const notifications = useContext(NotificationContext);
    const [loadError, setLoadError] = useState(props.loadError)
    const [idPreferinito, setIdPreferinito] = useState(props.idPredefinito)
    const [preventivo] = useState(props.preventivo)
    const [isCollapsed, setIsCollapsed] = useState(true)
    const [saved] = useState(props.saved)
    const [approved] = useState(props.approved)
    const [isCancellato, setIsCancellato] = useState(false)

    useEffect(() => {
        setLoadError(props.loadError)
    }, [props.loadError])

    useEffect(() => {
        setIdPreferinito(props.idPredefinito)
    }, [props.idPredefinito])

    function salvaPreventivoToPratica(e){
        e.preventDefault()
        let data={
            id_pratica : props.id_pratica,
            preventivo : preventivo
        }
        axios.put("/api/1.1/ws",data,{headers:headers}).then(res=>{
            props.reloadPreventivi(res.data.data)
            props.setNewIdPrevenivoPredefinito(res.data.id_prefedinito) 
            notifications.NotifyAlarm(false,"Preventivo salvato")
        }).catch(error=>{
            console.log(error)
            notifications.NotifyAlarm(true,"Errore, preventivo non salvato")
        })
    }

    function impostaComePrefefinito(e){
        e.preventDefault()
        let data={
            type:"setpredefinito",
            id_pratica : props.id_pratica,
            id_preventivo : preventivo.id
        }
        axios.put("/api/1.1/ws",data,{headers:headers}).then(res=>{
            props.setNewIdPrevenivoPredefinito(preventivo.id)
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Errore, operazione non valida")
        })
    }
    function cancella(e){
        var r = window.confirm("Vuoi cancellare il preventivo?");
        if (r === true) {
            e.preventDefault()
            axios.delete("/api/1.1/ws/"+preventivo.id,{headers:headers}).then(res=>{
                notifications.NotifyAlarm(false,"Preventivo Cancellato")
                setIsCancellato(true)
            }).catch(error=>{
                notifications.NotifyAlarm(true,"Errore, operazione non valida")
            })
        }
    }
    return (
        <div hidden={isCancellato}>
            <div className="card" style={{ marginBottom: '1rem' }} hidden={!loadError && preventivo.PreventivoInErrore===true}>
                <div className="card-header" onClick={()=>setIsCollapsed(!isCollapsed)}>
                    <Grid container onClick={()=>setIsCollapsed(!isCollapsed)} style={{cursor:"pointer"}}>
                        <Grid item lg={6} md={6} sm={6} xs={12} onClick={()=>setIsCollapsed(!isCollapsed)} >
                            {preventivo.PreventivoInErrore===true ? 
                                <span className="badge badge-danger">
                                X
                                </span>
                            :null} 
                            <b>{preventivo.nomeBanca} - {preventivo.nomeAssicurazione}</b>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12} onClick={()=>setIsCollapsed(!isCollapsed)}>TAEG <b>{preventivo.taeg !=="---" ? preventivo.taeg.toString() + " %" : "---"} </b></Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12} onClick={()=>setIsCollapsed(!isCollapsed)} ><b>{preventivo.importoNetto} €</b></Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            {preventivo.timestamp ? 
                            <span className="badge badge-primary">
                                {moment(new Date(preventivo.timestamp)).format("DD/MM/YYYY HH:mm")}
                            </span>
                            :null}
                            {idPreferinito===preventivo.id ? 
                                <span className="badge badge-success" style={{marginLeft:"15px"}}>
                                    Predefinito
                                </span>
                            :null}
                        </Grid>
                    </Grid>
                </div>
                <div hidden={isCollapsed}>
                    <div className="card-body" style={{padding:"5px"}}>
                        <ViewRisultato preventivo={preventivo} />
                        {!props.chiusa ? 
                            !saved ? 
                                <div>
                                    {props.id_pratica && preventivo.PreventivoInErrore!==true ? <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{salvaPreventivoToPratica(e)}}>Salva preventivo</Button>:null}
                                </div>
                            :!approved ?
                                <Grid container style={{marginTop:"15px"}}>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Button size="small" type="submit" variant="contained" color="primary" disabled={idPreferinito===preventivo.id} onClick={(e)=>{impostaComePrefefinito(e)}}><i className="fas fa-cogs"></i> Imposta come predefinito</Button>
                                        <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{cancella(e)}} style={{marginLeft:"5px"}}><i className="fas fa-trash"></i> Cancella</Button>
                                    </Grid>
                                </Grid>
                            :null
                        :null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RisultatoSimulatore;