import React,{useEffect,useContext,useState} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import {AppBar, Button, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@material-ui/core';
import TabPanel from '../../commons/TabPanel';
import moment from 'moment'
import DefaultSpinner from '../DefaultSpinner';
import ModalSchemaFatturazione from '../modali/ModalSchemaFatturazione';

let Auth = new AuthService();
let header=Auth.getHeadersJWT();

function Fatturazione(props) {
  const notifications = useContext(NotificationContext);
  const [periodo, setPeriodo] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [pratiche, setPratiche] = useState({list:[]})
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(true)
  const [selectedPratica, setSelectedPratica] = useState(null)

  function closeOpenModal(value,pratica){
    setSelectedPratica(pratica)
    setOpenModal(value)
  }

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  function pagaPratica(pratica_id){
    let data={id:pratica_id,type:"setpagata"};
    axios.put('/api/1.1/pratica', data, {headers:header}).then((res)=>{
        notifications.NotifyAlarm(false,"Pratica contrassegnata come pagata")
    }).catch(error=>{
      notifications.NotifyAlarm(false,"Errore assegnazione pagamento")
    })
  }

  useEffect(() => {
      axios.get('/api/1.1/fatturazione',{headers:header}).then((res)=>{
          setPratiche({list:res.data.data})
          setLoading(false)
      })
  }, [])

  return (
    !loading ?
      <div>
          <h4>Fatturazione</h4>
          <AppBar position="relative" color="default" style={{zIndex:"0"}} >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
              {/* <Tab label={"PRATICHE APERTE"} {...a11yProps(0)} /> */}
              <Tab label={"LIQUIDATE"} {...a11yProps(0)} />
              <Tab label={"PAGATE"} {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          {/* <TabPanel value={value} index={0}>
              PRATICHE APERTE? (SERVE)
          </TabPanel> */}
          <TabPanel value={value} index={0}>
          {pratiche && pratiche.list.filter((element) => {return element.liquidata === true && !element.data_pagata}).length>0 ?
            <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                  <TableHead>
                      <TableRow>
                        <TableCell style={{fontWeight:"bold"}}></TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Codice pratica</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Cliente</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Venditore</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Data caricamento</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Data approvazione</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Data liquidata</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Montante</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>3%</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>	&gt;3%</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Banca</TableCell>
                        <TableCell style={{fontWeight:"bold"}}></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {pratiche && pratiche.list.filter((element) => {return element.liquidata === true && !element.data_pagata})
                    .map((pratica,i)=>{
                      return(
                        <TableRow key={i}>
                            <TableCell></TableCell>
                            <TableCell>{(pratica.codice_pratica+"").padStart(6, '0')}</TableCell>
                            <TableCell>{pratica.anagrafica.nome+" "+pratica.anagrafica.cognome}</TableCell>
                            <TableCell>{pratica.venditore ? pratica.venditore.nome+" "+pratica.venditore.cognome : "-"}</TableCell>
                            <TableCell>{moment(pratica.timestamp).format("DD/MM/YYYY")}</TableCell>
                            <TableCell>{moment(pratica.data_approvazione).format("DD/MM/YYYY")}</TableCell>
                            <TableCell>{moment(pratica.data_liquidata).format("DD/MM/YYYY")}</TableCell>
                            <TableCell>{Number(JSON.parse(pratica.preventivo_predefinito).montante).toFixed(2)}€</TableCell>
                            <TableCell>{Number(JSON.parse(pratica.preventivo_predefinito).provAgenzia).toFixed(2)}€</TableCell>
                            <TableCell>{Number(JSON.parse(pratica.preventivo_predefinito).provAgente).toFixed(2)}€</TableCell>
                            <TableCell>{JSON.parse(pratica.preventivo_predefinito).nomeBanca}</TableCell>
                            <TableCell>
                              <Button size="small" variant="outlined" color="secondary" onClick={()=>closeOpenModal(true,pratica)}>%</Button>
                              <Button size="small" variant="outlined" color="primary" onClick={()=>pagaPratica(pratica.id)}>Paga</Button>
                            </TableCell>
                        </TableRow>
                      )
                    })}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>
                        {/* <Button size="small" variant="contained" color="primary" onClick={()=>console.log("scaricare")}><i className="fas fa-file-download"></i>Scarica</Button> */}
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <b>{pratiche && pratiche.list.filter((element) => {return element.liquidata === true && !element.data_pagata})
                      .reduce(function(tot, pratica) { 
                          return (tot + Number(JSON.parse(pratica.preventivo_predefinito).montante))
                        },0).toFixed(2)}€</b></TableCell>
                        <TableCell>
                        <b>{pratiche && pratiche.list.filter((element) => {return element.liquidata === true && !element.data_pagata})
                      .reduce(function(tot, pratica) { 
                          return (tot + Number(JSON.parse(pratica.preventivo_predefinito).provAgenzia))
                        },0).toFixed(2)}€</b></TableCell>
                        <TableCell>
                          <b>{pratiche && pratiche.list.filter((element) => {return element.liquidata === true && !element.data_pagata})
                      .reduce(function(tot, pratica) { 
                          return (tot + Number(JSON.parse(pratica.preventivo_predefinito).provAgente))
                        },0).toFixed(2)}€</b></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                  </TableRow>
                  </TableBody>
              </Table>
            </TableContainer>
            :<div style={{textAlign:'center'}}>Nessuna pratica al momento</div>}
          </TabPanel>
          <TabPanel value={value} index={1}>
          {pratiche && pratiche.list.filter((element) => {return element.liquidata === true && element.data_pagata}).length>0 ?
          <TableContainer component={Paper}>
              <Table aria-label="simple table" size="small">
                  <TableHead>
                      <TableRow>
                        <TableCell style={{fontWeight:"bold"}}></TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Codice pratica</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Cliente</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Venditore</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Data caricamento</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Data approvazione</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Data liquidata</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Montante</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>3%</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>	&gt;3%</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Banca</TableCell>
                        <TableCell style={{fontWeight:"bold"}}>Pagamento</TableCell>
                        <TableCell style={{fontWeight:"bold"}}></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                    {pratiche && pratiche.list.filter((element) => {
                        return element.liquidata === true && element.data_pagata
                    }).map((pratica,i)=>{
                      return(
                        <TableRow key={i} style={{fontSize:"14px !important"}}>
                            <TableCell></TableCell>
                            <TableCell>{(pratica.codice_pratica+"").padStart(6, '0')}</TableCell>
                            <TableCell>{pratica.anagrafica.nome+" "+pratica.anagrafica.cognome}</TableCell>
                            <TableCell>{pratica.venditore ? pratica.venditore.nome+" "+pratica.venditore.cognome : "-"}</TableCell>
                            <TableCell>{moment(pratica.timestamp).format("DD/MM/YYYY")}</TableCell>
                            <TableCell>{moment(pratica.data_approvazione).format("DD/MM/YYYY")}</TableCell>
                            <TableCell>{moment(pratica.data_liquidata).format("DD/MM/YYYY")}</TableCell>
                            <TableCell>{Number(JSON.parse(pratica.preventivo_predefinito).montante).toFixed(2)}€</TableCell>
                            <TableCell>{Number(JSON.parse(pratica.preventivo_predefinito).provAgenzia).toFixed(2)}€</TableCell>
                            <TableCell>{Number(JSON.parse(pratica.preventivo_predefinito).provAgente).toFixed(2)}€</TableCell>
                            <TableCell>{JSON.parse(pratica.preventivo_predefinito).nomeBanca}</TableCell>
                            <TableCell>{moment(pratica.data_pagata).format("DD/MM/YYYY")}</TableCell>
                            <TableCell> 
                              <Button size="small" variant="outlined" color="secondary" onClick={()=>closeOpenModal(true,pratica)}>%</Button>
                            </TableCell>
                        </TableRow>
                      )
                    })}
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <b>{pratiche && pratiche.list.filter((element) => {return element.liquidata === true && element.data_pagata})
                      .reduce(function(tot, pratica) { 
                          return (tot + Number(JSON.parse(pratica.preventivo_predefinito).montante))
                        },0).toFixed(2)}€</b></TableCell>
                        <TableCell>
                        <b>{pratiche && pratiche.list.filter((element) => {return element.liquidata === true && element.data_pagata})
                      .reduce(function(tot, pratica) { 
                          return (tot + Number(JSON.parse(pratica.preventivo_predefinito).provAgenzia))
                        },0).toFixed(2)}€</b></TableCell>
                        <TableCell>
                          <b>{pratiche && pratiche.list.filter((element) => {return element.liquidata === true && element.data_pagata})
                      .reduce(function(tot, pratica) { 
                          return (tot + Number(JSON.parse(pratica.preventivo_predefinito).provAgente))
                        },0).toFixed(2)}€</b></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                  </TableRow>
                  </TableBody>
              </Table>
            </TableContainer>
            :
            <div style={{textAlign:'center'}}>Nessuna pratica al momento</div>}
          </TabPanel>
          {console.log(selectedPratica)}
          {selectedPratica ? 
            <ModalSchemaFatturazione isOpenModal={openModal} closeOpenModal={closeOpenModal} pratica={selectedPratica}></ModalSchemaFatturazione>
          :null}
      </div>
      :
      <div style={{textAlign:'center'}}>
        <DefaultSpinner></DefaultSpinner>
      </div>
  );
}

export default Fatturazione;