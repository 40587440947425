
import React, { useState, useEffect } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import Button from '@material-ui/core/Button';

function ModalEstinzione(props) {
    const [nome, setNome] = useState("")
    const [id, setId] = useState(null)
    function chiudiModal(){
      props.closeModal(false)
    }
    function saveNuovoTipo(e){
        e.preventDefault()
        let data = {
            nome:nome,
            id:id,
        }
        props.saveNewTipo(data)
    }

    useEffect(() => {
        if(props.estinzione){
            setNome(props.estinzione.nome)
            setId(props.estinzione.id)
        }else{
            setNome("")
            setId(null)
        }
    }, [props.estinzione])

    return (
            <Modal isOpen={props.isOpenModal} className="modal-lg">
              <form onSubmit={(e)=>saveNuovoTipo(e)}>
                <ModalBody>
                  <div>
                    <ModalHeader>
                      <div style={{fontWeight:"bold",fontSize:"20px"}}>Estinzione</div>
                    </ModalHeader>
                  </div>
                  <div>
                      <div className="form-group-sm">
                        <label>Nome</label>
                        <input type="text" required className="form-control" value={nome} onChange={(e)=>{setNome(e.target.value)}}/>
                      </div>
                  </div>
                  <ModalFooter>
                    <Button size="small" type="submit" variant="contained" color="primary"> <i className="far fa-save"></i> Salva</Button>
                    <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{e.preventDefault();chiudiModal()}}><i className="fas fa-times"></i> Chiudi</Button>
                  </ModalFooter>   
                </ModalBody>
            </form>
        </Modal>
    );
}

export default ModalEstinzione;