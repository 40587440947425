import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import DefaultSpinner from '../DefaultSpinner';
import Button from '@material-ui/core/Button';
import { Checkbox, Grid, TextField } from '@material-ui/core';
import { AccountContext } from '../../contexts/AccountContext';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

let lista_preventivatori = [{name:"BANCA PROGETTO", code:"banca_progetto"},{name:"FINCONTINUO", code:"fincontinuo"},
                        {name:"IFIVER", code:"ifiver"},{name:"ADV", code:"adv"},{name:"PROV MANUALE",code:"provmanuale"}]

function Webservices(props) {
    const notifications = useContext(NotificationContext);
    const accounts = useContext(AccountContext);
    const [account, setAccount] = useState(null)

    function setPreventivoManuale(){
        setAccount({...account, preventivo_manuale:!account.preventivo_manuale});
    }
    function setPercentualiManuale(e){
        setAccount({...account, [e.target.name]:e.target.value});
    }

    function setAttivo(code){
        let newCredenziali = JSON.parse(account.credenziali_ws)
        newCredenziali.forEach(element => {
            if(element.code === code){
                element.attivo = !element.attivo
            }
        });
        setAccount({...account, credenziali_ws:JSON.stringify(newCredenziali)})
    }
    

    function setCredenziali(code,name,value){
        let newCredenziali = JSON.parse(account.credenziali_ws)
        newCredenziali.forEach(element => {
            if(element.code === code){
                element.credenziali[name] = value
            }
        });
        setAccount({...account, credenziali_ws:JSON.stringify(newCredenziali)})
    }

    function setPercentuali_default(code,name,value){
        let newCredenziali = JSON.parse(account.credenziali_ws)
        newCredenziali.forEach(element => {
            if(element.code === code){
                element.percentuali_default[name] = value
            }
        });
        setAccount({...account, credenziali_ws:JSON.stringify(newCredenziali)})
    }

    function salvaConfigurazioni(e){
        e.preventDefault()
        axios.put('/api/1.1/account',account,{headers:header}).then((res)=>{
            accounts.setAccount(res.data)
            notifications.NotifyAlarm(false,"Salvataggio effettuato")
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Qualcosa è andato storto, riprova")
        })
    }
    
    useEffect(() => {
        setAccount(accounts.account)
    }, [accounts])

    return (
        <>
            {account ?
            <form onSubmit={(e)=>salvaConfigurazioni(e)}>
            <Grid item lg={12} md={12} sm={12} xs={12}>   
                <div style={{margin:"15px 0"}}>
                    <img src={account.url_logo} style={{width:"300px",marginRight:"15px"}} alt=""/> 
                    <Button size="small" type="submit" variant="contained" color="primary"><i className="far fa-save"></i> Salva modifiche</Button> 
                </div>
                <div style={{marginTop:"20px"}}>
                    <div>
                        <h4><b>Preventivo Manuale</b></h4>
                        <h5>
                            <Checkbox size="small" color="primary" defaultChecked={account.preventivo_manuale} onChange={(e)=>{setPreventivoManuale()}}/>
                            <label>
                                Abilita preventivo manuale
                            </label>
                        </h5>
                        {account.preventivo_manuale ? 
                        <Grid container>
                            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                <TextField style={{width:"100%"}} label={"% Società"} 
                                    required value={account.percentuale_soc} name="percentuale_soc" onChange={(e)=>{setPercentualiManuale(e)}} type="number" />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                <TextField style={{width:"100%"}} label={"% max Collaboratore"} 
                                    required value={account.percentuale_collaboratore} name="percentuale_collaboratore" onChange={(e)=>{setPercentualiManuale(e)}} type="number" />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                Percentuale massima chiamabile: <b>{Number(account.percentuale_collaboratore)+Number(account.percentuale_soc)} %</b>
                            </Grid>
                        </Grid>
                        :null}
                        <hr/>
                    </div>
                    <div>
                        <h4><b>Web service abilitati</b></h4>
                        {JSON.parse(account.credenziali_ws).map((ws,i)=>{
                            return(
                                <div key={i} style={{borderBottom:"1px solid grey"}}>
                                    <h5>
                                        <Checkbox size="small" color="primary" onChange={(e)=>{setAttivo(ws.code)}} defaultChecked={ws.attivo}/>
                                            <label>
                                            {lista_preventivatori.find(x=>x.code === ws.code).name}
                                            </label>
                                        </h5>
                                    {ws.attivo ?
                                        <>
                                            <Grid container>
                                                <Grid item lg={12} md={12} sm={6} xs={12}>Credenziali:</Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                                    <TextField style={{width:"100%"}} label={"Username"} 
                                                        required value={ws.credenziali.username} name="username" onChange={(e)=>{setCredenziali(ws.code,e.target.name,e.target.value)}} type="text"/>
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                                    <TextField style={{width:"100%"}} label={"Password"} 
                                                        required value={ws.credenziali.password} name="password" onChange={(e)=>{setCredenziali(ws.code,e.target.name,e.target.value)}} type="text" />
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                                    <TextField style={{width:"100%"}} label={"% Società"} 
                                                        required value={ws.percentuali_default.perc_societa} name="perc_societa" onChange={(e)=>{setPercentuali_default(ws.code,e.target.name,e.target.value)}} type="number" />                                                        
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                                    <TextField style={{width:"100%"}} label={"% max Collaboratore"} 
                                                        required value={ws.percentuali_default.perc_collaboratore} name="perc_collaboratore" onChange={(e)=>{setPercentuali_default(ws.code,e.target.name,e.target.value)}} type="number" />
                                                </Grid>
                                                <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                                    Percentuale massima chiamabile: <b>{Number(ws.percentuali_default.perc_collaboratore)+Number(ws.percentuali_default.perc_societa)} %</b>
                                                </Grid>
                                            </Grid>
                                        </>    
                                    :null}
                            </div>
                            )
                        })}
                    </div>
                </div>
            </Grid>
        </form>
        : <DefaultSpinner></DefaultSpinner>}
    </>
    );
}

export default Webservices;