import {ADD_CLIENTE,SET_CLIENTI,SET_RAPPRESENTANTE,SET_DECORRENZA_PRATICA,SET_ALARM_PRATICA} from '../actions/clienteAction'
const initialState = {
    list:[]
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENTI:
        state={...state, list:action.payload.list}
        return state;
    case SET_DECORRENZA_PRATICA:
      let listad=state.list;
      listad.forEach(element => {
        if(element.id===action.payload.codicepratica){
          element.decorrenza=action.payload.data
        }
      });
      state.list=listad;
      break;
    case SET_ALARM_PRATICA:
      let listaa=state.list;
      listaa.forEach(element => {
          if(element.id===action.payload.codicepratica){
            element.alarm=action.payload.data
          }
        });
        state.list=listaa;
        break;
    case SET_RAPPRESENTANTE:
      let lista=state.list;
      lista.forEach(element => {
        if(element.id===action.payload.codicepratica){
          if(element.id_assegnataa===action.payload.rappresentante){
            element.id_assegnataa="";
          }else{
            element.id_assegnataa=action.payload.rappresentante;
          }
        }
      });
      state.list=lista;
      break;
    case ADD_CLIENTE:
      break;
    default:
      break;
  }
  return state;
}
