import React, { useEffect, useState } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import { Button, Checkbox, FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import { MenuItem } from 'react-bootstrap-typeahead';

function ModalUtentiImpostazione(props) {
    const [listautenti, setListautenti] = useState({list:[]})
    const [totale, setTotale] = useState(0)
    const [agenzia, setAgenzia] = useState(null)

    function chiudiModal(){
        props.chiudiModal(null)
    }
    function assegnaTitolari(){
        props.assegnaTitolari(listautenti)
    }
    function aggiornaTotaliUtenti(valore,user){
        let lista = listautenti.list
        let totale = 0
        for (let i = 0; i < lista.length; i++) {
            const element = lista[i];
            if(element.id === user.id){
                element.totale = Number(valore)
                if(agenzia){
                    element.id_capo=agenzia
                }
            }
            totale = totale + element.totale
        }
        setTotale(totale)
        setListautenti({list:lista})
    }
    
    useEffect(() => {
        if(props.userList){
            let lutenti = []
            props.userList.forEach(element => {
                lutenti.push({id:element.id, nome:element.nome+" "+element.cognome,id_capo:element.id_agenzia,totale:0})
            });
            setListautenti({list:lutenti})
        }
    }, [props.userList])

    useEffect(() => {
        if(props.lutenti){
            setListautenti({list:props.lutenti})
        }
    }, [])

    return (
        <Modal isOpen={props.isOpenModal} className="modal-md">
            <ModalBody>
                <ModalHeader>
                    <div style={{fontWeight:"bold",fontSize:"20px"}}>Utenti da assegnare: <span style={totale > props.countutenti ? {color:"red"}:null}>{props.countutenti - totale}</span>/{props.countutenti}</div>
                </ModalHeader>
                <div>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel id="outline-titolare">Agenzia</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            labelId="outline-agenzia"
                            value={agenzia}
                            onChange={(e)=>{setAgenzia(e.target.value)}}
                            label="Agenzia"
                            >
                            <MenuItem value={null}><em>None</em></MenuItem>
                                {props.userList && props.userList.filter(x=>x.role === "agenzia").map((rappresentante,i)=>{
                                    return(
                                        <MenuItem key={i} value={rappresentante.id}>{rappresentante.descrizione}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                    {listautenti.list && listautenti.list.filter((element)=>{
                        if(agenzia){
                            return element.id_capo === agenzia
                        }else{
                            return element
                        }}).map((user,i)=>{
                        return(
                            <Grid container key={i} style={{paddingTop:"5px"}}>
                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                    <TextField style={{width:"100%"}} label={user.nome} type="number" min={0} value={user.totale} onChange={(e)=>{aggiornaTotaliUtenti(e.target.value,user)}}/>
                                </Grid>
                            </Grid>
                        )
                    })}
                </div>
                <ModalFooter>
                    <Button size="small" variant="contained" color="primary" disabled={totale > props.countutenti} onClick={(e)=>{assegnaTitolari()}}><i className="fas fa-times"></i> Assegna</Button>
                    <Button size="small" variant="contained" color="primary" onClick={(e)=>{chiudiModal()}}><i className="fas fa-times"></i> Chiudi</Button>
                </ModalFooter>   
            </ModalBody>
    </Modal>
    );
}

export default ModalUtentiImpostazione;