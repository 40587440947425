import React, { useState } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import Button from '@material-ui/core/Button';
import { Checkbox } from '@material-ui/core';

let listOfType=["admin","agenzia","collaboratore","operatore","telemarketing"]

function ModalArchivioRete(props) {
  const [users] = useState(props.user)
  const [userFilterAgenzia, setUserFilterAgenzia] = useState({list:[]})
  const [userFilterAdmin, setUserFilterAdmin] = useState({list:[]})
  const [userFilterTelemarketing, setUserFilterTelemarketing] = useState({list:[]})
  const [userFilterCollaboratore, setUserFilterCollaboratore] = useState({list:[]})
  const [userFilterOperatore, setUserFilterOperatore] = useState({list:[]})

  function addRemoveUser(user,tipo){
    let index = -1;
    if(tipo === "admin"){
      let lista=userFilterAdmin.list
      index = lista.indexOf(user.id)
      if(index>=0){
          lista.splice(index, 1);
      }else{
          lista.push(user.id)
      }
      setUserFilterAdmin({list:lista})
    }
    if(tipo === "agenzia"){
      let lista=userFilterAgenzia.list
      index = lista.indexOf(user.id)
      if(index>=0){
          lista.splice(index, 1);
      }else{
          lista.push(user.id)
      }
      setUserFilterAgenzia({list:lista})
    }
    if(tipo === "collaboratore"){
      let lista=userFilterCollaboratore.list
      index = lista.indexOf(user.id)
      if(index>=0){
          lista.splice(index, 1);
      }else{
          lista.push(user.id)
      }
      setUserFilterCollaboratore({list:lista})
    }
    if(tipo === "operatore"){
      let lista=userFilterOperatore.list
      index = lista.indexOf(user.id)
      if(index>=0){
          lista.splice(index, 1);
      }else{
          lista.push(user.id)
      }
      setUserFilterOperatore({list:lista})
    }
    if(tipo === "telemarketing"){
      let lista=userFilterTelemarketing.list
      index = lista.indexOf(user.id)
      if(index>=0){
          lista.splice(index, 1);
      }else{
          lista.push(user.id)
      }
      setUserFilterTelemarketing({list:lista})
    }
}

  function applica(cerca){
    let data={
      admin:userFilterAdmin.list,
      agenzia:userFilterAgenzia.list,
      operatore:userFilterAgenzia.list,
      telemarketing:userFilterTelemarketing.list,
      collaboratore:userFilterCollaboratore.list,
    }
    if(cerca){
      props.setFiltriECerca(data)
    }else{
      props.setFiltri(data)
    }
    props.closeModal(true)
  }
  function removeFilter(){
    setUserFilterAgenzia({list:[]})
    setUserFilterAdmin({list:[]})
    setUserFilterTelemarketing({list:[]})
    setUserFilterCollaboratore({list:[]})
    setUserFilterOperatore({list:[]})
  }
  function checkifSet(user,tipo){
    let lista = []
    if(tipo === "admin"){
      lista=userFilterAdmin.list
    }
    if(tipo === "agenzia"){
      lista=userFilterAgenzia.list
    }
    if(tipo === "collaboratore"){
      lista=userFilterCollaboratore.list
    }
    if(tipo === "operatore"){
      lista=userFilterOperatore.list
    }
    if(tipo === "telemarketing"){
      lista=userFilterTelemarketing.list
    }
    var index = lista.indexOf(user.id)
      if(index>=0){
          return true;
      }else{
          return false;
      }
  }
  function chiudiModal(){
    props.closeModal(true)
  }
    return (
        <div>
            <Modal isOpen={props.isOpenModal} className="modal-lg">
              
                <ModalBody>
                  <div>
                    <ModalHeader>
                      <div style={{fontWeight:"bold",fontSize:"20px"}}>Archivio Rete</div>
                    </ModalHeader>
                  </div>
                  <div>
                    {listOfType.map((lot,j)=>{
                      return(
                        <div className="panel-body" key={j}>
                          {users.filter(x=>x.role === lot).length > 0 ?
                          <>
                            <b>{lot.toUpperCase()}</b>
                            <div style={{maxHeight:"120px",overflowY:"scroll",border:"1px solid lightgrey", padding:"5px"}}>
                              {users.filter(x=>x.role === lot).map((user,i) => {
                                  return(
                                      <div key={i}>
                                          <Checkbox size="small" color="primary" onClick={(e)=>{addRemoveUser(user,lot)}} checked={checkifSet(user,lot)}/>
                                          <label className="form-check-label" htmlFor="defaultCheck1">
                                          {user.nome} {user.cognome}
                                          </label>
                                      </div>
                                  )
                              })}
                            </div>
                          </>
                          :null}
                        </div>
                      )
                    })}
                  </div>
                  <ModalFooter>
                    <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>applica(false)}> <i className="far fa-save"></i> Applica</Button>
                    <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>applica(false)}> <i className="far fa-save"></i> Applica e Cerca</Button>
                    <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{chiudiModal()}}><i className="fas fa-times"></i> Chiudi</Button>
                    <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{removeFilter()}}><i className="fas fa-times"></i> Rimuovi Filtri</Button>
                  </ModalFooter>   
                </ModalBody>

        </Modal>
        </div>
    );
}

export default ModalArchivioRete;