import {ADD_USER,SET_USERS,DELETE_USER,CHANGE_STATUS_USER} from '../actions/userAction'
const initialState = {
    list:[]
}
export default (state = initialState, action) => {
    let list=[];
    let index = null;
  switch (action.type) {
    case SET_USERS:
        state={...state, list:action.payload.list}
        return state;
    case ADD_USER:
        list=state.list;
        index = list.findIndex(prev=>prev.email===action.payload.email);
        list.splice(index,1);
        list.push(action.payload)
        state={...state, list:list};
        return state;
    case DELETE_USER:
        index = state.list.findIndex(item=>item.email===action.payload.email);
        list=state.list;
        list.splice(index,1);
        state={...state, list:list}
        return state;
    case CHANGE_STATUS_USER:
        list=state.list;
        list.forEach(element => {
            if(element.email===action.payload.email){
                element.attivo=!element.attivo;
            }
        });
        state={...state, list:list}
        return state;
    default:
    return state;
    }   
}
