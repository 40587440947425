import React, { useState, useEffect,useContext } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import axios from 'axios'
import AuthService from '../../components/utente/AuthService';
import { NotificationContext } from '../../contexts/NotificationContext';
import Button from '@material-ui/core/Button';
import { Grid, TextField } from '@material-ui/core';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function ModalMandanteDoc(props) {
    const notifications = useContext(NotificationContext);
    const [nome, setNome] = useState(null)
    const [document, setDocument] = useState(null)
    const [filename, setFilename] = useState(null)

    function salvaDocumento(e){
      e.preventDefault()
      if(document){
          let formData = new FormData()
          
          formData.append('file', document, document.name)
          formData.append('nome',nome)
          formData.append('mandante_id',props.mandanteSelezionato.id)

          axios.put("/api/1.1/mandanti",formData,{headers:header}).then((res)=>{
              props.refreshMandante(res.data.data)
              notifications.NotifyAlarm(false,"Documento salvato")
          }).catch(error=>{
            notifications.NotifyAlarm(true,"Operazione non riuscita")
          })
      }
  }
  function handleImageChange(e){
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      setFilename(file.name)
      reader.onloadend = () => {
          setDocument(file)
      }
      reader.readAsDataURL(file);
  }

    function chiudiModal(){
      setDocument(null)
      setNome(null)
      props.closeModal(false)
    }

    useEffect(() => {
      if(!Auth.loggedIn()){
          window.location.href="/login"
      }
    }, [props.mandanteSelezionato])

    return (
            <Modal isOpen={props.isOpenModal} className="modal-lg">
              {props.mandanteSelezionato?
              <form onSubmit={(e)=>salvaDocumento(e)}>
                <ModalBody>
                  <div>
                    <ModalHeader>
                      <div style={{fontWeight:"bold",fontSize:"20px"}}>Aggiungi documento per {props.mandanteSelezionato.name}</div>
                    </ModalHeader>
                  </div>
                  <div>
                      <TextField style={{width:"100%"}} label="Nome" type="text" required onChange={(e)=>{setNome(e.target.value)}} value={nome} />
                      <Grid container style={{margin:"15px 0"}}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Button variant="contained" component="label">
                              Seleziona file
                              <input type="file" hidden required onChange={(e) => handleImageChange(e)}/>
                            </Button>
                            {filename ? <div><small>File: <b>{filename}</b></small></div>: null}
                        </Grid>
                        <div className="col-6 form-group"></div>
                    </Grid>
                  </div>
                  <ModalFooter>
                    <Button size="small" type="submit" variant="contained" color="primary"> <i className="far fa-save"></i> Salva</Button>
                    <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{e.preventDefault();chiudiModal()}}> <i className="fas fa-times"></i> Chiudi</Button>
                  </ModalFooter>   
                </ModalBody>
            </form>
            :null}
        </Modal>
    );
}

export default ModalMandanteDoc;