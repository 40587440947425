import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import Rappresentanti from './Rappresentanti';
import Anagrafica from './Anagrafica';
import Azienda from './Azienda';
import Documenti from './Documenti';
import Impegni from './Impegni';
import Stati from './Stati';
import ElencoPreventivi from './ElencoPreventivi';
import CensimentiAssicurativi from './CensimentiAssicurativi';
import Simulatore from '../simulatore/Simulatore';
import DefaultSpinner from '../DefaultSpinner';
import SimulatoreManuale from '../simulatore/SimulatoreManuale';
import {Tab,AppBar,Tabs,Grid} from '@material-ui/core';
import TabPanel from '../../commons/TabPanel';
import moment from 'moment'
import {NotePraticaContext} from '../../contexts/NotePraticaContext'
import {AccountContext} from '../../contexts/AccountContext'
import {NotificationContext} from '../../contexts/NotificationContext'
import {SettingContext} from '../../contexts/SettingContext'
import { Link } from 'react-router-dom';

// gestione delle note fuori la sezione degli stati

let Auth = new AuthService();
let header=Auth.getHeadersJWT();

let urls=[{url:"anagrafica",index:0},
  {url:"azienda",index:1},
  {url:"documenti",index:2},
  {url:"impegni",index:3},
  {url:"stati",index:4},
  {url:"simulatore",index:5},
  {url:"simulatore-manuale",index:6},
  {url:"preventivi-provvigioni",index:7},
  {url:"censimenti-assicurativi",index:8},
  // {url:"intervista",index:9},
  {url:"ruoli",index:9}]

function Pratica(props) {
  const accounts = useContext(AccountContext)
  const notifications = useContext(NotificationContext)
  const settings = useContext(SettingContext)
  const [loading, setLoading] = useState(true)
  const [pratica, setPratica] = useState(null)
  const [rappresentanti, setRappresentanti] = useState(null)
  const [value, setValue] = useState(0);
  const [note, setNote] = useState(null)
  const [oldstatoSelected, setOldstatoSelected] = useState(null)
  const [prodotto, setProdotto] = useState(null)

  const handleChange = (event, newValue) => {
      setValue(newValue);
  };
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  function setIdPreventivoPredefinito(id_preventivo_predefinito){
    setPratica({...pratica, id_preventivo_predefinito:id_preventivo_predefinito})
  }
  function reloadPreventivi(preventiviLista){
    setPratica({...pratica, elencoProvvigioni:preventiviLista})
  }
  function chiudiPratica(){ //ma funzione? non viene mai chiamata probabilmente
    setPratica({...pratica, chiusa:true})
  }
  function setNuovoProdotto(pratica){
    setPratica(pratica)
  }
  function checkIsPreventivoPredefinitoExists(){
    let trovato = false
    pratica.elencoProvvigioni.forEach(element => {
      if(element.id === pratica.id_preventivo_predefinito){
        trovato = true
      }
    });
    return trovato
  }
  function changeurl(value){
    var newurl = "/pratica/"+pratica.id+"/"+value
    window.history.pushState(null, null, newurl)
  }
  function returnNote(lnote){
    setNote(lnote)
  }
  function aggiungiNota(nuovanota,shownotifica){
    let old_stato=prodotto.stati_pratica.filter(x=>x.id === oldstatoSelected)[0]
    let nota={
        name:accounts.account.nome +" "+accounts.account.cognome,
        id_utente:accounts.account.id,
        stato:old_stato ? old_stato.name : "",
        stato_id:old_stato ? old_stato.id : "",
        nota:nuovanota.replace(/<br\s?\/?>/g,"\n"),
        timestamp:moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
    }
    let newnote = JSON.parse(note)
    newnote.push(nota)
    let data={
        type:"nota",
        stato:old_stato,
        id:pratica.id,
        name:accounts.account.nome +" "+accounts.account.cognome,
        newnote:JSON.stringify(newnote)
    }
    axios.put('/api/1.1/pratica/stato',data,{headers:header}).then((res)=>{
        setNote(JSON.stringify(newnote))
        if(shownotifica){
          notifications.NotifyAlarm(false,"Nota aggiunta")
        }
    }).catch(error=>{
        notifications.NotifyAlarm(true,"Errore")
    })
  }
  function setOldStato(old_stato){
    setOldstatoSelected(old_stato)
  }
  function aggiornaImpegniPratica(impegni){
    setPratica({...pratica, impegni_resume:impegni})
  }
  function aggiornaListaImpegniPratica(finanziamentiincorsolista){
    setPratica({...pratica, finanziamentiincorsolista:finanziamentiincorsolista})
  }
  function aggiornaListaCensimenti(lista){
    setPratica({...pratica, assicurazioni:JSON.stringify(lista)})
  }
  function aggiornaSchemaUtenti(schemaUtenti,speseSostenute){
    setPratica({...pratica,
      spese_sostenute:speseSostenute,
      titolare:schemaUtenti.titolare,
      percentualeTitolare:schemaUtenti.percentualeTitolare,
      venditore:schemaUtenti.venditore,
      percentualeVenditore:schemaUtenti.percentualeVenditore,
      collocatore:schemaUtenti.collocatore,
      percentualeCollocatore:schemaUtenti.percentualeCollocatore,
      areamanager:schemaUtenti.areamanager,
      capoarea:schemaUtenti.capoarea,
      backoffice:schemaUtenti.backoffice,
      id_capo:schemaUtenti.id_capo
    })
  }

  useEffect(() => {
    axios.get("/api/1.1/pratica/"+props.match.params.id_pratica,{headers:header}).then(res=>{
        setPratica(res.data.data)
        setProdotto(settings.prodotti.list.filter(x=>x.id===res.data.data.categoria_id)[0])
        setOldstatoSelected(res.data.data.stato_attuale_id)
        axios.get('/api/1.1/pratica/stato/'+res.data.data.id,{headers:header}).then((resnote)=>{
            setNote(resnote.data.data)
        })
        if(res.data.data.tipopratica === "AZIENDA"){
          setValue(1)
        }
        if(props.match.params.nome_pagina){
          let index = urls.filter(x=>x.url === props.match.params.nome_pagina)
          if(index){
            setValue(index[0].index)
          }
        }             
        setLoading(false)
    })
  }, [])

  useEffect(() => {
    if(pratica && pratica.id){
        axios.all([
          axios.get('/api/1.1/rappresentanti/'+pratica.id,{headers:header}),
      ]).then(axios.spread((resrappresentanti) => {
          setRappresentanti(resrappresentanti.data.data)
          setLoading(false)
      })).catch(error=>{
          setLoading(false)
      })
    }
  }, [pratica])

  return (
      <div>
        {!loading && pratica ? 
        <NotePraticaContext.Provider value={{note:note,aggiungiNota:aggiungiNota,old_stato:oldstatoSelected,setOldStato:setOldStato}}>
          <Grid container>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <h2>Pratica n°<b>{(pratica.codice_pratica+"").padStart(6, '0')}</b></h2>
              <h5>
                  {pratica.tipopratica === "AZIENDA" ? 
                    <span><i className={"fas fa-industry"}></i> <Link to={"/cliente/"+pratica.id_cliente} style={{color:"grey"}}>{pratica.azienda.ragione_sociale}</Link> - {pratica.categoria}</span>
                  :
                    <span><i className={"fas fa-user-alt"}></i> <Link to={"/cliente/"+pratica.id_cliente} style={{color:"grey"}}>{pratica.anagrafica.nome} {pratica.anagrafica.cognome}</Link> - {pratica.categoria}</span>
                  }
                {!pratica.attiva ? 
                  <span style={{color:"rebeccapurple"}}> - CHIUSA</span>
                : 
                  null
                }
                </h5>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                {pratica.timestamp ? 
                    <div style={{margin:"5px 0"}}>
                        <b>DATA DI INSERIMENTO:</b> {moment(pratica.timestamp).format('DD/MM/YYYY') }
                    </div>
                :null}
                {pratica.data_approvazione ? 
                    <div style={{margin:"5px 0"}}>
                        <b>DATA DI APPROVAZIONE:</b> {moment(pratica.data_approvazione).format('DD/MM/YYYY') }
                    </div>
                :null}
                {pratica.data_liquidata ? 
                    <div style={{margin:"5px 0"}}>
                        <b>DATA DI LIQUIDAZIONE:</b> {moment(pratica.data_liquidata).format('DD/MM/YYYY') }
                    </div>
                :null}
              </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <AppBar position="relative" color="default" style={{zIndex:"0"}}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                  <Tab onClick={()=>{changeurl("anagrafica")}} hidden={pratica.tipopratica === "AZIENDA"} label={pratica.anagrafica ? <div>Dati anagrafica <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Dati anagrafica <i style={{opacity:"0.1"}} className="fas fa-check"></i></div>} {...a11yProps(0)} />
                  <Tab onClick={()=>{changeurl("azienda")}} label={pratica.azienda ? <div>Dati azienda <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Dati azienda <i style={{opacity:"0.5"}} className="fas fa-check"></i></div>} {...a11yProps(1)} />
                  <Tab onClick={()=>{changeurl("documenti")}} label={pratica.attiva ? <div>Documenti <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Documenti <i style={{opacity:"0.5"}} className="fas fa-check"></i></div>} {...a11yProps(2)} />
                  <Tab onClick={()=>{changeurl("impegni")}} label="Impegni" {...a11yProps(3)} />
                  <Tab onClick={()=>{changeurl("stati")}} label="Stati e Note" {...a11yProps(4)} />
                  <Tab onClick={()=>{changeurl("simulatore")}} hidden={!pratica.attiva || pratica.approvata || pratica.tipopratica === "AZIENDA"} label="Simulatore" {...a11yProps(5)} />
                  <Tab onClick={()=>{changeurl("simulatore-manuale")}} hidden={!pratica.attiva || pratica.approvata} label="Simulatore man." {...a11yProps(6)} />
                  <Tab onClick={()=>{changeurl("preventivi-provvigioni")}} label={checkIsPreventivoPredefinitoExists() ? <div>Elenco prev e prov <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Elenco prev e prov  <i style={{opacity:"0.5"}} className="fas fa-check"></i></div>} {...a11yProps(7)} />
                  <Tab onClick={()=>{changeurl("censimenti-assicurativi")}} label="Censimenti ass." {...a11yProps(8)} />
                  {/* <Tab onClick={()=>{changeurl("intervista")}} label={pratica.intervista || pratica.intervista_azienda ? <div>Intervista <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Intervista <i style={{opacity:"0.5"}} className="fas fa-check"></i></div>} {...a11yProps(9)} /> */}
                  <Tab onClick={()=>{changeurl("ruoli")}} label={pratica.titolare ? <div>Ruoli <i style={{color:"lightgreen"}} className="fas fa-check"></i></div>:<div>Ruoli <i style={{opacity:"0.5"}} className="fas fa-check"></i></div>} {...a11yProps(9)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0} hidden={pratica.tipopratica === "AZIENDA"}>
                <Anagrafica pratica={pratica}></Anagrafica>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Azienda pratica={pratica}></Azienda>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Documenti pratica={pratica}></Documenti>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Impegni pratica={pratica} tipopratica={pratica.tipopratica} aggiornaImpegniPratica={aggiornaImpegniPratica} aggiornaListaImpegniPratica={aggiornaListaImpegniPratica}/>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Stati pratica={pratica} chiudiPratica={chiudiPratica} tipopratica={pratica.tipopratica} setNuovoProdotto={setNuovoProdotto} returnNote={returnNote} note={note}></Stati>
              </TabPanel>
              <TabPanel hidden={!pratica.attiva || pratica.tipopratica === "AZIENDA"} value={value} index={5}>
                <Simulatore pratica={pratica} tipo={"pratica"} reloadPreventivi={reloadPreventivi} setIdPreventivoPredefinito={setIdPreventivoPredefinito}></Simulatore>
              </TabPanel>
              <TabPanel hidden={!pratica.attiva} value={value} index={6}>
                <SimulatoreManuale pratica={pratica} tipo={"pratica"} reloadPreventivi={reloadPreventivi} setIdPreventivoPredefinito={setIdPreventivoPredefinito}></SimulatoreManuale>
              </TabPanel>
              <TabPanel value={value} index={7}>
                <ElencoPreventivi pratica={pratica} setIdPreventivoPredefinito={setIdPreventivoPredefinito}></ElencoPreventivi>
              </TabPanel>
              <TabPanel value={value} index={8}>
                <CensimentiAssicurativi pratica={pratica} aggiornaListaCensimenti={aggiornaListaCensimenti}></CensimentiAssicurativi>
              </TabPanel>
              {/* <TabPanel value={value} index={9}>
                <Intervista intervista={pratica.intervista} intervista_azienda={pratica.intervista_azienda}/>
              </TabPanel> */}
              <TabPanel value={value} index={9}>
                <Rappresentanti pratica={pratica} rappresentanti={rappresentanti} aggiornaSchemaUtenti={aggiornaSchemaUtenti} />
              </TabPanel>
            </Grid> 
          </Grid> 
        </NotePraticaContext.Provider>
        : 
        <div style={{textAlign:'center'}}>
          <DefaultSpinner></DefaultSpinner>
        </div>}            
            
      </div>
    );
}

export default Pratica; 