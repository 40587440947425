import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService';
import { NotificationContext } from '../../contexts/NotificationContext';
import ModalMandanteDoc from '../modali/ModalMandanteDoc';
import {downloadDoc} from '../../commons/documenti'
import Button from '@material-ui/core/Button';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@material-ui/core';
import DefaultSpinner from '../DefaultSpinner';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function Mandanti(props) {
    const notifications = useContext(NotificationContext);
    const [mandanti, setMandanti] = useState({list:[]})
    const [nomeBanca, setNomeBanca] = useState(null)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [mandanteSelezionato, setMandanteSelezionato] = useState(null)
    const [loading, setLoading] = useState(true)
   
    function cancellaDocumento(documento,mandante){
        if(window.confirm("Sei sicuro di voler eliminare il documento?")){   
            axios.delete("/api/1.1/document/mandante/"+documento.id,{headers:header}).then((res)=>{
                mandanti.list.forEach((element,i) => {
                    if(mandante.id === element.id){
                        mandante.documenti.forEach((doc,j)=>{
                            if(documento.id===doc.id){
                                mandante.documenti.splice(j,1)
                            }
                        })
                    }
                });
                setMandanti({list:mandanti.list})
                notifications.NotifyAlarm(false,"Documento eliminato")
            })
        }
    }

    function cancellaMandante(e,man){
        e.preventDefault()
        if(window.confirm("Sei sicuro di voler eliminare la banca "+man.name+" ?")){   
            axios.delete("/api/1.1/mandanti/"+man.id,{headers:header}).then((res)=>{
                mandanti.list.forEach((element,i) => {
                    if(man.id === element.id){
                        mandanti.list.splice(i,1)
                    }
                });
                setMandanti({list:mandanti.list})
                notifications.NotifyAlarm(false,"Banca eliminata!")
            })
        }
    }
    function openModal(mandante){
        setIsOpenModal(!isOpenModal)
        setMandanteSelezionato(mandante)
    }
    function closeModal(valore){
        setIsOpenModal(valore)
    }

    function refreshMandante(mandante){
        let mand=mandanti.list
        mand.forEach((element,i) => {
            if(mandante.id === element.id){
                element.documenti=mandante.documenti
            }
        });
        setMandanti({list:mand})
        setIsOpenModal(false)
    }

    function salvaMandante(e){
        e.preventDefault()
        let data={name:nomeBanca}
        axios.post('/api/1.1/mandanti',data,{headers:header}).then((res)=>{
            let mand=mandanti.list
            mand.push(res.data.data)
            setMandanti({...mandanti, list:mand})
            setNomeBanca("")
            notifications.NotifyAlarm(false,"Salvataggio completato!")
        }).catch(error=>{
            notifications.NotifyAlarm(true,"errore salvataggio!")
        })
    }
    useEffect(() => {
        axios.get("/api/1.1/mandanti",{headers:header}).then((res)=>{
            setMandanti({list:res.data.data})
            setLoading(false)
        })
    }, [])
    
    return (
        <div>
            {!loading ? 
            <>
                <div>
                    <form onSubmit={(e)=>{salvaMandante(e)}}>
                        <Grid container>
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <h3>Aggiungi Banca/Servizi </h3>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <TextField style={{width:"100%"}} label="Nome Banca" type="text" required onChange={(e)=>{setNomeBanca(e.target.value)}} value={nomeBanca} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                                <Button size="small" type="submit" variant="contained" color="primary"><i className="fas fa-plus"></i> Aggiungi</Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <div className="accordion" id="accordionExample" style={{marginTop:"15px"}}>
                    {mandanti.list.map((man,i)=>{
                        return(
                            <div className="card">
                            <div className="card-header" id={"heading"+i}>
                            <h5 className="mb-0">
                                <Grid container>
                                    <Grid item lg={6} md={6} sm={12} xs={12} data-toggle="collapse" data-target={"#collapse"+i} aria-expanded="true" aria-controls={"collapse"+i}>
                                    {man.name} <small>({man.documenti.length})</small>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} style={{textAlign:"right"}}>
                                        <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>{openModal(man)}}><i className="fas fa-plus"></i> Aggiungi documento</Button>
                                        <Button size="small" type="submit" variant="contained" color="secondary" style={{marginLeft:"15px"}} onClick={(e)=>{cancellaMandante(e,man)}}><i className="fas fa-trash"></i> Cancella</Button>
                                    </Grid>
                                </Grid>
                            </h5>
                            </div>
                            <div id={"collapse"+i} className="collapse" aria-labelledby={"heading"+i} data-parent="#accordionExample">
                                <div className="card-body">
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table" size="small">
                                            <TableBody>
                                                {man.documenti.map((doc,j)=>{
                                                    return(
                                                        <TableRow key={j}>
                                                            <TableCell component="th" scope="row"><Button className="btn btn-link" onClick={(e)=>{downloadDoc(doc.proxy_url,doc.file.split("?")[0].split("/")[doc.file.split("?")[0].split("/").length - 1],header,'application/pdf')}}><i className="fas fa-download"></i> {doc.file.split('/')[doc.file.split('/').length-1].split("?")[0]}</Button></TableCell>
                                                            <TableCell>{doc.alt}</TableCell>
                                                            <TableCell style={{textAlign:"right"}}><Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{cancellaDocumento(doc,man)}}><i className="fas fa-trash"></i> Elimina</Button></TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                </div>
                <ModalMandanteDoc closeModal={closeModal} 
                                isOpenModal={isOpenModal} 
                                mandanteSelezionato={mandanteSelezionato}
                                refreshMandante={refreshMandante}></ModalMandanteDoc>
            </>
            :
            <div style={{textAlign:"center"}}>
                <DefaultSpinner></DefaultSpinner>
            </div>}
        </div>
    );
}

export default Mandanti;