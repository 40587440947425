import React, { useState,useContext} from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import axios from 'axios'
import AuthService from '../utente/AuthService';
import { NotificationContext } from '../../contexts/NotificationContext';
import Button from '@material-ui/core/Button';
import { Grid, TextField } from '@material-ui/core';
import { SettingContext } from '../../contexts/SettingContext';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function ModalMandanteDoc(props) {
    const notifications = useContext(NotificationContext);
    const settings = useContext(SettingContext);
    const [nome, setNome] = useState(null)
    const [document, setDocument] = useState(null)
    const [filename, setFilename] = useState(null)

    function salvaDocumento(e){
      e.preventDefault()
      if(document){
          let formData = new FormData()
          formData.append('file', document, document.name)
          formData.append('nome',nome)

          axios.post("/api/1.1/precompilato",formData,{headers:header}).then((res)=>{
            let trovato = false
            let list = settings.precompilati.list
            for (let index = 0; index < list.length; index++) {
                if(list[index].id === res.data.data.id){
                    list[index]=res.data.data
                    trovato=true
                    break
                }
            }
            if(!trovato){
                list.push(res.data.data)
            }
            settings.setPrecompilati({list:list})
            chiudiModal()
            notifications.NotifyAlarm(false,"Documento salvato")
          }).catch(error=>{
            notifications.NotifyAlarm(true,"Operazione non riuscita")
          })
      }
  }
  function handleImageChange(e){
      e.preventDefault();
      let reader = new FileReader();
      let file = e.target.files[0];
      setFilename(file.name)
      reader.onloadend = () => {
          setDocument(file)
      }
      reader.readAsDataURL(file);
  }

    function chiudiModal(){
      setDocument(null)
      setNome(null)
      props.closeModal(false)
    }

    return (
            <Modal isOpen={props.isOpenModal} className="modal-lg">
              <form onSubmit={(e)=>salvaDocumento(e)}>
                {/* TODO: non funziona il reload */}
                <ModalBody>
                  <div>
                    <ModalHeader>
                      <div style={{fontWeight:"bold",fontSize:"20px"}}>Aggiungi Precompilato</div>
                    </ModalHeader>
                  </div>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} required label="Nome" name="nome" value={nome} onChange={(e)=>setNome(e.target.value)} type="text" />
                    </Grid>
                    <Grid item lg={12} md={12} sm={6} xs={12} style={{padding:"5px"}}>
                      <Button variant="contained" component="label">
                        Seleziona file
                        <input type="file" hidden required accept="application/pdf" onChange={(e) => handleImageChange(e)}/>
                      </Button>
                      {filename ? <div><small>File: <b>{filename}</b></small></div>: null}
                    </Grid>
                  </Grid>
                  <ModalFooter>
                    <Button size="small" type="submit" variant="contained" color="primary"> <i className="far fa-save"></i> Salva</Button>
                    <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{e.preventDefault();chiudiModal()}}> <i className="fas fa-times"></i> Chiudi</Button>
                  </ModalFooter>   
                </ModalBody>
            </form>
        </Modal>
    );
}

export default ModalMandanteDoc;