import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment'
import { TableCell, TableRow,Chip, Checkbox } from '@material-ui/core';

function ClienteItem(props) {
  const [redirect, setRedirect] = useState(false)
  const [datibase, setDatibase] = useState(null)
  const [pratica_id, setPratica_id] = useState(null)

  useEffect(() => {
    if(props.pratica){
      setPratica_id(props.pratica.id)
      setDatibase(props.pratica.anagrafica ? props.pratica.anagrafica : props.pratica.intervista)
    }
  }, [props.pratica])

  function redirectTo(){
    setRedirect(true)
  }
  return (
      datibase && props.pratica.tipopratica === "PRIVATO" ? 
      <>
      {redirect ? 
          <Redirect to={{
            pathname: '/cliente/'+pratica_id,
            utente:props.pratica}}/>
        :null}
        
      <TableRow style={{cursor:"pointer"}}>
        <TableCell>
          {props.showCheck ? 
            <Checkbox size="small" color="primary" onClick={(e)=>{props.checkedItem(props.pratica.id)}}/>  
          :null}
          {!props.pratica.attiva ? <i style={{color:"#fc7303",fontSize:"12px",marginLeft:"5px"}} className="fas fa-dot-circle"></i>:null}
        </TableCell>
        <TableCell onClick={()=>{redirectTo()}}>{pratica_id}</TableCell>
        <TableCell onClick={()=>{redirectTo()}}>{props.pratica.prodotti_richiesti && props.pratica.prodotti_richiesti.length>0 ? 
              props.pratica.prodotti_richiesti.filter((element)=>{return element.active || element.decorrenza}).map((prodotto,i)=>{
                return(
                <li key={i}>{prodotto.nome}</li>
                )
              })
              :null}
        </TableCell>
        <TableCell onClick={()=>{redirectTo()}}> <i className={props.pratica.tipopratica === "AZIENDA" ? "fas fa-industry":"fas fa-user-alt"}></i>  {datibase.cognome} {datibase.nome}</TableCell>
        {!props.pratica.chiusa ?
          <TableCell onClick={()=>{redirectTo()}}>
            {props.pratica.prodotti_richiesti.filter((element)=>{return element.active || element.decorrenza}).map((stati,j)=>{
              return(
                <div key={j}>
                  {stati.lista_stati.map((stato,i)=>{
                    return(
                      stato.attivo ? 
                      <div key={i}>
                        <Chip size="small" label={stato.nome_stato} style={{cursor:"pointer",backgroundColor:stato.color,minWidth:"48px",border:"1px solid #606161",margin:"1px",padding:"1px"}} />
                      </div> 
                    : null
                  )
                })}
                </div>
              )}
            )}
          </TableCell>
         : 
          <TableCell onClick={()=>{redirectTo()}}>
            <div>CHIUSA</div>
          </TableCell>
        }
        {!props.pratica.chiusa ? 
          <TableCell onClick={()=>{redirectTo()}}>
            {props.pratica.prodotti_richiesti.filter((element)=>{return element.active || element.decorrenza}).map((stati,j)=>{
              return(
                stati.lista_stati.map((stato,i)=>{
                  return(
                    stato.attivo ? 
                    <div key={i}>
                      {stato.alarm ? 
                        <div>{moment(new Date(stato.alarm)).format("DD/MM/YYYY HH:mm")}
                          <span style={{marginLeft:"5px"}}>
                            {moment(new Date(stato.alarm)) < moment(new Date(), "DD/MM/YYYY") ? 
                              <i style={{color:"red"}} className={"fas fa-exclamation-triangle"}></i>
                            : 
                            moment(new Date(stato.alarm)) < moment(new Date(), "DD/MM/YYYY").set({hour:23,minute:59,second:59}) ? 
                              <i style={{color:"orange"}} className={"fas fa-clock"}></i> 
                            :null}
                            </span>
                        </div> 
                      : <span>&nbsp;</span>}
                    </div> 
                  : null
                  )
                })
              )}
            )}
          </TableCell>
          :<TableCell onClick={()=>{redirectTo()}}></TableCell>}
        <TableCell onClick={()=>{redirectTo()}}>{props.pratica.intervista.lavoro}</TableCell>
        <TableCell onClick={()=>{redirectTo()}}>
            {props.pratica.prodotti_richiesti.filter((element)=>{return element.active || element.decorrenza}).map((stati,j)=>{
              return(
                stati.decorrenza ? <div key={j}>{moment(new Date(stati.decorrenza)).format("DD/MM/YYYY")}</div>: <div key={j}>&nbsp;</div> 
                )
              })
          }
        </TableCell>
      </TableRow>
      </>
    :
    <>
    {redirect ? 
      <Redirect to={{
          pathname: '/cliente/'+pratica_id,
          utente:props.pratica}}>
      </Redirect>:null}
      <TableRow style={{cursor:"pointer"}}>
        <TableCell>
            {props.showCheck ? 
              <Checkbox size="small" color="primary" onClick={(e)=>{props.checkedItem(props.pratica.id)}}/>  
            :null}
            {!props.pratica.attiva ? <i style={{color:"#fc7303",fontSize:"12px",marginLeft:"5px"}} className="fas fa-dot-circle"></i>:null}
          </TableCell>
          <TableCell onClick={()=>{redirectTo()}}>{pratica_id}</TableCell>
          <TableCell onClick={()=>{redirectTo()}}>{props.pratica.prodotti_richiesti && props.pratica.prodotti_richiesti.length>0 ? 
                props.pratica.prodotti_richiesti.filter((element)=>{return element.active || element.decorrenza}).map((prodotto,i)=>{
                  return(
                  <li key={i}>{prodotto.nome}</li>
                  )
                })
                :null}
          </TableCell>
          <TableCell onClick={()=>{redirectTo()}}> <i className={"fas fa-industry"}></i> {props.pratica.intervista_azienda ? props.pratica.intervista_azienda.ragioneSociale:null}</TableCell>
          {!props.pratica.chiusa ?
            <TableCell onClick={()=>{redirectTo()}}>
              {props.pratica.prodotti_richiesti.filter((element)=>{return element.active || element.decorrenza}).map((stati,j)=>{
                return(
                  <div key={j}>
                    {stati.lista_stati.map((stato,i)=>{
                      return(
                        stato.attivo ? 
                        <div key={i}>
                          <Chip size="small" label={stato.nome_stato} style={{cursor:"pointer",backgroundColor:stato.color,minWidth:"48px",border:"1px solid #606161",margin:"1px",padding:"1px"}} />
                        </div> 
                      : null
                    )
                  })}
                  </div>
                )}
              )}
            </TableCell>
          : 
            <TableCell onClick={()=>{redirectTo()}}>
              <div>CHIUSA</div>{/* <span style={{padding:"5px 15px",backgroundColor:"#63141e",color:"white"}}>CHIUSA</span> */}
            </TableCell>
          }
          {!props.pratica.chiusa ? 
            <TableCell onClick={()=>{redirectTo()}}>
              {props.pratica.prodotti_richiesti.filter((element)=>{return element.active || element.decorrenza}).map((stati,j)=>{
                return(
                  stati.lista_stati.map((stato,i)=>{
                    return(
                      stato.attivo ? 
                      <div key={i}>
                        {stato.alarm ? 
                          <div>{moment(new Date(stato.alarm)).format("DD/MM/YYYY HH:mm")}
                            <span style={{marginLeft:"5px"}}>
                              {moment(new Date(stato.alarm)) < moment(new Date(), "DD/MM/YYYY") ? 
                                <i style={{color:"red"}} className={"fas fa-exclamation-triangle"}></i>
                              : 
                              moment(new Date(stato.alarm)) < moment(new Date(), "DD/MM/YYYY").set({hour:23,minute:59,second:59}) ? 
                                <i style={{color:"orange"}} className={"fas fa-clock"}></i> 
                              :null}
                              </span>
                          </div> 
                        : <span>&nbsp;</span>}
                      </div> 
                    : null
                    )
                  })
                )}
              )}
            </TableCell>
          :<TableCell onClick={()=>{redirectTo()}}></TableCell>
          }
          <TableCell onClick={()=>{redirectTo()}}>{props.pratica.intervista_azienda ? props.pratica.intervista_azienda.categoria : null}</TableCell>
          <TableCell onClick={()=>{redirectTo()}}>
              {props.pratica.prodotti_richiesti.filter((element)=>{return element.active || element.decorrenza}).map((stati,j)=>{
                return(
                  stati.decorrenza ? <div key={j}>{moment(new Date(stati.decorrenza)).format("DD/MM/YYYY")}</div> : <div key={j}>&nbsp;</div> 
                  )
                })
            }
          </TableCell>
          {console.log(props.pratica)}
      </TableRow>
    </>
  );
}

export default ClienteItem;