import React from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import moment from 'moment'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import DefaultSpinner from '../DefaultSpinner';

function ModalNote(props) {
    function chiudiModal(){
        props.chiudiModal(null)
    }
    return (
        <Modal isOpen={props.isOpenModal} className="modal-lg">
            <ModalBody>
                <div>
                <ModalHeader>
                    <div style={{fontWeight:"bold",fontSize:"20px"}}>Pratica {props.codicepratica}</div>
                </ModalHeader>
                </div>
                {!props.loadingModal && props.notePratica ? 
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight:"bold"}}>Nota</TableCell>
                                    <TableCell style={{fontWeight:"bold",maxWidth:"10%"}}>Stato</TableCell>
                                    <TableCell style={{fontWeight:"bold",maxWidth:"10%"}}>Data</TableCell>
                                    <TableCell style={{fontWeight:"bold",maxWidth:"10%"}}>Utente</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.notePratica && JSON.parse(props.notePratica).sort(function(a,b){
                                        return moment(b.timestamp, "DD/MM/YYYY HH:mm:ss").toDate() - moment(a.timestamp, "DD/MM/YYYY HH:mm:ss").toDate();
                                    }).map((elm,i)=>{
                                    return(
                                        <TableRow key={i}>
                                            <TableCell><div dangerouslySetInnerHTML={{__html:elm.nota}} /> </TableCell>
                                            <TableCell>{elm.stato ? elm.stato : "-"}</TableCell>
                                            <TableCell>{elm.timestamp}</TableCell>
                                            <TableCell component="th" scope="row">{elm.name}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                :<DefaultSpinner></DefaultSpinner>}
                <ModalFooter>
                <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{chiudiModal()}}><i className="fas fa-times"></i> Chiudi</Button>
                </ModalFooter>   
            </ModalBody>
    </Modal>
    );
}

export default ModalNote;