import React from 'react';
import Button from '@material-ui/core/Button';
import { Switch, TableCell, TableRow } from '@material-ui/core';

function UserItem(props) {
    return (
      <TableRow>
        <TableCell component="th" scope="row">
        {props.utente.nome}
        </TableCell>
        <TableCell>{props.utente.cognome}</TableCell>
        <TableCell>{props.utente.email}</TableCell>
        <TableCell>{props.utente.role.toUpperCase()}</TableCell>
        <TableCell>
          <Switch
            checked={props.utente.attivo}
            onChange={(e)=>{props.changeStatus(props.utente)}}
            color="primary"
            name="checkedB"
            inputProps={{'aria-label': 'primary checkbox'}}
          />
        </TableCell>
        <TableCell>
          <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{props.setUserDaModificare(props.utente); props.setIsOpenModal(true)}}><i className="fas fa-pencil-alt"></i> Modifica</Button>
        </TableCell>
        <TableCell>          
          <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{props.cancellaUser(props.utente)}} style={{marginLeft:"10px"}}><i className="fas fa-user-times"></i> Cancella</Button>
        </TableCell>
      </TableRow>
    );
}

export default UserItem;