import React,{useState,useEffect} from 'react';
import { Button, Checkbox, Grid } from '@material-ui/core';
import axios from 'axios'
import {Modal,ModalBody,ModalHeader} from 'reactstrap'
import {responseToPdf} from '../../commons/documenti'
import { ReactSortable } from "react-sortablejs";
import ModalFooter from 'reactstrap/lib/ModalFooter';
import AuthService from '../../components/utente/AuthService';
import { PDFDocument } from 'pdf-lib'
import fileDownload from 'js-file-download';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function ModalDocumentiSort(props) {
    const [documenti, setDocumenti] = useState([])
    const [changeFake, setChangeFake] = useState(false)

    function changeEnabledDocument(item){
        let docs = documenti;
        docs.forEach(element => {
            if(element.id === item.id){
                element.enabled = !element.enabled
            }
        });
        setDocumenti(docs)
        setChangeFake(!changeFake)
    }

    async function scaricaFileMultipli(e){
        //https://github.com/Hopding/pdf-lib/issues/252 
        let ordineDocumenti=[]
        documenti.forEach(element => {
            if(element.enabled){
                ordineDocumenti.push({num:element.id,url:element.doc.url})
            }
        });
        const mergedPdf = await PDFDocument.create();
        for (let document of ordineDocumenti) {
            const url = "/api/1.1/getproxiedfile?f="+document.url
            const arrayBuffer = await fetch(url,{headers:header}).then(res => res.arrayBuffer())
            const pdfDoc = await PDFDocument.load(arrayBuffer)
            const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
            copiedPages.forEach((page) => mergedPdf.addPage(page));
        }
        const pdfBytes = await mergedPdf.save()
        fileDownload(pdfBytes, "allpdf.pdf", "application/pdf");
    }
   
    function chiudiModal(e){
        e.preventDefault()
        props.setIsOpenModal(false)
    }
    useEffect(() => {
        if(props.documenti){
            let newDoc=[]
            props.documenti.forEach((element,i) => {
                newDoc.push({id:i,enabled:false,doc:element})
            });
            setDocumenti(newDoc)
        }
    }, [props.documenti])

    return (
        <Modal isOpen={props.isOpenModal} className="modal-lg">
            <ModalBody>
                <div>
                  <ModalHeader>
                    Documenti
                  </ModalHeader>
                </div>
                <div>
                    <ReactSortable list={documenti} setList={setDocumenti}>
                        {documenti.map((item) => (
                            <Grid container key={item.id} style={{borderBottom:"1px solid lightgrey"}}>
                                <Grid item lg={1} md={1} sm={1} xs={1}>
                                    <Checkbox size="small" color="primary" checked={item.enabled} onChange={()=>changeEnabledDocument(item)}/>  
                                </Grid>
                                <Grid item lg={3} md={3} sm={5} xs={5} className={item.enabled ? "sortdocumenti" : "sortdocumenti-disabled"}>
                                    {item.doc.nome_tipo}
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={6} className={item.enabled ? "sortdocumenti" : "sortdocumenti-disabled"}>
                                    {item.doc.url.split('/')[item.doc.url.split('/').length-1]}
                                </Grid>
                                <Grid item lg={4} md={4} sm={6} xs={6} className={item.enabled ? "sortdocumenti" : "sortdocumenti-disabled"}>
                                    {item.doc.alt}
                                </Grid>
                            </Grid>
                        ))}
                    </ReactSortable>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>scaricaFileMultipli(e)}> <i className="far fa-save"></i> Scarica</Button>
                <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{chiudiModal(e)}}> <i className="fas fa-times"></i> Chiudi</Button>
            </ModalFooter> 
        </Modal>
    );
}

export default ModalDocumentiSort;