import React,{useState,useEffect,useContext} from 'react'
import axios from 'axios';
import AuthService from '../utente/AuthService'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { NotificationContext } from '../../contexts/NotificationContext';
import { NotePraticaContext } from '../../contexts/NotePraticaContext';
import DefaultSpinner from '../DefaultSpinner';

let Auth = new AuthService();
let header=Auth.getHeadersJWT();

console.log("FIXARE LA QUESTIONE PROVINCIA,COMUNE,CAP")
console.log("INSERIRE ELEMENTO PER AUTOASSEGNAZIONE IN FASE DI CONFIGURAZIONE UTENTE")
console.log("RECUPERO PASSWORD E INSERIMENTO PASSWORD (non la decide il master, viene inviata al collaboratore una mail con le istruzioni) ")

function Rappresentanti(props) {
  const notapraticas = useContext(NotePraticaContext)
  const notifications = useContext(NotificationContext);
  const [saved] = useState(!props.pratica.attiva)
  const [rappresentanti, setrappresentanti] = useState(null)
  const [schemaUtenti, setSchemaUtenti] = useState({item:null})
  const [schemaUtentiOrig, setSchemaUtentiOrig] = useState({item:null})
  const [loading, setLoading] = useState(true)
  const [speseSostenute, setSpeseSostenute] = useState(props.pratica.spese_sostenute ? props.pratica.spese_sostenute : 0)
  
  function setPercentualeNominativo(e){
    setSchemaUtenti({...schemaUtenti,item:{...schemaUtenti.item,[e.target.name]:e.target.value}})
  }

  useEffect(() => {
    setSchemaUtenti({...schemaUtenti,item:{
      titolare: props.pratica.titolare ? props.pratica.titolare.id : "",
      percentualeTitolare: props.pratica.percentuale_titolare ? props.pratica.percentuale_titolare : 100,
      venditore: props.pratica.venditore ? props.pratica.venditore.id : "",
      percentualeVenditore: props.pratica.percentuale_venditore ? props.pratica.percentuale_venditore : 100,
      collocatore: props.pratica.collocatore ? props.pratica.collocatore.id : "",
      percentualeCollocatore: props.pratica.percentuale_collocatore ? props.pratica.percentuale_collocatore : 100,
      backoffice : props.pratica.backoffice ? props.pratica.backoffice.id : "",
      capoarea : props.pratica.capoarea ? props.pratica.capoarea.id : "",
      areamanager: props.pratica.areamanager ? props.pratica.areamanager.id : "",
      id_capo :props.pratica.id_capo ? props.pratica.id_capo.id : ""
    }})
    setSchemaUtentiOrig({...schemaUtenti,item:{
      titolare: props.pratica.titolare ? props.pratica.titolare.id : "",
      percentualeTitolare: props.pratica.percentuale_titolare ? props.pratica.percentuale_titolare : 100,
      venditore: props.pratica.venditore ? props.pratica.venditore.id : "",
      percentualeVenditore: props.pratica.percentuale_venditore ? props.pratica.percentuale_venditore : 100,
      collocatore: props.pratica.collocatore ? props.pratica.collocatore.id : "",
      percentualeCollocatore: props.pratica.percentuale_collocatore ? props.pratica.percentuale_collocatore : 100,
      backoffice : props.pratica.backoffice ? props.pratica.backoffice.id : "",
      capoarea : props.pratica.capoarea ? props.pratica.capoarea.id : "",
      areamanager: props.pratica.areamanager ? props.pratica.areamanager.id : "",
      id_capo :props.pratica.id_capo ? props.pratica.id_capo.id : ""
    }})
    setLoading(false)
  }, [])

  // salvare il cambio dei vari ruoli come per gli Impegni,
  // fare un oggetto da gestire sia le persone e le varie percentuali
  
  function onlyUniqueNotNull(value, index, self) {
    return value !== null && value !== "" && self.indexOf(value) === index;
  }

  function salvaRuoli(e){
    e.preventDefault();
    
    if(checkPercentuali()){
      let cambiamenti="[Ruoli] Modifica ruoli"
      let usersId = []
      
      usersId.push(schemaUtenti.item.id_capo)
      usersId.push(schemaUtenti.item.titolare)
      usersId.push(schemaUtenti.item.venditore)
      usersId.push(schemaUtenti.item.collocatore)
      usersId.push(schemaUtenti.item.capoarea)
      usersId.push(schemaUtenti.item.areamanager)
      usersId.push(schemaUtenti.item.backoffice)
      let user = null;
      if(schemaUtenti.item.id_capo !== schemaUtentiOrig.item.id_capo){
        if(schemaUtenti.item.id_capo && !saved){
          user = rappresentanti.filter(x=>x.id === schemaUtenti.item.id_capo)[0]
          cambiamenti = cambiamenti + "<div>Nuova Agenzia:<b>"+user.descrizione+"</b></div>"
        }else{
          cambiamenti = cambiamenti + "<div>Rimossa agenzia</div>"
        } 
      }

      if((schemaUtenti.item.titolare !== schemaUtentiOrig.item.titolare) || (Number(schemaUtenti.item.percentualeTitolare) !== Number(schemaUtentiOrig.item.percentualeTitolare))){
        if(schemaUtenti.item.titolare){
          user = rappresentanti.filter(x=>x.id === schemaUtenti.item.titolare)[0]
          cambiamenti = cambiamenti + "<div>Nuovo Titolare:<b>"+user.nome+" "+user.cognome+"</b>, percentuale:<b>"+schemaUtenti.item.percentualeTitolare+"%</b></div>"
        }else{
          cambiamenti = cambiamenti + "<div>Rimosso Titolare pratica</div>"
        }
      }

      if((schemaUtenti.item.venditore !== schemaUtentiOrig.item.venditore) || (Number(schemaUtenti.item.percentualeVenditore) !== Number(schemaUtentiOrig.item.percentualeVenditore))){
        if(schemaUtenti.item.venditore){
          user = rappresentanti.filter(x=>x.id === schemaUtenti.item.venditore)[0]
        cambiamenti = cambiamenti + "<div>Nuovo Venditore:<b>"+user.nome+" "+user.cognome+"</b>, percentuale:<b>"+schemaUtenti.item.percentualeVenditore+"%</b></div>"
        }else{
          cambiamenti = cambiamenti + "<div>Rimosso Venditore pratica</div>"
        } 
      }

      if((schemaUtenti.item.collocatore !== schemaUtentiOrig.item.collocatore) || (Number(schemaUtenti.item.percentualeCollocatore) !== Number(schemaUtentiOrig.item.percentualeCollocatore))){
        if(schemaUtenti.item.collocatore){
          user = rappresentanti.filter(x=>x.id === schemaUtenti.item.collocatore)[0]
          cambiamenti = cambiamenti + "<div>Nuovo Collocatore:<b>"+user.nome+" "+user.cognome+"</b>, percentuale:<b>"+schemaUtenti.item.percentualeCollocatore+"%</b></div>"
        }else{
          cambiamenti = cambiamenti + "<div>Rimosso Collocatore pratica</div>"
        } 
      }

      if(schemaUtenti.item.capoarea !== schemaUtentiOrig.item.capoarea){
        if(schemaUtenti.item.capoarea){
          user = rappresentanti.filter(x=>x.id === schemaUtenti.item.capoarea)[0]
          cambiamenti = cambiamenti + "<div>Nuovo Capo area:<b>"+user.nome+" "+user.cognome+"</b></div>"
        }else{
          cambiamenti = cambiamenti + "<div>Rimosso Capo area pratica</div>"
        } 
      }
      if(schemaUtenti.item.areamanager !== schemaUtentiOrig.item.areamanager){
        if(schemaUtenti.item.areamanager){
          user = rappresentanti.filter(x=>x.id === schemaUtenti.item.areamanager)[0]
          cambiamenti = cambiamenti + "<div>Nuovo Area manager:<b>"+user.nome+" "+user.cognome+"</b></div>"
        }else{
          cambiamenti = cambiamenti + "<div>Rimosso Area manager pratica</div>"
        } 
      }
      if(schemaUtenti.item.backoffice !== schemaUtentiOrig.item.backoffice){
        if(schemaUtenti.item.backoffice){
          user = rappresentanti.filter(x=>x.id === schemaUtenti.item.backoffice)[0]
          cambiamenti = cambiamenti + "<div>Nuovo Backoffice:<b>"+user.nome+" "+user.cognome+"</b></div>"
        }else{
          cambiamenti = cambiamenti + "<div>Rimosso Backoffice pratica</div>"
        } 
      }


      let data={
        id:props.pratica.id,
        type:"setruoli",
        id_capo:schemaUtenti.item.id_capo,
        titolare:schemaUtenti.item.titolare,
        percentualeTitolare:Number(schemaUtenti.item.percentualeTitolare),
        venditore:schemaUtenti.item.venditore,
        percentualeVenditore:Number(schemaUtenti.item.percentualeVenditore),
        collocatore:schemaUtenti.item.collocatore,
        percentualeCollocatore:Number(schemaUtenti.item.percentualeCollocatore),
        speseSostenute:Number(speseSostenute),
        backoffice:schemaUtenti.item.backoffice,
        capoarea:schemaUtenti.item.capoarea,
        areamanager:schemaUtenti.item.areamanager,
        usersId:usersId.filter(onlyUniqueNotNull)      
      }

      axios.put('/api/1.1/pratica',data,{headers:header}).then((res)=>{
        let schema={
          id_capo:props.rappresentanti.filter(x=>x.id === schemaUtenti.item.id_capo)[0],
          titolare:props.rappresentanti.filter(x=>x.id === schemaUtenti.item.titolare)[0],
          percentualeTitolare:Number(schemaUtenti.item.percentualeTitolare),
          venditore:props.rappresentanti.filter(x=>x.id === schemaUtenti.item.venditore)[0],
          percentualeVenditore:Number(schemaUtenti.item.percentualeVenditore),
          collocatore:props.rappresentanti.filter(x=>x.id === schemaUtenti.item.collocatore)[0],
          percentualeCollocatore:Number(schemaUtenti.item.percentualeCollocatore),
          areamanager:props.rappresentanti.filter(x=>x.id === schemaUtenti.item.areamanager)[0],
          capoarea:props.rappresentanti.filter(x=>x.id === schemaUtenti.item.capoarea)[0],
          backoffice:props.rappresentanti.filter(x=>x.id === schemaUtenti.item.backoffice)[0]     
        }
        props.aggiornaSchemaUtenti(schema,speseSostenute)
        setSchemaUtentiOrig(schemaUtenti)
        if(cambiamenti!=="[Ruoli] Modifica ruoli"){
            notapraticas.aggiungiNota(cambiamenti,false)
        }
        notifications.NotifyAlarm(false,"operazione completata")
    }).catch(error=>{
        notifications.NotifyAlarm(true,"Operazione non completata")
    })
    }else{
      notifications.NotifyAlarm(true,"Somma delle percentuali diverso da 100")
    }
  }

  function checkPercentuali(){
    let sumOfPercentage = (Number(schemaUtenti.item.percentualeTitolare) + Number(schemaUtenti.item.percentualeVenditore) + Number(schemaUtenti.item.percentualeCollocatore))
    if( sumOfPercentage === 100 || sumOfPercentage === 0){
      return true
    }
    return false;
  }

  useEffect(() => {
      setrappresentanti(props.rappresentanti)
  }, [props.rappresentanti])

  useEffect(() => {
    if(schemaUtenti.item && !schemaUtenti.item.titolare){
      setSchemaUtenti({...schemaUtenti,item:{...schemaUtenti.item , percentualeTitolare:0}})
    }
  }, [schemaUtenti.item && schemaUtenti.item.titolare])

  useEffect(() => {
    if(schemaUtenti.item && !schemaUtenti.item.venditore){
      setSchemaUtenti({...schemaUtenti,item:{...schemaUtenti.item , percentualeVenditore:0}})
    }
  }, [schemaUtenti.item && schemaUtenti.item.venditore])

  useEffect(() => {
    if(schemaUtenti.item && !schemaUtenti.item.collocatore){
      setSchemaUtenti({...schemaUtenti,item:{...schemaUtenti.item , percentualeCollocatore:0}})
    }
  }, [schemaUtenti.item && schemaUtenti.item.collocatore])

  return (
    <div>
      {!loading && rappresentanti ?
      <form onSubmit={(e) => salvaRuoli(e)}>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12} >
              <FormControl style={{width:"100%"}}>
                <InputLabel id="outline-bopratica">Agenzia</InputLabel>
                  <Select
                      disabled={saved}
                      style={{width:"100%"}}
                      labelId="outline-agenzia"
                      value={schemaUtenti.item.id_capo}
                      onChange={(e)=>{setPercentualeNominativo(e)}}
                      name="id_capo"
                      label="Agenzia"
                      >
                      <MenuItem value=""><em>None</em></MenuItem>
                          {rappresentanti && rappresentanti.map((rappresentante,i)=>{
                              return(
                                  <MenuItem key={i} value={rappresentante.id}>{rappresentante.descrizione}</MenuItem>
                              )
                          })}
                  </Select>
                </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item lg={10} md={10} sm={10} xs={10}>
              <FormControl style={{width:"100%"}}>
                <InputLabel id="outline-titolare">Titolare</InputLabel>
                <Select
                    disabled={saved}
                    style={{width:"100%"}}
                    labelId="outline-titolare"
                    value={schemaUtenti.item.titolare}
                    onChange={(e)=>{setPercentualeNominativo(e)}}
                    label="Titolare"
                    name="titolare"
                    >
                    <MenuItem value=""><em>None</em></MenuItem>
                        {rappresentanti && rappresentanti.filter((element)=>{
                          if(schemaUtenti.item.id_capo && !saved){
                            return element.id_agenzia === schemaUtenti.item.id_capo
                          }else{
                            return element
                          }
                        }).map((rappresentante,i)=>{
                            return(
                                <MenuItem key={i} value={rappresentante.id}>{rappresentante.nome + " "+rappresentante.cognome} ({rappresentante.role} {rappresentante.descrizione ? " - " + rappresentante.descrizione :null})</MenuItem>
                            )
                        })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <TextField style={{width:"100%"}}  label="%" disabled={saved} name="percentualeTitolare" type="number" min={0} required={schemaUtenti.item.titolare} onChange={(e)=>{setPercentualeNominativo(e)}} value={schemaUtenti.item.percentualeTitolare} />
            </Grid>          
          </Grid>

          <Grid container spacing={1}>
            <Grid item lg={10} md={10} sm={10} xs={10} >
              <FormControl style={{width:"100%"}}>
                <InputLabel id="outline-venditore">Venditore</InputLabel>
                <Select
                    disabled={saved}
                    style={{width:"100%"}}
                    labelId="outline-venditore"
                    value={schemaUtenti.item.venditore}
                    onChange={(e)=>{setPercentualeNominativo(e)}}
                    name="venditore"
                    label="Venditore"
                    >
                    <MenuItem value=""><em>None</em></MenuItem>
                        {rappresentanti && rappresentanti.filter((element)=>{
                          if(schemaUtenti.item.id_capo && !saved){
                            return element.id_agenzia === schemaUtenti.item.id_capo
                          }else{
                            return element
                          }
                        }).map((rappresentante,i)=>{
                            return(
                                <MenuItem key={i} value={rappresentante.id}>{rappresentante.nome + " "+rappresentante.cognome} ({rappresentante.role} {rappresentante.descrizione ? " - " + rappresentante.descrizione :null})</MenuItem>
                            )
                        })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <TextField style={{width:"100%"}} disabled={schemaUtenti.item && !schemaUtenti.item.venditore || saved} name="percentualeVenditore" label="%" type="number" min={0} required={schemaUtenti.item.venditore} onChange={(e)=>{setPercentualeNominativo(e)}} value={schemaUtenti.item.venditore ? schemaUtenti.item.percentualeVenditore : 0} />
            </Grid>          
          </Grid>

          <Grid container spacing={1}>
            <Grid item lg={10} md={10} sm={10} xs={10} >
              <FormControl style={{width:"100%"}}>
                <InputLabel id="outline-collocatore">Collocatore</InputLabel>
                <Select
                    disabled={saved}
                    style={{width:"100%"}}
                    labelId="outline-collocatore"
                    value={schemaUtenti.item.collocatore}
                    onChange={(e)=>{setPercentualeNominativo(e)}}
                    name="collocatore"
                    label="Collocatore"
                    >
                    <MenuItem value=""><em>None</em></MenuItem>
                        {rappresentanti && rappresentanti.filter((element)=>{
                          if(schemaUtenti.item.id_capo && !saved){
                            return element.id_agenzia === schemaUtenti.item.id_capo
                          }else{
                            return element
                          }
                        }).map((rappresentante,i)=>{
                            return(
                                <MenuItem key={i} value={rappresentante.id}>{rappresentante.nome + " "+rappresentante.cognome} ({rappresentante.role} {rappresentante.descrizione ? " - " + rappresentante.descrizione :null})</MenuItem>
                            )
                        })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2}>
              <TextField style={{width:"100%"}} disabled={(schemaUtenti.item && !schemaUtenti.item.collocatore) || saved} name="percentualeCollocatore" label="%" type="number" min={0} required={schemaUtenti.item.collocatore} onChange={(e)=>{setPercentualeNominativo(e)}} value={schemaUtenti.item.collocatore ? schemaUtenti.item.percentualeCollocatore : 0} />
            </Grid>          
          </Grid>
          <hr/>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField disabled={saved} style={{width:"100%"}} label="Spese Sostenute €" type="number" min={0} required={true} onChange={(e)=>{setSpeseSostenute(e.target.value)}} value={speseSostenute} />
            </Grid>          
          </Grid>
          <hr/>
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <FormControl style={{width:"100%"}}>
                <InputLabel id="outline-bopratica">Backoffice Pratica</InputLabel>
                  <Select
                      disabled={saved}
                      style={{width:"100%"}}
                      labelId="outline-bopratica"
                      value={schemaUtenti.item.backoffice}
                      onChange={(e)=>{setPercentualeNominativo(e)}}
                      name="backoffice"
                      label="Backoffice Pratica"
                      >
                      <MenuItem value=""><em>None</em></MenuItem>
                          {rappresentanti && rappresentanti
                          // .filter((element)=>{
                          // if(schemaUtenti.item.id_capo && !saved){
                          //   return element.id_agenzia === schemaUtenti.item.id_capo
                          // }else{
                          //   return element
                          // }})
                           .map((rappresentante,i)=>{
                              return(
                                  <MenuItem key={i} value={rappresentante.id}>{rappresentante.nome + " "+rappresentante.cognome} ({rappresentante.role} {rappresentante.descrizione ? " - " + rappresentante.descrizione :null})</MenuItem>
                              )
                          })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item lg={12} md={12} sm={12} xs={12} >
                <FormControl style={{width:"100%"}}>
                  <InputLabel id="outline-capoarea">Capo area</InputLabel>
                    <Select                      
                        disabled={saved}
                        style={{width:"100%"}}
                        labelId="outline-bopratica"
                        value={schemaUtenti.item.capoarea}
                        onChange={(e)=>{setPercentualeNominativo(e)}}
                        name="capoarea"
                        label="Capo area"
                        >
                        <MenuItem value=""><em>None</em></MenuItem>
                            {rappresentanti && rappresentanti
                            // .filter((element)=>{
                            // if(schemaUtenti.item.id_capo && !saved){
                            //   return element.id_agenzia === schemaUtenti.item.id_capo
                            // }else{
                            //   return element
                            // }})
                            .map((rappresentante,i)=>{
                                return(
                                    <MenuItem key={i} value={rappresentante.id}>{rappresentante.nome + " "+rappresentante.cognome} ({rappresentante.role} {rappresentante.descrizione ? " - " + rappresentante.descrizione :null})</MenuItem>
                                )
                            })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                  <FormControl style={{width:"100%"}}>
                    <InputLabel id="outline-areamanager">Area manager</InputLabel>
                      <Select
                          disabled={saved}
                          style={{width:"100%"}}
                          labelId="outline-areamanager"
                          value={schemaUtenti.item.areamanager}
                          onChange={(e)=>{setPercentualeNominativo(e)}}
                          name="areamanager"
                          label="Area manager"
                          >
                          <MenuItem value=""><em>None</em></MenuItem>
                              {rappresentanti && rappresentanti
                            //   .filter((element)=>{
                            //   if(schemaUtenti.item.id_capo && !saved){
                            //     return element.id_agenzia === schemaUtenti.item.id_capo
                            //   }else{
                            //     return element
                            //   }
                            // })
                            .map((rappresentante,i)=>{
                                return(
                                    <MenuItem key={i} value={rappresentante.id}>{rappresentante.nome + " "+rappresentante.cognome} ({rappresentante.role} {rappresentante.descrizione ? " - " + rappresentante.descrizione :null})</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                  </Grid>
                </Grid>
          {/* percentuale titolare </div>
          percentuale agenzia </div> */}
          {!saved ?
            <Grid container style={{marginTop:"15px"}}>
              <Button size="small" disabled={saved} variant="contained" type="submit" color="primary"><i className="fas fa-save"></i> Salva</Button>
            </Grid>
          :null}
      </form>
      :
      <div style={{textAlign:"center"}}>
        <DefaultSpinner></DefaultSpinner>
      </div>}
    </div>
  );
}

export default Rappresentanti;