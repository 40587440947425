import React,{ useState ,useEffect} from 'react';
import moment from 'moment'
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ModalAgenda from '../modali/ModalAgenda';
import axios from 'axios';
import AuthService from '../utente/AuthService'
import DefaultSpinner from '../DefaultSpinner';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'moment/locale/it';
import Button from '@material-ui/core/Button';
import { Grid, Select } from '@material-ui/core';
import { MenuItem } from 'react-bootstrap-typeahead';

let Auth = new AuthService();
let header=Auth.getHeadersJWT();
moment.locale("it-IT")
const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(BigCalendar);

//https://codesandbox.io/s/react-big-calendar-drag-and-drop-events-between-months-ykivg

const CustomEvent = (event) => { 
    return ( 
      <span><strong>{event.title}</strong></span> 
    ) 
  }
function Agenda(props) {
    const [periodiScaricati, setPeriodiScaricati] = useState({list:[]})
    const [loading, setLoading] = useState(true)
    const [listEvent, setListEvent] = useState({items:[]})
    const [openModalAgenda, setOpenModalAgenda] = useState(false)
    const [event, setEvent] = useState(null)
    const [periodo, setPeriodo] = useState(new Date())
    const [usersList, setUsersList] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)

    function openModal(){
        setOpenModalAgenda(true);
    }
    function closeModal(){
        setOpenModalAgenda(false);
    }
    function filtraPerUtente(element){
        if(!selectedUser){
            return element
        }else{
            if(element.identificativo === Number(selectedUser)){
                return element
            }else{
                return null
            }
        }
    }

    function selectEvent(e){
        setEvent(e);
        openModal();
    }
    function moveResizeEvent(e){
        let oldStart = e.event.start;
        let oldEnd = e.event.end;
        let start = moment(e.start).toDate();
        let end = moment(e.end).toDate();

        let event= e.event;
        if(event.id_pratica === null){
            let newList= listEvent.items
            for (let i = 0; i < newList.length; i++) {
                if(newList[i].id === event.id){
                    newList[i] = event;
                    newList[i].start = start;
                    newList[i].end = end;
                }
            }
            setListEvent({items:newList})
                    
            axios.put('/api/1.1/agenda',event,{headers:header}).then((res)=>{}).catch(error=>{
                newList= listEvent.items
                for (let i = 0; i < newList.length; i++) {
                    if(newList[i].id === event.id){
                        newList[i] = event;
                        newList[i].start = oldStart;
                        newList[i].end = oldEnd;
                    }
                }
                setListEvent({items:newList})  
            })
        }
    }

    function addNewEvent(event){       
        let newList = listEvent.items
        newList.push(event)
        setListEvent({...listEvent,items:newList})
    }
    function updateNewEvent(event){
        let newList= listEvent.items
        for (let i = 0; i < newList.length; i++) {
            if(newList[i].id === event.id){
                newList[i] = event;
            }
        }
        setListEvent({...listEvent,items:newList})
    }
    function changePeriod(periodo){
        setPeriodo(periodo)
    }
    function removeEvent(event){
        let newList= listEvent.items
        newList.forEach((element,index,object) => {
            if(element.id === event.id){
                object.splice(index,1)
            }
        });
        setListEvent({...listEvent,items:newList})
    }
    useEffect(() => {
        if(periodo){
            let prdEsame = periodo.getFullYear().toString()+"-"+("0" + (periodo.getMonth() + 1)).slice(-2)+"-01"
            if(periodiScaricati.list.indexOf(prdEsame) < 0){
                let prdS = periodiScaricati.list;
                prdS.push(prdEsame);
                setPeriodiScaricati({...periodiScaricati, list:prdS})
                let data={
                    periodo:prdEsame
                }
                axios.post('/api/1.1/agenda', data , {headers:header}).then((res)=>{
                    let items = res.data.data;
                    for (let index = 0; index < items.length; index++) {
                        const element = items[index];
                        element.start = new Date(element.start)
                        element.end = moment(element.end).toDate()
                    }
                    let tmp_list=listEvent.items.concat(items);
                    setListEvent({items:tmp_list})
                    setLoading(false)
                }).catch(error=>{
                    //TODO: da gestire l'errore
                    console.log(error)
                })
            }
        }
    }, [periodo])

    useEffect(() => {
        axios.get('/api/1.1/user', {headers:header}).then(res=>{
            setUsersList({list:res.data.data})
        })
    }, [])

    return (
        !loading && usersList.list ?
        <> 
            <Grid container>
           {/* filtrare per utente se si è agenzia, quindi possibilità di modificare la data di contatto? alarm e decorrenza 
              se si è agenzia si vede tutto che non sia personale
           */}
            {/* https://codesandbox.io/s/m5vr0wq5ox */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Button size="small" type="submit" variant="contained" color="primary" style={{marginBottom:"10px"}} onClick={()=>{setEvent(null);openModal()}}><i className="fas fa-plus"></i> Aggiungi</Button>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} style={{textAlign:"right"}}>
                            <Select
                                labelId="outline-user"
                                onChange={(e)=>{setSelectedUser(e.target.value)}}
                                label="Utente"
                                value={selectedUser}
                                >
                                <MenuItem value={null}><em>tutti</em></MenuItem>
                                    {usersList.list.map((user,i)=>{
                                        return(
                                            <MenuItem key={i} value={user.id}>{user.nome} {user.cognome}</MenuItem>
                                        )
                                    })}
                            </Select>
                        </Grid>
                    </Grid>
                    <DragAndDropCalendar 
                        selectable
                        // resizable
                        style={{minHeight:"500px", height:"100%"}}
                        messages={{next: "avanti", previous: "indietro", today: "oggi", month: "mese", week: "settimana",day:"giorno"}}
                        events={listEvent.items.filter(filtraPerUtente)}
                        onEventDrop={moveResizeEvent}
                        // onEventResize={moveResizeEvent}
                        onNavigate={changePeriod}
                        localizer={localizer}
                        culture='it-IT'
                        timeslots={10}
                        views={['month', 'week',"day","agenda"]}
                        eventPropGetter={event => ({
                            style: {
                              backgroundColor: event.background,
                              color:event.color,
                              fontSize:"13px"
                            },
                          })}
                          components={{
                            event: CustomEvent
                          }}
                        onDoubleClickEvent={selectEvent}
                    />
                </Grid>
            </Grid>
            <ModalAgenda 
                event={event} 
                openModalAgenda={openModalAgenda} 
                closeModalAgenda={closeModal} 
                addNewEvent={addNewEvent} 
                updateNewEvent = {updateNewEvent}
                removeEvent={removeEvent}>
            </ModalAgenda>
        </>
        :<DefaultSpinner></DefaultSpinner>
    );
}

export default Agenda;