import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios';
import AuthService from '../utente/AuthService'
import  CodiceFiscale  from 'codice-fiscale-js';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SettingContext } from '../../contexts/SettingContext';
import { Button, FormControl, FormControlLabel, Grid, InputLabel, Paper, Radio, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import {MenuItem} from 'react-bootstrap-typeahead'
import {getProvincieLista,getComuniByProvincia, getProvinciaNomeByCode} from '../../commons/provincie_comuni'
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete';
import {PDFDocument} from 'pdf-lib';
import fileDownload from 'js-file-download';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

const tipilavoro = ["PUBBLICO", "PARAPUBBLICO", "STATALE" , "PENSIONATO", "PRIVATO", "PRIVATO SMALLBUSINESS"]
// const tipoproprieta = ["AFFITTUARIO","PROPRIETARIO"],
// consttipipensioni = ["INPS","INPDAP"]
const tipidipendente = ["PRIVATO","PUBBLICO"]
const duratafinanziamento =[24,36,48,60,72,84,96,108,120]
let lunghezzaPaginaPDF=1300;

function IntervistaCliente(props) {
    const [codfiscaleObbligatorio, setCodfiscaleObbligatorio] = useState(false)
    const [searching, setSearching] = useState(false)
    const [confermaPrivacy, setConfermaPrivacy] = useState(false)
    const notifications = useContext(NotificationContext);
    const settings = useContext(SettingContext);
    const [provincie] = useState(getProvincieLista())
    const [nome, setNome] = useState("")
    const [cognome, setCognome] = useState("")
    const [datadinascita, setDatadinascita] = useState("")
    const [provinciadinascita, setProvinciadinascita] = useState("")
    const [sesso, setSesso] = useState("M")
    const [comunedinascita, setComunedinascita] = useState("")
    const [pubblicita, setPubblicita] = useState("")
    const [codicefiscale, setCodicefiscale] = useState("")
    const [lavoro, setLavoro] = useState("")
    const [invalidita, setInvalidita] = useState("")
    const [qualifica, setQualifica] = useState("")
    const [telefono, setTelefono] = useState("")
    const [cellulare, setCellulare] = useState("")
    const [stipendio, setStipendio] = useState(0)
    const [ritardipagamento, setRitardipagamento] = useState("")
    const [finanziamentiincorso, setFinanziamentiincorso] = useState("")
    const [email, setEmail] = useState("")
    const [richiestacliente, setRichiestacliente] = useState({list:[]})
    const [id_richiestacliente, setId_richiestacliente] = useState({list:[]})
    const [proprieta, setProprieta] = useState("")
    const [assicurazioneincorso, setAssicurazioneincorso] = useState("")
    const [finanziamentiincorsolista, setFinanziamentiincorsolista] = useState({list:[]})
    const [finanziamentoSelected, setFinanziamentoSelected] = useState(null)
    const [tipopratica] = useState("PRIVATO")
    const [default_finanziamento, setDefault_finanziamento] = useState({
            tipo:"",
            data_prima_rata:"",
            rata:"",
            durata:"",
            coobligato:null,
            estinzione:""
        }
    )
    const [documento, setDocumento] = useState(null)
    const [scaricato, setScaricato] = useState(false)

    async function exportPDF(e){
        e.preventDefault();
        let doc_proxy = settings.precompilati.list.filter(x=>x.id === documento)[0]
        const existingPdfBytes = await fetch(doc_proxy.proxy_url,{headers:header}).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const pages = pdfDoc.getPages()
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            let list = JSON.parse(doc_proxy.listaopzioni).filter(x=>x.y_real >= (lunghezzaPaginaPDF*i) && x.y_real < (lunghezzaPaginaPDF*(i+1)))
            for (let l = 0; l < list.length; l++) {
                const element = list[l];
                let percentualeConversione = page.getMediaBox().height/lunghezzaPaginaPDF
                page.drawText(element.value, {
                    x: ((element.x)*percentualeConversione)+21,
                    y: (((lunghezzaPaginaPDF*(i+1) - element.y_real)*percentualeConversione)-Number(element.fontSize)-13.5),
                    size: Number(element.fontSize)*percentualeConversione,
                    // color: rgb(0.95, 0.1, 0.1),
                    // rotate: degrees(-45),
                  })               
            } 
        }
        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()
        fileDownload(pdfBytes, doc_proxy.file.split("?")[0].split("/")[doc_proxy.file.split("?")[0].split("/").length - 1], "application/pdf");
        setScaricato(true)
    }

    function salvaIntervista(e){
        e.preventDefault()
        let datacheck = {
            tiporicerca:"ricercautente",
            tipopratica:tipopratica,
            nome:nome,
            cognome:cognome,
            codicefiscale:codicefiscale
        }
        setSearching(true)
        axios.post('/api/1.1/cliente',datacheck,{headers:header}).then(res=>{
            if(res.data.exists && !codfiscaleObbligatorio && !codicefiscale){
                notifications.NotifyAlarm(true,"Il cliente "+nome+" "+cognome+" potrebbe essere già presente, richiesto codice fiscale per ulteriore verifica")
                setCodfiscaleObbligatorio(true)
                setSearching(false)
                return
            }else if(res.data.exists && (codfiscaleObbligatorio || (!codfiscaleObbligatorio && codicefiscale))){
                    notifications.NotifyAlarm(true,"Il cliente "+nome+" "+cognome+" ("+codicefiscale+") già presente")
                    setSearching(false)
                    return
            }
            let data={
                nome:nome,
                cognome:cognome,
                datadinascita:datadinascita,
                provinciadinascita:provinciadinascita,
                sesso:sesso,
                comunedinascita:comunedinascita,
                pubblicita:pubblicita,
                codicefiscale:codicefiscale,
                lavoro:lavoro,
                qualifica:qualifica,
                invalidita:invalidita,
                telefono:telefono,
                cellulare:cellulare,
                stipendio:stipendio,
                ritardipagamento:ritardipagamento,
                finanziamentiincorso:finanziamentiincorso,
                email:email,
                id_richiestacliente:id_richiestacliente.list,
                proprieta:proprieta,
                assicurazioneincorso:assicurazioneincorso,
                finanziamentiincorsolista:finanziamentiincorsolista.list,
                tipopratica:"PRIVATO"
            }
    
            if(datadinascita && provinciadinascita && comunedinascita && sesso){
                calcolaCodiceFiscale(e);
            }
            setTimeout(() => {
                axios.put('/api/1.1/cliente',data,{headers:header}).then((res)=>{
                    resetItem();
                    notifications.NotifyAlarm(false,"operazione completata")
                }).catch(error=>{
                    notifications.NotifyAlarm(true,"Operazione non riuscita")
                  })  
            }, 200);
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Operazione non riuscita")
            setSearching(false)
            return
        })
    }

    function aggiungiFinanziamento(e){
        e.preventDefault()
        var lista = finanziamentiincorsolista.list
        lista.push(default_finanziamento)

        setFinanziamentiincorsolista({...finanziamentiincorsolista,lista:lista})
        setDefault_finanziamento({
            tipo:"",
            data_prima_rata:"",
            rata:"",
            durata:"",
            coobligato:null,
            estinzione:""
            }
        )
        setFinanziamentoSelected(null)
    }

    function rimuoviFinanziamento(i){
        var lista = finanziamentiincorsolista.list
        lista.splice(i,1)
        setFinanziamentiincorsolista({...finanziamentiincorsolista,lista:lista})
    }
    function selectFinanziamento(event){
        event.preventDefault()
        let f=null
        if(settings.finanziamenti.list.filter(x=>x.nome === event.target.value).length>0){
            f=settings.finanziamenti.list.filter(x=>x.nome === event.target.value)[0]
        }
        setDefault_finanziamento({...default_finanziamento,[event.target.name]:event.target.value})
        setFinanziamentoSelected(f)
    }

    function handleOptionDefFinanziamento(value){
        setDefault_finanziamento({...default_finanziamento,coobligato:value})       
    }

    function onChangeDefFinanziamento(e){
        setDefault_finanziamento({...default_finanziamento,[e.target.name]:e.target.value})
    }

    function calcolaCodiceFiscale(e){
        e.preventDefault()
        try{
            const cf = new CodiceFiscale({
                name: nome.toUpperCase(),
                surname: cognome.toUpperCase(),
                gender: sesso.toUpperCase(),
                day: datadinascita.split("-")[2],
                month: datadinascita.split("-")[1],
                year: datadinascita.split("-")[0],
                birthplace: comunedinascita.toUpperCase() ,
                birthplaceProvincia: provinciadinascita.toUpperCase()
            });
            setCodicefiscale(cf.code)
        }catch(err){
            alert("Errore: compilare tutti i campi correttamente ("+err+")")
        }
    }

    function modificaRichiestaCliente(id,nome){
        let lista = richiestacliente.list
        let lista_id = id_richiestacliente.list

        var index = lista.indexOf(nome)
        if(index>=0){
            lista.splice(index, 1);
        }else{
            lista.push(nome)
        }

        var index_id = lista.indexOf(id)
        if(index_id>=0){
            lista_id.splice(index_id, 1);
        }else{
            lista_id.push(id)
        }
        setId_richiestacliente({...id_richiestacliente,list:lista_id})
        setRichiestacliente({...richiestacliente,list:lista})
    }

    function resetItem(){
        setNome("")
        setCognome("")
        setDatadinascita("")
        setProvinciadinascita("")
        setSesso("")
        setComunedinascita("")
        setPubblicita("")
        setCodicefiscale("")
        setLavoro("")
        setInvalidita("")
        setQualifica("")
        setTelefono("")
        setCellulare("")
        setStipendio(0)
        setRitardipagamento("")
        setFinanziamentiincorso("")
        setEmail("")
        setRichiestacliente({list:[]})
        setId_richiestacliente({list:[]})
        setProprieta("")
        setAssicurazioneincorso("")
        setFinanziamentiincorsolista({list:[]})
        setFinanziamentoSelected(null)
        setDefault_finanziamento({
                tipo:"",
                data_prima_rata:"",
                rata:"",
                durata:"",
                coobligato:null,
                estinzione:""
            }
        )
    }

    useEffect(() => {
        setCodfiscaleObbligatorio(false)
    }, [nome || cognome])
    return (
        <div>
            <form onSubmit={(e)=>salvaIntervista(e)}>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Nome"} required  onChange={(e)=>{setNome(e.target.value)}} value={nome} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Cognome"} required   onChange={(e)=>{setCognome(e.target.value)}} value={cognome} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Telefono"} required onChange={(e)=>{setTelefono(e.target.value)}} value={telefono} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Cellulare"} onChange={(e)=>{setCellulare(e.target.value)}} value={cellulare} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <span style={{marginRight:"5px"}}>Sesso: </span>
                    <FormControlLabel value="M" control={<Radio color="primary" />} checked={sesso==="M"} label="MASCHIO" onChange={()=>setSesso("M")} />
                    <FormControlLabel value="F" control={<Radio color="primary" />} checked={sesso==="F"} label="FEMMINA" onChange={()=>setSesso("F")} />
                </Grid>
            </Grid>
            <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provincie ? 
                            <Autocomplete
                                id="combo-box-demo"
                                options={provincie}
                                getOptionLabel={(option) => option.name ? option.name : getProvinciaNomeByCode(option)}
                                style={{width:"100%"}}
                                name="provincia"
                                defaultValue={provinciadinascita}
                                onChange={(event, value) => {
                                    if(value){
                                        setProvinciadinascita(value.code);
                                    }else{
                                        setComunedinascita("");
                                        setProvinciadinascita("");
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Provincia di nascita"} value={provinciadinascita}/>}
                            />
                            :null}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provinciadinascita === "EE" ? 
                            <TextField style={{width:"100%"}} label={"Stato di nascita"} 
                                required onChange={(e)=>setComunedinascita(e.target.value)} value={comunedinascita}/>
                        :
                        provincie ? 
                            <Autocomplete
                                id="combo-box-c"
                                options={getComuniByProvincia(provinciadinascita)}
                                getOptionLabel={(option) =>{return option.nome ? option.nome : option}}
                                disabled={!provinciadinascita}
                                style={{width:"100%"}}
                                name="comunedinascita"
                                defaultValue={comunedinascita}
                                onChange={(event, value) => {
                                    if(value){
                                        setComunedinascita(value.nome);
                                    }else{setComunedinascita("")}
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Comune di nascita"} value={comunedinascita}/>}
                                />
                        :null}
                    </Grid>
                
                </Grid> 

            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField label="Data di nascita"
                        InputLabelProps={{
                        shrink: true
                        }}
                        fullWidth={true}
                        onChange={(e)=>{setDatadinascita(e.target.value)}} value={datadinascita} type="date">
                    </TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <Grid container>
                        <Grid item lg={9} md={9} sm={9} xs={9} style={{padding:"5px"}}>
                            <TextField style={{width:"100%"}} label={"Codice fiscale"} required={codfiscaleObbligatorio} onChange={(e)=>{setCodicefiscale(e.target.value)}} value={codicefiscale} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3} style={{padding:"5px"}}>
                            <Button color="primary" variant="contained" size="small" onClick={(e)=>calcolaCodiceFiscale(e)}>Calcola CF</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel id="outline-fonte">Fonte</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            labelId="outline-fonte"
                            value={pubblicita} onChange={(e)=>setPubblicita(e.target.value)}
                            label="Backoffice Pratica"
                            >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {settings.fonti.list.filter((fonte)=>{
                                if(tipopratica==="PRIVATO" && fonte.isPrivato){
                                    return fonte
                                }
                                return null
                            }).map((item,i)=>{
                                return(
                                        <MenuItem key={i} value={item.nome}>{item.nome}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Email"} onChange={(e)=>{setEmail(e.target.value)}} value={email} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel id="outline-cc">Categoria</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            labelId="outline-cc"
                            value={lavoro} onChange={(e)=>setLavoro(e.target.value)}
                            label="Categoria"
                            >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {tipilavoro.map((item,i) => {
                                return(
                                    <MenuItem key={i} value={item}>{item}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                {lavoro.indexOf("DIPENDENTE")>=0 || lavoro.indexOf("PENSIONATO")>=0  ?
                    <FormControl style={{width:"100%"}}>
                        <InputLabel id="outline-q">Qualifica</InputLabel>
                        <Select
                            style={{width:"100%"}}
                            labelId="outline-q"
                            value={qualifica} onChange={(e)=>setQualifica(e.target.value)}
                            label="Qualifica"
                            >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {tipidipendente.map((item,i) => {
                                return(
                                        <MenuItem key={i} value={item}>{item}</MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                :null}
                </Grid>
            </Grid> 
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Quanto percepisce?"} type="numeric" onChange={(e)=>{setStipendio(e.target.value)}} value={stipendio} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <span>Richiesta cliente</span>
                    <div style={{maxHeight:"200px",overflowY:"scroll",border:"1px solid lightgrey", padding:"5px"}}>
                        {settings.prodotti.list.filter((pratica)=>{
                            if(tipopratica==="PRIVATO" && pratica.isPrivato){
                                return pratica
                            }
                            return null
                        }).map((item,i) => {
                            return(
                                <div className="form-check" key={i}>
                                    <input className="form-check-input" type="checkbox" checked={id_richiestacliente.list.indexOf(item.id)>-1} onChange={(e)=>modificaRichiestaCliente(item.id,item.nome)} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        {item.nome}
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </Grid> 
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
            </Grid>
            <Grid container style={{marginBottom:"15px"}}>
                <Grid item lg={12} md={12} sm={12} xs={12} style={{padding:"5px"}}>
                    <span>Ha mai subito ritardi su pagamenti?</span> <br/>
                    <FormControlLabel control={<Radio color="primary" />} label="SI" value="S" checked={ritardipagamento === 'Si'} onChange={()=>setRitardipagamento("Si")}/>
                    <FormControlLabel control={<Radio color="primary" />} label="NO" value="S" checked={ritardipagamento === 'No'} onChange={()=>setRitardipagamento("No")}/>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} style={{padding:"5px"}}>
                    <span>Assicurazione in corso?</span> <br/>
                    <FormControlLabel control={<Radio color="primary" />} label="SI" value="S" checked={assicurazioneincorso === 'Si'} onChange={()=>setAssicurazioneincorso("Si")}/>
                    <FormControlLabel control={<Radio color="primary" />} label="NO" value="S" checked={assicurazioneincorso === 'No'} onChange={()=>setAssicurazioneincorso("No")}/>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12} style={{padding:"5px"}}>
                    <span>Ha finanziamenti in corso?</span> <br/>
                    <FormControlLabel control={<Radio color="primary" />} label="SI" value="S" checked={finanziamentiincorso === 'Si'} onChange={()=>setFinanziamentiincorso("Si")}/>
                    <FormControlLabel control={<Radio color="primary" />} label="NO" value="S" checked={finanziamentiincorso === 'No'} onChange={()=>setFinanziamentiincorso("No")}/>
                </Grid>
            </Grid>
            {finanziamentiincorso === 'Si' ?
                <>
                    <div className="table-responsive-md">
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{fontWeight:"bold"}}>Tipo</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Rata</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Durata</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Coobligato</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Data prima rata</TableCell>
                                        <TableCell style={{fontWeight:"bold"}}>Estinzione</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {finanziamentiincorsolista.list.map((finanziamento,i)=>{
                                        return(
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">{finanziamento.tipo}</TableCell>
                                                <TableCell>{finanziamento.rata}</TableCell>
                                                <TableCell>{finanziamento.durata}</TableCell>
                                                <TableCell>{finanziamento.coobligato === "S" ? "Si":null} {finanziamento.coobligato === "N" ? "No":null}</TableCell>
                                                <TableCell>{moment(new Date(finanziamento.data_prima_rata)).format("DD/MM/YYYY")}</TableCell>
                                                <TableCell>{finanziamento.estinzione}</TableCell>
                                                <TableCell><Button size="small" variant="outlined" color="secondary" onClick={(e)=>rimuoviFinanziamento(i)}>Rimuovi</Button></TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>                      
                    <div style={{marginBottom:"15px"}}>
                        <hr/>
                        <h5>Aggiungi finanziamento:</h5>
                        <Grid container>
                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                            <FormControl style={{width:"100%"}}>
                                <InputLabel id="outline-tp">Tipo</InputLabel>
                                <Select
                                    required
                                    style={{width:"100%"}}
                                    labelId="outline-tp"
                                    name="tipo"
                                    value={default_finanziamento.tipo} onChange={(e)=>selectFinanziamento(e)}
                                    label="Tipo"
                                    >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {settings.finanziamenti.list.filter((element)=>{
                                        if(tipopratica==="PRIVATO" && element.isPrivato){
                                            return element
                                        }
                                        return null
                                    }).map((finanziamento,i)=>{
                                        return(
                                                <MenuItem key={i} value={finanziamento.nome}>{finanziamento.nome}</MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                                <TextField required style={{width:"100%"}} label={"Dec 1° rata"} value={default_finanziamento.data_prima_rata} 
                                InputLabelProps={{
                                    shrink: true
                                    }}
                                name="data_prima_rata" type="date" onChange={(e)=>onChangeDefFinanziamento(e)} /> 
                            </Grid>   
                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                                <TextField required style={{width:"100%"}} label={"Rata"} value={default_finanziamento.rata} name="rata" type="number" onChange={(e)=>onChangeDefFinanziamento(e)} /> 
                            </Grid>                             
                        </Grid>
                        <Grid container>
                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                                <FormControl style={{width:"100%"}}>
                                    <InputLabel id="outline-dr">Durata</InputLabel>
                                    <Select
                                        required
                                        style={{width:"100%"}}
                                        labelId="outline-dr"
                                        name="durata" value={default_finanziamento.durata} onChange={(e)=>onChangeDefFinanziamento(e)}
                                        label="Tipo"
                                        >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {duratafinanziamento.map((item,i) => {
                                            return(
                                                    <MenuItem key={i} value={item}>{item}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                                <FormControl style={{width:"100%"}}>
                                    <InputLabel id="outline-es">Estinzione</InputLabel>
                                    <Select
                                        required
                                        style={{width:"100%"}}
                                        labelId="outline-ds"
                                        name="estinzione" value={default_finanziamento.estinzione} onChange={(e)=>{onChangeDefFinanziamento(e)}} 
                                        label="Estinzione"
                                        >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {settings.estinzioni.list.map((est,i)=>{
                                            return(
                                                    <MenuItem key={i} value={est.nome}>{est.nome}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {finanziamentoSelected && finanziamentoSelected.coobligato ?
                                <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                                    <span>Coobbligato</span>
                                    <div>                                    
                                        <FormControlLabel value="S" control={<Radio color="primary" />} checked={default_finanziamento.coobligato==="S"} label="SI" onChange={(e)=>handleOptionDefFinanziamento("S")} />
                                        <FormControlLabel value="N" control={<Radio color="primary" />} checked={default_finanziamento.coobligato==="N"} label="NO" onChange={(e)=>handleOptionDefFinanziamento("N")} />
                                    </div>
                                </Grid>
                            :null}
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Button size="small" color="primary" variant="outlined" onClick={(e)=>aggiungiFinanziamento(e)}>Aggiungi finanziamento</Button>
                        </Grid>
                    </div>
                </>
                :null}
                <Grid container style={{marginBottom:"15px"}}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <span>Il cliente acconsente al trattamento dei dati?</span>
                        <div>
                            <FormControlLabel control={<Radio color="primary" />} required checked={confermaPrivacy} label="SI" onChange={(e)=>setConfermaPrivacy(true)} />
                            <FormControlLabel control={<Radio color="primary" />} checked={!confermaPrivacy} label="NO" onChange={(e)=>setConfermaPrivacy(false)} />
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outline-fg">Documento privacy</InputLabel>
                            <Select
                                style={{width:"100%"}}
                                labelId="outline-fg"
                                value={documento} onChange={(e)=>setDocumento(e.target.value)}
                                label="Forma giuridica"
                                >
                                <MenuItem value=""><em>None</em></MenuItem>
                                {settings.precompilati.list.map((item,i)=>{
                                    return(
                                            <MenuItem key={i} value={item.id}>{item.alt}</MenuItem>
                                        )
                                    })}
                            </Select>
                        </FormControl>
                        <button disabled={!confermaPrivacy} onClick={(e)=>exportPDF(e)}> Scarica file privacy</button>
                    </Grid>
                </Grid>
                <Button size="small" color="primary" variant="contained" type="submit" disabled={!confermaPrivacy}>Salva</Button>
            </form>
        </div>
    );
}

export default IntervistaCliente;