import React,{useEffect, useState,useContext} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import { Button, FormControl, Grid, InputLabel, Select, TextField,MenuItem } from '@material-ui/core';
import {getProvincieLista,getComuniByProvincia,getProvinciaNomeByCode} from '../../commons/provincie_comuni'
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment'

let Auth=new AuthService();
let header=Auth.getHeadersJWT();
function Azienda(props) {
    const notifications = useContext(NotificationContext);
    const [provincie] = useState(getProvincieLista())
    const [pratica_id] = useState(props.pratica.id)
    const [saved, setSaved] = useState(props.pratica.azienda!==null || !props.pratica.attiva)
    const [partita_iva, setPartita_iva] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.partitaIva : props.pratica.azienda ? props.pratica.azienda.partita_iva : "")
    const [codice_fiscale, setCodice_fiscale] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.codicefiscale : props.pratica.azienda ? props.pratica.azienda.codice_fiscale : "")
    const [ragione_sociale, setRagione_sociale] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.ragioneSociale : props.pratica.azienda ? props.pratica.azienda.ragione_sociale : "")
    const [indirizzo, setIndirizzo] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.indirizzoSedeLegale : props.pratica.azienda ? props.pratica.azienda.indirizzo : "")
    const [civico, setCivico] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.civico : props.pratica.azienda ? props.pratica.azienda.civico : "")
    const [cap, setCap] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.cap : props.pratica.azienda ? props.pratica.azienda.cap : "")
    const [comune, setComune] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.comune : props.pratica.azienda ? props.pratica.azienda.comune : "")
    const [provincia, setProvincia] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.provincia : props.pratica.azienda ? props.pratica.azienda.provincia : "")
    const [telefono, setTelefono] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.telefono1 : props.pratica.azienda ? props.pratica.azienda.telefono : "")
    const [telefono2, setTelefono2] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.telefono2 : props.pratica.azienda ? props.pratica.azienda.telefono2 : "")
    const [fax, setFax] = useState(props.pratica.azienda ? props.pratica.azienda.fax : "")
    const [email, setEmail] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.indirizzoEmail : props.pratica.azienda ? props.pratica.azienda.email : "")
    const [persona_di_riferimento, setPersona_di_riferimento] = useState(!props.pratica.azienda && props.pratica.intervista_azienda && props.pratica.intervista_azienda.riferimento ? 
        JSON.parse(props.pratica.intervista_azienda.riferimento).nome + " " +JSON.parse(props.pratica.intervista_azienda.riferimento).cognome 
        : props.pratica.azienda ? props.pratica.azienda.persona_di_riferimento : "")    
    const [pec, setPec] = useState(!props.pratica.azienda && props.pratica.intervista_azienda ? props.pratica.intervista_azienda.indirizzoPec : props.pratica.azienda ? props.pratica.azienda.pec:"")
    const [stato_lavorativo, setStato_lavorativo] = useState(props.pratica.dati_cedente ? props.pratica.dati_cedente.stato_lavorativo : "")
    const [data_assunzione, setData_assunzione] = useState(props.pratica.dati_cedente ? moment(new Date(props.pratica.dati_cedente.data_assunzione)).format("YYYY-MM-DD") : null)
    const [stipendio_lordo, setStipendio_lordo] = useState(props.pratica.dati_cedente ? props.pratica.dati_cedente.stipendio_lordo : null)
    const [stipendio_netto, setStipendio_netto] = useState(props.pratica.dati_cedente ? props.pratica.dati_cedente.stipendio_netto : null)

    function salva(e){
        e.preventDefault()
        let data={
            id:pratica_id,
            type:"addazienda",
            dati_azienda:{
                partita_iva:partita_iva,
                codice_fiscale:codice_fiscale,
                ragione_sociale:ragione_sociale,
                indirizzo:indirizzo,
                civico:civico,
                provincia:provincia,
                cap:cap,
                comune:comune,
                telefono:telefono,
                telefono2:telefono2,
                fax:fax,
                email:email,
                pec:pec,
                persona_di_riferimento:persona_di_riferimento
            },
            dati_cedente:{
                stato_lavorativo:stato_lavorativo,
                stipendio_lordo:stipendio_lordo,
                stipendio_netto:stipendio_netto,
                data_assunzione:data_assunzione
            }
        }
        
        axios.put('/api/1.1/pratica',data,{headers:header}).then((res)=>{
            notifications.NotifyAlarm(false,"operazione completata")
            setSaved(true)
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Operazione non completata")
        })
    }
    function cercaAzienda(e){
        e.preventDefault()
        let data={
            type:"azienda",
            partita_iva:partita_iva
        }
        axios.post('/api/1.1/recuperadati',data,{headers:header}).then(res=>{
            setCodice_fiscale(res.data.data.codice_fiscale)
            setRagione_sociale(res.data.data.ragione_sociale)
            setIndirizzo(res.data.data.indirizzo)
            setCivico(res.data.data.civico)
            setProvincia(res.data.data.provincia)
            setCap(res.data.data.cap)
            setComune(res.data.data.comune)
            setTelefono(res.data.data.telefono)
            setTelefono2(res.data.data.telefono2)
            setFax(res.data.data.fax)
            setEmail(res.data.data.email)
            setPec(res.data.data.pec)
            setPersona_di_riferimento(res.data.data.persona_di_riferimento)
        })
    }
    useEffect(() => {
        setComune(null)
        setCap("")
    }, [provincia])

    return (
        <div>
            <form onSubmit={(e)=>salva(e)}>
                <h5>Dati Azienda</h5>
                <Grid container>    
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <Grid container>
                            <Grid item lg={!props.pratica.azienda ? 9 : 12} md={!props.pratica.azienda ? 9 : 12} sm={12} xs={12} style={{padding:"5px"}}>
                                <TextField style={{width:"100%"}} label={"Partita iva"} 
                                    required disabled={saved} onChange={(e)=>{setPartita_iva(e.target.value)}} value={partita_iva}/>
                            </Grid>
                            {!props.pratica.azienda ? 
                                <Grid item lg={3} md={3} sm={12} xs={12} style={{padding:"5px"}}>
                                    <Button size="small" type="submit" variant="contained" color="primary" disabled={saved} onClick={(e)=>{cercaAzienda(e)}}><i className="fas fa-search"></i> Cerca</Button>
                                </Grid>
                            :null}
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Codice fiscale"} 
                            required disabled={saved} onChange={(e)=>{setCodice_fiscale(e.target.value)}} value={codice_fiscale} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Ragione sociale"} 
                            required  disabled={saved} onChange={(e)=>{setRagione_sociale(e.target.value)}} value={ragione_sociale} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Indirizzo"} 
                            required  disabled={saved} onChange={(e)=>{setIndirizzo(e.target.value)}} value={indirizzo} />
                    </Grid>
                
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Civico"} 
                            required  disabled={saved} onChange={(e)=>{setCivico(e.target.value)}} value={civico} />
                    </Grid>
                </Grid>
                
                <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provincie ? 
                            <Autocomplete
                                id="combo-box-demo"
                                options={provincie}
                                getOptionLabel={(option) => option.name ? option.name : getProvinciaNomeByCode(option)}
                                style={{width:"100%"}}
                                name="provincia"
                                disabled={saved}
                                defaultValue={provincia}
                                onChange={(event, value) => {
                                    if(value){
                                        setProvincia(value.code);
                                    }else{
                                        setComune("");
                                        setProvincia("");
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Provincia"} required value={provincia}/>}
                            />
                            :null}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provincia === "EE" ? 
                            <TextField style={{width:"100%"}} label={"Stato"} 
                                required onChange={(e)=>setComune(e.target.value)} value={comune} disabled={saved} />
                        :
                        provincie ? 
                            <Autocomplete
                                id="combo-box-c"
                                options={getComuniByProvincia(provincia)}
                                getOptionLabel={(option) =>{return option.nome ? option.nome : option}}
                                disabled={!provincia || saved}
                                style={{width:"100%"}}
                                name="comunedinascita"
                                defaultValue={comune}
                                onChange={(event, value) => {
                                    if(value){
                                        setComune(value.nome);
                                        setCap(value.cap);
                                    }else{
                                        setComune("");
                                        setCap("")
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Comune"} required value={comune}/>}
                                />
                        :null}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Cap"} 
                            required  disabled={saved} onChange={(e)=>{setCap(e.target.value)}} value={cap} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Telefono"} 
                            required  disabled={saved} onChange={(e)=>{setTelefono(e.target.value)}} value={telefono} />
                    </Grid>
                
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Telefono 2"} 
                            disabled={saved} onChange={(e)=>{setTelefono2(e.target.value)}} value={telefono2} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Fax"} 
                            disabled={saved} onChange={(e)=>{setFax(e.target.value)}} value={fax} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Email"} 
                            required disabled={saved} onChange={(e)=>{setEmail(e.target.value)}} value={email} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Persona di riferimento"} 
                            required disabled={saved} onChange={(e)=>{setPersona_di_riferimento(e.target.value)}} value={persona_di_riferimento} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                            <TextField style={{width:"100%"}} label={"Pec"} 
                                disabled={saved} onChange={(e)=>{setPec(e.target.value)}} value={pec} />
                    </Grid>
                </Grid>
                <br/>
                {props.pratica.tipopratica !== "AZIENDA" ? 
                    <>
                        <h5>Dati Cedente</h5>
                        <Grid container>
                            <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                                <FormControl style={{width:"100%"}}>
                                    <InputLabel id="outline-sl">Stato Lavorativo</InputLabel>
                                    <Select
                                        disabled={saved}
                                        required
                                        style={{width:"100%"}}
                                        labelId="outline-sl"
                                        onChange={(e)=>setStato_lavorativo(e.target.value)} value={stato_lavorativo}
                                        >
                                        <MenuItem value="OCCUPATO">OCCUPATO</MenuItem>
                                        <MenuItem value="DISOCCUPATO">DISOCCUPATO</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                                <TextField label="Data di assunzione" required
                                    InputLabelProps={{
                                    shrink: true
                                    }}
                                    fullWidth={true}
                                    disabled={saved} onChange={(e)=>{setData_assunzione(e.target.value)}} value={data_assunzione} type="date">
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                                <TextField style={{width:"100%"}} label={"Stipendio lordo"}  type="number"
                                    disabled={saved} onChange={(e)=>{setStipendio_lordo(e.target.value)}} value={stipendio_lordo} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                                <TextField style={{width:"100%"}} label={"Stipendio netto"}  type="number"
                                    disabled={saved} onChange={(e)=>{setStipendio_netto(e.target.value)}} value={stipendio_netto} />
                            </Grid>
                        </Grid>
                    </>
                :null}
                <br/>
                <Button size="small" type="submit" variant="contained" color="primary" hidden={saved}><i className="far fa-save"></i> Salva</Button>
            </form>
        </div>
    );
}

export default Azienda;