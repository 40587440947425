import provincie_comuni from '../assets/italia_comuni.json'
import {sortByName} from './sortsfunct'

export function getRegioneLista(){
    let res = []
    provincie_comuni.regioni.forEach(regioni => {
        res.push({name:regioni.nome,code:regioni.nome})
    });
    return res.sort(sortByName);
}

export function getProvincieLista(){
    let res = []
    provincie_comuni.regioni.forEach(regioni => {
        regioni.province.forEach(provincie => {
            res.push({name:provincie.nome,code:provincie.code})
        });
    });
    res.push({name:"Estera",code:"EE"})
    return res.sort(sortByName);
}

export function getComuniByProvincia(provincia){
    let res = []
    provincie_comuni.regioni.forEach(regioni => {
        regioni.province.forEach(provincie => {
            if(provincie.code === provincia){
                res = provincie.comuni
            }  
        });
    });
    return res;
}
export function getProvinciaNomeByCode(code){
    let res = ""
    if(code === "EE"){
        res = "Estera"
    }else{
       provincie_comuni.regioni.forEach(regioni => {
            regioni.province.forEach(provincie => {
                if(provincie.code === code){
                    res = provincie.nome
                }
            });
        });
    }
    return res
}
export function getComuneNomeByCode(code){
    let res = ""
       provincie_comuni.regioni.forEach(regioni => {
            regioni.province.forEach(provincie => {
                if(provincie.code === code){
                    res = provincie.nome
                }
            });
        });
    return res
}
export function getProvincieByRegioneCode(code){
    let res = []
    let provincie = provincie_comuni.regioni.filter(x=>x.nome === code)[0].province
    provincie.forEach(provincia=>{
        res.push({name:provincia.nome,code:provincia.code})
    })
    return res
}
