import React,{useState,useEffect, useContext} from 'react';
import axios from 'axios'
import initpreventivatore from "./initpreventivatore.json" 
import RisultatoSimulatore from './RisultatoSimulatore';
import AuthService from '../utente/AuthService'
import DefaultSpinner from '../DefaultSpinner';
import { Button, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, Select, TextField } from '@material-ui/core';
import { sortByImportoNetto } from '../../commons/sortsfunct';
import { AccountContext } from '../../contexts/AccountContext';

let Auth=new AuthService();
var headers=Auth.getHeadersJWT();
let convenzioniadv_o = [{codice:"0",convenzione:"Privata"},{codice:"1",convenzione:"Privata NO TFR"},{codice:"2",convenzione:"Statale"},{codice:"3",convenzione:"Pubblica"},
            {codice:"4",convenzione:"Parapubblica"},{codice:"5",convenzione:"Pensionato INPS"},{codice:"6",convenzione:"Pensionato INPDAP"},
            {codice:"7",convenzione:"Pensionato Enasarco"},{codice:"8",convenzione:"Pensionato Altro"},{codice:"9",convenzione:"Piccola Impresa"},{codice:"10",convenzione:"Medici Convenzionati"}]

function Simulatore(props) {
    const accounts = useContext(AccountContext);
    const [pratica] = useState(props.pratica)
    const [saved, setSaved] = useState(false)
    const [sesso, setSesso] = useState("M")
    const [data_nascita, setData_nascita] = useState(null)
    const [data_assunzione, setData_assunzione] = useState(null)
    const [tipiImpiego] = useState(initpreventivatore.tipiImpiego)
    const [qualifiche] = useState(initpreventivatore.qualifiche)
    const [numeroRate] = useState(initpreventivatore.numeroRate)
    const [tipiContratto] = useState(initpreventivatore.tipiContratto)
    const [tipoContrattoSelected, setTipoContrattoSelected] = useState(null)
    const [rataSelected, setRataSelected] = useState(null)
    const [qualificaSelected, setQualificaSelected] = useState(null)
    const [impiegoSelected, setImpiegoSelected] = useState(null)
    const [stipendioNetto, setStipendioNetto] = useState(null)
    const [rata, setRata] = useState(null)
    const [isRinnovo, setIsRinnovo] = useState("N")
    const [montanteResiduo, setMontanteResiduo] = useState(null)
    const [convenzioniadvSelected, setConvenzioniadvSelected] = useState(null) 
    const [convenzioniBPSelected, setConvenzioniBPSelected] = useState(null)
    const [convenzionifinSelected, setConvenzionifinSelected] = useState(null)
    const [convenzioniIFI2Selected, setConvenzioniIFI2Selected] = useState(null)
    const [amministrazioniIFISelected, setAmministrazioniIFISelected] = useState(null)
    const [coefficenteBase, setCoefficenteBase] = useState(0) //////////NON MI RICORDO CHE CAZZO è
    const [speseIstruttorie, setSpeseIstruttorie] = useState(0) //////////NON MI RICORDO CHE CAZZO è
    const [contrattoSelected, setContrattoSelected] = useState(null)
    const [convenzioniifiverSelected, setConvenzioniifiverSelected] = useState(null)
    const [loadError, setLoadError] = useState(false)
    const [provvigioneBP, setProvvigioneBP] = useState(null)
    const [provvigioneFC, setProvvigioneFC] = useState(null)
    const [provvigioneIFI, setProvvigioneIFI] = useState(null)
    const [provvigioneADV, setProvvigioneADV] = useState(null)
    const [convenzioniBP, setConvenzioniBP] = useState([])
    const [convenzionifin, setConvenzionifin] = useState([])
    const [convenzioniifiver, setConvenzioniifiver] = useState([])
    const [preventiviResponse, setPreventiviResponse] = useState([])
    const [isRichiediPreventivo, setIsRichiediPreventivo] = useState(false)
    const [credenziali_ws,setCredenziali_ws] = useState(null)

    useEffect(() => {
        if(impiegoSelected && tipoContrattoSelected && impiegoSelected!=="" && tipoContrattoSelected!==""){
            getConvenzioni()
        }
    }, [impiegoSelected,tipoContrattoSelected])

    useEffect(() => {
        setCredenziali_ws(accounts.account.credenziali_ws)
    }, [accounts])


    function percentualiByName(nome,tipo,isRinnovo){
        if(tipo==="soc"){
            return JSON.parse(credenziali_ws).find(x=>x.code === nome).percentuali_default.perc_societa
        }
        if(tipo==="col"){
            return JSON.parse(credenziali_ws).find(x=>x.code === nome).percentuali_default.perc_collaboratore
        }
    }
    function richiediPreventivo(e){
        e.preventDefault()
        setIsRichiediPreventivo(true)
        let impiegoselezionato = tipiImpiego.find(x=>x.tipologiaTipoImpiego === impiegoSelected)
        let contrattoselezionato = tipiContratto.find(x=>x.gruppoTipoContratto === contrattoSelected)
        let convadv = convenzioniadv_o.find(x=>x.codice === convenzioniadvSelected) 
        let data={
            codiceQualifica: qualificaSelected !== undefined ? qualificaSelected : "",
            codiceTipoContratto: contrattoselezionato !== undefined ? contrattoselezionato.codiceTipoContratto : "",
            codiceTipoImpiego: impiegoselezionato !== undefined ? impiegoselezionato.codiceTipoImpiego : "",
            numeroRate: rataSelected !== undefined ? rataSelected : "",
            importoRata: rata !== undefined ? rata.toString() : "",
            provvigioneBP: provvigioneBP!==undefined  && provvigioneBP!=="" ? (Number(provvigioneBP)+Number(percentualiByName("banca_progetto","soc",isRinnovo))).toString():"",
            provvigioneSocBP:Number(percentualiByName("banca_progetto","soc",isRinnovo)),
            provvigioneFC: provvigioneFC!==undefined  && provvigioneFC!=="" ? (Number(provvigioneFC)+Number(percentualiByName("fincontinuo","soc",isRinnovo))).toString():"",
            provvigioneSocFC:Number(percentualiByName("fincontinuo","soc",isRinnovo)),
            provvigioneIFI: provvigioneIFI!==undefined && provvigioneIFI!=="" ? (Number(provvigioneIFI)+Number(percentualiByName("ifiver","soc",isRinnovo))).toString():"",
            provvigioneSocIFI:Number(percentualiByName("ifiver","soc",isRinnovo)),
            provvigioneADV: provvigioneADV!==undefined && provvigioneADV!=="" ? (Number(provvigioneADV)+Number(percentualiByName("adv","soc",isRinnovo))).toString():"",
            provvigioneSocADV:Number(percentualiByName("adv","soc",isRinnovo)),
            dataNascita: data_nascita ? data_nascita : "",
            dataAssunzione: data_assunzione? data_assunzione : "",
            sesso: sesso,
            isRinnovo:isRinnovo,
            stipendioNetto: stipendioNetto !== undefined ? stipendioNetto : "",
            dataDecorrenza: "",
            convenzione: (convenzioniBPSelected !== null && convenzioniBPSelected!==undefined) ? convenzioniBPSelected.codiceConvenzione : "" ,
            convenzionefin: (convenzionifinSelected !== null && convenzionifinSelected!==undefined) ? convenzionifinSelected : "" ,
            convenzioniadv: (convadv !== null && convadv!==undefined) ? convadv.convenzione : "" ,            
            montanteResiduo:montanteResiduo ? montanteResiduo : "",
            cod_prodotto_ifiver: (convenzioniifiverSelected !== null && convenzioniifiverSelected!==undefined) ? convenzioniifiverSelected.kktco_tipo_contratto : "" ,
            cod_ammin_ifiver: (amministrazioniIFISelected !== null && amministrazioniIFISelected!==undefined) ? amministrazioniIFISelected.cod_ammin : "" ,
            cod_conv_ifiver: (convenzioniIFI2Selected !== null && convenzioniIFI2Selected!==undefined) ? convenzioniIFI2Selected.cod_conv : "" ,
            codiceConvenzioneAmministrazione: (convenzionifinSelected !== null && 
                                                convenzionifinSelected!==undefined &&
                                                convenzionifinSelected.codice === "CPEN") ? "<codiceConvenzioneAmministrazione>131</codiceConvenzioneAmministrazione>"  : "",
            codiceSedeDatoreLavoro :(convenzionifinSelected !== null && 
                convenzionifinSelected!==undefined &&
                convenzionifinSelected.codice === "CPEN") ? "<codiceSedeDatoreLavoro>3058</codiceSedeDatoreLavoro>"  : "",
            "rinnovoPrestitoPrecedente": "N",
            "importoRataPrestitoPrecedente": "",
            "rateResiduePrestitoPrecedente": "",
            "flagIPA": "",
            "codiceFormaGiuridica": "",
            coefficenteBase:coefficenteBase,
            speseIstruttorie:speseIstruttorie,
            tipo:tipoContrattoSelected ? tipoContrattoSelected : ""
        }
        
        axios.post('/api/1.1/CalcolaPreventiviMult',{payload:data},{headers:headers}).then((res)=>{
            if(!res.data.error){
                setPreventiviResponse(res.data.data)
                setIsRichiediPreventivo(false)
            }else{
                setPreventiviResponse(null)
                setIsRichiediPreventivo(false)
            }
        })
    }

    function getConvenzioni(){
            axios.all([
                axios.post('/api/1.1/convenzioniprev/'+impiegoSelected+'/'+tipoContrattoSelected,null,{headers:headers}),
                axios.get('/api/1.1/initfincontinuo',{headers:headers}),
                axios.get('/api/1.1/convenzioniifiver',{headers:headers})
              ])
              .then(axios.spread((bpRes,finRes,ifiRes) => {
                let convenzioni = bpRes.data.data.convenzione
                if(!bpRes.data.error){                    
                    setConvenzioniBP(convenzioni)
                }
                let convenzioniifiver_o=[]
                if(!ifiRes.data.error){
                    convenzioniifiver_o=ifiRes.data.data
                }
                // console.log(convenzioniifiver_o)
                let cessionifincontinuo=[]
                cessionifincontinuo = finRes.data.data.filter(function(value, index, arr){
                    return value.codice!=="CPI" && value.codice!=="CQEXI" && value.codice!=="CQENP";    
                });

                var foundIndex = cessionifincontinuo.findIndex(x => x.codice==="CPEN");
                cessionifincontinuo[foundIndex] = {
                    categoria: "C",
                    codice: "CPEN",
                    descrizione: "CESSIONE PENSIONATI CON CONVENZIONE",
                };
                cessionifincontinuo.push({
                    categoria: "C",
                    codice: "CPENSC",
                    descrizione: "CESSIONE PENSIONATI SENZA CONVENZIONE",
                })
                setConvenzionifin(cessionifincontinuo)
                setConvenzioniifiver(convenzioniifiver_o)
            })
        );
    }

    return (
        <div>
            {(props.pratica && props.pratica.attiva)|| !props.pratica || props.tipo==="cliente" ?
            <>
            <form onSubmit={(e)=>{richiediPreventivo(e)}}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <span style={{marginRight:"5px"}}>Sesso: </span>
                        <FormControlLabel value="M" control={<Radio color="primary" />} checked={sesso==="M"} label="MASCHIO" onChange={()=>setSesso("M")} disabled={saved}/>
                        <FormControlLabel value="F" control={<Radio color="primary" />} checked={sesso==="F"} label="FEMMINA" onChange={()=>setSesso("F")} disabled={saved}/>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField required label="Data di nascita" type="date" disabled={saved} value={data_nascita}
                            InputLabelProps={{
                            shrink: true
                            }}
                            fullWidth={true}
                            onChange={(e)=>{setData_nascita(e.target.value)}}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField required label="Data di assunzione" type="date" disabled={saved}
                            InputLabelProps={{
                            shrink: true
                            }}
                            fullWidth={true}
                            onChange={(e)=>{setData_assunzione(e.target.value)}} value={data_assunzione}
                        />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outlined-tipiimpiego">Tipi impiego*</InputLabel>
                            <Select
                                required
                                style={{width:"100%"}}
                                labelId="outlined-tipiimpiego"
                                onChange={(e)=>{setImpiegoSelected(e.target.value)}}
                                label="Tipi impiego"
                                >
                                <MenuItem value=""><em>None</em></MenuItem>
                                    {tipiImpiego && tipiImpiego.map((element,i)=>{
                                        return(
                                            <MenuItem key={i} value={element.tipologiaTipoImpiego}>{element.descrizioneTipoImpiego}</MenuItem>
                                        )
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outline-qualifiche">Qualifiche*</InputLabel>
                            <Select
                                required
                                style={{width:"100%"}}
                                labelId="outline-qualifiche"
                                onChange={(e)=>{setQualificaSelected(e.target.value)}}
                                label="Qualifiche"
                                >
                                <MenuItem value=""><em>None</em></MenuItem>
                                    {qualifiche && qualifiche.map((element,i)=>{
                                        return(
                                            <MenuItem key={i} value={element.codiceQualifiche}>{element.descrizioneQualifiche}</MenuItem>
                                        )
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outline-contratto">Tipo contratto*</InputLabel>
                            <Select
                                required
                                style={{width:"100%"}}
                                labelId="outline-contratto"
                                onChange={(e)=>{setTipoContrattoSelected(e.target.value)}}
                                label="Tipo contratto"
                                >
                                <MenuItem value=""><em>None</em></MenuItem>
                                    {tipiContratto && tipiContratto.map((element,i)=>{
                                        return(
                                            <MenuItem key={i} value={element.gruppoTipoContratto}>{element.descrizioneTipoContratto}</MenuItem>
                                        )
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outline-numero-rate">Nro rate*</InputLabel>
                            <Select
                                required
                                style={{width:"100%"}}
                                labelId="outline-numero-rate"
                                onChange={(e)=>{setRataSelected(e.target.value)}}
                                label="Nro rate"
                                >
                                <MenuItem value=""><em>None</em></MenuItem>
                                    {numeroRate && numeroRate.map((element,i)=>{
                                        return(
                                            <MenuItem key={i} value={element}>{element}</MenuItem>
                                        )
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Stipendio Netto" type="number" min={0} required onChange={(e)=>{setStipendioNetto(e.target.value)}} value={stipendioNetto} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Rata" type="number" min={0} required onChange={(e)=>{setRata(e.target.value)}} value={rata} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <span style={{marginRight:"5px"}}>Rinnovo: </span>
                        <FormControlLabel value="S" control={<Radio color="primary" />} checked={isRinnovo==="S"} label="SI" onChange={()=>setIsRinnovo("S")} disabled={saved}/>
                        <FormControlLabel value="N" control={<Radio color="primary" />} checked={isRinnovo==="N"} label="NO" onChange={()=>setIsRinnovo("N")} disabled={saved}/>
                    </Grid>
                </Grid>
                {rataSelected && rata && Number(rataSelected)*Number(rata) ? 
                <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label="Montante" type="number" disabled={true} min={0} value={Number(rataSelected)*Number(rata)} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                        {isRinnovo === "S" ? 
                            <TextField style={{width:"100%"}} label="Montante residuo" type="number" min={0} required onChange={(e)=>{setMontanteResiduo(e.target.value)}} value={montanteResiduo} />
                        :null}
                    </Grid>
                </Grid>
                :null}
                <hr/>
                <div className="convenzioni">
                    {credenziali_ws && JSON.parse(credenziali_ws).find(x=>x.code === "banca_progetto").attivo ? 
                        <Grid container>
                            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                <TextField style={{width:"100%"}} label={isRinnovo==="N"? "Prov. Banca Progetto (ML)":"Prov. Banca Progetto"} type="number"  min={0} max={Number(JSON.parse(credenziali_ws).find(x=>x.code === "banca_progetto").percentuali_default.perc_collaboratore)} className="form-control input-sm" onChange={(e)=>{setProvvigioneBP(e.target.value)}} value={provvigioneBP} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                            <FormControl style={{width:"100%"}}>
                                <InputLabel id="outline-banca-progetto">Conv. Banca Progetto</InputLabel>
                                <Select
                                    style={{width:"100%"}}
                                    labelId="outline-banca-progetto"
                                    onChange={(e)=>{setConvenzioniBPSelected(e.target.value)}}
                                    label="Convenzione Banca Progetto"
                                    >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                        {convenzioniBP && convenzioniBP.map((element,i)=>{
                                            return(
                                                <MenuItem key={i} value={element.codiceConvenzione}>{element.descrizioneConvenzione}</MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                            </Grid>
                            <hr/>
                        </Grid>
                    :null }
                    {credenziali_ws && JSON.parse(credenziali_ws).find(x=>x.code === "fincontinuo").attivo ?
                        <Grid container>
                            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                <TextField style={{width:"100%"}} label={isRinnovo==="N"? "Prov. Fincontinuo (ML)":"Prov. Fincontinuo"} type="number" min={0} max={Number(JSON.parse(credenziali_ws).find(x=>x.code === "fincontinuo").percentuali_default.perc_collaboratore)}onChange={(e)=>{setProvvigioneFC(e.target.value)}} value={provvigioneFC} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                            <FormControl style={{width:"100%"}}>
                                <InputLabel id="outline-fincontinuo">Conv. Fincontinuo</InputLabel>
                                <Select
                                    style={{width:"100%"}}
                                    labelId="outline-fincontinuo"
                                    onChange={(e)=>{setConvenzionifinSelected(e.target.value)}}
                                    label="Conv. Fincontinuo"
                                    >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    {convenzionifin && convenzionifin.filter(x=>{
                                            if(tipoContrattoSelected===x.categoria){
                                                return x
                                            }
                                        }).map((element,i)=>{
                                            return(
                                                <MenuItem key={i} value={element.codice}>{element.descrizione}</MenuItem>
                                            )
                                        })}
                                </Select>
                            </FormControl>
                            </Grid>
                            <hr/>
                        </Grid>
                        :null}
                    {credenziali_ws && JSON.parse(credenziali_ws).find(x=>x.code === "adv").attivo ?
                        <Grid container>
                            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}><span>Provvigione Adv <small style={{color:"grey"}}>{isRinnovo==="N"?"(ML)":"(DM)"}</small></span><br/>
                                <TextField style={{width:"100%"}} label={isRinnovo==="N"? "Prov. Adv (ML)":"Prov. Adv (DM)"} type="number"  min={0} max={Number(JSON.parse(credenziali_ws).find(x=>x.code === "adv").percentuali_default.perc_collaboratore)} required onChange={(e)=>{setProvvigioneADV(e.target.value)}} value={provvigioneADV} />
                            </Grid>
                            <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}>
                                <FormControl style={{width:"100%"}}>
                                    <InputLabel id="outline-adv">Conv. Adv</InputLabel>
                                    <Select
                                        style={{width:"100%"}}
                                        labelId="outline-adv"
                                        onChange={(e)=>{setConvenzioniBPSelected(e.target.value)}}
                                        label="Convenzione Adv"
                                        >
                                        <MenuItem value=""><em>None</em></MenuItem>
                                            {convenzioniadv_o && convenzioniadv_o.map((element,i)=>{
                                                return(
                                                    <MenuItem key={i} value={element.codice}>{element.convenzione}</MenuItem>
                                                )
                                            })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <hr/>
                        </Grid>
                    :null}
                    {credenziali_ws && JSON.parse(credenziali_ws).find(x=>x.code === "ifiver").attivo ?
                    <Grid container>
                        <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}><span>Provvigione IFiver<small style={{color:"grey"}}>{isRinnovo==="N"?"(ML)":""}</small></span><br/>
                            <TextField style={{width:"100%"}} label={isRinnovo==="N"? "Prov. IFiver (ML)":"Prov. IFiver"} type="number" min={0} max={Number(JSON.parse(credenziali_ws).find(x=>x.code === "ifiver").percentuali_default.perc_collaboratore)} onChange={(e)=>{setProvvigioneIFI(e.target.value)}} value={provvigioneIFI}/>
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} style={{padding:"5px"}}><span>Convenzione IFiver</span><br/></Grid>
                    </Grid>
                    :null}
                </div>
                <hr/>
                <Button size="small" type="submit" variant="contained" color="primary" disabled={isRichiediPreventivo}><i className="far fa-paper-plane"></i> Richiedi preventivo</Button>
                </form>
                {isRichiediPreventivo===true ?
                    <DefaultSpinner></DefaultSpinner>
                    :
                    <div>
                        {preventiviResponse && preventiviResponse.length > 0 ?
                            <>
                                <div style={{margin:"15px 0"}}>
                                    <span>Totale pratiche {preventiviResponse.length}</span> 
                                    <span style={{marginLeft:"30px"}}>
                                        <Button size="small" variant="outlined" color="secondary" onClick={()=>setLoadError(!loadError)}> Mostra errori {preventiviResponse.filter(x=>x.PreventivoInErrore === true).length}</Button> 
                                    </span>
                                </div>
                                {preventiviResponse && preventiviResponse.sort(sortByImportoNetto).map((preventivo,i)=>{
                                    return(
                                        <RisultatoSimulatore key={i}
                                            saved={false}
                                            id_pratica={pratica ? pratica.id : null} 
                                            loadError={loadError} 
                                            preventivo={preventivo}
                                            idPredefinito = {null}
                                            reloadPreventivi={props.reloadPreventivi}
                                            setIdPreventivoPredefinito={props.setIdPreventivoPredefinito} 
                                            hasPermission={!Auth.hasPermission(["admin"])}/>
                                    )
                                })}
                            </>
                        : null}
                    </div>
                }
            </>
            :
            <div style={{textAlign:"center"}}>Pratica chiusa, impossibile eseguire nuove simulazioni.</div>}
        </div>
    );
}

export default Simulatore;