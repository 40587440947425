import React,{useState,useEffect,useContext} from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import AuthService from '../utente/AuthService';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SettingContext } from '../../contexts/SettingContext';
import ModalPrecompilatoDoc from '../modali/ModalPrecompilatoDoc';
import {downloadDoc} from '../../commons/documenti'
import Button from '@material-ui/core/Button';
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function Documenti(props) {
    const notifications = useContext(NotificationContext);
    const settings = useContext(SettingContext)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [documentoSelected, setDocumentoSelected] = useState(null)

    function closeModal(valore){
        setIsOpenModal(valore)
    }

    function cancellaDocumento(documento){
        if(window.confirm("Sei sicuro di voler eliminare il documento?")){   
            axios.delete("/api/1.1/precompilato/"+documento.id,{headers:header}).then((res)=>{
                let lista = settings.precompilati.list
                lista.forEach((element,i) => {
                    if(documento.id===element.id){
                        lista.splice(i,1)
                    }
                });
                settings.setPrecompilati({list:lista})
                notifications.NotifyAlarm(false,"Documento eliminato")
            })
        }
    }

    function modificaDocumento(documento){
        setDocumentoSelected(documento)
    }

    return (
        <Grid container>
            <h3><b style={{marginRight:"15px"}}>Documenti</b> 
            <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>setIsOpenModal(true)}><i className="fas fa-plus"></i> Aggiungi</Button> </h3>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableBody>
                            {settings.precompilati.list.map((doc,j)=>{
                                return(
                                    <TableRow>
                                        <TableCell component="th" scope="row"><button className="btn btn-link" onClick={(e)=>{downloadDoc(doc.proxy_url,doc.file.split("?")[0].split("/")[doc.file.split("?")[0].split("/").length - 1],header,'application/pdf')}}><i className="fas fa-file"></i> {doc.file.split('/')[doc.file.split('/').length-1].split("?")[0]}</button></TableCell>
                                        <TableCell><Button  size="small" type="submit" variant="contained" color="primary" style={{marginRight:"15px"}} onClick={(e)=>{modificaDocumento(doc)}}><i className="fas fa-file"></i> Modifica</Button></TableCell>
                                        <TableCell><Button  size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{cancellaDocumento(doc)}}><i className="fas fa-trash"></i> Elimina</Button></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            {documentoSelected ? 
                <Redirect to={{
                pathname: '/impostazioni/precompilati/'+documentoSelected.id,
                documento:documentoSelected
            }}></Redirect>:null}
            
            <ModalPrecompilatoDoc closeModal={closeModal} 
                isOpenModal={isOpenModal} chiudiModal={closeModal}></ModalPrecompilatoDoc>
        </Grid>
    );
}

export default Documenti;
