import React,{ useState ,useEffect} from 'react'
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import axios from 'axios'
import moment from 'moment'
import AuthService from '../utente/AuthService'
import Button from '@material-ui/core/Button';
import { Chip, FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { MenuItem } from 'react-bootstrap-typeahead'

let Auth = new AuthService();
let header=Auth.getHeadersJWT();

let listSchema=[
    {background:"#CED3DC",color:"#000000"},
    {background:"#275DAD",color:"#ffffff"},
    {background:"#914D76",color:"#ffffff"},
    {background:"#69353F",color:"#ffffff"},
    {background:"#CEF7A0",color:"#000000"}]

function ModalAgenda(props) {
    const [modificaEvento, setModificaEvento] = useState(false);
    const [titolo, setTitolo] = useState("")
    const [nota, setNota] = useState("")
    const [inizio, setInizio] = useState(new Date())
    const [fine, setFine] = useState(new Date())
    const [nominativo, setNominativo] = useState("")
    const [tipo, setTipo] = useState("PERSONALE")
    const [id, setId] = useState("")
    const [idPratica, setIdPratica] = useState(null)
    const [background, setBackground] = useState("#CED3DC")
    const [color, setColor] = useState("#000000")
    const [tipoPratica, setTipoPratica] = useState(null)

    useEffect(() => {
        if(props.event){
            setTitolo(props.event.title)
            setTipo(props.event.tipo)
            setNota(props.event.nota)
            setBackground(props.event.background)
            setColor(props.event.color)
            setInizio(moment(props.event.start).format("YYYY-MM-DDTHH:mm"))
            setFine(moment(props.event.end).format("YYYY-MM-DDTHH:mm"))
            setNominativo(props.event.nominativo)
            setId(props.event.id)
            setTipoPratica(props.event.tipo_pratica)
            setIdPratica(props.event.id_pratica)
        }
    }, [props.event])

    function setColorToTask(backgrd,clr){
        setBackground(backgrd)
        setColor(clr)
    }

    function addNewEvent(e){
        e.preventDefault()
        let start = moment(inizio).toDate();
        let end = start;
        if(fine){
            end =moment(fine).toDate();
        }

        let dat ={
            title:titolo,
            nota:nota,
            background: background,
            color: color,
            start:start,
            end:end,
            nominativo:nominativo,
            tipo:tipo,
            id:(id !== "" ? id : null)
        }
        axios.put('/api/1.1/agenda',dat,{headers:header}).then((res)=>{
            if(id){
                props.updateNewEvent(dat);
            }else{
                props.addNewEvent(dat);
            }
            resetItems();
            props.closeModalAgenda(false)
            
        }).catch(error=>{
            //TODO: gestire l'errore
            console.log(error)
        })    
    }
    function resetItems(){
        setTitolo("")
        setTipo("PERSONALE")
        setNota("")
        setInizio("")
        setBackground("#CED3DC")
        setColor("#000000")
        setFine("")
        setNominativo("")
        setId("")
        setTipoPratica("")
    }
    function chiudiModal(e){
        resetItems();
        setModificaEvento(true);
        props.closeModalAgenda(false);
    }

    function eliminaAgenda(e){
        e.preventDefault();
        removeEvent(e);
        resetItems();
    }

    function removeEvent(e){
        let dat ={
            title:titolo,
            nota:nota,
            start:inizio,
            end:fine,
            nominativo:nominativo,
            tipo:tipo,
            id:id
        }
        axios.delete('/api/1.1/agenda/'+id,{headers:header}).then((res)=>{
            props.removeEvent(dat)
            props.closeModalAgenda(false)
            setModificaEvento(false);
        }).catch(error=>{
            //TODO: gestire l'errore
            console.log(error)
        })
    }

    return (
        <Modal isOpen={props.openModalAgenda} className="modal-lg">
            <ModalBody>
                {props.event === null || modificaEvento===true ?
                    <form onSubmit={(e)=>addNewEvent(e)}>
                        <div>
                            <ModalHeader>
                                <Chip label="Nuovo evento" style={{fontWeight:"bold",fontSize:"19px",backgroundColor:background,color:color}} /> 
                            </ModalHeader>
                            <Grid container>
                                <Grid item lg={1} md={1} sm={1} xs={1} style={{textAlign:"center"}}>
                                    {listSchema.map((ls,i)=>{
                                        return(
                                            <div key={i} onClick={()=>setColorToTask(ls.background,ls.color)} className="div-schema-color" style={{background:"linear-gradient(-45deg, "+ls.color+", "+ls.color+" 49%, white 49%, white 51%, "+ls.background+" 51% )"}}></div>
                                        )
                                    })}    
                                </Grid>
                                <Grid item lg={11} md={11} sm={11} xs={11}>
                                    <div style={{marginTop:"15px"}}>
                                        <TextField type="text" label="Titolo" required name="titolo" onChange={(e)=>setTitolo(e.target.value)} value={titolo} style={{width:"100%"}}/>
                                    </div>
                                    <div style={{marginTop:"15px"}}>
                                        <TextField type="datetime-local" label="Inizio" required 
                                            InputLabelProps={{shrink: true}} name="inizio" onChange={(e)=>setInizio(e.target.value)} value={inizio} style={{width:"100%"}}/>
                                    </div>
                                    <div style={{marginTop:"15px"}}>
                                        <TextField type="datetime-local" label="Fine" 
                                            InputLabelProps={{shrink: true}} name="fine" onChange={(e)=>setFine(e.target.value)} value={fine} style={{width:"100%"}}/>
                                    </div>
                                    <div style={{marginTop:"15px"}}>
                                        <TextField type="text" label="Nominativo" name="nominativo" onChange={(e)=>setNominativo(e.target.value)} value={nominativo} style={{width:"100%"}}/>
                                    </div>
                                    <div style={{marginTop:"15px"}}>
                                        <FormControl style={{width:"100%"}}>
                                            <InputLabel id="outline-contratto">Tipo</InputLabel>
                                            <Select
                                                required
                                                style={{width:"100%"}}
                                                labelId="outline-contratto"
                                                onChange={(e)=>{setTipo(e.target.value)}}
                                                label="Tipo"
                                                value={tipo}
                                                >
                                                <MenuItem value="PERSONALE">PERSONALE</MenuItem>
                                                <MenuItem value="AGENZIA">AGENZIA</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div style={{marginTop:"15px"}}>
                                        <TextField id="outlined-nota" label="Nuova Nota" variant="standard"
                                            InputLabelProps={{shrink: true}} value={nota} name="nota" 
                                            type="textarea" multiline rows={4} onChange={(e)=>{setNota(e.target.value)}} style={{width:"100%"}} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <ModalFooter>
                            <Button size="small" type="submit" variant="contained" color="primary"> <i className="far fa-save"></i> Salva</Button>
                            {id !== null ? <Button size="small" variant="contained" color="secondary" onClick={(e)=>eliminaAgenda(e)}><i className="fas fa-trash"></i> Elimina</Button> : null}
                            <Button size="small" variant="contained" color="primary" onClick={(e)=>chiudiModal(e)}> <i className="fas fa-times"></i> Chiudi</Button>
                        </ModalFooter>  
                    </form> 
                :
                <div>
                    <ModalHeader>
                        <Chip label={titolo} style={{fontWeight:"bold",fontSize:"19px",backgroundColor:background,color:color}} />        
                    </ModalHeader>
                    <Grid container>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Data</b>
                                    </td>
                                    {!idPratica ?
                                        <td>
                                            {moment(inizio).format("DD/MM/YYYY HH:mm")} {inizio !== fine ? <span>- {moment(fine).format("DD/MM/YYYY HH:mm")}</span>:null}
                                        </td>
                                        :<td>
                                            {moment(inizio).format("DD/MM/YYYY")} {inizio !== fine ? <span>- {moment(fine).format("DD/MM/YYYY")}</span>:null}
                                        </td>}
                                </tr>
                                <tr>
                                    <td style={{width:"200px"}}>
                                        <b>Riferimento</b>
                                    </td>
                                    <td><i className={tipoPratica === "AZIENDA" ? "fas fa-industry":"fas fa-user-alt"}></i> {nominativo}</td>
                                </tr>
                                {!idPratica ?
                                    <tr>
                                        <td>
                                            <b>Tipo</b>
                                        </td>
                                        <td>{tipo}</td>
                                    </tr>
                                :null}
                            </tbody>
                        </table>
                        {!idPratica ?
                            <div className="">
                                <span>Nota</span> <br/>
                                {nota}
                            </div>
                        :null}
                    </Grid>
            
                    <ModalFooter>
                        {!idPratica ?
                            <>
                                <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>setModificaEvento(true)}><i className="fas fa-edit"></i> modifica</Button>
                                {id !== null ? 
                                    <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{eliminaAgenda(e)}}><i className="fas fa-trash"></i> Elimina</Button> 
                                : null}
                            </>
                        :
                        <Link to={'/pratica/'+idPratica}>
                            <Button size="small" variant="outlined" color="primary"><i className="fas fa-edit"></i> Vai alla pratica</Button>
                        </Link>
                        }
                        <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{props.closeModalAgenda(false);}}> <i className="fas fa-times"></i> Chiudi</Button>
                    </ModalFooter>
                </div>}
            </ModalBody>
        </Modal>
  );
}

export default ModalAgenda;