export function downloadDoc(urlr,filename,header,type){
  console.log(urlr)
    fetch(urlr, {
        method: 'GET',
        headers: header
    })
  .then((response) => response.blob())
  .then((blob) => {
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      filename,
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  });
}

export function responseToPdf(blob){
  const url = window.URL.createObjectURL(
    new Blob([blob]),
  );
  // window.open(url)
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    "allpdf.pdf",
  );
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}