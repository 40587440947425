import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import DefaultSpinner from '../DefaultSpinner';
import { Button, Grid, TextField } from '@material-ui/core';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();
function CensimentiAssicurativi(props) {
    const notifications = useContext(NotificationContext);
    const [saved] = useState(false) //props.pratica.chiusa)
    const [praticaListaAss] = useState({list:JSON.parse(props.pratica.assicurazioni)})
    const [assicurazioni, setAssicurazioni] = useState({list:[]})

    function salvaAssicurazioni(event){
        event.preventDefault();
        let data={valore:JSON.stringify(assicurazioni.list),id:props.pratica.id,type:"setassicurazionipratica"};
        axios.put('/api/1.1/cliente', data, {headers:header}).then((res)=>{
            if(!res.data.error){
                notifications.NotifyAlarm(false,"Assicurazioni aggionate")
            }
        })
    }
    useEffect(() => {
        axios.get("/api/1.1/assicurazione",{headers:header}).then((res)=>{
            let lass = []
            res.data.data.forEach(element => {
                let ttt = praticaListaAss.list.find(x=>x.nome === element.nome)
                lass.push({nome:element.nome,valore:ttt ? ttt.valore : ""})
            });
            setAssicurazioni({list:lass})
        }).catch(error=>{
            console.log(error)
        })
    }, [])

    function onChangeAssicurazione(e){
        let ass=assicurazioni.list;
        ass.forEach(element => {
          if(element.nome===e.target.name){
            element.valore=e.target.value;
          }
        });
        setAssicurazioni({list:ass})
      }

    return (
        <div>
            {assicurazioni ? 
                <Grid container>
                    {assicurazioni.list.map((ass,i)=>{
                        return(
                            <Grid key={i} item  lg={6} md={6} sm={12} xs={12} style={{paddingRight:"15px"}}>
                                <TextField type="text" label={ass.nome} disabled={saved} name={ass.nome} onChange={(e)=>{onChangeAssicurazione(e)}} value={ass.valore} style={{width:"100%"}}/>
                            </Grid>
                        )
                    })}
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop:"15px"}}>
                        <Button size="small" type="submit" variant="contained" color="primary" disabled={saved} onClick={(e)=>{salvaAssicurazioni(e)}}><i className="far fa-save"></i> Salva</Button>
                    </Grid>
                </Grid>
            :<DefaultSpinner></DefaultSpinner>}
        </div>
    );
}

export default CensimentiAssicurativi;