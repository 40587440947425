import React, { useState, useEffect } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import Button from '@material-ui/core/Button';
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';

function ModalSchemaFatturazione(props) {
    function chiudiModal(){
        props.closeOpenModal(false,null)
    }
    const [percentualeAgenzia, setPercentualeAgenzia] = useState(null)
    const [commissioneAgenzia, setCommissioneAgenzia] = useState(0)

    useEffect(() => {
        if(props.pratica.id_capo){
            setPercentualeAgenzia(Number(props.pratica.id_capo.percentuale_agenzia))
            setCommissioneAgenzia(Number(JSON.parse(props.pratica.preventivo_predefinito).provAgente)*(Number(props.pratica.id_capo.percentuale_agenzia)/100))
        }
    }, [props.pratica.id_capo])

    return (
        <Modal isOpen={props.isOpenModal} className="modal-lg">
        <ModalBody>
            <div>
            <ModalHeader>
                <div style={{fontWeight:"bold",fontSize:"20px"}}>Pratica {(props.pratica.codice_pratica+"").padStart(6, '0')}</div>
            </ModalHeader>
            </div>
            <div>
            <TableContainer>
            <Table aria-label="simple table" size="small">
                <TableBody>
                    {props.pratica.id_capo ? 
                        <TableRow>
                            <TableCell>AGENZIA</TableCell>
                            <TableCell>{props.pratica.id_capo.descrizione}</TableCell>
                            <TableCell>{Number(percentualeAgenzia).toFixed(2)}%</TableCell>
                            <TableCell style={{textAlign:'right'}}>{Number(commissioneAgenzia).toFixed(2)}€</TableCell>
                        </TableRow>
                    :null}
                    {props.pratica.titolare ? 
                        <TableRow>
                            <TableCell>TITOLARE</TableCell>
                            <TableCell>{props.pratica.titolare.nome +" "+props.pratica.titolare.cognome}</TableCell>
                            <TableCell>{Number(props.pratica.percentuale_titolare).toFixed(2)}%</TableCell>
                            <TableCell style={{textAlign:'right'}}>{((Number(JSON.parse(props.pratica.preventivo_predefinito).provAgente)-commissioneAgenzia)*(Number(props.pratica.percentuale_titolare)/100)).toFixed(2)}€</TableCell>
                        </TableRow>
                    :null}
                    {props.pratica.venditore ? 
                        <TableRow>
                            <TableCell>VENDITORE</TableCell>
                            <TableCell>{props.pratica.venditore.nome +" "+props.pratica.venditore.cognome}</TableCell>
                            <TableCell>{Number(props.pratica.percentuale_venditore).toFixed(2)}%</TableCell>
                            <TableCell style={{textAlign:'right'}}>{((Number(JSON.parse(props.pratica.preventivo_predefinito).provAgente)-commissioneAgenzia)*(Number(props.pratica.percentuale_venditore)/100)).toFixed(2)}€</TableCell>
                        </TableRow>
                    :null}
                    {props.pratica.collocatore ? 
                        <TableRow>
                            <TableCell>COLLOCATORE</TableCell>
                            <TableCell>{props.pratica.collocatore.nome +" "+props.pratica.collocatore.cognome}</TableCell>
                            <TableCell>{Number(props.pratica.percentuale_collocatore).toFixed(2)}%</TableCell>
                            <TableCell style={{textAlign:'right'}}>{((Number(JSON.parse(props.pratica.preventivo_predefinito).provAgente)-commissioneAgenzia)*(Number(props.pratica.percentuale_collocatore)/100)).toFixed(2)}€</TableCell>
                        </TableRow>
                    :null}
                    <TableRow>
                        <TableCell>Totale</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell style={{textAlign:'right',fontWeight:"bold"}}>{Number(JSON.parse(props.pratica.preventivo_predefinito).provAgente).toFixed(2)}€</TableCell>
                    </TableRow>
                </TableBody>
                </Table>
            </TableContainer>
            </div>
            <ModalFooter>
            <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{e.preventDefault();chiudiModal()}}><i className="fas fa-times"></i> Chiudi</Button>
            </ModalFooter>   
        </ModalBody>
    </Modal>
    );
}

export default ModalSchemaFatturazione;