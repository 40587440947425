import React,{useState,useContext} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import Button from '@material-ui/core/Button';
import {getProvincieLista,getComuniByProvincia,getProvinciaNomeByCode} from '../../commons/provincie_comuni'
import moment from 'moment'
import { FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Radio, Select, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

let Auth=new AuthService();
let header=Auth.getHeadersJWT(); 
function Anagrafica(props) {
    const notifications = useContext(NotificationContext);
    const [provincie] = useState(getProvincieLista())
    const [pratica_id] = useState(props.pratica.id)
    const [saved, setSaved] = useState(props.pratica.anagrafica!==null || !props.pratica.attiva)
    const [sesso, setSesso] = useState(!props.pratica.anagrafica && props.pratica.intervista ? props.pratica.intervista.sesso: props.pratica.anagrafica ? props.pratica.anagrafica.sesso : "M")
    const [nome, setNome] = useState(!props.pratica.anagrafica && props.pratica.intervista ? props.pratica.intervista.nome: props.pratica.anagrafica ? props.pratica.anagrafica.nome : "")
    const [cognome, setCognome] = useState(!props.pratica.anagrafica && props.pratica.intervista ? props.pratica.intervista.cognome:props.pratica.anagrafica ? props.pratica.anagrafica.cognome: "")
    const [comune_nascita, setComune_nascita] = useState(!props.pratica.anagrafica && props.pratica.intervista ? props.pratica.intervista.comunedinascita:props.pratica.anagrafica ? props.pratica.anagrafica.comune_nascita : "")
    const [provincia_nascita, setProvincia_nascita] = useState(!props.pratica.anagrafica && props.pratica.intervista ? props.pratica.intervista.provinciadinascita:props.pratica.anagrafica ? props.pratica.anagrafica.provincia_nascita:"")
    const [data_nascita, setData_nascita] = useState(!props.pratica.anagrafica && props.pratica.intervista ? 
        moment(new Date(props.pratica.intervista.datadinascita)).format("YYYY-MM-DD") : props.pratica.anagrafica ? moment(new Date(props.pratica.anagrafica.data_nascita)).format("YYYY-MM-DD") : "")
    const [codicefiscale, setCodicefiscale] = useState(!props.pratica.anagrafica && props.pratica.intervista ? props.pratica.intervista.codicefiscale:props.pratica.anagrafica? props.pratica.anagrafica.codicefiscale : "")
    const [telefono, setTelefono] = useState(!props.pratica.anagrafica && props.pratica.intervista ? props.pratica.intervista.telefono:props.pratica.anagrafica ? props.pratica.anagrafica.telefono : "")
    const [cellulare, setCellulare] = useState(!props.pratica.anagrafica && props.pratica.intervista ? props.pratica.intervista.cellulare:props.pratica.anagrafica ? props.pratica.anagrafica.cellulare : "")
    const [email, setEmail] = useState(!props.pratica.anagrafica && props.pratica.intervista ? props.pratica.intervista.email:props.pratica.anagrafica ? props.pratica.anagrafica.email : "")
    const [attivita_lavorativa, setAttivita_lavorativa] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.attivita_lavorativa:null)
    const [politicamente_esposto, setPoliticamente_esposto] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.politicamente_esposto:false)
    // parente_grado=models.CharField(max_length=100,blank=True, null=True,default="")
    // codice_fiscale_parente
    //documento
    const [tipo, setTipo] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.documento_identita.tipo : "")
    const [numero, setNumero] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.documento_identita.numero : "")
    const [rilasciato_da, setRilasciato_da] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.documento_identita.rilasciato_da :  "")
    const [luogo_rilascio, setLuogo_rilascio] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.documento_identita.luogo_rilascio :  "")
    const [data_rilascio, setData_rilascio] = useState(props.pratica.anagrafica ? moment(new Date(props.pratica.anagrafica.documento_identita.data_rilascio)).format("YYYY-MM-DD") :  null)
    const [scadenza, setscadenza] = useState(props.pratica.anagrafica ? moment(new Date(props.pratica.anagrafica.documento_identita.scadenza)).format("YYYY-MM-DD") :  null)
    //residenza
    const [viapiazzaR, setViapiazzaR] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.residenza.viapiazza : "")
    const [numeroR, setNumeroR] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.residenza.numero : "")
    const [comuneR, setComuneR] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.residenza.comune : "")
    const [capR, setCapR] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.residenza.cap : "")
    const [provinciaR, setProvinciaR] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.residenza.provincia : "")
    //domicilio
    const [viapiazzaD, setViapiazzaD] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.domicilio.viapiazza : "")
    const [numeroD, setNumeroD] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.domicilio.numero : "")
    const [comuneD, setComuneD] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.domicilio.comune : "")
    const [capD, setCapD] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.domicilio.cap : "")
    const [provinciaD, setProvinciaD] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.domicilio.provincia : "")
    //modalita pagamento
    const [assegnoBonifico, setAssegnoBonifico] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.modalita_pagamento.assegnoBonifico : "ASSEGNO_BANCARIO")
    const [nomeBanca, setNomeBanca] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.modalita_pagamento.nomeBanca : "")
    const [iban, setIban] = useState(props.pratica.anagrafica ? props.pratica.anagrafica.modalita_pagamento.iban : "")

    function copiaDaResidenza(e){
        e.preventDefault()
        setViapiazzaD(viapiazzaR)
        setNumeroD(numeroR)
        setComuneD(comuneR)
        setCapD(capR)
        setProvinciaD(provinciaR)
    }    

    function salva(e){
        e.preventDefault()
        let data={
            id:pratica_id,
            type:"addanagrafica",
            residenza:{
                viapiazza:viapiazzaR,
                numero:numeroR,
                comune:comuneR,
                cap:capR,
                provincia:provinciaR
            },
            modalitaPagamento:{
                assegnoBonifico:assegnoBonifico,
                nomeBanca:nomeBanca,
                iban:iban
            },
            domicilio:{
                viapiazza:viapiazzaR,
                numero:numeroR,
                comune:comuneR,
                cap:capR,
                provincia:provinciaR
            },
            dati_anagrafici:{
                sesso:sesso,
                nome:nome,
                cognome:cognome,
                comune_nascita:comune_nascita,
                provincia_nascita:provincia_nascita,
                data_nascita:data_nascita,
                codicefiscale:codicefiscale,
                telefono:telefono,
                cellulare:cellulare,
                email:email,
                attivita_lavorativa:attivita_lavorativa,
                politicamente_esposto:politicamente_esposto
            },
            documento:{
                tipo:tipo,
                numero:numero,
                rilasciato_da:rilasciato_da,
                luogo_rilascio:luogo_rilascio,
                data_rilascio:data_rilascio,
                scadenza:scadenza
            }
        }
        axios.put('/api/1.1/pratica',data,{headers:header}).then((res)=>{
            notifications.NotifyAlarm(false,"operazione completata")
            setSaved(true)
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Operazione non completata")
        })
    }

    return (
        
        <form onSubmit={(e)=>salva(e)}>
            <h5>Dati utente</h5>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <span style={{marginRight:"5px"}}>Sesso: </span>
                    <FormControlLabel value="M" control={<Radio color="primary" />} checked={sesso==="M"} label="MASCHIO" onChange={()=>setSesso("M")} disabled={saved}/>
                    <FormControlLabel value="F" control={<Radio color="primary" />} checked={sesso==="F"} label="FEMMINA" onChange={()=>setSesso("F")} disabled={saved}/>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Nome"} required disabled={saved} onChange={(e)=>{setNome(e.target.value)}} value={nome} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Cognome"} required  disabled={saved} onChange={(e)=>{setCognome(e.target.value)}} value={cognome} />
                </Grid>
            </Grid>

            <Grid container>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provincie ? 
                            <Autocomplete
                                id="combo-box-demo"
                                options={provincie}
                                getOptionLabel={(option) => option.name ? option.name : getProvinciaNomeByCode(option)}
                                style={{width:"100%"}}
                                name="provincia"
                                disabled={saved}
                                defaultValue={provincia_nascita}
                                onChange={(event, value) => {
                                    if(value){
                                        setProvincia_nascita(value.code);
                                    }else{
                                        setComune_nascita("");
                                        setProvincia_nascita("");
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Provincia"} required value={provincia_nascita}/>}
                            />
                            :null}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provincia_nascita === "EE" ? 
                            <TextField style={{width:"100%"}} label={"Stato"} 
                                required onChange={(e)=>setComune_nascita(e.target.value)} value={comune_nascita} disabled={saved} />
                        :
                        provincie ? 
                            <Autocomplete
                                id="combo-box-c"
                                options={getComuniByProvincia(provincia_nascita)}
                                getOptionLabel={(option) =>{return option.nome ? option.nome : option}}
                                disabled={!provincia_nascita || saved}
                                style={{width:"100%"}}
                                name="comunedinascita"
                                defaultValue={comune_nascita}
                                onChange={(event, value) => {
                                    if(value){
                                        setComune_nascita(value.nome);
                                    }else{setComune_nascita("")}
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Comune"} required value={comune_nascita}/>}
                                />
                        :null}
                    </Grid>
                
                </Grid> 
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField label="Data di nascita" required
                        InputLabelProps={{
                        shrink: true
                        }}
                        fullWidth={true}
                        disabled={saved} onChange={(e)=>{setData_nascita(e.target.value)}} value={data_nascita} type="date">
                    </TextField>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Codice fiscale"} required disabled={saved} onChange={(e)=>{setCodicefiscale(e.target.value)}} value={codicefiscale} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Telefono"} required disabled={saved} onChange={(e)=>{setTelefono(e.target.value)}} value={telefono} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Cellulare"}  disabled={saved} onChange={(e)=>{setCellulare(e.target.value)}} value={cellulare} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <TextField style={{width:"100%"}} label={"Email"}  disabled={saved} onChange={(e)=>{setEmail(e.target.value)}} value={email} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <FormControl style={{width:"100%"}}>
                        <InputLabel id="outline-sl">Attività lavorativa</InputLabel>
                        <Select
                            required
                            style={{width:"100%"}}
                            labelId="outline-sl"
                            disabled={saved}
                            onChange={(e)=>setAttivita_lavorativa(e.target.value)} value={attivita_lavorativa}
                            >
                            <MenuItem value="DIPENDENTE">DIPENDENTE</MenuItem>
                            <MenuItem value="PENSIONATO">PENSIONATO</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
            </Grid>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                    <span style={{marginRight:"5px"}}>Politicamente esposto: </span>
                    <FormControlLabel control={<Radio color="primary" />} label="SI" checked={politicamente_esposto} onClick={()=>setPoliticamente_esposto(true)} disabled={saved}/>
                    <FormControlLabel control={<Radio color="primary" />} label="NO" checked={!politicamente_esposto} onClick={()=>setPoliticamente_esposto(false)} disabled={saved}/>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
                </Grid>
                Inserire la questione parentela
                <br/>
                <h5>Documento - 
                    <a style={{color:"lightgrey"}} href="https://www.crimnet.dcpc.interno.gov.it/crimnet/ricerca-documenti-rubati-smarriti" target="_blank" rel="noopener noreferrer"><i className="fas fa-shield-alt" aria-hidden="true"></i>&nbsp</a>
                </h5> 
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outline-t">Tipo</InputLabel>
                            <Select
                                required
                                style={{width:"100%"}}
                                labelId="outline-t"
                                disabled={saved}
                                onChange={(e)=>setTipo(e.target.value)} value={tipo}
                                >
                                <MenuItem value="PATENTE">PATENTE</MenuItem>
                                <MenuItem value="CARTA_DI_IDENTITA">CARTA DI IDENTITA'</MenuItem>
                                <MenuItem value="PASSAPORTO">PASSAPORTO'</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Numero"} required  disabled={saved} onChange={(e)=>{setNumero(e.target.value)}} value={numero} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Rilasciato da"} required  disabled={saved} onChange={(e)=>{setRilasciato_da(e.target.value)}} value={rilasciato_da} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Luogo rilascio"} required  disabled={saved} onChange={(e)=>{setLuogo_rilascio(e.target.value)}} value={luogo_rilascio} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField label="Data rilascio" required
                            InputLabelProps={{
                            shrink: true
                            }}
                            fullWidth={true}
                            disabled={saved} onChange={(e)=>{setData_rilascio(e.target.value)}} value={data_rilascio} type="date">
                        </TextField>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField label="Scadenza" required
                            InputLabelProps={{
                            shrink: true
                            }}
                            fullWidth={true}
                            disabled={saved} onChange={(e)=>{setscadenza(e.target.value)}} value={scadenza} type="date">
                        </TextField>
                    </Grid>
                </Grid>
                <br/>
                <h5>Residenza</h5>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Indirizzo"} required  disabled={saved} onChange={(e)=>{setViapiazzaR(e.target.value)}} value={viapiazzaR} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Civico"} required  disabled={saved} onChange={(e)=>{setNumeroR(e.target.value)}} value={numeroR} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provincie ? 
                            <Autocomplete
                                id="combo-box-demo"
                                options={provincie}
                                disabled={saved}
                                getOptionLabel={(option) => option.name ? option.name : getProvinciaNomeByCode(option)}
                                style={{width:"100%"}}
                                name="provinciaR"
                                defaultValue={provinciaR}
                                onChange={(event, value) => {
                                    if(value){
                                        setProvinciaR(value.code);
                                    }else{
                                        setComuneR("");
                                        setProvinciaR("");
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Provincia"} required value={provinciaR}/>}
                            />
                            :null}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provinciaR === "EE" ? 
                            <TextField style={{width:"100%"}} label={"Stato"} 
                                required onChange={(e)=>setComuneR(e.target.value)} value={comuneR} />
                        :
                        provincie ? 
                            <Autocomplete
                                id="combo-box-c"
                                options={getComuniByProvincia(provinciaR)}
                                getOptionLabel={(option) =>{return option.nome ? option.nome : option}}
                                disabled={!provinciaR || saved}
                                style={{width:"100%"}}
                                name="comuneR"
                                defaultValue={comuneR}
                                onChange={(event, value) => {
                                    if(value){
                                        setComuneR(value.nome);
                                        setCapR(value.cap)
                                    }else{setComuneR("");setCapR("")}
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Comune"} required value={comuneR}/>}
                                />
                        :null}
                    </Grid>
                </Grid>
                <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Cap"} required  disabled={saved} onChange={(e)=>{setCapR(e.target.value)}} value={capR} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
                </Grid>
                <br/>
                <h5>Domicilio <Button size="small" type="submit" variant="contained" color="primary" hidden={saved} onClick={(e)=>{copiaDaResidenza(e)}}><i className="fas fa-copy"></i> Copia da Residenza</Button></h5>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Indirizzo"} required  disabled={saved} onChange={(e)=>{setViapiazzaD(e.target.value)}} value={viapiazzaD} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Civico"} required  disabled={saved} onChange={(e)=>{setNumeroD(e.target.value)}} value={numeroD} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provincie ? 
                            <Autocomplete
                                id="combo-box-demo"
                                options={provincie}
                                getOptionLabel={(option) => option.name ? option.name : getProvinciaNomeByCode(option)}
                                style={{width:"100%"}}
                                name="provinciaD"
                                disabled={saved}
                                defaultValue={provinciaD}
                                onChange={(event, value) => {
                                    if(value){
                                        setProvinciaD(value.code);
                                    }else{
                                        setComuneD("");
                                        setProvinciaD("");
                                        setCapD("")
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Provincia"} required value={provinciaD}/>}
                            />
                            :null}
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        {provinciaR === "EE" ? 
                            <TextField style={{width:"100%"}} label={"Stato"} 
                                required onChange={(e)=>setComuneR(e.target.value)} value={comuneD} />
                        :
                        provincie ? 
                            <Autocomplete
                                id="combo-box-c"
                                options={getComuniByProvincia(provinciaD)}
                                getOptionLabel={(option) =>{return option.nome ? option.nome : option}}
                                disabled={!provinciaD || saved}
                                style={{width:"100%"}}
                                name="comuneR"
                                defaultValue={comuneD}
                                onChange={(event, value) => {
                                    if(value){
                                        setComuneD(value.nome);
                                        setCapD(value.cap)
                                    }else{setComuneD("");setCapD("")}
                                }}
                                renderInput={(params) => <TextField {...params} style={{width:"100%"}} label={"Comune"} required value={comuneD}/>}
                                />
                        :null}
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <TextField style={{width:"100%"}} label={"Cap"} required disabled={saved} onChange={(e)=>{setCapD(e.target.value)}} value={capD} />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
                </Grid>
                <br/>
                <h5>Modalità pagamento</h5>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                        <FormControlLabel disabled={saved} control={<Radio color="primary" />} label="ASSEGNO BANCARIO" checked={assegnoBonifico==="ASSEGNO_BANCARIO"} onClick={()=>setAssegnoBonifico("ASSEGNO_BANCARIO")}/>
                        <FormControlLabel disabled={saved} control={<Radio color="primary" />} label="BONIFICO" checked={assegnoBonifico==="BONIFICO"} onClick={()=>setAssegnoBonifico("BONIFICO")}/>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}></Grid>
                </Grid>
                { assegnoBonifico === "BONIFICO" ? 
                    <Grid container>
                        <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                            <TextField style={{width:"100%"}} label={"Nome Banca"} disabled={!props.pratica.attiva} required  name="nomeBanca" onChange={(e)=>{setNomeBanca(e.target.value)}} value={nomeBanca} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                            <TextField style={{width:"100%"}} label={"IBAN"} disabled={!props.pratica.attiva} required  name="iban" onChange={(e)=>{setIban(e.target.value)}} value={iban} />
                        </Grid>
                    </Grid>
                :null}
                {!saved ?
                    <Button size="small" type="submit" variant="contained" color="primary" disabled={!props.pratica.attiva}><i className="far fa-save"></i>Salva</Button>
                :null}
        </form>
    );
}

export default Anagrafica;