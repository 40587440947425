import axios from 'axios';

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        this.domain = domain || '/' // API server domain
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.roles=this.setRole();
    }
    setRole(){
        let roles={}
        roles["admin"]="1aa11a37-2a58-4d8f-809c-b97d95f90d2c"
        roles["agenzia"]="1b6ca452-cfa4-438f-a903-0720e392fa21"
        roles["collaboratore"]="c7bc45b5-0e4f-4c6c-82a1-33a0e3e40fd1"
        roles["operatore"]="c7bc45b5-0e4f-4c6c-82a1-33a0e3e40fd1"
        roles["telemarketing"]="c7bc45b5-0e4f-4c6c-82a1-33a0e3e40fd1"
        return roles;
    }

    getHeadersJWT(){
        if(this.getToken()){
            return {'Authorization': 'Bearer '+this.getToken(),'Content-Type': 'application/json',};
        }else{
            return null;
        }
        
    }

    login(username, password) {
        return axios.post('/api/1.1/login',{
            username:username,
            password:password
        }).then(res => {
            if(!res.data.error){
                localStorage.setItem('tk',res.data.tk)
            }
            return Promise.resolve(res);
        })
    }
    setLocalStorageVariable(nome,valore){
        localStorage.setItem(nome,valore);
    }

    getRole(){
        return localStorage.getItem("rl");
    }
    goToHomeIfNotPermission(listPermission){
        if(!this.hasPermission(listPermission)){
            window.location.href="/404";
        }   
    }

    hasPermission(listaStatiPermessi){
        try {   
            if(listaStatiPermessi[0] === "all"){
                return true;
            }
        } catch (error) {
            console.log("errore permessi")
        }
        let role = this.getRole();
        let conversionePermessi=[];
        try{
            listaStatiPermessi.forEach(element => {
                conversionePermessi.push(this.roles[element])
            });
        }catch(err){
            console.log(err)
        }
        if(conversionePermessi.includes(role)){
            return true;
        }else{
            return false;
        }
    }
    // fakeLogin(username, password) {
    //     console.log("FAKE LOGIN", username, password)
    //     if (username === "emilio" && password === "123456") {
    //         this.setToken("ilmiotoken");
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
    
    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token // handwaiving here
    }

    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('tk', idToken)
    }

    getToken() {
        //questo non mi serve, il controllo lo faccio lato server
        return localStorage.getItem('tk')
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('tk');
        localStorage.removeItem('nome');
        localStorage.removeItem('rl');
        localStorage.removeItem('role');
    }

    // getProfile() {
    //     // Using jwt-decode npm package to decode the token
    //     return decode(this.getToken());
    // }


    fetch(url, options) {
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken();
            headers['Autorizzazione'] = 'Bearer ' + this.getToken();
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }
}