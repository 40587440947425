import React, { useState, useEffect,useContext } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import Button from '@material-ui/core/Button';
import { Checkbox, FormControl,  Grid,  MenuItem, Select, TextField } from '@material-ui/core';
import {getProvincieLista,getRegioneLista,getProvincieByRegioneCode} from '../../commons/provincie_comuni'
import { SettingContext } from '../../contexts/SettingContext';

var list_categoria = ["CORPORATE","PMI","PUBBLICO", "PARAPUBBLICO", "STATALE" , "PENSIONATO", "PRIVATO", "PRIVATO SMALLBUSINESS"]
let durata = [24,36,48,60,72,84,96,108,120];
let tipologie=["AZIENDA","PRIVATO"]

function ModalFiltriArchivio(props) {
  const settings = useContext(SettingContext)
  const [statiPratica,setStatiPratica] = useState({list:[]})
  const [prodottoSelected, setProdottoSelected] = useState("")
  const [statiSelezionati, setStatiSelezionati] = useState({list:[]})
  const [listaFonti, setListaFonti] = useState({list:[]})
  const [durataSelezionata, setDurataSelezionata] = useState("")
  const [dataNascita, setDataNascita] = useState("")
  const [decOperazioneInizio, setDecOperazioneInizio] = useState("")
  const [decOperazioneFine, setDecOperazioneFine] = useState("")
  const [alarmInizio, setAlarmInizio] = useState("")
  const [alarmFine, setAlarmFine] = useState("")
  const [email, setEmail] = useState("")
  const [provinciaResidenza, setProvinciaResidenza] = useState({list:[]})
  const [provinciaAtc, setProvinciaAtc] = useState({list:[]})
  const [regioneResidenza, setRegioneResidenza] = useState({list:[]})
  const [categorie, setCategorie] = useState({list:[]})
  const [allRegioni] = useState(getRegioneLista())
  const [allProvincie] = useState(getProvincieLista())
  const [ritardoPagamento, setRitardoPagamento] = useState(null)
  const [assicurazioniInCorso, setAssicurazioniInCorso] = useState(null)
  const [finanziamentiInCorso, setFinanziamentiInCorso] = useState(null)
  const [coobligato, setCoobligato] = useState(null)
  const [atc, setAtc] = useState("")
  const [maggioreMinore, setMaggioreMinore] = useState(false)
  const [tipo, setTipo] = useState(null)
  
  function filterPrivatoAzienda(element){
    if(!tipo){
      return element
    }else if(tipo==="AZIENDA" && element.isAzienda){
      return element
    }else if(tipo==="PRIVATO" && element.isPrivato){
      return element
    }
  }
  function changeRitardiPagamento(valore){
    if(ritardoPagamento!==valore){
      setRitardoPagamento(valore)
    }else{
      setRitardoPagamento(null)  
    }
  }
  function changeFinanziamentiInCorso(valore){
    if(finanziamentiInCorso!==valore){
      setFinanziamentiInCorso(valore)
    }else{
      setFinanziamentiInCorso(null)  
    }
  }
  function changeAssicurazioniInCorso(valore){
    if(assicurazioniInCorso!==valore){
      setAssicurazioniInCorso(valore)
    }else{
      setAssicurazioniInCorso(null)  
    }
  }
  function changeCoobligato(valore){
    if(coobligato!==valore){
      setCoobligato(valore)
    }else{
      setCoobligato(null)  
    }
  }

  function addRemove(item,tipo){
    let index = -1;
    if(tipo === "categoria"){
      let lista=categorie.list
      index = lista.indexOf(item)
      if(index>=0){
          lista.splice(index, 1);
      }else{
          lista.push(item)
      }
      setCategorie({list:lista})
    }
    if(tipo === "regione"){
      let lista=regioneResidenza.list
      index = lista.indexOf(item.code)
      if(index >= 0){
          lista.splice(index, 1);
      }else{
          lista.push(item.code)
      }
      let lista_p = getProvincieByRegioneCode(item.code)
      lista_p.forEach(element => {
        addRemove(element,"provincia")
      });
      setRegioneResidenza({list:lista})
    }
    if(tipo === "provincia"){
      let lista=provinciaResidenza.list
      index = lista.indexOf(item.code)
      if(index >= 0){
          lista.splice(index, 1);
      }else{
          lista.push(item.code)
      }
      setProvinciaResidenza({list:lista})
    }
    if(tipo === "provinciaatc"){
      let lista=provinciaAtc.list
      index = lista.indexOf(item.code)
      if(index >= 0){
          lista.splice(index, 1);
      }else{
          lista.push(item.code)
      }
      setProvinciaAtc({list:lista})
    }
    if(tipo === "stato"){
      let lista=statiSelezionati.list
      index = lista.indexOf(item.id)
      if(index>=0){
          lista.splice(index, 1);
      }else{
          lista.push(item.id)
      }
      setStatiSelezionati({list:lista})
    }
    if(tipo === "pubblicita"){
      let lista=listaFonti.list
      index = lista.indexOf(item.nome)
      if(index>=0){
          lista.splice(index, 1);
      }else{
          lista.push(item.nome)
      }
      setListaFonti({list:lista})
    }
  }
  
  function checkifSet(item,tipo){
    let lista = []
    var index = -1
    if(tipo === "categoria"){
      let lista=categorie.list
      index = lista.indexOf(item)
    }
    if(tipo === "regione"){
      lista=regioneResidenza.list
      index = lista.indexOf(item.code)
    }
    if(tipo === "provincia"){
      lista=provinciaResidenza.list
      index = lista.indexOf(item.code)
    }
    if(tipo === "provinciaatc"){
      lista=provinciaAtc.list
      index = lista.indexOf(item.code)
    }
    if(tipo === "stato"){
      lista=statiSelezionati.list
      index = lista.indexOf(item.id)
    }
    if(tipo === "pubblicita"){
      lista=listaFonti.list
      index = lista.indexOf(item.nome)
    }
    if(index>=0){
        return true;
    }else{
        return false;
    }
  }

  useEffect(() => {
    let lista = []
    if(prodottoSelected){
      lista = settings.prodotti.list.find(x=>x.id === prodottoSelected).stati_cliente
      setStatiPratica({list:lista})
    }else{
      setStatiPratica({list:[]})
    }
    
  }, [prodottoSelected])

  function applica(cerca){
    let data={
      statiSelezionati:statiSelezionati.list,
      prodotti:prodottoSelected,
      listaFonti:listaFonti.list,
      durataSelezionata:durataSelezionata,
      dataNascita:dataNascita,
      email:email,
      provinciaResidenza:provinciaResidenza.list,
      regioneResidenza:regioneResidenza.list,
      categorie:categorie.list,
      assicurazioniInCorso:assicurazioniInCorso,
      finanziamentiInCorso:finanziamentiInCorso,
      coobligato:coobligato,
      ritardoPagamento:ritardoPagamento,
      provinciaAtc:provinciaAtc.list,
      atc:atc,
      decOperazioneInizio:decOperazioneInizio,
      decOperazioneFine:decOperazioneFine,
      maggioreMinore:maggioreMinore,
      alarmInizio:alarmInizio,
      alarmFine:alarmFine,
    }
    if(cerca){
      props.setFiltriECerca(data)
    }else{
      props.setFiltri(data)
    }
    props.closeModal(true)
  }
  function chiudiModal(){
    props.closeModal(true)
  }
  function removeFilter(){
    setProdottoSelected("");
    setStatiSelezionati({list:[]})
    setListaFonti({list:[]})
    setDurataSelezionata("")
    setDataNascita("")
    setEmail("")
    setProvinciaResidenza({list:[]})
    setProvinciaAtc({list:[]})
    setRegioneResidenza({list:[]})
    setFinanziamentiInCorso(null)
    setCategorie({list:[]})
    setRitardoPagamento(null)
    setAssicurazioniInCorso(null)
    setCoobligato(null)
    setDecOperazioneInizio("")
    setDecOperazioneFine("")
    setAlarmInizio("")
    setAlarmFine("")
    setMaggioreMinore(false)
    setTipo(null)
  }
  return (
    <div>
        <Modal isOpen={props.isOpenModal} className="modal-lg">
            <ModalBody>
              <div>
                <ModalHeader>
                  <div style={{fontWeight:"bold",fontSize:"20px"}}>Filtri archivio</div>
                </ModalHeader>
              </div>
              <div>
                <div>
                  <FormControl style={{width:"100%"}}>
                    <b>TIPOLOGIA</b>
                    <Select
                        value={tipo}
                        labelId="outlined-prodotto"
                        onChange={(e)=>{setTipo(e.target.value)}}
                        label="Tipologia"
                        >
                        <MenuItem value=""><em>TUTTE</em></MenuItem>
                            {tipologie.map((element,i)=>{
                                return(
                                <MenuItem key={i} value={element}>{element}</MenuItem>
                                )
                            })}
                    </Select>
                  </FormControl>
                </div> 
                <div>
                  <FormControl style={{width:"100%"}}>
                    <b>PRODOTTO</b>
                    <Select
                        value={prodottoSelected}
                        labelId="outlined-prodotto"
                        onChange={(e)=>{setProdottoSelected(e.target.value)}}
                        label="Prodotto" placeholder="Seleziona prodotto"
                        >
                        <MenuItem value=""><em>None</em></MenuItem>
                            {settings.prodotti.list.filter(filterPrivatoAzienda).map((element,i)=>{
                                return(
                                <MenuItem key={i} value={element.id}>{element.nome}</MenuItem>
                                )
                            })}
                    </Select>
                  </FormControl>
                </div> 
                <div>
                  {statiPratica.list && statiPratica.list.length > 0 ?
                  <>
                    <b>STATI</b>
                      <div className="panel-body" style={{marginTop:"15px"}}>
                        <div style={{maxHeight:"120px",overflowY:"scroll",border:"1px solid lightgrey", padding:"5px"}}>
                          {statiPratica.list.map((item,i) => {
                              return(
                                  <div key={i}>
                                      <Checkbox size="small" color="primary" onClick={(e)=>{addRemove(item,"stato")}} checked={checkifSet(item,"stato")}/>
                                      <label className="form-check-label" htmlFor="defaultCheck1">
                                      {item.name}
                                      </label>
                                  </div>
                              )
                          })}
                        </div>
                      </div>
                      </>
                    :null}
                </div>
                <div style={{marginTop:"15px"}}>
                  <b>FONTE</b>
                  {settings.fonti.list.length > 0 ?
                      <div className="panel-body">
                        <div style={{maxHeight:"120px",overflowY:"scroll",border:"1px solid lightgrey", padding:"5px"}}>
                          {settings.fonti.list.filter(filterPrivatoAzienda).map((item,i) => {
                              return(
                                  <div key={i}>
                                      <Checkbox size="small" color="primary" onClick={(e)=>{addRemove(item,"pubblicita")}} checked={checkifSet(item,"pubblicita")}/>
                                      <label className="form-check-label" htmlFor="defaultCheck1">
                                        {item.nome}
                                      </label>
                                  </div>
                              )
                          })}
                        </div>
                      </div>
                    :null}
                </div>
                {/* dec operazione deve essere anche sugli impegni */}
                <Grid container style={{marginTop:"15px"}}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <b>DEC OPERAZIONE (Da)</b>
                      <TextField label="" type="date" value={decOperazioneInizio}
                        InputLabelProps={{
                        shrink: true
                        }}
                        fullWidth={true}
                        onChange={(e)=>{setDecOperazioneInizio(e.target.value)}}
                      />
                    </Grid> 
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                    <b>DEC OPERAZIONE (A)</b>
                      <TextField label="" type="date" value={decOperazioneFine}
                        InputLabelProps={{
                        shrink: true
                        }}
                        fullWidth={true}
                        onChange={(e)=>{setDecOperazioneFine(e.target.value)}}
                      />
                    </Grid> 
                </Grid>
                <Grid container style={{marginTop:"15px"}}>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <b>Alarm (Da)</b>
                      <TextField label="" type="date" value={alarmInizio}
                        InputLabelProps={{
                        shrink: true
                        }}
                        fullWidth={true}
                        onChange={(e)=>{setAlarmInizio(e.target.value)}}
                      />
                    </Grid> 
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                    <b>Alarm (A)</b>
                      <TextField label="" type="date" value={alarmFine}
                        InputLabelProps={{
                        shrink: true
                        }}
                        fullWidth={true}
                        onChange={(e)=>{setAlarmFine(e.target.value)}}
                      />
                    </Grid> 
                </Grid>

                <div style={{marginTop:"15px"}}>
                  <FormControl style={{width:"100%"}}>
                    <b>DURATA</b>
                    <Select
                        value={durataSelezionata}
                        labelId="outlined-durata"
                        onChange={(e)=>{setDurataSelezionata(e.target.value)}}
                        label="Durata"
                        >
                        <MenuItem value=""><em>None</em></MenuItem>
                            {durata.map((element,i)=>{
                                return(
                                <MenuItem key={i} value={element}>{element}</MenuItem>
                                )
                            })}
                    </Select>
                  </FormControl>
                </div> 
                <div style={{marginTop:"15px"}}>
                  <b>DATA DI NASCITA</b> <b></b>
                  <TextField label="" type="date" value={dataNascita}
                      InputLabelProps={{
                      shrink: true
                      }}
                      fullWidth={true}
                      onChange={(e)=>{setDataNascita(e.target.value)}}
                    />
                </div>
                <div style={{marginTop:"15px"}}>
                  <b>REGIONE DI RESIDENZA</b><br/>
                  <div className="panel-body" style={{marginTop:"15px"}}>
                    <div style={{maxHeight:"120px",overflowY:"scroll",border:"1px solid lightgrey", padding:"5px"}}>
                      {allRegioni.map((item,i) => {
                          return(
                            <div key={i}>
                                <Checkbox size="small" color="primary" onClick={(e)=>{addRemove(item,"regione")}} checked={checkifSet(item,"regione")}/>
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                {item.name}
                                </label>
                            </div>
                          )
                      })}
                    </div>
                  </div>
                </div>
                <div style={{marginTop:"15px"}}>
                  <b>PROVINCIA DI RESIDENZA</b><br/>
                  <div className="panel-body" style={{marginTop:"15px"}}>
                    <div style={{maxHeight:"120px",overflowY:"scroll",border:"1px solid lightgrey", padding:"5px"}}>
                      {allProvincie.map((item,i) => {
                          return(
                            <div key={i}>
                                <Checkbox size="small" color="primary" onClick={(e)=>{addRemove(item,"provincia")}} checked={checkifSet(item,"provincia")}/>
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                {item.name}
                                </label>
                            </div>
                          )
                      })}
                    </div>
                  </div>
                </div>
                <div style={{marginTop:"15px"}}>
                  <b>EMAIL</b><br/>
                  <TextField value={email}
                      fullWidth={true}
                      onChange={(e)=>{setEmail(e.target.value)}}
                    />
                </div>
                <div style={{marginTop:"15px"}}>
                  <b>CATEGORIA</b><br/>
                  <div className="panel-body" style={{marginTop:"15px"}}>
                    <div style={{maxHeight:"120px",overflowY:"scroll",border:"1px solid lightgrey", padding:"5px"}}>
                      {list_categoria.map((item,i) => {
                          return(
                            <div key={i}>
                                <Checkbox size="small" color="primary" onClick={(e)=>{addRemove(item,"categoria")}} checked={checkifSet(item,"categoria")}/>
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                {item}
                                </label>
                            </div>
                          )
                      })}
                    </div>
                  </div>
                </div>
                <div style={{marginTop:"15px"}}>
                  <b>ATC</b> <b></b>
                  <TextField label="" value={atc}
                      
                      fullWidth={true}
                      onChange={(e)=>{setAtc(e.target.value)}}
                    />
                </div>
                <div style={{marginTop:"15px"}}>
                  <b>PROVINCIA ATC</b><br/>
                  <div className="panel-body" style={{marginTop:"15px"}}>
                    <div style={{maxHeight:"120px",overflowY:"scroll",border:"1px solid lightgrey", padding:"5px"}}>
                      {allProvincie.map((item,i) => {
                          return(
                            <div key={i}>
                                <Checkbox size="small" color="primary" onClick={(e)=>{addRemove(item,"provinciaatc")}} checked={checkifSet(item,"provinciaatc")}/>
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                {item.name}
                                </label>
                            </div>
                          )
                      })}
                    </div>
                  </div>
                </div>
              <div style={{marginTop:"15px"}}>
                <b>RITARDO PAGAMENTO</b><br/>
                <Checkbox
                  checked={ritardoPagamento === true}
                  onChange={()=>changeRitardiPagamento(true)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {"SI"}
                <Checkbox
                  checked={ritardoPagamento === false}
                  onChange={()=>changeRitardiPagamento(false)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {"NO"}
              </div>
              <div style={{marginTop:"15px"}}>
                <b>ASSICURAZIONI IN CORSO</b><br/>
                <Checkbox
                  checked={assicurazioniInCorso === true}
                  onChange={()=>changeAssicurazioniInCorso(true)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {"SI"}
                <Checkbox
                  checked={assicurazioniInCorso === false}
                  onChange={()=>changeAssicurazioniInCorso(false)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {"NO"}
              </div>
              <div style={{marginTop:"15px"}}>
                <b>FINANZIAMENTI IN CORSO</b><br/>
                <Checkbox
                  checked={finanziamentiInCorso === true}
                  onChange={()=>changeFinanziamentiInCorso(true)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {"SI"}
                <Checkbox
                  checked={finanziamentiInCorso === false}
                  onChange={()=>changeFinanziamentiInCorso(false)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {"NO"}
              </div>
              <div style={{marginTop:"15px"}}>
                <b>COOBLIGATO</b><br/>
                <Checkbox
                  checked={coobligato === true}
                  onChange={()=>changeCoobligato(true)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {"SI"}
                <Checkbox
                  checked={coobligato === false}
                  onChange={()=>changeCoobligato(false)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> {"NO"}
              </div>
              <div hidden={true}>
              SEGNALATORE: ____( menù a tendina)________<br/>
              UNIONI : MARITO E MOGLIE   (FLAG)           FIGLIO/FIGLIA  (FLAG)            NUORA E COGNATO  (FLAG)         <br/>
              SUOCERO E SUOCERA    (FLAG)          <br/>
              COMPAGNO E COMPAGNA  (FLAG)             CUGINO (FLAG)         AMICO/A (FLAG)           COLLEGA (FLAG) <br/>
              </div>

              </div>
              <ModalFooter>
                <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>applica(false)}> <i className="far fa-save"></i> Applica</Button>
                <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>applica(true)}> <i className="far fa-save"></i> Applica e Cerca</Button>
                <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{chiudiModal()}}><i className="fas fa-times"></i> Chiudi</Button>
                <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{removeFilter()}}><i className="fas fa-times"></i> Rimuovi Filtri</Button>
              </ModalFooter>   
            </ModalBody>

    </Modal>
    </div>
  );
}

export default ModalFiltriArchivio;