import React,{useState,useEffect} from 'react';
import ClienteItem from './cliente/ClienteItem';
import axios from 'axios'
import AuthService from './utente/AuthService'
import DefaultSpinner from './DefaultSpinner';
import { AppBar, Grid, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@material-ui/core';
import PraticaItem from './pratica/PraticaItem';
import TabPanel from '../commons/TabPanel';

const Auth=new AuthService();
let header=Auth.getHeadersJWT();

function Homepage(props) {
  const [pratiche, setPratiche] = useState([])
  const [clienti, setClienti] = useState([])
  const [pratichewl, setPratichewl] = useState([]) //mettere il conteggio?
  const [clientiwl, setClientiwl] = useState([]) //mettere il conteggio?
  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState(0);

  function getPratiche(){
    setLoading(true)
    header=Auth.getHeadersJWT();
    let data={}
    //se admin o agenzia deve prendere tutti e 4 i valori, 
    //altrimenti solo da lavorare, per pratiche e per clienti
    data["periodo"]=new Date();
    data["tiporicerca"]="homepage"
    axios.all([
      axios.post('/api/1.1/cliente/0',data,{headers:header}),
      axios.post('/api/1.1/pratica/0',data,{headers:header}),
      axios.get('/api/1.1/cliente',{headers:header}),
      axios.get('/api/1.1/pratica',{headers:header})
  ]).then(axios.spread((resclienti,respratiche,resclientiwl,respraticawl) => {
      setPratiche(respratiche.data.data)
      setPratichewl(respraticawl.data.data)
      setClienti(resclienti.data.data)
      setClientiwl(resclientiwl.data.data)
      setLoading(false) 
    }))
  }
  
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };
  
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  useEffect(() => {
      getPratiche();
  }, [])

  return (
    !loading ?
    <Grid container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        {/* <div>
        <h2> {clientiwl > 0 ? 
          <>Clienti da lavorare: <Link style={{color:"lightgrey"}} to={"/work-list-cliente"}>{clientiwl} <small><i className="fas fa-external-link-alt"></i></small></Link></>
          :
          <>Nessun cliente da lavorare</>
          }
        </h2>
        <h2> {pratichewl>0 ? 
          <>Pratiche da lavorare: <Link style={{color:"lightgrey"}} to={"/work-list-pratica"}>{pratichewl} <small><i className="fas fa-external-link-alt"></i></small></Link></>
          :
          <>Nessuna pratica da lavorare</>
          }
        </h2>
        </div> */}
        <AppBar position="relative" color="default" style={{zIndex:"0"}}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
                <Tab label={"Clienti da assegnare ("+clienti.length+")"} {...a11yProps(0)} />
                <Tab label={"Pratiche da assegnare ("+pratiche.length+")"} {...a11yProps(1)} />
                <Tab label={"Clienti da lavorare ("+clientiwl.length+")"} {...a11yProps(2)} />
                <Tab label={"Pratiche da lavorare ("+pratichewl.length+")"} {...a11yProps(3)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              {clienti.length>0 ?
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                      <TableHead>
                          <TableRow>
                            <TableCell style={{fontWeight:"bold"}}></TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Id</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Operazione</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Riferimento</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Stato pratica</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Alarm</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Categoria</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Decorrenza</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                        {clienti && clienti.filter((element) => {
                            return element.titolare === null
                        }).map((cliente,i)=>{
                          return(
                              <ClienteItem key={i} pratica={cliente} isPratica={true} />
                          )
                        })}
                      </TableBody>
                  </Table>
                </TableContainer>
              :"Nessun cliente da assegnare"}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {pratiche.length>0 ?
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                      <TableHead>
                          <TableRow>
                            {/* <TableCell style={{fontWeight:"bold"}}></TableCell> */}
                            <TableCell style={{fontWeight:"bold"}}>Id</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Operazione</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Riferimento</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Stato pratica</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Data caricata</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Data approvata</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Data liquidata</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                        {pratiche && pratiche.filter((element) => {
                            return element.titolare === null
                        }).map((pratica,i)=>{
                          return(
                            <PraticaItem key={i} pratica={pratica} isPratica={true} showCheck={false} shownotebutton={false}/>
                          )
                        })}
                      </TableBody>
                  </Table>
                </TableContainer>
                :"Nessuna pratica da assegnare"}
              </TabPanel>
              <TabPanel value={value} index={2}>
                {clientiwl.length>0 ?
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                      <TableHead>
                          <TableRow>
                            <TableCell style={{fontWeight:"bold"}}></TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Id</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Operazione</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Riferimento</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Stato pratica</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Alarm</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Categoria</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Decorrenza</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                        {clientiwl && clientiwl.map((pratica,i)=>{
                          return(
                            <ClienteItem key={i} pratica={pratica} isPratica={true} showCheck={false} />
                          )
                        })}
                      </TableBody>
                  </Table>
                </TableContainer>
                :"Nessuna cliente da assegnare"}
              </TabPanel>
              <TabPanel value={value} index={3}>
                {pratichewl.length>0 ?
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" size="small">
                      <TableHead>
                          <TableRow>
                            {/* TODO: eliminare le liquidate nella sezione da lavorare*/}
                            <TableCell style={{fontWeight:"bold"}}>Id</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Operazione</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Riferimento</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Stato pratica</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Data caricata</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Data approvata</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Data liquidata</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                        {pratichewl && pratichewl.map((pratica,i)=>{
                          return(
                            <PraticaItem key={i} pratica={pratica} isPratica={true} showCheck={false} shownotebutton={false}/>
                          )
                        })}
                      </TableBody>
                  </Table>
                </TableContainer>
                :"Nessuna pratica da lavorare"}
              </TabPanel>
      </Grid>
      <div hidden={true}>
      <div>
        per ADMIN: caricare le pratiche da smistare, quindi quelle che non hanno il titolare pratica [OK]<br/>
        per l'agenzia stessa cosa? ma ovviamente con filtro agenzia [VERIFICARE]<br/>
        per l'operatore inserire il numero delle pratiche da lavorare oggi (quelle della worklist) per intenderci<br/>
        pratiche lavorate oggi? come faccio saperlo? su pratica dovrei mettere ultima lavorazione<br/>
      </div>
      </div>
    </Grid>
    :
    <div style={{textAlign:"center"}}>
      <DefaultSpinner></DefaultSpinner>
    </div>
  );
}

export default Homepage;