import React,{useContext} from 'react';
import moment from 'moment'
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { SettingContext } from '../../contexts/SettingContext';

function Intervista(props) {
    const settings = useContext(SettingContext)
    return (
        <div>
            {props.intervista !== null ?
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row"  style={{fontWeight:"bold", width:"30%"}}>TIPO PUBBLICITA</TableCell>
                                <TableCell>
                                    {props.intervista.pubblicita}
                                </TableCell>
                            </TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>NOME</TableCell><TableCell>{props.intervista.nome}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>COGNOME</TableCell><TableCell>{props.intervista.cognome}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>LUOGO DI NASCITA</TableCell><TableCell>{props.intervista.comunedinascita} {props.intervista.provinciadinascita ? "("+props.intervista.provinciadinascita+")" : null}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>DATA DI NASCITA</TableCell><TableCell>{props.intervista.datadinascita}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>SESSO</TableCell><TableCell>{props.intervista.sesso}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>CODICE FISCALE</TableCell><TableCell>{props.intervista.codicefiscale}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>EMAIL</TableCell><TableCell>{props.intervista.email}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>TELEFONO</TableCell><TableCell>{props.intervista.telefono}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>CELLULARE</TableCell><TableCell>{props.intervista.cellulare}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>QUALIFICA</TableCell><TableCell>{props.intervista.lavoro || props.intervista.qualifica ? props.intervista.lavoro  +" - "+ props.intervista.qualifica : null}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>STIPENDIO</TableCell><TableCell>{props.intervista.stipendio}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>RICHIESTE</TableCell><TableCell>
                                <div>
                                    {JSON.parse(props.intervista.richiestacliente).map((id_prodotto,i)=>{
                                        return(
                                            <li key={i}>
                                                {settings.prodotti.list.find(x=>x.id === id_prodotto).nome}
                                            </li>
                                        )
                                    })}
                                </div>
                            </TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>RITARDO PAGAMENTI</TableCell><TableCell>{!props.intervista.ritardipagamento ? null : props.intervista.ritardipagamento===false ?"NO":"SI"}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>FIN. IN CORSO</TableCell><TableCell>{!props.intervista.finansiamentiincorso ? null :props.intervista.finansiamentiincorso===false ?"NO":"SI"}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold"}}>INVALIDITA'</TableCell><TableCell>{!props.intervista.invalidita ? null :props.intervista.invalidita===false ?"NO":"SI"}</TableCell></TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            :null}
            
            {props.intervista_azienda !== null ?
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row"  style={{fontWeight:"bold", width:"30%"}}>TIPO PUBBLICITA</TableCell>
                                <TableCell>
                                    {props.intervista_azienda.pubblicita}
                                </TableCell>
                            </TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>RAGIONE SOCIALE</TableCell><TableCell>{props.intervista_azienda.ragioneSociale}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>FORMA GIURIDICA</TableCell><TableCell>{props.intervista_azienda.formaGiuridica}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>CATEGORIA</TableCell><TableCell>{props.intervista_azienda.categoria}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>PARTITA IVA</TableCell><TableCell>{props.intervista_azienda.partitaIva}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>CODICE FISCALE</TableCell><TableCell>{props.intervista_azienda.codicefiscale}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>CAPITALE SOCIALE</TableCell><TableCell>{props.intervista_azienda.capitaleSociale}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>INDIRIZZO SEDE LEGALE</TableCell><TableCell>{props.intervista_azienda.indirizzoSedeLegale}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>CIVICO</TableCell><TableCell>{props.intervista_azienda.civico}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>COMUNE</TableCell><TableCell>{props.intervista_azienda.comune}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>PROVINCIA</TableCell><TableCell>{props.intervista_azienda.provincia}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>CAP</TableCell><TableCell>{props.intervista_azienda.cap}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>INIZIO ATTIVITà</TableCell><TableCell>{moment(new Date(props.intervista_azienda.inizioAttivita)).format("DD/MM/YYYY")}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>N° DIPENDENTI</TableCell><TableCell>{props.intervista_azienda.numeroDipendenti}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>INDIRIZZO PEC</TableCell><TableCell>{props.intervista_azienda.indirizzoPec}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>INDIRIZZO EMAIL</TableCell><TableCell>{props.intervista_azienda.indirizzoEmail}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>TELEFONO</TableCell><TableCell>{props.intervista_azienda.telefono1} - {props.intervista_azienda.telefono1}</TableCell></TableRow>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold", width:"30%"}}>RICHIESTE</TableCell>
                                <TableCell>
                                    {JSON.parse(props.intervista_azienda.richiestacliente).map((rc,i)=>{
                                        return(
                                            <li key={i}>{rc}</li>
                                        )
                                    })}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold", width:"30%"}}>CUSTOMERS</TableCell>
                                <TableCell>
                                    {JSON.parse(props.intervista_azienda.customers).map((cus,i)=>{
                                        return(
                                            <li key={i}>{cus}</li>
                                        )
                                    })}
                                </TableCell>
                            </TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>CARTA DI CREDITO</TableCell><TableCell>{props.intervista_azienda.cartaDiCredito}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>ASSICURAZIONI IN CORSO</TableCell><TableCell>{!props.intervista_azienda.assicurazioniInCorso ? null : props.intervista_azienda.assicurazioniInCorso ? "Si" : "No"}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>FIN. IN CORSO</TableCell><TableCell>{!props.intervista_azienda.finanziamentiInCorso ? null : props.intervista_azienda.finanziamentiInCorso? "Si" : "No"}</TableCell></TableRow>
                            <TableRow><TableCell style={{fontWeight:"bold", width:"30%"}}>RITARDI PAGAMENTO</TableCell><TableCell>{!props.intervista_azienda.ritardiPagamento ? null : props.intervista_azienda.ritardiPagamento? "Si" : "No"}</TableCell></TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            :null}

        </div>
    );
}

export default Intervista;