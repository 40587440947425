export const ADD_CLIENTE="ADD_CLIENTE";
export const SET_CLIENTI="SET_CLIENTI";
export const SET_RAPPRESENTANTE="SET_RAPPRESENTANTE";
export const SET_ASSICURAZIONI="SET_ASSICURAZIONI";
export const SET_DECORRENZA_PRATICA = 'SET_DECORRENZA_PRATICA'
export const SET_ALARM_PRATICA = 'SET_ALARM_PRATICA'

export const setAlarmPratica = (payload) => ({
  type: SET_ALARM_PRATICA,
  payload
})

export const setDecorrenzaPratica = (payload) => ({
  type: SET_DECORRENZA_PRATICA,
  payload
})

export const setAssicurazioni = (payload) => ({
  type: SET_ASSICURAZIONI,
  payload
})

export const setRappresentante = (payload) => ({
  type: SET_RAPPRESENTANTE,
  payload
})

export const addCliente = (payload) => ({
  type: ADD_CLIENTE,
  payload
})
export const setClienti = (payload) => ({
  type: SET_CLIENTI,
  payload
})


