import React,{ useState,useContext } from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import { AccountContext } from '../../contexts/AccountContext';
import { SettingContext } from '../../contexts/SettingContext';
import StatoDetail from './StatoDetail';
import DefaultSpinner from '../DefaultSpinner';
import { Button, FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import moment from 'moment'
import { MenuItem } from 'react-bootstrap-typeahead';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();
//TODO: non aggiorna tutti i dati della pratica, verificare, non aggiorna i dati provincia e comune
function Stati(props) {
    const notifications = useContext(NotificationContext);
    const accounts = useContext(AccountContext);
    const settings = useContext(SettingContext)
    const [nuovostatoSelected, setNuovostatoSelected] = useState(null)
    const [nuovadecorrenza, setNuovadecorrenza] = useState("")
    const [lista_stati_cliente, setLista_stati_cliente] = useState({lista:props.pratica.prodotti_richiesti})
    const [statoSelezionato, setStatoSelezionato] = useState(null)
    const [nuovanota, setNuovanota] = useState("")
    const [showStatiChiusi, setShowStatiChiusi] = useState(false)
    const [nuovoProdotto, setNuovoProdotto] = useState(null)
    
    function setNuovostato(id_stato,id_prodotto,tipo_id){
        setNuovostatoSelected({...nuovostatoSelected, id_stato:id_stato,id_prodotto:id_prodotto,tipo_id:tipo_id})
    }
    function filterPrivatoAzienda(element){
        if(props.tipopratica==="AZIENDA" && element.isAzienda){
            return element
        }if(props.tipopratica==="PRIVATO" && element.isPrivato){
            return element
        }
    }

    function selezionaStatoDaModificare(st){
        setStatoSelezionato(st)
    }
    function aggiornaData(e,type,id_prodotto,tipo_id){
        e.preventDefault()
        let data={
            id:props.pratica.id,
            type:type,
            id_prodotto:id_prodotto,
            tipo_id:tipo_id,
            valore:null
        }
        if(type==="setdecorrenza"){
            data["valore"]=nuovadecorrenza
        }
        axios.put('/api/1.1/cliente',data,{headers:header}).then((res)=>{
            setLista_stati_cliente({lista:res.data.data.prodotti_richiesti})
        })
    }
    function rimuoviData(e,type,id_prodotto,tipo_id){
        e.preventDefault()
        let data={
            id:props.pratica.id,
            type:type,
            id_prodotto:id_prodotto,
            tipo_id:tipo_id,
            valore:null
        }
        axios.put('/api/1.1/cliente',data,{headers:header}).then((res)=>{
            setLista_stati_cliente({lista:res.data.data.prodotti_richiesti})
        })
    }
    function setAlarmStato(e,id_stato,id_prodotto,valore){
        e.preventDefault()
        let data = {
            type:"setalarm",
            id:props.pratica.id,
            id_stato:id_stato,
            valore:valore
        }
        var lstati = lista_stati_cliente.lista
        
        axios.put('/api/1.1/cliente/stato',data,{headers:header}).then((res)=>{
            lstati.forEach(element => {
                if(element.id === id_prodotto){
                    element.lista_stati.forEach((lsta,j)=>{    
                        if(lsta.id===id_stato){
                            element.lista_stati[j].alarm = valore;
                        }
                    })
                }
            });
            setLista_stati_cliente({lista:lstati})
            notifications.NotifyAlarm(false,"Alarm aggiornato")
        })
    }

    function chiudiStato(e,id_stato,id_prodotto){
        e.preventDefault()
        let data = {
            type:"closestato",
            id:props.pratica.id,
            id_stato:id_stato
        }
        var lstati = lista_stati_cliente.lista
        axios.put('/api/1.1/cliente/stato',data,{headers:header}).then((res)=>{
            lstati.forEach(element => {
                if(element.id === id_prodotto){
                    element.lista_stati.forEach((lsta,j)=>{    
                        if(lsta.id===id_stato){
                            element.lista_stati[j].attivo = false;
                        }  
                    })
                }
            });
            setLista_stati_cliente({lista:lstati})
            notifications.NotifyAlarm(false,"Stato chiuso")
        })
    }
    
    function addProdotto(e){
        e.preventDefault()
        let data={
            type:"addprodotto",
            id:props.pratica.id,
            id_prodotto:nuovoProdotto,
            tipopratica:props.tipopratica
        }
        axios.put('/api/1.1/cliente',data,{headers:header}).then(res=>{
            setNuovoProdotto(null)
            props.setNuovoProdotto(res.data.data)
            setLista_stati_cliente({lista:res.data.data.prodotti_richiesti})
        })
    }

    function aggiungiNota(e,id_stato,id_prodotto){
        e.preventDefault()
        if(nuovanota){
            let data = {
                type:"addnotastato",
                id:props.pratica.id,
                id_stato:id_stato,
                nuovanota:nuovanota
            }
            var lstati = lista_stati_cliente.lista
            axios.put('/api/1.1/cliente/stato',data,{headers:header}).then((res)=>{
                lstati.forEach(element => {
                    if(element.id === id_prodotto){
                        element.lista_stati.forEach((lsta,j)=>{    
                            if(lsta.id===id_stato){
                                element.lista_stati[j].listanote = res.data.data.listanote;
                            }  
                        })
                    }
                }); 
                setLista_stati_cliente({lista:lstati})
                notifications.NotifyAlarm(false,"Nota aggiunta")
            }).catch(error=>{
                notifications.NotifyAlarm(true,"Errore")
            })
        }
    }
    function chiudiProdotto(tipo_id){
        if(window.confirm("Sei sicuro di voler procedere?")){
            let data = {
                type:"closeprodotto",
                id:props.pratica.id,
                prodotto_id:tipo_id
            }
            var lstati = lista_stati_cliente.lista
            axios.put('/api/1.1/cliente/stato',data,{headers:header}).then((res)=>{
                console.log(lstati)
                lstati.forEach(element => {
                    if(element.id === tipo_id){
                        element.active=false;
                        element.lista_stati.forEach((lsta,j)=>{    
                            element.lista_stati[j].attivo = false;
                            element.lista_stati[j].alarm = null;
                        })
                    }
                });
                setLista_stati_cliente({lista:lstati})
                notifications.NotifyAlarm(false,"Prodotto chiuso")
            })
        }
    }

    function checkIfDisabled(tipo,stato){
        let prodotto_selezionato = lista_stati_cliente.lista.filter(x=>x.id === Number(tipo.id))[0]
        if(prodotto_selezionato.lista_stati){
            let element = prodotto_selezionato.lista_stati.filter(x=>x.id_stato === Number(stato.id))
            if(element.length > 0 && !stato.ripetibile){
                return true
            }else{
                return false
            }
        }
        return true
    }

    function aggiungiStato(e,tipo_id){
        e.preventDefault()
        if(!nuovostatoSelected){
            return;
        }
        let trovato = false
        let prodotto = settings.prodotti.list.find(x=>x.id === nuovostatoSelected.id_prodotto)
        let stato = prodotto.stati_cliente.find(x=>x.id === Number(nuovostatoSelected.id_stato))

        if(props.pratica.tipopratica === "PRIVATO"){
            if(stato.trasforma_in_pratica && (!props.pratica.anagrafica || !props.pratica.azienda)){
                notifications.NotifyAlarm(true,"Dati anagrafica o dati azienda non completi")
                return;
            }
        }
        if(props.pratica.tipopratica === "AZIENDA"){
            if(stato.trasforma_in_pratica && !props.pratica.azienda){
                notifications.NotifyAlarm(true,"Dati azienda non completi")
                return;
            }
        }

        let prodotto_selezionato = lista_stati_cliente.lista.filter(x=>x.id === Number(tipo_id))[0]
        if(!stato.ripetibile){
            let elements = prodotto_selezionato.lista_stati.filter(x=>x.id_stato === Number(stato.id))
            if(elements.length>0){
                notifications.NotifyAlarm(true,"Impossibile aggiungere lo stato, stato già inserito")
                return
            }
        }
        if(stato.ripetibile){ //Controlla che lo stato non sia attivo
            let elements = prodotto_selezionato.lista_stati.filter(x=>x.id_stato === Number(stato.id) && x.attivo===true)
            if(elements.length>0){
                notifications.NotifyAlarm(true,"Impossibile aggiungere lo stato, attualmente aperto")
                return
            }
        }
        let nota = [{
                name:accounts.account.nome +" "+accounts.account.cognome,
                id_utente:accounts.account.id,
                stato:"",
                nota:"Pratica caricata da cliente",
                timestamp:moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
            }]
        
        let data = {
            type:"addstato",
            id:props.pratica.id,
            id_categoria:prodotto_selezionato.id_prodotto,
            categoria:prodotto_selezionato.nome,
            id_prodotto:nuovostatoSelected.tipo_id,
            id_stato:nuovostatoSelected.id_stato,
            stato:{
                colorlist:prodotto.colorlist,
                id_stato:stato.id,
                nome_stato:stato.name,
                basegroup:prodotto.nome,
                invio_mail:stato.invio_mail,
                inserimento_note:stato.inserimento_note,
                trasforma_in_pratica:stato.trasforma_in_pratica,
                note : stato.trasforma_in_pratica ? JSON.stringify(nota):null,
                ripetibile:stato.ripetibile,
                listanote:[],
                inserimento_alarm:stato.inserimento_alarm
            }
        }
        axios.put('/api/1.1/cliente/stato',data,{headers:header}).then((res)=>{   
            console.log(res.data.data)           
            setLista_stati_cliente({...lista_stati_cliente,lista:res.data.data.prodotti_richiesti})
            setNuovostatoSelected(null)
            notifications.NotifyAlarm(false,"Stato aggiunto")
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Errore")
        })
    }
    
    return (
        <div>
            {settings.prodotti.list.filter(filterPrivatoAzienda).length>0 ? 
            <form onSubmit={(e)=>addProdotto(e)}>
                <Grid container>
                    <Grid item lg={10} md={10} sm={10} xs={12}>
                        <FormControl style={{width:"100%"}}>
                            <InputLabel id="outline-11">Aggiungi prodotto</InputLabel>
                            <Select
                                required
                                style={{width:"100%"}}
                                labelId="outline-11"
                                onChange={(e)=>setNuovoProdotto(e.target.value)}
                                label="Prodotto"
                                >
                                <MenuItem value=""><em>None</em></MenuItem>
                                    {settings.prodotti.list.filter(filterPrivatoAzienda).map((prodotto,i)=>{
                                        return(
                                            <MenuItem key={i} value={prodotto.id}>{prodotto.nome}</MenuItem>
                                        )
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg={2} md={2} sm={2} xs={12} style={{padding:"0 5px"}}>
                        <Button size="small" type="submit" variant="contained" color="primary" style={{width:"100%",marginTop:"15px"}}><i className="fas fa-plus"></i> Aggiungi</Button>
                    </Grid>    
                </Grid>
                <hr/>
            </form>
            :null}
            {props.pratica ?
                <div id="accordion">
                {lista_stati_cliente.lista && lista_stati_cliente.lista.sort(function(a,b){
                        return new Date(b.nome) - new Date(a.nome);
                    }).sort((a,b) => b.active - a.active).map((tipo,i)=>{
                    return(
                        <div key={i} className="card" style={{marginBottom:"10px"}}>
                            <div className="card-header" id={"heading"+tipo.id}>
                                <Grid container className="mb-0"> 
                                    <Grid item lg={11} sm={11} md={11} xs={12} className="collapsed" style={{cursor:"pointer"}} data-toggle="collapse" data-target={"#collapse"+tipo.id} aria-expanded="true" aria-controls={"collapse"+tipo.id} onClick={()=>{setStatoSelezionato(null)}}>
                                        {tipo.nome} {tipo.decorrenza ? <small>- <b>decorrenza</b>: {moment(new Date(tipo.decorrenza)).format("DD/MM/YYYY")}</small> : null} 
                                    </Grid>
                                    <Grid item lg={1} sm={11} md={11} xs={12}>
                                        <small>{!tipo.active ? "(CHIUSO)" : null}</small>
                                    </Grid>
                                </Grid>
                            </div>
                            <div id={"collapse"+tipo.id} className="collapse" aria-labelledby={"heading"+tipo.id} data-parent="#accordion">
                                <Grid container className="bar-divider">
                                    <Grid item lg={7} md={7} sm={12} xs={12} style={{padding:"5px"}}>
                                        <Grid container>
                                            <Grid item lg={8} md={8} sm={8} xs={12}>
                                                <TextField required label="Decorrenza" type="date"
                                                    InputLabelProps={{
                                                    shrink: true
                                                    }}
                                                    fullWidth={true}
                                                    onChange={(e)=>{setNuovadecorrenza(e.target.value)}}
                                                />
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={4} xs={12} style={{padding:"5px"}}>
                                                <Button size="small" type="submit" variant="contained" color="primary" style={{marginRight:"10px"}} onClick={(e)=>aggiornaData(e,"setdecorrenza",tipo.id_prodotto,tipo.id)}>Aggiorna</Button> 
                                                <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>rimuoviData(e,"removedecorrenza",tipo.id_prodotto,tipo.id)}>Rimuovi</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={5} sm={5} md={5} xs={12} style={{padding:"5px",textAlign:"right"}}>
                                        {tipo.active ? 
                                            <Button size="small" type="submit" variant="contained" color="primary"onClick={(e)=>chiudiProdotto(tipo.id)}><i className="fas fa-plus"></i> Chiudi</Button>
                                        :null}
                                    </Grid>
                                </Grid>
                                <div className="card-body" style={{padding:"0 15px"}}>
                                    {tipo.active ? 
                                        <form onSubmit={(e)=>e.preventDefault()}>
                                            <Grid container>
                                                <Grid item lg={5} md={5} sm={12} xs={12} style={{padding:"5px"}}>
                                                    {settings.prodotti.list.filter((element)=>{ //qui da sostituire con i corrispondenti stati
                                                            if(element.id === tipo.id_prodotto){ 
                                                                return element
                                                            }
                                                            return null
                                                        }).map((prod)=>{ //disabilitare gli stati non ripetibili se sono già stati inseriti
                                                            return(
                                                                <FormControl style={{width:"100%"}}>
                                                                    <InputLabel id="outline-9">Stato</InputLabel> 
                                                                    <Select
                                                                        required
                                                                        labelId="outline-9"
                                                                        onChange={(e)=>setNuovostato(e.target.value,tipo.id_prodotto,tipo.id)}
                                                                        label="Stato"
                                                                        >
                                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                                            {prod.stati_cliente.map((stato,i)=>{
                                                                                return(
                                                                                    <MenuItem key={i} value={stato.id} disabled={checkIfDisabled(tipo,stato)}>{stato.name}</MenuItem>
                                                                                )
                                                                            })}
                                                                    </Select>
                                                                </FormControl>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                                <Grid item lg={5} md={5} sm={12} xs={12} style={{padding:"5px"}}>
                                                    <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>aggiungiStato(e,tipo.id)}><i className="fas fa-plus"></i> Aggiungi</Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    :null}
                                <div>
                                    <StatoDetail 
                                        setNuovostato={setNuovostato} 
                                        statiPratica={tipo.lista_stati}
                                        type={tipo.code}
                                        id_prodotto = {tipo.id}
                                        showStatiChiusi={showStatiChiusi}
                                        setShowStatiChiusi={setShowStatiChiusi}
                                        selezionaStatoDaModificare={selezionaStatoDaModificare}
                                        statoSelezionato={statoSelezionato}
                                        chiudiStato={chiudiStato}
                                        setAlarmStato={setAlarmStato}
                                        setNuovanota={setNuovanota}
                                        nuovanota={nuovanota}
                                        aggiungiNota={aggiungiNota}
                                        saved={!tipo.active}
                                        // saved={props.pratica.chiusa}
                                        ></StatoDetail>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                </div>
            : <DefaultSpinner></DefaultSpinner>}
        </div>
    );
}

export default Stati;