import { FormControlLabel, Grid, Radio } from '@material-ui/core';
import React,{useState} from 'react';
import IntervistaAzienda from './IntervistaAzienda';
import IntervistaCliente from './IntervistaCliente';

function Intervista(props) {
    const [tipopratica, setTipopratica] = useState("PRIVATO")
    return (
        <div className="container-fluid">
                <h4>Aggiungi Cliente</h4>
                <div>
                    Aggiungere controllo che l'utente sia unico, iniziare con nome e cognome, trimmato,
                    se presente, occorre inserire anche il codice fiscale
                </div>
                <Grid container style={{marginBottom:"15px"}}>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop:"15px"}}><span>Tipologia</span></Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControlLabel value="AZIENDA" control={<Radio color="primary" />} checked={tipopratica === 'AZIENDA'} label="AZIENDA" onChange={()=>setTipopratica("AZIENDA")} />
                        <FormControlLabel value="PRIVATO" control={<Radio color="primary" />} checked={tipopratica === 'PRIVATO'} label="PRIVATO" onChange={()=>setTipopratica("PRIVATO")} />
                    </Grid>
                </Grid>
            {tipopratica === "PRIVATO" ? 
                <IntervistaCliente></IntervistaCliente>
            :
                <IntervistaAzienda></IntervistaAzienda>
            }
        </div>
    );
}

export default Intervista;