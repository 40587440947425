import React from 'react';
import Loader from 'react-loader-spinner'

function DefaultSpinner(props) {
    return (
        <div style={{textAlign:"center",marginTop:"20px"}}>
            <Loader
                type="Bars"
                color="#373a47"
                height={80}
                width={80}
            />
        </div>
    );
}

export default DefaultSpinner;