import React,{useState,useEffect} from 'react';
import { Button, Chip, Grid, Switch, TableHead, TextField } from '@material-ui/core';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import moment from 'moment';

function StatoDetail(props) {
    const [saved,setSaved] = useState(false)
    const [alarm, setAlarm] = useState(null)
    
    useEffect(() => {
        setSaved(props.saved)
    }, [props.saved])

    return (
        <div>
            {!saved? 
            <>                
                <div style={{textAlign:"right"}}>
                    {props.statiPratica && props.statiPratica.filter((element)=>{
                            return !element.attivo
                        }).length>0 ?  
                        <div>
                            <Switch
                                checked={props.showStatiChiusi}
                                onChange={(e)=>props.setShowStatiChiusi(!props.showStatiChiusi)}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            {!props.showStatiChiusi ? 
                            <><b>Mostra stati chiusi</b> ({props.statiPratica && props.statiPratica.filter((element)=>{
                                return !element.attivo
                            }).length})</> 
                            : <><b>Nascondi stati chiusi</b> ({props.statiPratica && props.statiPratica.filter((element)=>{
                                return !element.attivo
                            }).length})</> }
                        </div>
                    :null}
                </div>
            </>
            :null}
            {props.statiPratica && props.statiPratica.length>0 ?
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold"}}>Creazione</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Stato</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Nome</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>N° note</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Alarm</TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.statiPratica && props.statiPratica.sort(function(a,b){
                                return new Date(b.timestamp) - new Date(a.timestamp);
                                }).map((st,i)=>{
                                return(
                                    <TableRow key={i} hidden={!saved && (!props.showStatiChiusi && !st.attivo)} onClick={()=>{props.selezionaStatoDaModificare(st)}} className={props.statoSelezionato && props.statoSelezionato.id ===st.id ? "tab-stato tab-stato-active" : "tab-stato"}>
                                        <TableCell component="th" scope="row">{moment(new Date(st.timestamp)).format("DD/MM/YYYY HH:mm")}</TableCell>
                                        <TableCell>
                                            <Chip size="small" label={st.nome_stato} style={{backgroundColor:st.color,minWidth:"48px",border:"1px solid #606161"}} />      
                                        </TableCell>
                                        <TableCell>{st.nome} {st.cognome}</TableCell>
                                        <TableCell>{JSON.parse(st.listanote).length}</TableCell>
                                        <TableCell>
                                            {st.alarm ? 
                                                <>
                                                {moment(new Date(st.alarm)).format("DD/MM/YYYY HH:mm")}
                                                <span style={{marginLeft:"5px"}}>
                                                {moment(new Date(st.alarm)) < moment(new Date(), "DD/MM/YYYY") ? 
                                                <i style={{color:"red"}} className={"fas fa-exclamation-triangle"}></i>
                                                : 
                                                moment(new Date(st.alarm)) < moment(new Date(), "DD/MM/YYYY").set({hour:23,minute:59,second:59}) ? 
                                                <i style={{color:"orange"}} className={"fas fa-clock"}></i> 
                                                :null}
                                                </span>
                                            </> 
                                            :""}</TableCell>
                                        <TableCell>{st.attivo && !saved ? <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>props.chiudiStato(e,st.id,props.id_prodotto)}><i className="fas fa-times"></i> Chiudi</Button>:
                                        <Button size="small" variant="outlined" disabled={true} color="secondary">Chiuso</Button>} </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            :null}
            <br/>
            {props.statoSelezionato ? 
            <>
                {!saved && props.statoSelezionato.attivo===true ? 
                    // Da rimuovere il form e prevent default   
                        <form onSubmit={(e)=>e.preventDefault()}>
                            <Grid container>
                                <div>
                                    <h3>
                                        {props.statoSelezionato.nome_stato}
                                    </h3>
                                </div>
                                <Grid container style={{marginBottom:"20px"}}>
                                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                                        <TextField id="outlined-nota" label="Nuova Nota" variant="outlined" size="small" value={props.nuovanota} name="nuovanota" type="textarea" 
                                            multiline rows={4} fullWidth={true}
                                            disabled={!props.statoSelezionato.inserimento_note} onChange={(e)=>{props.setNuovanota(e.target.value)}}
                                            />
                                        <div style={{marginTop:"10px"}}>
                                            <Button size="small" disabled={!props.statoSelezionato.inserimento_note} type="submit" variant="contained" color="primary" onClick={(e)=>{props.aggiungiNota(e,props.statoSelezionato.id,props.id_prodotto)}}><i className="fas fa-plus"></i> Aggiungi nota</Button>
                                        </div>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={12} xs={12} style={{padding:"5px"}}>
                                        <Grid container>
                                            <Grid item lg={8} md={8} sm={12} xs={12} style={{padding:"5px"}}>
                                                <TextField type="datetime-local" required disabled={!props.statoSelezionato.inserimento_alarm}
                                                    InputLabelProps={{shrink: true}} name="inizio" label="Alarm" value={alarm} onChange={(e)=>setAlarm(e.target.value)} style={{width:"100%"}}/>
                                            </Grid>
                                            <Grid item lg={4} md={4} sm={12} xs={12} style={{padding:"5px"}}>
                                                <Button size="small" disabled={!props.statoSelezionato.inserimento_alarm} variant="contained" color="primary" style={{marginRight:"10px"}} onClick={(e)=>props.setAlarmStato(e,props.statoSelezionato.id,props.id_prodotto,alarm)}>Aggiorna</Button> 
                                                <br/>
                                                <Button size="small" disabled={!props.statoSelezionato.inserimento_alarm} variant="contained" color="secondary" style={{marginTop:"10px"}} onClick={(e)=>props.setAlarmStato(e,props.statoSelezionato.id,props.id_prodotto,null)}>Rimuovi</Button> 
                                            </Grid>      
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                :null}
                {props.statoSelezionato && JSON.parse(props.statoSelezionato.listanote).length>0 ? 
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight:"bold"}}>Data</TableCell>
                                    <TableCell style={{fontWeight:"bold"}}>Nota</TableCell>
                                    <TableCell style={{fontWeight:"bold"}}>Autore</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {JSON.parse(props.statoSelezionato.listanote).sort(function(a,b){
                                            return new Date(b.timestamp) - new Date(a.timestamp);
                                        }).map((ln,i)=>{
                                    return(
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row">{ln.timestamp.split(" ")[0]}<br/><small>{ln.timestamp.split(" ")[1]}</small></TableCell>
                                            <TableCell>
                                                {ln.nota.split("\n").map(function(item) {
                                                return (
                                                    <>
                                                    {item}
                                                    <br/>
                                                    </>
                                                )})}
                                            </TableCell>
                                            <TableCell>{ln.nome} {ln.cognome}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                : null}
            </>
            :null}
        </div>
    );
}

export default StatoDetail;