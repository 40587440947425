import React, { useState } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';

function ModalRicercaRapida(props) {
  const [cognome, setCognome] = useState("")
  const [cellulare, setCellulare] = useState("")
  const [codiceFiscale, setCodiceFiscale] = useState("")
  const [mail, setMail] = useState("")

  function applica(cerca){
    let data={
      cognome:cognome,
      cellulare:cellulare,
      codiceFiscale:codiceFiscale,
      mail:mail
    }
    if(cerca){
      props.setFiltriECerca(data)
    }else{
      props.setFiltri(data)
    }
    props.closeModal(true)
  }
  function chiudiModal(){
    props.closeModal(true)
  }
  function removeFilter(){
    setCognome("")
    setCellulare("")
    setCodiceFiscale("")
    setMail("")
  }
  return (
      <div>
          <Modal isOpen={props.isOpenModal} className="modal-lg">
              <ModalHeader>
                <div style={{fontWeight:"bold",fontSize:"20px"}}>Ricerca Rapida</div>
              </ModalHeader>
              <ModalBody>
                <div>
                  <div style={{marginBottom:"15px"}}>
                    <TextField required label="Cognome" value={cognome}
                      fullWidth={true} onChange={(e)=>{setCognome(e.target.value)}}/>
                  </div>
                  <div style={{marginBottom:"15px"}}>
                    <TextField required label="Cellulare" value={cellulare}
                      fullWidth={true} onChange={(e)=>{setCellulare(e.target.value)}}/>
                  </div>
                  <div style={{marginBottom:"15px"}}>
                    <TextField required label="Codice fiscale" value={codiceFiscale}
                      fullWidth={true} onChange={(e)=>{setCodiceFiscale(e.target.value)}}/>
                  </div>
                  <div style={{marginBottom:"15px"}}>
                    <TextField required label="Email" value={mail}
                      fullWidth={true} onChange={(e)=>{setMail(e.target.value)}}/>
                  </div>
                  <div hidden={true}>
                    ID: _______ <br/>
                  </div>
                </div>
                <ModalFooter>
                  <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>applica(false)}> <i className="far fa-save"></i> Applica</Button>
                  <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>applica(true)}> <i className="far fa-save"></i> Applica e Cerca</Button>
                  <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{chiudiModal()}}><i className="fas fa-times"></i> Chiudi</Button>
                  <Button size="small" type="submit" variant="contained" color="secondary" onClick={(e)=>{removeFilter()}}><i className="fas fa-times"></i> Rimuovi Filtri</Button>
                </ModalFooter>   
              </ModalBody>

      </Modal>
      </div>
  );
}

export default ModalRicercaRapida;