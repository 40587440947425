import React,{useState,useEffect} from 'react';
import axios from 'axios'
import AuthService from './AuthService'
import UserItem from './UserItem';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ModalInserimentoUtente from '../modali/ModalInserimentoUtente';
import DefaultSpinner from '../DefaultSpinner';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});


let Auth=new AuthService();
let header=Auth.getHeadersJWT();
let blank_user = {
    id:null,
    nome:"",
    cognome:"",
    data_di_nascita:"",
    luogo_di_nascita:"",
    partita_iva:"",
    codice_fiscale:"",
    email:"",
    descrizione:"",
    password:"",
    role:"",
    indirizzo:"",
    provincia:"",
    comune:"",
    cap:"",
    giorni_per_fatturare:0
}

function UserLista(props) {
    const classes = useStyles();
    const [utentiLista, setUtentiLista] = useState({list:[]})
    const [userDaModificare,setUserDaModificare] = useState(null)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [listaAgenzie, setListaAgenzie] = useState({list:[]})
    const [loading, setLoading] = useState(true)

    function cancellaUser(utente){
        if(window.confirm("vuoi eliminare "+ utente.nome + " " + utente.cognome+"?")){
            axios.delete('/api/1.1/user/'+utente.id, {headers:header}).then(res=>{
                let lista = utentiLista.list
                lista.forEach((element,i) => {
                    if(element.id === utente.id){
                        lista.splice(i,1)
                    }
                });
                setUtentiLista({list:lista})
            })
        }
    }
    function returnUser(utente){
        let lista = utentiLista.list
        let trovato=false
        for (let index = 0; index < lista.length; index++) {
            if(lista[index].id === utente.id){
                lista[index] = utente
                trovato=true
                break
            }
        }
        if(!trovato){
            lista.push(utente)
        }
        setIsOpenModal(false)
        setUtentiLista({list:lista})
    }
    function changeStatus(utente){
        let data={
            tipologia:"changestatus",
            user:{id:utente.id}
        }
        axios.put("/api/1.1/user",data,{headers:header}).then(res=>{
            let lista = utentiLista.list
            for (let index = 0; index < lista.length; index++) {
                if(lista[index].id === utente.id){
                    lista[index] = res.data.data
                    break
                }
            }
            setUtentiLista({list:lista})
        })
    }

    useEffect(() => {
        axios.get('/api/1.1/user', {headers:header}).then(res=>{
            setUtentiLista({list:res.data.data});
            var list=[]
            res.data.data.forEach(element => {
                if(element.role==="agenzia" && element.attivo){
                    list.push({id_agenzia:element.id,descrizione:element.descrizione})
                }    
            });
            setListaAgenzie({...listaAgenzie,list:list})
            setLoading(false)
        }
    )}, [])
    
    return (
        <div>
            {!loading ?
            <>
            <div style={{margin:"15px 0"}}>
                <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{setUserDaModificare(blank_user);setIsOpenModal(true)}}><i className="fas fa-plus"></i> Aggiungi utente</Button>
            </div>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight:"bold"}}>Nome</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Cognome</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Email</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Ruolo</TableCell>
                            <TableCell style={{fontWeight:"bold"}}>Attivo</TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {utentiLista.list && utentiLista.list.map((utente, i)=>{
                        return(
                            <UserItem key={i} utente={utente} 
                            setUserDaModificare={setUserDaModificare} 
                            setIsOpenModal={setIsOpenModal}
                            changeStatus={changeStatus}
                            cancellaUser={cancellaUser}></UserItem>
                        )
                    })}
                    </TableBody>
                </Table>
            </TableContainer>
            {userDaModificare ? 
                <ModalInserimentoUtente utente={userDaModificare} 
                    isOpenModal={isOpenModal} 
                    setIsOpenModal={setIsOpenModal}
                    listaAgenzie={listaAgenzie}
                    returnUser={returnUser}></ModalInserimentoUtente>
            :null}
            </>
            :
            <div style={{textAlign:"center"}}>
                <DefaultSpinner></DefaultSpinner>
            </div>
        }
    </div>
    );
}

export default UserLista;