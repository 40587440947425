import React,{useState,useEffect,useContext} from 'react';
import axios from 'axios'
import AuthService from '../../components/utente/AuthService';
import DefaultSpinner from '../DefaultSpinner';
import Button from '@material-ui/core/Button';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import ModalEstinzione from '../modali/ModalEstinzione';
import { SettingContext } from '../../contexts/SettingContext';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function Estinzione(props) {
    const settings = useContext(SettingContext)
    const [loading, setLoading] = useState(true)
    const [estinzione, setEstinzione] = useState(null)
    const [openModal, setOpenModal] = useState(false)

    function saveNewTipo(item){
        axios.post("/api/1.1/estinzione",item,{headers:header}).then((res)=>{
            let trovato = false
            let list = settings.estinzioni.list
            for (let index = 0; index < list.length; index++) {
                if(list[index].id === res.data.data.id){
                    list[index]=res.data.data
                    trovato=true
                    break
                }
            }
            if(!trovato){
                list.push(res.data.data)
            }
            settings.setEstinzioni({list:list})
            isOpenModal(false)
        }).catch(error=>{
            console.log(error)
            isOpenModal(false)
        })
    }
    function openModalNewTipo(item){
        setEstinzione(item)
        isOpenModal(true)
    }
    function deleteEstinzione(id){
        axios.delete("/api/1.1/estinzione/"+id,{headers:header}).then((res)=>{
            let list = settings.estinzioni.list
            list.forEach((element,index,objects) => {   
                if(element.id === id){
                    objects.splice(index,1)
                }
            });
            settings.setEstinzioni({list:list})
        }).catch(error=>{
            console.log("errore")
        })
    }

    function isOpenModal(value){
        setOpenModal(!openModal)
    }

    useEffect(() => {
        setLoading(false)
    },[])
    return (
        <div>
            {!loading ? 
            <>
            <h3><b style={{marginRight:"15px"}}>ESTINZIONI</b>
            <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>openModalNewTipo(null)}><i className="fas fa-plus"></i> Aggiungi</Button></h3>
            <div>
                <TableContainer>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold"}}>Nome</TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {settings.estinzioni.list.map((item,i)=>{
                                return(
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">{item.nome}</TableCell>
                                        <TableCell style={{textAlign:"right"}}><Button size="small" type="submit" variant="contained" color="primary" onClick={()=>{setEstinzione(item); setOpenModal(true)}}><i className="fas fa-folder-open"></i> Modifica</Button></TableCell>
                                        <TableCell style={{textAlign:"right"}}><Button size="small" type="submit" variant="contained" color="secondary" onClick={()=>{deleteEstinzione(item.id)}}><i className="fas fa-trash"></i> Cancella</Button></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>  
            <ModalEstinzione estinzione={estinzione} saveNewTipo={saveNewTipo} isOpenModal={openModal} closeModal={isOpenModal} />
            </>
            :<DefaultSpinner></DefaultSpinner>}
        </div>
    )}

export default Estinzione;