import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import { Provider } from 'react-redux'
import './index.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import App from './App';
import Login from './components/utente/login'
import Logout from './components/utente/logout'
import * as serviceWorker from './serviceWorker';
//https://redux.js.org/basics/example

import store from './store'
import Homepage from './components/homepage';
import AuthService from './components/utente/AuthService';
import Impostazioni from './components/impostazioni/Impostazioni';
import Pratica from './components/pratica/Pratica';
import Agenda from './components/agenda/Agenda';
import Assicurazioni from './components/impostazioni/Assicurazioni';
import WorklistCliente from './components/worklist/WorklistCliente';
import WorklistPratica from './components/worklist/WorklistPratica';
import Liste from './components/liste/Liste';
import Statistiche from './components/statistiche/Statistiche';
import Simulatore from './components/simulatore/Simulatore';
import TipoDocumento from './components/impostazioni/TipoDocumento';
import Mandanti from './components/impostazioni/Mandanti';
import Fatturazione from './components/fatturazione/Fatturazione';
import UserLista from './components/utente/UserLista';
import Account from './components/impostazioni/Account';
import Documenti from './components/documenti/Documenti';
import EditorDocumenti from './components/documenti/EditorDocumenti';
import Webservices from './components/impostazioni/Webservices';
import Fonti from './components/impostazioni/Fonti';
import Intervista from './components/intervista/Intervista';
import TipoFinanziamento from './components/impostazioni/TipoFinanziamento';
import Estinzione from './components/impostazioni/Estinzione';
import Cliente from './components/cliente/Cliente';
import Prodotti from './components/impostazioni/Prodotti';

let Auth=new AuthService();

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )} />
  );
  const MainLayout = (props) => (
    <App {...props}>{props.children}</App>
  );
  const SimpleRoute = ({ component: Component,  ...rest }) => (
    <Route render={props => (
        <Component {...props} />
    )} />
  );

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <Switch>
                <SimpleRoute exact path="/login" component={Login} />
                <SimpleRoute exact path="/logout" component={Logout} />
                <AppRoute exact path="/impostazioni" layout={MainLayout} component={(props) => (<Impostazioni {...props}></Impostazioni>)}/>
                {/* <AppRoute exact path="/impostazioni/statipratica" layout={MainLayout} component={(props) => (<StatiPratica {...props}></StatiPratica>)}/> */}
                <AppRoute exact path="/impostazioni/prodotti" layout={MainLayout} component={(props) => (<Prodotti {...props}></Prodotti>)}/>
                <AppRoute exact path="/pratica/:id_pratica" layout={MainLayout} component={(props) => (<Pratica {...props}></Pratica>)}/>
                <AppRoute exact path="/pratica/:id_pratica/:nome_pagina" layout={MainLayout} component={(props) => (<Pratica {...props}></Pratica>)}/>

                <AppRoute exact path="/cliente/:id_pratica" layout={MainLayout} component={(props) => (<Cliente {...props}></Cliente>)}/>
                <AppRoute exact path="/cliente/:id_pratica/:nome_pagina" layout={MainLayout} component={(props) => (<Cliente {...props}></Cliente>)}/>

                <AppRoute exact path="/agenda" layout={MainLayout} component={(props) => (<Agenda {...props}></Agenda>)}/>
                <AppRoute exact path="/liste" layout={MainLayout} component={(props) => (<Liste {...props}></Liste>)}/>
                <AppRoute exact path="/fatturazione" layout={MainLayout} component={(props) => (<Fatturazione {...props}></Fatturazione>)}/>
                <AppRoute exact path="/work-list-cliente" layout={MainLayout} component={(props) => (<WorklistCliente {...props}></WorklistCliente>)}/>
                <AppRoute exact path="/work-list-pratica" layout={MainLayout} component={(props) => (<WorklistPratica {...props}></WorklistPratica>)}/>
                <AppRoute exact path="/simulatore" layout={MainLayout} component={(props) => (<Simulatore {...props}></Simulatore>)}/>
                <AppRoute exact path="/statistiche" layout={MainLayout} component={(props) => (<Statistiche {...props}></Statistiche>)}/>
                <AppRoute exact path="/impostazioni/assicurazioni" layout={MainLayout} component={(props) => (<Assicurazioni {...props}></Assicurazioni>)}/>
                <AppRoute exact path="/impostazioni/tipodocumento" layout={MainLayout} component={(props) => (<TipoDocumento {...props}></TipoDocumento>)}/>
                <AppRoute exact path="/impostazioni/bancheeservizi" layout={MainLayout} component={(props) => (<Mandanti {...props}></Mandanti>)}/>
                <AppRoute exact path="/impostazioni/tipifinanziamento" layout={MainLayout} component={(props) => (<TipoFinanziamento {...props}></TipoFinanziamento>)}/>
                <AppRoute exact path="/impostazioni/estinzioni" layout={MainLayout} component={(props) => (<Estinzione {...props}></Estinzione>)}/>
                <AppRoute exact path="/impostazioni/account" layout={MainLayout} component={(props) => (<Account {...props}></Account>)}/>
                <AppRoute exact path="/impostazioni/precompilati" layout={MainLayout} component={(props) => (<Documenti {...props}></Documenti>)}/>
                <AppRoute exact path="/impostazioni/precompilati/:id_documento" layout={MainLayout} component={(props) => (<EditorDocumenti {...props}></EditorDocumenti>)}/>
                <AppRoute exact path="/impostazioni/ws-config" layout={MainLayout} component={(props) => (<Webservices {...props}></Webservices>)}/>
                <AppRoute exact path="/impostazioni/fonti" layout={MainLayout} component={(props) => (<Fonti {...props}></Fonti>)}/>
                <AppRoute exact path="/" layout={MainLayout} component={(props) => (<Homepage {...props}></Homepage>)}/>
                
                {/* <AppRoute exact path="/" layout={MainLayout} component={(props) => (
                    !(Auth.loggedIn()) ? (
                        <Redirect to="/login" />
                    ) : (
                        <Homepage {...props} />
                    )
                    )}/> */}
                <AppRoute exact path="/newclient" layout={MainLayout} component={(props) => (
                    !(Auth.loggedIn()) ? (
                        <Redirect to="/login" />
                    ) : (
                        <Intervista {...props} />
                    )
                    )}/>
                <AppRoute exact path="/impostazioni/users" layout={MainLayout} component={(props) => (
                    !(Auth.loggedIn()) ? (
                        <Redirect to="/login" />
                    ) : (
                        <UserLista {...props} />
                    )
                    )}/>
            </Switch>
        </Router>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
