import React,{ useState,useEffect,useContext} from 'react';
import { Document,Page, pdfjs } from 'react-pdf';
import Draggable from 'react-draggable';
import axios from 'axios';
import AuthService from '../utente/AuthService';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SettingContext } from '../../contexts/SettingContext';
import {PDFDocument} from 'pdf-lib';
import fileDownload from 'js-file-download';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import labelprecompilati from '../../commons/labelprecompilati.json';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let lunghezzaPaginaPDF=1300;
let topPaddingBox = 140;
let leftPaddingBox = 200;
  
let listaOpzioni=labelprecompilati

function EditorDocumenti(props) {
    const notifications = useContext(NotificationContext);
    const settings = useContext(SettingContext);
    const [loading, setLoading] = useState(true)
    const [numPages, setNumPages] = useState(null);
    const [selectedElement, setSelectedElement] = useState(null);
    const [listOfElement, setListOfElement] = useState({list:[]});
    const [newElement, setNewElement] = useState(null)
    const [categoriaSelezionata, setCategoriaSelezionata] = useState(null)
    const [documento, setDocumento] = useState(null)
    const [precompilato_id, setPrecompilato_id] = useState(null)

    useEffect(() => {
        if(props.location.documento){
            setDocumento(props.location.documento)
            setPrecompilato_id(props.location.documento.id)
            if(props.location.documento.listaopzioni!==""){
                setListOfElement({list:JSON.parse(props.location.documento.listaopzioni)})
            }
            setLoading(false);
        }else{
            window.location.href="/impostazioni/precompilati/"
        }
    }, [props.location.documento])

    async function exportPDFTest(e){
        e.preventDefault();
        console.log(documento.proxy_url)
        const existingPdfBytes = await fetch(documento.proxy_url,{headers:header}).then(res => res.arrayBuffer())
        const pdfDoc = await PDFDocument.load(existingPdfBytes)
        const pages = pdfDoc.getPages()
        for (let i = 0; i < pages.length; i++) {
            const page = pages[i];
            let list = listOfElement.list.filter(x=>x.y_real >= (lunghezzaPaginaPDF*i) && x.y_real < (lunghezzaPaginaPDF*(i+1)))
            for (let l = 0; l < list.length; l++) {
                const element = list[l];
                let percentualeConversione = page.getMediaBox().height/lunghezzaPaginaPDF
                page.drawText(element.value, {
                    x: ((element.x)*percentualeConversione)+21,
                    y: (((lunghezzaPaginaPDF*(i+1) - element.y_real)*percentualeConversione)-Number(element.fontSize)-13.5),
                    size: Number(element.fontSize)*percentualeConversione,
                    // color: rgb(0.95, 0.1, 0.1),
                    // rotate: degrees(-45),
                  })               
            } 
        }
        // Serialize the PDFDocument to bytes (a Uint8Array)
        const pdfBytes = await pdfDoc.save()
        fileDownload(pdfBytes, documento.file.split("?")[0].split("/")[documento.file.split("?")[0].split("/").length - 1], "application/pdf");
    }

    function deleteSelectedElement(){
        let list = listOfElement.list;
        for (let i = 0; i < list.length; i++) {
            const element = list[i];
            if(element.id === selectedElement.id){
                list.splice(i,1);
                break;
            }
        }
        setListOfElement({...listOfElement, list:list})
        setSelectedElement(null);
    }

    function changevaluebox(e){
        let list = listOfElement.list;
        list.forEach(elm => {
            if(elm.id === selectedElement.id){   
                elm[e.target.name] = e.target.value;
                return;
            }
        });
        setListOfElement({...listOfElement, list:list})
    }
    
    function onDocumentLoadSuccess({ numPages }) {
        let arrPage = []
        for (let index = 0; index < numPages; index++) {
            arrPage.push(index+1);
        }
        setNumPages(arrPage);
    }
    function addElementToDocument(e){
        e.preventDefault();
        let trovato = null;
        let lstopzioni = listaOpzioni.find(x=>x.code === categoriaSelezionata.code)
        lstopzioni.options.forEach(element => {
            if(element.key === newElement){
                trovato = element;
            }
        });
        if(trovato !== null){
            let id=0;
            let list = listOfElement.list;
            if(list.length>0){
                list.map(function(obj){if (obj.id > id) id = obj.id; return id});
                id = id+1
            }
            let element={
                id:id, 
                x:0, 
                y: (((numPages.length * lunghezzaPaginaPDF)-window.pageYOffset-60)*(-1)), 
                y_real : 0,
                code:categoriaSelezionata.code,
                key:trovato.key, 
                value:trovato.text,
                moved:false,
                fontSize:"13"
            }
            list.push(element)
            setListOfElement({...listOfElement, list:list})
        }
    }

    function openMenuStyle(id){
        setSelectedElement(listOfElement.list.find(x=>x.id === id));
    }

    function changeCategoria(categoria){
        setCategoriaSelezionata(listaOpzioni.find(x=>x.code === categoria));
    }
    
    function salvaImpostazioni(e){
        e.preventDefault();
        let data = {
            precompilato_id:precompilato_id,
            listaopzioni:JSON.stringify(listOfElement.list)
        }
        axios.put('/api/1.1/precompilato', data ,{headers:header}).then((res)=>{
            let trovato = false
            let list = settings.precompilati.list
            for (let index = 0; index < list.length; index++) {
                if(list[index].id === res.data.data.id){
                    list[index]=res.data.data
                    trovato=true
                    break
                }
            }
            if(!trovato){
                list.push(res.data.data)
            }
            settings.setPrecompilati({list:list}) 
            notifications.NotifyAlarm(false,"Impostazioni salvate")
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Operazione non riuscita")
          })
    }
    function dragon(e,info){
        let list = listOfElement.list;
        setSelectedElement(null);
        list.forEach(elm => {
            if(elm.id === Number(info.node.id)){
                elm.x=info.x;
                elm.y=info.y;
                elm.moved=true;
                elm.y_real = (numPages.length*Number(lunghezzaPaginaPDF))-(Number(info.y)*-1);
                return;
            }
        });
        setListOfElement({...listOfElement, list:list})
    }
    
    return (
        <div>
            {!loading && documento ? 
            <>
            <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12} className="stiky-header">
                    <form onSubmit={(e)=>{addElementToDocument(e)}}>
                    <div className="bar-box row">
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <div style={{padding:"10px"}}>
                                <div className="bar-box-label"><small><b>Categoria</b></small></div>
                                <select required className="input-sm" style={{height:"25px",width:"100%"}} onChange={(e)=>changeCategoria(e.target.value)}>
                                    <option value=""></option>
                                    {listaOpzioni.map((lo,i)=>{
                                        return(
                                            <option key={i} value={lo.code}>{lo.code}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            {categoriaSelezionata ? 
                                <div style={{padding:"10px"}}>
                                    <div className="bar-box-label"><small><b>Opzione</b></small></div>
                                    <select required className="input-sm" style={{height:"25px",width:"100%"}} onChange={(e)=>{setNewElement(e.target.value)}}>
                                        <option value=""></option>
                                        {categoriaSelezionata.options.map((cs,i)=>{
                                            return(
                                                <option key={i} value={cs.key}>{cs.menu}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            :null}
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={4}>
                            <Grid container style={{marginTop:"30px"}}>
                                <Grid item lg={4} md={4} sm={4} xs={4}><Button size="small" type="submit" variant="contained" color="primary">Aggiungi</Button></Grid>
                                <Grid item lg={4} md={4} sm={4} xs={4}><Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{salvaImpostazioni(e)}}>Salva</Button></Grid>
                                <Grid item lg={4} md={4} sm={4} xs={4}><Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{exportPDFTest(e)}}>test</Button></Grid>
                            </Grid>
                        </Grid>
                    </div>
                    </form>
                </Grid>
                <Document
                    file={"/api/1.1/getproxiedfile?f="+documento.url}
                    onLoadSuccess={onDocumentLoadSuccess}>
                    {numPages && numPages.map((page,i)=>{
                        return(
                            <div key={i}>
                                <Page pageNumber={page} renderMode="canvas" height={lunghezzaPaginaPDF}></Page>
                            </div>
                        )
                    })}
                {listOfElement && listOfElement.list.map((elm,i)=>{
                    return(
                        <Draggable key={i} defaultClassNameDragged={elm.id}
                            onStop={dragon} 
                            position={{
                                x: elm.x,
                                y: elm.y
                              }}
                              >
                                <div id={elm.id} className={elm.moved ? "drag-wrapper":"drag-wrapper drag-wrapper-pulsing"}>
                                    <div id={elm.id} className="test" title={elm.text} style={{fontSize:elm.fontSize+"px"}}>
                                        <div style={{paddingRight:"15px"}}>{elm.value}</div>
                                        <div style={{position:"absolute", top:0, right:0, cursor:"pointer"}} onClick={(e)=>openMenuStyle(elm.id)}>
                                            <i style={{paddingRight:"5px"}} className="fas fa-ellipsis-v"></i>
                                        </div>
                                    </div>
                                </div>
                        </Draggable>
                    )
                    })}
                    {selectedElement ? 
                        <div id="box-draggable-value" style={{zIndex:200,top:selectedElement.y+topPaddingBox+(numPages.length*lunghezzaPaginaPDF),left:selectedElement.x+leftPaddingBox}}>
                            <div style={{zIndex:"10"}}>
                                <div style={{textAlign:"right",position:"absolute",top:"5px",right:"5px",cursor:"pointer"}} onClick={()=>{setSelectedElement(null)}}>X</div>
                                <div><small>({selectedElement.code})</small></div>
                                <div className="form-group">
                                    <label><small><b>Testo di prova</b></small></label>
                                    <input type="text" className="form-control input-sm" value={selectedElement.value} style={{height:"25px"}} name="value" onChange={(e)=>changevaluebox(e)} placeholder="Testo di prova"/>
                                </div>
                                <div className="form-group">
                                    <label><small><b>Grandezza Testo</b> (px)</small></label>
                                    <input type="number" className="form-control" value={selectedElement.fontSize} style={{height:"25px"}} name="fontSize"  onChange={(e)=>changevaluebox(e)} placeholder="13px" min={0} max={20}/>
                                </div>
                            </div>
                            <Button size="small" type="submit" variant="contained" color="secondary" onClick={()=>{deleteSelectedElement()}} style={{width:"100%"}}>Elimina</Button>
                        </div>
                    :null}
                </Document>
        </Grid>
        </>
        :"LOADING..."}
        </div>
    );
}

export default EditorDocumenti;