import React,{useState,useEffect} from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import PraticaItem from '../pratica/PraticaItem';
import axios from 'axios';
import AuthService from '../utente/AuthService';
import ModalNote from '../modali/ModalNote';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function PraticheAssociate(props) {
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [loadingModal, setLoadingModal] = useState(true)
    const [notePratica, setNotePratica] = useState([])
    const [codicepratica, setCodicepratica] = useState("")
    
    function showNotePratica(pratica){
        if(pratica){
            setCodicepratica((pratica.codice_pratica+"").padStart(6, '0'))
            setOpenModal(true)
            axios.get('/api/1.1/pratica/stato/'+pratica.id,{headers:header}).then((resnote)=>{
                setNotePratica(resnote.data.data)
                setLoadingModal(false)
            })
        }else{
            setNotePratica([])
            setCodicepratica("")
            setOpenModal(false)
            setLoadingModal(true)
        }
        
    }

    useEffect(() => {
        if(props.id_pratica){    
            let data = {
                id_cliente:props.id_pratica
            }
            axios.post("/api/1.1/cliente/pratichecliente",data,{headers:header}).then(res=>{
                setResults(res.data.data)
                setLoading(false)
            })
        }else{
            setResults([])
            setLoading(false)
        }
        
    }, [props.id_pratica])
    return (
        <div>
            {!loading ? 
            <div>
                {results.length>0 ? 
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>                              
                                <TableCell style={{fontWeight:"bold"}}>Id</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Operazione</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Riferimento</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Stato pratica</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Data caricata</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Data approvata</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Data liquidata</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {results && results.map((res,i)=>{
                            return(
                                <PraticaItem key={i} pratica={res} showCheck={true} isPratica={true} showCheck={false} shownotebutton={true} showNote={showNotePratica}/>
                            )
                        })}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <div style={{textAlign:'center'}}>
                    Nessuna pratica associata a questo cliente
                </div>}
            </div>
            :null}
            <ModalNote chiudiModal={showNotePratica} isOpenModal={openModal} codicepratica={codicepratica} notePratica={notePratica} loadingModal={loadingModal}></ModalNote>
        </div>
    );
}

export default PraticheAssociate;  