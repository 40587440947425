
import React, { useState, useEffect } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import Button from '@material-ui/core/Button';
import { Checkbox, TextField } from '@material-ui/core';

function ModalTipoFinanziamento(props) {
    const [nome, setNome] = useState("")
    const [id, setId] = useState(null)
    const [isAzienda, setIsAzienda] = useState(false)
    const [isPrivato, setIsPrivato] = useState(false)
    const [coobligato, setCoobligato] = useState(false)
    function chiudiModal(){
      props.closeModal(false)
    }
    function saveNuovoTipoFinanziamento(e){
        e.preventDefault()
        let data = {
            nome:nome,
            id:id,
            isAzienda:isAzienda,
            isPrivato:isPrivato,
            coobligato:coobligato,
        }
        props.saveNewTipo(data)
    }

    useEffect(() => {
        if(props.tipo){
            setNome(props.tipo.nome)
            setId(props.tipo.id)
            setIsAzienda(props.tipo.isAzienda)
            setIsPrivato(props.tipo.isPrivato)
            setCoobligato(props.tipo.coobligato)
        }else{
            setNome("")
            setId(null)
            setIsAzienda(false)
            setIsPrivato(false)
            setCoobligato(false)
        }
    }, [props.tipo])

    return (
            <Modal isOpen={props.isOpenModal} className="modal-lg">
              <form onSubmit={(e)=>saveNuovoTipoFinanziamento(e)}>
                <ModalBody>
                  <div>
                    <ModalHeader>
                      <div style={{fontWeight:"bold",fontSize:"20px"}}>Tipo Finanziamento</div>
                    </ModalHeader>
                  </div>
                  <div>
                    <div>
                        <TextField type="text" label="Nome" required value={nome} onChange={(e)=>{setNome(e.target.value)}} style={{width:"100%"}}/>
                    </div>
                    <div>
                        <Checkbox size="small" color="primary" value={isAzienda} onClick={(e)=>{setIsAzienda(!isAzienda)}} checked={isAzienda}/>
                        <label>Azienda</label>
                    </div>
                    <div>
                        <Checkbox size="small" color="primary" value={isPrivato} onClick={(e)=>{setIsPrivato(!isPrivato)}} checked={isPrivato}/>
                        <label>Privato</label>
                    </div>
                    <div>
                        <Checkbox size="small" color="primary" value={coobligato} onClick={(e)=>{setCoobligato(!coobligato)}} checked={coobligato}/>
                        <label>
                        Coobligato
                        </label>
                    </div>
                  </div>
                  <ModalFooter>
                    <Button size="small" type="submit" variant="contained" color="primary"> <i className="far fa-save"></i> Salva</Button>
                    <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{e.preventDefault();chiudiModal()}}><i className="fas fa-times"></i> Chiudi</Button>
                  </ModalFooter>   
                </ModalBody>
            </form>
        </Modal>
    );
}

export default ModalTipoFinanziamento;