
import React, { useState, useEffect } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import Button from '@material-ui/core/Button';
import { Checkbox, TextField } from '@material-ui/core';

function ModalTipoDocumento(props) {
    const [nome, setNome] = useState("")
    const [id, setId] = useState(null)
    const [privacy, setPrivacy] = useState(false)
    function chiudiModal(){
      props.closeModal(false)
    }
    function saveNuovoTipoDocumento(e){
        e.preventDefault()
        let data = {
            nome:nome,
            id:id,
            privacy:privacy
        }
        props.saveNewTipoDocumento(data)
    }

    useEffect(() => {
        if(props.tipodocumento){
            setNome(props.tipodocumento.nome)
            setId(props.tipodocumento.id)
            setPrivacy(props.tipodocumento.privacy)
        }else{
            setNome("")
            setId(null)
            setPrivacy(false)
        }
    }, [props.tipodocumento])

    return (
            <Modal isOpen={props.isOpenModal} className="modal-lg">
              <form onSubmit={(e)=>saveNuovoTipoDocumento(e)}>
                <ModalBody>
                  <div>
                    <ModalHeader>
                      <div style={{fontWeight:"bold",fontSize:"20px"}}>Tipo documento</div>
                    </ModalHeader>
                  </div>
                  <div>
                    <div>
                      <TextField type="text" label="Nome" required value={nome} onChange={(e)=>{setNome(e.target.value)}} style={{width:"100%"}}/>
                    </div>
                    <div>
                        <Checkbox size="small" color="primary" defaultChecked={privacy} onClick={(e)=>{setPrivacy(!privacy)}} checked={privacy}/>
                        <label>Documento di privacy</label>
                    </div>
                  </div>
                  <ModalFooter>
                    <Button size="small" type="submit" variant="contained" color="primary"> <i className="far fa-save"></i> Salva</Button>
                    <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{e.preventDefault();chiudiModal()}}><i className="fas fa-times"></i> Chiudi</Button>
                  </ModalFooter>   
                </ModalBody>
            </form>
        </Modal>
    );
}

export default ModalTipoDocumento;