import React, { useState,useEffect } from 'react';
import RisultatoSimulatore from '../simulatore/RisultatoSimulatore';
import AuthService from '../utente/AuthService'

let Auth=new AuthService();

function ElencoPreventivi(props) {
    const [chiusa] = useState(false)//props.pratica.chiusa)
    const [idPredefinito, setIdPredefinito] = useState(props.pratica.id_preventivo_predefinito)
    const [listaPreventivi, setListaPreventivi] = useState({list:props.pratica.elencoProvvigioni})

    useEffect(() => {
        setListaPreventivi({list:props.pratica.elencoProvvigioni})
    }, [props.pratica.elencoProvvigioni])

    useEffect(() => {
        setIdPredefinito(props.pratica.id_preventivo_predefinito)
    }, [props.pratica.id_preventivo_predefinito])

    function setNewIdPrevenivoPredefinito(id_preventivo_predefinito){
        props.setIdPreventivoPredefinito(id_preventivo_predefinito)
    }
    return (
        <div>
            <h3>Elenco preventivi</h3>
            <div>
                {listaPreventivi && listaPreventivi.list.length>0 ? 
                    listaPreventivi.list.map((preventivo,i)=>{
                        return(
                            <RisultatoSimulatore key={i}
                                saved={true}
                                chiusa={chiusa}
                                id_pratica={props.pratica.id} 
                                approved={false}
                                loadError={false} 
                                preventivo={preventivo}
                                idPredefinito = {idPredefinito}
                                hasPermission={!Auth.hasPermission(["admin"])}
                                setNewIdPrevenivoPredefinito={setNewIdPrevenivoPredefinito}/>
                        )
                    })
                :"Nessun preventivo calcolato"}
            </div>
        </div>
    );
}

export default ElencoPreventivi;