import React, { useState, useEffect,useContext } from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService';
import ModalStateCliente from '../modali/ModalStateCliente';
import ModalProdotto from '../modali/ModalProdotto';
import DefaultSpinner from '../DefaultSpinner';
import { NotificationContext } from '../../contexts/NotificationContext';
import { SettingContext } from '../../contexts/SettingContext';
import Button from '@material-ui/core/Button';
import StatiPraticaClienti from './StatiPraticaClienti'
import ModalStatePratica from '../modali/ModalStatePratica';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

let nuovoStatoPratica = {
    id:null,
    name:"",
    iniziale:false,
    ripetibile:false,
    inserimento_note:false,
    invio_mail: false,
    inserimento_alarm:false,
    chiusura_pratica:false,
    liquidata:false,
    approvata:false
}

let nuovoStatoCliente = {
    id:null,
    name:"",
    iniziale:false,
    ripetibile:false,
    inserimento_note:false,
    invio_mail: false,
    trasforma_in_pratica:false,
    inserimento_alarm:false
}
let nuovoProdotto = {
    id:null,
    nome:"",
    active:true,
    isAzienda:false,
    isPrivato:false,
    coobligato:false,
    colorlist:[
      {type:"admin",color:"#ffffff"},
      {type:"agenzia",color:"#ffffff"},
      {type:"collaboratore",color:"#ffffff"},
      {type:"operatore",color:"#ffffff"},
      {type:"telemarketing",color:"#ffffff"}
    ]
}
//inserire tipo colore per ogni utente, per ogni stato, questo può essere un json

//TODO: impostare stato iniziale, (UNO SOLO DI DEFAULT), se non presenti altri stati, quello è di default
//      impostare uno stato irripetibile, se già creato non se ne può creare un altro uguale
//      impostare stato di chiusura, se quello stato presente, allora la pratica si considera chiusa 

function Prodotti(props) {
    const notifications = useContext(NotificationContext);
    const settings = useContext(SettingContext);
    const [loading, setLoading] = useState(true)
    const [statoClienteSelected, setStatoClienteSelected] = useState(null)
    const [statoPraticaSelected, setStatoPraticaSelected] = useState(null)
    const [prodottoSelected, setProdottoSelected] = useState(null)
    const [basegroup, setbasegroup] = useState(null)
    const [idProdotto, setIdProdotto] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [openModalProdotto, setOpenModalProdotto] = useState(false)
    const [openModalPratica, setOpenModalPratica] = useState(false)

    function saveNewProdotto(nuovoPrd){
        nuovoPrd["colorlist"]=JSON.stringify(nuovoPrd["colorlist"])
        if(!nuovoPrd.id){
            axios.post("/api/1.1/prodotto",nuovoPrd,{headers:header}).then((res)=>{
                let result=res.data.data
                let lprodotti = settings.prodotti.list
                lprodotti.push(result)
                setProdottoSelected(nuovoProdotto)
                settings.setProdotti({list:lprodotti})
                isOpenModalProdotto(false)
            }).catch(error=>{
                isOpenModalProdotto(false)
            })
        }else{
            axios.put("/api/1.1/prodotto",nuovoPrd,{headers:header}).then((res)=>{
                let lprodotti = settings.prodotti.list
                lprodotti.forEach((element,i)=>{
                    if(element.id === nuovoPrd.id){
                        lprodotti[i] = nuovoPrd
                    }
                })
                setProdottoSelected(nuovoProdotto)
                settings.setProdotti({list:lprodotti})
                isOpenModalProdotto(false)
            }).catch(error=>{
                isOpenModalProdotto(false)
            })
        }
    }
    function deleteProdotto(prodotto){
        if(window.confirm("Sei sicuro di voler eliminare il Prodotto?")){
            axios.delete("/api/1.1/prodotto/"+prodotto.id,{headers:header}).then((res)=>{
                let lprodotti = settings.prodotti.list
                lprodotti.forEach((element,i) => {
                    if(element.id === prodotto.id){
                        lprodotti.splice(i,1)
                    }
                });
                settings.setProdotti({list:lprodotti})
                notifications.NotifyAlarm(false,"Prodotto eliminato")
            }).catch(error=>{
                notifications.NotifyAlarm(true,"Operazione non completata")
            })
        }
    }
    function saveNewStatePratica(nuovostato){
        nuovostato["basegroup"] = basegroup
        nuovostato["idProdotto"] = idProdotto
        axios.post("/api/1.1/statipratica",nuovostato,{headers:header}).then((res)=>{
            let result=res.data.data
            let lprodotti = settings.prodotti.list
            if(!nuovostato.id){
                lprodotti.forEach(element => {
                    if(element.id === idProdotto){
                        element.stati_pratica.push(result);  
                    }
                });
            }else{
                lprodotti.forEach(element => {
                    if(element.id === idProdotto){
                        element.stati_pratica.forEach((stato,i)=>{
                            if(stato.id === result["id"]){
                                element.stati_cliente[i]=result;
                            }
                        })   
                    }
                });
            }
            setStatoPraticaSelected(nuovoStatoPratica)
            settings.setProdotti({list:lprodotti})
            isOpenModalPratica(false)
        }).catch(error=>{
            console.log(error)
            isOpenModalPratica(false)
        })
    }

    function saveNewStateCliente(nuovostato){
        nuovostato["basegroup"] = basegroup
        nuovostato["idProdotto"] = idProdotto
        axios.post("/api/1.1/staticliente",nuovostato,{headers:header}).then((res)=>{
            let result=res.data.data
            let lprodotti = settings.prodotti.list
            if(!nuovostato.id){
                lprodotti.forEach(element => {
                    if(element.id === idProdotto){
                        element.stati_cliente.push(result);  
                    }
                });
            }else{
                lprodotti.forEach(element => {
                    if(element.id === idProdotto){
                        element.stati_cliente.forEach((stato,i)=>{
                            if(stato.id === result["id"]){
                                element.stati_cliente[i]=result;
                            }
                        })   
                    }
                });
            }
            setStatoClienteSelected(nuovoStatoCliente)
            settings.setProdotti({list:lprodotti})
            isOpenModal(false)
        }).catch(error=>{
            console.log(error)
            isOpenModal(false)
        })
    }
    function openModalNewStato(base_group,id_prodotto){
        setStatoClienteSelected(nuovoStatoCliente);
        setbasegroup(base_group)
        setIdProdotto(id_prodotto)
        setOpenModal(true)
    }
    function openModalNewStatoPratica(base_group,id_prodotto){
        setStatoPraticaSelected(nuovoStatoPratica);
        setbasegroup(base_group)
        setIdProdotto(id_prodotto)
        setOpenModalPratica(true)
    }
    function openModalNewProdotto(){
        setProdottoSelected(nuovoProdotto)
        setOpenModalProdotto(true)
    }
    
    function deleteStatoById(id,id_prodotto){
        if(window.confirm("Sei sicuro di voler eliminare lo stato?")){
        axios.delete("/api/1.1/staticliente/"+id,{headers:header}).then((res)=>{
            let lprodotti = settings.prodotti.list
            lprodotti.forEach(element => {
                if(element.id === id_prodotto){
                    element.stati_cliente.forEach((stato,index,object)=>{
                        if(stato.id === id){
                            object.splice(index,1)
                        }
                    })   
                }
            });
            settings.setProdotti({list:lprodotti})
            notifications.NotifyAlarm(false,"Stato cliente eliminato")
            }).catch(error=>{
                notifications.NotifyAlarm(false,"Operazione non completata")
            })
        }
    }
    function deleteStatoPraticaById(id,id_prodotto){
        if(window.confirm("Sei sicuro di voler eliminare lo stato?")){
        axios.delete("/api/1.1/statipratica/"+id,{headers:header}).then((res)=>{
            let lprodotti = settings.prodotti.list
            lprodotti.forEach(element => {
                if(element.id === id_prodotto){
                    element.stati_pratica.forEach((stato,index,object)=>{
                        if(stato.id === id){
                            object.splice(index,1)
                        }
                    })   
                }
            });
            settings.setProdotti({list:lprodotti})
            notifications.NotifyAlarm(false,"Stato pratica eliminato")
            }).catch(error=>{
                notifications.NotifyAlarm(false,"Operazione non completata")
            })
        }
    }
    function isOpenModalPratica(value){
        setOpenModalPratica(!openModalPratica)
    }
    function isOpenModal(value){
        setOpenModal(!openModal)
    }
    function isOpenModalProdotto(value){
        setOpenModalProdotto(!openModalProdotto)
    }
    function modifyState(stato,base_group,id_prodotto){
        setbasegroup(base_group)
        setIdProdotto(id_prodotto)
        setStatoClienteSelected(stato)
        setOpenModal(true)
    }
    function modifyStatePratica(stato,base_group,id_prodotto){
        setbasegroup(base_group)
        setIdProdotto(id_prodotto)
        setStatoPraticaSelected(stato)
        setOpenModalPratica(true)
    }
    function modifyProdotto(nuovoPrd){
        nuovoPrd["colorlist"]=JSON.parse(nuovoPrd["colorlist"])
        setProdottoSelected(nuovoPrd)
        setOpenModalProdotto(true)
    }
    useEffect(() => {
        if(settings.prodotti){
            setLoading(false)
        }
    }, [])

    return (
        <div>
            {!loading ? 
            <>
            <h3>Prodotti e Stati</h3>
            <div>
                <h4><Button size="small" type="submit" variant="contained" color="primary" onClick={()=>openModalNewProdotto()}><i className="fas fa-plus"></i> Aggiungi Prodotto</Button></h4>
                <hr/>
            </div>
            <div>
                {settings.prodotti.list.map((prd,i)=>{
                    return(
                    <div key={i}>
                        <h5>{prd.nome}</h5>
                        <div style={{margin:"10px 0"}}>
                            <Button size="small" type="submit" variant="contained" color="primary" style={{marginRight:"10px"}} onClick={(e)=>{modifyProdotto(prd)}}><i className="fas fa-save"></i> Modifica</Button>
                            <Button size="small" type="submit" variant="contained" color="secondary" style={{marginRight:"10px"}} onClick={(e)=>{deleteProdotto(prd)}}><i className="fas fa-trash"></i> Cancella</Button>
                        </div>
                        <StatiPraticaClienti 
                            stati_cliente={prd.stati_cliente}
                            stati_pratica={prd.stati_pratica}
                            nome={prd.nome}
                            id={prd.id}
                            modifyState={modifyState}
                            modifyStatePratica={modifyStatePratica}
                            deleteStatoById={deleteStatoById}
                            deleteStatoPraticaById={deleteStatoPraticaById}
                            openModalNewStato={openModalNewStato}
                            openModalNewStatoPratica={openModalNewStatoPratica}
                        ></StatiPraticaClienti>
                        <hr/>
                    </div>
                        
                    )
                })}
            </div>
            {openModalPratica ? 
                <ModalStatePratica basegroup={basegroup} saveNewState={saveNewStatePratica} statoSelected={statoPraticaSelected} isOpenModal={openModalPratica} closeModal={isOpenModalPratica} />
            :null}
            {openModal ? 
                <ModalStateCliente basegroup={basegroup} saveNewState={saveNewStateCliente} statoSelected={statoClienteSelected} isOpenModal={openModal} closeModal={isOpenModal} />
            :null}
            {openModalProdotto ? 
                <ModalProdotto saveNewProdotto={saveNewProdotto} prodottoSelected={prodottoSelected} isOpenModalProdotto={openModalProdotto} closeModal={isOpenModalProdotto} />
            :null}
            </>
            :<DefaultSpinner></DefaultSpinner>}
        </div>
    );
}
export default Prodotti;