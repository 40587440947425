import React,{useState,useEffect,useContext} from 'react'
import axios from 'axios';
import AuthService from '../utente/AuthService'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { NotificationContext } from '../../contexts/NotificationContext';

let Auth = new AuthService();
let header=Auth.getHeadersJWT();

function Rappresentanti(props) {
  const notifications = useContext(NotificationContext);
  const [saved] = useState(false)//props.pratica.chiusa)
  const [titolare, setTitolare] = useState(props.pratica.titolare ? props.pratica.titolare.id : "")
  const [agenzia, setAgenzia] = useState(props.pratica.id_capo ? props.pratica.id_capo.id : "")

  function onlyUniqueNotNull(value, index, self) {
    return value !== null && value !== "" && self.indexOf(value) === index;
  }

  function salvaRuoli(e){
    e.preventDefault();
    let usersId = []
    usersId.push(titolare)
    usersId.push(agenzia)
    let data={
      id:props.pratica.id,
      type:"setruoli",
      titolare:titolare,
      agenzia:agenzia,
      usersId:usersId.filter(onlyUniqueNotNull)      
    }
    axios.put('/api/1.1/cliente',data,{headers:header}).then((res)=>{
      if(titolare){
        let titolare_u = props.rappresentanti.filter(x=>x.id === titolare)[0]
        let agenzia_u = props.rappresentanti.filter(x=>x.id === agenzia)[0]
        props.aggiornaSchemaUtenti(titolare_u,agenzia_u)
      }else{
        props.aggiornaSchemaUtenti(null,null)
      }
      notifications.NotifyAlarm(false,"operazione completata")
    }).catch(error=>{
        notifications.NotifyAlarm(true,"Operazione non completata")
    })
  }

  return (
    <div>
      <form onSubmit={(e) => salvaRuoli(e)}>
        <Grid container spacing={1}>
        <Grid item lg={5} md={5} sm={10} xs={10} >
            <FormControl style={{width:"100%"}}>
              <InputLabel id="outline-titolare">Agenzia</InputLabel>
              <Select
                  disabled={saved}
                  style={{width:"100%"}}
                  labelId="outline-agenzia"
                  value={agenzia}
                  onChange={(e)=>{setAgenzia(e.target.value)}}
                  label="Agenzia"
                  >
                  <MenuItem value={null}><em>None</em></MenuItem>
                      {props.rappresentanti && props.rappresentanti.filter(x=>x.role === "agenzia").map((rappresentante,i)=>{
                          return(
                              <MenuItem key={i} value={rappresentante.id}>{rappresentante.descrizione}</MenuItem>
                          )
                      })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={5} md={5} sm={10} xs={10} >
            <FormControl style={{width:"100%"}}>
              <InputLabel id="outline-titolare">Titolare</InputLabel>
              <Select
                  disabled={saved}
                  style={{width:"100%"}}
                  labelId="outline-titolare"
                  value={titolare}
                  onChange={(e)=>{setTitolare(e.target.value)}}
                  label="Titolare"
                  >
                  <MenuItem value=""><em>None</em></MenuItem>
                      {props.rappresentanti && props.rappresentanti.filter((element)=>{
                        if(agenzia){
                          return element.id_agenzia === agenzia
                        }else{
                          return element
                        }
                      }).map((rappresentante,i)=>{
                          return(
                              <MenuItem key={i} value={rappresentante.id}>{rappresentante.nome + " "+rappresentante.cognome} ({rappresentante.role} {rappresentante.descrizione ? " - " + rappresentante.descrizione :null})</MenuItem>
                          )
                      })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={2} md={2} sm={2} xs={2} style={{paddingTop:"15px"}}>
            <Button size="small" variant="contained" type="submit" color="primary"><i className="fas fa-save"></i> Salva</Button>
          </Grid>
        </Grid>
        {/* percentuale titolare <br/>
        percentuale agenzia <br/> */}
      </form>
    </div>
  );
}

export default Rappresentanti;