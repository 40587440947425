
import React, { useState, useEffect } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import Button from '@material-ui/core/Button';
import { Checkbox, TextField } from '@material-ui/core';

function ModelProdotto(props) {
    const [nuovoProdotto,setNuovoProdotto] = useState(props.prodottoSelected)
    const [colorAdmin, setColorAdmin] = useState(null)
    const [colorCollaboratore, setColorCollaboratore] = useState(null)
    const [colorOperatore, setColorOperatore] = useState(null)
    const [colorTelemarketing, setColorTelemarketing] = useState(null)
    const [colorAgenzia, setColorAgenzia] = useState(null)

    function onChangeValue(nome,valore){
      setNuovoProdotto({...nuovoProdotto, [nome]:valore})
    }
    function setNameValue(e){
      setNuovoProdotto({...nuovoProdotto,[e.target.name]:e.target.value})
    }
    function chiudiModal(){
      props.closeModal(false)
    }
    function saveNewProdotto(e){
        e.preventDefault()
        props.saveNewProdotto(nuovoProdotto)
    }
    function setNuovoProdottoColore(e){
      let lcolori = nuovoProdotto.colorlist
      lcolori.forEach(element => {
        if(element.type===e.target.name){
          element.color = e.target.value
        }
      });
      setNuovoProdotto({...nuovoProdotto,colorlist:lcolori})
    }

    useEffect(() => {
      setColorAdmin(props.prodottoSelected.colorlist.find(x=>x.type==="admin").color)
      setColorCollaboratore(props.prodottoSelected.colorlist.find(x=>x.type==="collaboratore").color)
      setColorOperatore(props.prodottoSelected.colorlist.find(x=>x.type==="operatore").color)
      setColorTelemarketing(props.prodottoSelected.colorlist.find(x=>x.type==="telemarketing").color)
      setColorAgenzia(props.prodottoSelected.colorlist.find(x=>x.type==="agenzia").color)
    }, [props.prodottoSelected])

    return (
            <Modal isOpen={props.isOpenModalProdotto} className="modal-lg">
              <form onSubmit={(e)=>saveNewProdotto(e)}>
                <ModalBody>
                  <ModalHeader>
                    <div style={{fontWeight:"bold",fontSize:"20px"}}>Nuovo Prodotto</div>
                  </ModalHeader>
                  <div>
                    <div className="form-group-sm">
                      <TextField type="text" name="nome" label="Nome" required value={nuovoProdotto.nome} onChange={(e)=>{setNameValue(e)}} style={{width:"100%"}}/>
                    </div>
                    <div className="form-group-sm"> 
                      <Checkbox size="small" color="primary" defaultChecked={nuovoProdotto.isAzienda} onClick={(e)=>{onChangeValue("isAzienda",!nuovoProdotto.isAzienda)}}/>
                      <label>Azienda</label>
                    </div>
                    <div className="form-group-sm"> 
                      <Checkbox size="small" color="primary" defaultChecked={nuovoProdotto.isPrivato} onClick={(e)=>{onChangeValue("isPrivato",!nuovoProdotto.isPrivato)}}/>
                      <label>Privato</label>
                    </div>
                    <div className="form-group-sm"> 
                      <Checkbox size="small" color="primary" defaultChecked={nuovoProdotto.coobligato} onClick={(e)=>{onChangeValue("coobligato",!nuovoProdotto.coobligato)}}/>
                      <label>Coobligato</label>
                    </div>
                    <div className="form-group-sm">
                      <label>Admin Colore</label>
                      <input type="color" className="form-control" name="admin" value={colorAdmin} onChange={(e)=>{setColorAdmin(e.target.value);setNuovoProdottoColore(e)}}/>
                    </div>
                    <div className="form-group-sm">
                      <label>Agenzia Colore</label>
                      <input type="color" className="form-control" name="agenzia" value={colorAgenzia} onChange={(e)=>{setColorAgenzia(e.target.value);setNuovoProdottoColore(e)}}/>
                    </div>
                    <div className="form-group-sm">
                      <label>Collaboratore Colore</label>
                      <input type="color" className="form-control" name="collaboratore" value={colorCollaboratore} onChange={(e)=>{setColorCollaboratore(e.target.value);setNuovoProdottoColore(e)}}/>
                    </div>
                    <div className="form-group-sm">
                      <label>Operatore Colore</label>
                      <input type="color" className="form-control" name="operatore" value={colorOperatore} onChange={(e)=>{setColorOperatore(e.target.value);setNuovoProdottoColore(e)}}/>
                    </div>
                    <div className="form-group-sm">
                      <label>Telemarketing Colore</label>
                      <input type="color" className="form-control" name="telemarketing" value={colorTelemarketing} onChange={(e)=>{setColorTelemarketing(e.target.value);setNuovoProdottoColore(e)}}/>
                    </div>
                    </div>
                  <ModalFooter>
                    <Button size="small" type="submit" variant="contained" color="primary"> <i className="far fa-save"></i> Salva</Button>
                    <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{e.preventDefault();chiudiModal()}}> <i className="fas fa-times"></i> Chiudi</Button>
                  </ModalFooter>   
                </ModalBody>
            </form>
        </Modal>
    );
}

export default ModelProdotto;