import React,{useState,useContext,useEffect} from 'react';
import axios from 'axios'
import AuthService from '../utente/AuthService'
import { NotificationContext } from '../../contexts/NotificationContext';
import Button from '@material-ui/core/Button';
import DefaultSpinner from '../DefaultSpinner';
import CSVReader from "react-csv-reader";
import { FormControl, Grid, InputLabel, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { MenuItem } from 'react-bootstrap-typeahead';
import ModalUtentiImpostazione from '../modali/ModalUtentiImpostazione';


let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function ListaPrivato(props) {
    const notifications = useContext(NotificationContext);
    const [document, setDocument] = useState({list:null})
    const [loading, setLoading] = useState(false)
    const [hasPrivacy, setHasPrivacy] = useState(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [lutenti, setLutenti] = useState(null)

    function salvaDocumento(){
        if(document.list){
            let usrid=[]
            for (let i = 0; i < document.list.length; i++) {
                if(usrid.indexOf(document.list[i].assegnatoa) < 0){
                    usrid=usrid.concat(document.list[i].assegnatoa)
                }   
            }
            let data={
                nominativi:document.list.filter(x=>x.trovato===false),
                tipopratica:"PRIVATO",
                usersId:usrid
            }
            axios.post('/api/1.1/importexport',data,{headers:header}).then(res=>{
                let cdocumenti = document.list
                cdocumenti.forEach(element => {
                    element.trovato=true
                });
                notifications.NotifyAlarm(false,"Importazione eseguita correttamente")
            }).catch(error=>{
                notifications.NotifyAlarm(true,"Errore in upload, riprovare")
            })
        }
    }
    function assegnaTitolari(valori){
        let n = 0
        let cdocumenti = document.list.filter(x=>x.trovato === false)
        cdocumenti.forEach(element => {
            element.assegnatoa=null
        });
        for (let i = 0; i < valori.list.length; i++) {
            const element = valori.list[i];
            for (let j = 0; j < element.totale; j++) {
                const celement = cdocumenti[n];
                celement.assegnatoa = element.id
                celement.id_capo = element.id_capo
                n=n+1
            }
        }
        cdocumenti = cdocumenti.concat(document.list.filter(x=>x.trovato === true))
        setDocument({list:cdocumenti})
        setIsOpenModal(false)
    }

    function handleForce(csv, fileInfo){
        setLoading(true)
        setDocument(null)
        let data={
            nominativi:csv,
            tipopratica:"PRIVATO"
        }
        axios.put('/api/1.1/importexport',data,{headers:header}).then(res=>{
            for (let i = 0; i < csv.length; i++) {
                const element = csv[i];
                let trovato = res.data.data.filter(x=>x.nome === element.nome && x.cognome === element.cognome && (x.telefono === element.telefono || x.codice_fiscale === element.codice_fiscale))
                if(trovato.length>0){
                    csv[i].trovato = true
                }else{
                    csv[i].trovato = false
                }
                csv[i].assegnatoa=null
            }
            setDocument({list:csv})
            setLoading(false)
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Errore in fase di controllo, riprovare")
            setLoading(false)
        })
    }

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    }

    function chiudiModal(valore){
        setIsOpenModal(valore)
    }

    function exportfile(){
        axios.get('/api/1.1/importexport',{headers:header}).then((res)=>{ 
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = window.document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.csv');
            window.document.body.appendChild(link);
            link.click();
        }).catch(error=>{
            notifications.NotifyAlarm(true,"Errore in download, riprovare")
        })
    }
    function assegnaClienti(){
        setIsOpenModal(true)
    }
    function changeTitolareCliente(titolare,utente){
        let cdocumenti = document.list;
        cdocumenti.forEach(x => {
            if(x.nome === utente.nome && x.cognome === utente.cognome && x.telefono === utente.telefono && x.codice_fiscale === utente.codice_fiscale){
                x.assegnatoa = titolare
            }
        })
        setDocument({list:cdocumenti})
    }

    return (
        <Grid container>
            <Grid item lg={3} md={3} sm={12} xs={12} style={{padding:"5px"}}>
                <h4>Importa</h4>
                <div style={{marginBottom:"15px"}} className="break-text">
                    <CSVReader
                        cssClass="react-csv-input"
                        label=""
                        onFileLoaded={handleForce}
                        parserOptions={papaparseOptions}
                        />
                </div>
                <div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" checked={hasPrivacy} onChange={(e)=>setHasPrivacy(!hasPrivacy)} />
                        <label className="form-check-label" htmlFor="defaultCheck1">
                            <smal>Dichiari di avere tutti i documenti di privacy dei clienti nella lista che stai per importare</smal>
                        </label>
                    </div>
                </div>
                <div className="form-group">
                    <Button size="small" type="submit" variant="contained" color="primary" disabled={!document || !document.list || !hasPrivacy} onClick={(e)=>salvaDocumento()}><i className="fas fa-file-upload"></i> Importa</Button>
                </div>
                {/* <hr/>
                <h4>Esporta</h4>
                <div>
                    <div>Inserire filtri?</div>
                    <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>exportfile()}><i className="fas fa-file-download"></i> Esporta</Button>
                </div> */}
            </Grid>
            <Grid item lg={9} md={9} sm={12} xs={12} style={{padding:"5px"}}>
                {document && document.list ? 
                    <TableContainer component={Paper} style={{padding:"5px"}}>
                        <h5>NOMINATIVI TOTALI: {document.list.length} - NOMINATIVI GIA' PRESENTI: {document.list.filter(x=>x.trovato === true).length}</h5>
                        <Table aria-label="simple table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{fontWeight:"bold"}}>Cognome</TableCell>
                                    <TableCell style={{fontWeight:"bold"}}>Nome</TableCell>
                                    <TableCell style={{fontWeight:"bold"}}>Telefono</TableCell>
                                    <TableCell style={{fontWeight:"bold"}}>Codice Fiscale</TableCell>
                                    <TableCell style={{fontWeight:"bold"}}>Agenzia</TableCell>
                                    <TableCell style={{fontWeight:"bold"}}><Button size="small" variant="contained" color="secondary" onClick={()=>assegnaClienti()}>Assegna</Button></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {document.list.sort(function(a, b) {
                                if(a.cognome.toLowerCase() < b.cognome.toLowerCase()) return -1;
                                if(a.cognome.toLowerCase() > b.cognome.toLowerCase()) return 1;
                                return 0;
                            }).map((utente,i)=>{
                                return(
                                    <TableRow key={i} className={utente.trovato ? "liste-trovato" : null}>
                                        <TableCell>{utente.cognome}</TableCell>
                                        <TableCell>{utente.nome}</TableCell>
                                        <TableCell>{utente.telefono}</TableCell>
                                        <TableCell>{utente.codice_fiscale}</TableCell>
                                        {props.usersList.list.filter(x=>x.id_agenzia===utente.id_capo).length>0 ? 
                                            <TableCell>{props.usersList.list.filter(x=>x.id_agenzia===utente.id_capo)[0].descrizione}</TableCell>
                                        :<TableCell>-</TableCell>}
                                        {utente.trovato ? 
                                        <>
                                            <TableCell>Già presente</TableCell>
                                        </>
                                        :
                                        <>
                                            {props.usersList.list.filter(x=>x.id_agenzia===utente.id_capo).length>0 ? 
                                                <TableCell>{props.usersList.list.filter(x=>x.id===utente.assegnatoa)[0].nome +" "+props.usersList.list.filter(x=>x.id===utente.assegnatoa)[0].cognome}</TableCell>
                                            :<TableCell>-</TableCell>}
                                        </>}
                                    </TableRow>
                                )
                            })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                :null}
                {loading ? 
                    <div style={{textAlign:"center"}}>
                        <DefaultSpinner></DefaultSpinner>
                    </div>
                :null}
            </Grid>
            <ModalUtentiImpostazione 
                isOpenModal={isOpenModal} 
                lutenti={lutenti} 
                countutenti={document && document.list ? document.list.filter(x=>x.trovato === false).length : 0} 
                chiudiModal={chiudiModal} 
                assegnaTitolari={assegnaTitolari} 
                userList={props.usersList.list} />
        </Grid>
    );
}

export default ListaPrivato;