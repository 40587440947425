import React, { useState } from 'react'
import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import moment from 'moment'
import { TableCell, TableRow,Chip, Checkbox, Button } from '@material-ui/core';

function PraticaItem(props) {
  const [redirect, setRedirect] = useState(false)
  const [datibase, setDatibase] = useState(null)
  const [pratica_id, setPratica_id] = useState(null)

  useEffect(() => {
    if(props.pratica){
      setPratica_id((props.pratica.codice_pratica+"").padStart(6, '0'))
      setDatibase(props.pratica.anagrafica)
    }
  }, [props.pratica])

  function redirectTo(){
    setRedirect(true)
  }
  return (
    datibase && props.pratica.tipopratica === "PRIVATO" ? 
      <>
      {redirect ? 
          <Redirect to={{
              pathname: '/pratica/'+props.pratica.id,
              utente:props.pratica}}/>
        :null}
        
      <TableRow style={{cursor:"pointer"}}>
        {props.showCheck ?
          <TableCell>
              <Checkbox size="small" color="primary" onClick={(e)=>{props.checkedItem(props.pratica.id)}}/>  
          </TableCell>
        :null}
        <TableCell onClick={()=>{redirectTo()}}>{pratica_id}</TableCell>
        <TableCell onClick={()=>{redirectTo()}}>{props.pratica.categoria}</TableCell>
        <TableCell onClick={()=>{redirectTo()}}> <i className={props.pratica.tipopratica === "AZIENDA" ? "fas fa-industry":"fas fa-user-alt"}></i>  {datibase.cognome} {datibase.nome}</TableCell>
        {!!props.pratica.attiva ?
          <TableCell onClick={()=>{redirectTo()}}>
            {props.pratica.stato_attuale}
          </TableCell>
         : 
          <TableCell onClick={()=>{redirectTo()}}>
            <div>CHIUSA</div>
          </TableCell>
        }
        <TableCell>{moment(props.pratica.timestamp).format("DD/MM/YYYY")}</TableCell>
        <TableCell>{props.pratica.data_approvazione ? moment(props.pratica.data_approvazione).format("DD/MM/YYYY") : null}</TableCell>
        <TableCell>{props.pratica.data_liquidata ? moment(props.pratica.data_liquidata).format("DD/MM/YYYY") : null}</TableCell>
        {props.shownotebutton ?
          <TableCell><Button color="primary" size="small" variant="contained" onClick={()=>props.showNote(props.pratica)}>Note</Button></TableCell>
        :null}
      </TableRow>
      </>
    :
    <>
    {redirect ? 
      <Redirect to={{
          pathname: '/pratica/'+props.pratica.id,
          utente:props.pratica}}>
      </Redirect>:null}
      <TableRow style={{cursor:"pointer"}}>
          {props.showCheck ?
            <TableCell>     
                <Checkbox size="small" color="primary" onClick={(e)=>{props.checkedItem(props.pratica.id)}}/>  
            </TableCell>
          :null}
          <TableCell onClick={()=>{redirectTo()}}>{pratica_id}</TableCell>
          <TableCell onClick={()=>{redirectTo()}}>{props.pratica.categoria}</TableCell>
          <TableCell onClick={()=>{redirectTo()}}> <i className={"fas fa-industry"}></i> {props.pratica.azienda.ragione_sociale}</TableCell>
          {!!props.pratica.attiva ?
            <TableCell onClick={()=>{redirectTo()}}>
              {props.pratica.stato_attuale}
            </TableCell>
          : 
            <TableCell onClick={()=>{redirectTo()}}>
              <div>CHIUSA</div>{/* <span style={{padding:"5px 15px",backgroundColor:"#63141e",color:"white"}}>CHIUSA</span> */}
            </TableCell>
          } 
          <TableCell>{moment(props.pratica.timestamp).format("DD/MM/YYYY")}</TableCell>
          <TableCell>{props.pratica.data_approvazione ? moment(props.pratica.data_approvazione).format("DD/MM/YYYY") : null}</TableCell>
          <TableCell>{props.pratica.data_liquidata ? moment(props.pratica.data_liquidata).format("DD/MM/YYYY") : null}</TableCell>
          {props.shownotebutton ?
            <TableCell><Button color="primary" size="small" variant="contained" onClick={()=>props.showNotePratica(props.pratica)}>Note</Button></TableCell>
          :null}
      </TableRow>
    </>
  );
}

export default PraticaItem;