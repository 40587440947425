import React,{useState} from 'react';
import { AppBar, Button, Checkbox, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from '@material-ui/core';
import { sortByName } from '../../commons/sortsfunct';
import TabPanel from '../../commons/TabPanel';

function StatiPraticaClienti(props) {
    const [value, setValue] = useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function a11yProps(index) {
      return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
      };
    }

    return (
        <div style={{marginBottom:"15px"}}>
            <AppBar position="relative" color="default" style={{zIndex:"0"}}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
                <Tab label={"Stati cliente ("+props.stati_cliente.length+")"} {...a11yProps(0)} />
                <Tab label={"Stati pratica ("+props.stati_pratica.length+")"} {...a11yProps(1)} />
              </Tabs>
            </AppBar>
                <TabPanel value={value} index={0}>
                    <div style={{marginBottom:"10px"}}>
                        <Button size="small" type="submit" variant="contained" color="primary" style={{marginRight:"10px"}} onClick={()=>props.openModalNewStato(props.nome,props.id)}><i className="fas fa-plus"></i> Aggiungi stato</Button>
                    </div>
                    <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold"}}>Nome</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Iniziale/default</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Inserimento note</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Ripetibile</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Alarm</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Invio mail</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Trasforma in pratica</TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.stati_cliente.sort(sortByName)
                                .map((sp,j)=>{
                                return(
                                    <TableRow key={j}>
                                        <TableCell component="th" scope="row">{sp.name}</TableCell>
                                        <TableCell>{sp.iniziale ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{sp.inserimento_note ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{sp.ripetibile ?  <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{sp.inserimento_alarm ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{sp.invio_mail ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{sp.trasforma_in_pratica ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell><Button size="small" type="submit" variant="contained" color="primary" onClick={()=>{props.modifyState(sp,props.nome,props.id)}}><i className="fas fa-folder-open"></i> Modifica</Button></TableCell>
                                        <TableCell><Button size="small" type="submit" variant="contained" color="secondary" style={{marginLeft:"15px"}} onClick={()=>{props.deleteStatoById(sp.id,props.id)}}><i className="fas fa-trash"></i> Cancella</Button></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div style={{marginBottom:"10px"}}>
                    <Button size="small" type="submit" variant="contained" color="primary" style={{marginRight:"10px"}} onClick={()=>props.openModalNewStatoPratica(props.nome,props.id)}><i className="fas fa-plus"></i> Aggiungi stato</Button>
                </div>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold"}}>Nome</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Iniziale/default</TableCell>
                                {/* <TableCell style={{fontWeight:"bold"}}>Inserimento note</TableCell> */}
                                <TableCell style={{fontWeight:"bold"}}>Ripetibile</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Alarm</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Invio mail</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Liquidata</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Chiusura pratica</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Approvata</TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.stati_pratica.sort(sortByName)
                                .map((sp,j)=>{
                                return(
                                    <TableRow key={j}>
                                        <TableCell component="th" scope="row">{sp.name}</TableCell>
                                        <TableCell>{sp.iniziale ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        {/* <TableCell>{sp.inserimento_note ? <Checkbox size="small" color="primary" checked/>:null}</TableCell> */}
                                        <TableCell>{sp.ripetibile ?  <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{sp.inserimento_alarm ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{sp.invio_mail ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{sp.liquidata ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{sp.chiusura_pratica ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell>{sp.approvata ? <Checkbox size="small" color="primary" checked/>:null}</TableCell>
                                        <TableCell><Button size="small" type="submit" variant="contained" color="primary" onClick={()=>{props.modifyStatePratica(sp,props.nome,props.id)}}><i className="fas fa-folder-open"></i> Modifica</Button></TableCell>
                                        <TableCell><Button size="small" type="submit" variant="contained" color="secondary" style={{marginLeft:"15px"}} onClick={()=>{props.deleteStatoPraticaById(sp.id,props.id)}}><i className="fas fa-trash"></i> Cancella</Button></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            
        </div>
    );
}

export default StatiPraticaClienti;