import React, { useState, useEffect } from 'react';
import axios from 'axios'
import AuthService from '../../components/utente/AuthService';
import ModalAssicurazioni from '../modali/ModelAssicurazioni';
import DefaultSpinner from '../DefaultSpinner';
import Button from '@material-ui/core/Button';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function Assicurazioni(props) {
    const [loading, setLoading] = useState(true)
    const [assicurazione, setAssicurazione] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [lassicurazioni, setAssicurazioni] = useState({all:[]})

    function saveNewAssicurazione(assicurazione){
        if(assicurazione.id){
            axios.put("/api/1.1/assicurazione",assicurazione,{headers:header}).then((res)=>{
                let assi = lassicurazioni.all
                for (let index = 0; index < assi.length; index++) {
                    if(assi[index].id === res.data.data.id){
                        assi[index]=res.data.data
                        break
                    }
                }
                setAssicurazioni({...lassicurazioni,all:assi})
                isOpenModal(false)
            }).catch(error=>{
                console.log(error)
                isOpenModal(false)
            })         
        }else{
            axios.post("/api/1.1/assicurazione",assicurazione,{headers:header}).then((res)=>{
                let assi = lassicurazioni.all
                assi.push(res.data.data)
                setAssicurazioni({...lassicurazioni,all:assi})
                isOpenModal(false)
            }).catch(error=>{
                console.log(error)
                isOpenModal(false)
            })
        }
    }

    function openModalNewAssicurazione(assicurazione){
        setAssicurazione(assicurazione)
        isOpenModal(true)
    }
    function deleteAssicurazione(id){
        axios.delete("/api/1.1/assicurazione/"+id,{headers:header}).then((res)=>{
            let assi = lassicurazioni.all
            assi.forEach((element,index,objects) => {   
                if(element.id === id){
                    objects.splice(index,1)
                }
            });
            setAssicurazioni({...lassicurazioni,all:assi})
            
        }).catch(error=>{
            console.log("errore")
        })
    }

    function isOpenModal(value){
        setOpenModal(!openModal)
    }

    useEffect(() => {
        if(!Auth.loggedIn()){
            window.location.href="/login"
        }
        axios.get("/api/1.1/assicurazione",{headers:header}).then((res)=>{
            setAssicurazioni({...lassicurazioni,all:res.data.data})
            setLoading(false)
        }).catch(error=>{
            console.log(error)
            setLoading(false)
        })
        
    },[])
    return (
        <div>
            {!loading ? 
            <>
            <h3><b style={{marginRight:"15px"}}>Assicurazioni</b> <Button size="small" type="submit" variant="contained" color="primary" onClick={()=>openModalNewAssicurazione(null)}><i className="fas fa-plus"></i> Aggiungi</Button> </h3>
            <div>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontWeight:"bold"}}>Nome</TableCell>
                                <TableCell style={{fontWeight:"bold"}}>Moltiplicatore</TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                                <TableCell style={{fontWeight:"bold"}}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lassicurazioni && lassicurazioni.all.map((ass,i)=>{
                                return(
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">{ass.nome}</TableCell>
                                        <TableCell>{ass.moltiplicatore}</TableCell>
                                        <TableCell><Button size="small" type="submit" variant="contained" color="primary" onClick={()=>{setAssicurazione(ass); setOpenModal(true)}}><i className="fas fa-folder-open"></i> Modifica</Button></TableCell>
                                        <TableCell><Button size="small" type="submit" variant="contained" color="secondary" style={{marginLeft:"15px"}} onClick={()=>{deleteAssicurazione(ass.id)}}><i className="fas fa-trash"></i> Cancella</Button></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>  
            <ModalAssicurazioni assicurazione={assicurazione} 
            saveNewAssicurazione={saveNewAssicurazione} 
            isOpenModal={openModal} closeModal={isOpenModal}/>
            </>
            :<DefaultSpinner></DefaultSpinner>}
        </div>
    );
}

export default Assicurazioni;