import React,{useState,useEffect} from 'react';
import { AppBar, Grid, Tab, Tabs } from '@material-ui/core';
import axios from 'axios'
import TabPanel from '../../commons/TabPanel';
import ListaPrivato from './ListaPrivato';
import ListaAzienda from './ListaAzienda';
import AuthService from '../utente/AuthService'

let Auth=new AuthService();
let header=Auth.getHeadersJWT();

function Liste(props) {
    const [value, setValue] = useState(0)
    const [usersList, setUsersList] = useState({list:[]})

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    function a11yProps(index) {
      return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
      };
    }
    useEffect(() => {
        axios.get('/api/1.1/user', {headers:header}).then(res=>{
            setUsersList({list:res.data.data})
        })
    }, [])
    
    return (
        <Grid item lg={12} md={12} sm={12} xs={12}> 
            <AppBar position="relative" color="default" style={{zIndex:"0"}} >
                <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                >
                    <Tab label={"IMPORTA PRIVATO"} {...a11yProps(0)} />
                    <Tab label={"IMPORTA AZIENDA"} {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <ListaPrivato usersList={usersList}></ListaPrivato>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ListaAzienda usersList={usersList}></ListaAzienda>
            </TabPanel>
  
        </Grid>
    );
}

export default Liste;