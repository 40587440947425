
import React, { useState } from 'react';
import {Modal,ModalBody,ModalFooter,ModalHeader} from 'reactstrap'
import Button from '@material-ui/core/Button';
import { Checkbox, TextField } from '@material-ui/core';

function ModalStateCliente(props) {
    const [nuovoStato,setNuovoStato] = useState(props.statoSelected)

    function onChangeValue(nome,valore){
      setNuovoStato({...nuovoStato, [nome]:valore})
    }
    function setNameValue(e){
      e.preventDefault()
      setNuovoStato({...nuovoStato,[e.target.name]:e.target.value})
    }
    function chiudiModal(){
      props.closeModal(false)
    }
    function saveNewState(e){
        e.preventDefault()
        props.saveNewState(nuovoStato)
    }

    return (
            <Modal isOpen={props.isOpenModal} className="modal-lg">
              <form onSubmit={(e)=>saveNewState(e)}>
                <ModalBody>
                  <ModalHeader>
                    <div style={{fontWeight:"bold",fontSize:"20px"}}>Nuovo Stato <small>({props.basegroup})</small></div>
                  </ModalHeader>
                  <div>
                    <div>
                      <TextField type="text" label="Nome" required name="name" value={nuovoStato.name} onChange={(e)=>{setNameValue(e)}} style={{width:"100%"}}/>
                    </div>
                    <div className="form-group-sm"> 
                      <Checkbox size="small" color="primary" defaultChecked={nuovoStato.iniziale} onClick={(e)=>{onChangeValue("iniziale",!nuovoStato.iniziale)}}/>
                      <label>Stato iniziale</label>
                    </div>
                    <div className="form-group-sm">
                      <Checkbox size="small" color="primary" defaultChecked={nuovoStato.ripetibile} onClick={(e)=>{onChangeValue("ripetibile",!nuovoStato.ripetibile)}}/>
                      <label>Ripetibile</label>
                    </div>
                    <div className="form-group-sm">
                      <Checkbox size="small" color="primary" defaultChecked={nuovoStato.inserimento_note} onClick={(e)=>{onChangeValue("inserimento_note",!nuovoStato.inserimento_note)}}/>
                      <label>Inserimento note</label>
                    </div>
                    <div className="form-group-sm">
                      <Checkbox size="small" color="primary" defaultChecked={nuovoStato.inserimento_alarm} onClick={(e)=>{onChangeValue("inserimento_alarm",!nuovoStato.inserimento_alarm)}}/>
                      <label>Alarm</label>
                    </div>
                    <div className="form-group-sm">
                      <Checkbox size="small" color="primary" defaultChecked={nuovoStato.invio_mail} onClick={(e)=>{onChangeValue("invio_mail",!nuovoStato.invio_mail)}}/>
                      <label>Invio mail</label>
                    </div>
                    <div className="form-group-sm">
                      <Checkbox size="small" color="primary" defaultChecked={nuovoStato.trasforma_in_pratica} onClick={(e)=>{onChangeValue("trasforma_in_pratica",!nuovoStato.trasforma_in_pratica)}}/>
                      <label>Trasforma in pratica</label>
                    </div>
                  </div>
                  <ModalFooter>
                    <Button size="small" type="submit" variant="contained" color="primary"> <i className="far fa-save"></i> Salva</Button>
                    <Button size="small" type="submit" variant="contained" color="primary" onClick={(e)=>{e.preventDefault();chiudiModal()}}> <i className="fas fa-times"></i> Chiudi</Button>
                  </ModalFooter>   
                </ModalBody>
            </form>
        </Modal>
    );
}

export default ModalStateCliente;